import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";
import FinalResult from "components/modules/ressources/portfolio/completed-projects/LogicielDeCaisse/FinalResult";
import HowDidWeProceed
  from "components/modules/ressources/portfolio/completed-projects/LogicielDeCaisse/HowDidWeProceed";
import WhatTheProject from "components/modules/ressources/portfolio/completed-projects/LogicielDeCaisse/WhatTheProject";
import WhoIsTheClient from "components/modules/ressources/portfolio/completed-projects/LogicielDeCaisse/WhoIsTheClient";
import React from "react";

const LogicielDeCaisse = () => {
  return (
    <>
      <Breadcrumbs />
      <WhatTheProject />
      <WhoIsTheClient />
      <HowDidWeProceed />
      <FinalResult />
    </>
  );
};

export default LogicielDeCaisse;