import React from 'react';
import { useAppContext } from 'providers/AppProvider';
import { Navbar } from 'react-bootstrap';
import logoSecondary from '../../../assets/img/ipercom/logo/logo_secundary.svg';
import logoSecondaryDark from '../../../assets/img/ipercom/logo/logo_secudary_dark.svg';
import logoLight from 'assets/img/ipercom/logo/logo_light.svg'; // Assuming this is the light logo
import logoDark from "assets/img/ipercom/logo/logo_dark.svg"; // Assuming this is the dark logo

import { Link } from 'react-router-dom';

interface NavbarBrandProps {
  version?: 'main' | 'secondary';
  width?: number;
  height?: number;
}

const NavbarBrand: React.FC<NavbarBrandProps> = ({
                                                   version = "main",
                                                   width = 230,
                                                   height = 30
                                                 }) => {
  const { config } = useAppContext(); // Access config from the context

  // Decide which logo to use based on the theme and version
  let logo;
  if (version === 'secondary') {
    logo = config.theme === 'dark' ? logoSecondaryDark : logoSecondary; // Always use the secondary logo if version is specified as 'secondary'
  } else {
    logo = config.theme === 'dark' ? logoDark : logoLight; // Use dark or light logo based on theme
  }

  return (
    <>
      <div className="navbar-logo">
        <Navbar.Brand as={Link} to="/">
          <div className="d-flex align-items-center">
            <img
              src={logo}
              alt="Logo"
              style={{
                width: `${width}px`,
                height: `${height}px`,
                maxWidth: `${width}px`,
                maxHeight: `${height}px`,
                minWidth: `${width}px`,
                minHeight: `${height}px`
              }}
            />
          </div>
        </Navbar.Brand>
      </div>
    </>
  );
};

export default NavbarBrand;
