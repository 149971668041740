import dossier from "assets/img/ipercom/ressources/portfolio/projet realisé/ipercloudimagedossier_15_11zon.webp";

const WhoIsTheClient = () => {
  return (
    <section className="container my-5">
      <div className="row">
        <div className="col-md-6 pb-2 pb-md-0 mb-4 mb-md-0 order-2">
          <div className="pe-lg-5 text-center">
            <img src={dossier} className="rounded-3" alt="Image" style={{ width: "56%" }} />
          </div>
        </div>
        <div className="col-md-6 order-1">
          <h2 className="mb-sm-4">Qui est le client ?</h2>
          <p className="d-md-none d-xl-block pb-2 pb-md-3 mb-3">
            Le client est une entreprise cherchant à améliorer ses opérations en adoptant une solution moderne et
            sécurisée pour le stockage et le partage de fichiers dans le cloud. Ils souhaitent également simplifier la
            collaboration à distance pour leurs équipes dispersées géographiquement.
          </p>
        </div>
      </div>
    </section>
  );
};

export default WhoIsTheClient;
