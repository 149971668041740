import React from "react";

const WorkFlow = () => {
  return (
    <section className="container mb-md-3 mb-xl-3">
      <h2 className="pb-2 pb-md-0 mb-4 mb-md-5">Work flow </h2>
      <div className="steps steps-sm steps-horizontal-md pb-2 pb-sm-3">
        <div className="step">
          <div className="step-number">
            <div className="step-number-inner">1</div>
          </div>
          <div className="step-body">
            <h3 className="h4 mb-3">La Commande</h3>
            <p className="mb-0">
              Optimisez la facturation avec une gestion précise des commandes.
              Réduisez les erreurs et les retards.
            </p>
          </div>
        </div>
        <div className="step">
          <div className="step-number">
            <div className="step-number-inner">2</div>
          </div>
          <div className="step-body">
            <h3 className="h4 mb-3">Le Décompte</h3>
            <p className="mb-0">
              Le décompte impacte la facturation. Simplifiez-le pour une gestion
              financière fluide et efficace.
            </p>
          </div>
        </div>
        <div className="step">
          <div className="step-number">
            <div className="step-number-inner">3</div>
          </div>
          <div className="step-body">
            <h3 className="h4 mb-3">La Facture</h3>
            <p className="mb-0">
              La facturation est essentielle à toute entreprise. Simplifiez
              votre processus pour une gestion financière efficace.
            </p>
          </div>
        </div>
        <div className="step">
          <div className="step-number">
            <div className="step-number-inner">4</div>
          </div>
          <div className="step-body">
            <h3 className="h4 mb-3">Le Règlement</h3>
            <p className="mb-0">
              Optimisez le règlement de vos factures pour une gestion financière
              fluide et transparente.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WorkFlow;
