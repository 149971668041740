import React from 'react';
import ampoule from 'assets/img/ipercom/a-propos/commitmentAndValues/ampoule.webp';
import handpaper from 'assets/img/ipercom/a-propos/commitmentAndValues/hand and paper.webp';
import handshake from 'assets/img/ipercom/a-propos/commitmentAndValues/hand shaking.webp';
import manwomen from 'assets/img/ipercom/a-propos/commitmentAndValues/man and women.webp';
import pen from 'assets/img/ipercom/a-propos/commitmentAndValues/pen.webp';
import peoplehand from 'assets/img/ipercom/a-propos/commitmentAndValues/people hand.webp';

const items = [
  {
    image: manwomen,
    title: "Intégrité et Honnêteté",
    text: "L'intégrité et l'honnêteté sont au cœur de tout ce que nous faisons. Nous agissons toujours avec transparence et éthique, en veillant à être dignes de confiance pour nos clients, nos employés et nos partenaires."
  },
  {
    image: ampoule,
    title: "Innovation et Excellence",
    text: "Nous sommes passionnés par l'innovation et la recherche constante d'excellence. ipercom s'engage à développer des solutions avant-gardistes pour répondre aux défis de demain, tout en maintenant les plus hauts standards de qualité dans chaque projet que nous entreprenons."
  },
  {
    image: handpaper,
    title: "Responsabilité et Durabilité",
    text: "Conscients de notre impact sur le monde, nous prenons nos responsabilités au sérieux. Cela implique non seulement une approche durable et respectueuse de l'environnement dans nos opérations, mais aussi un engagement envers le bien-être des communautés dans lesquelles nous opérons."
  },
  {
    image: handshake,
    title: "Respect et Diversité",
    text: "Le respect mutuel et la valorisation de la diversité sont fondamentaux chez ipercom. Nous croyons que chaque individu apporte une perspective unique qui enrichit notre culture d'entreprise et stimule l'innovation."
  },
  {
    image: pen,
    title: "Engagement Communautaire",
    text: "Notre entreprise est déterminée à faire une différence positive dans la société. Par le biais de nos initiatives de bénévolat et de nos programmes de soutien communautaire, nous nous engageons à contribuer au bien-être des personnes autour de nous."
  },
  {
    image: peoplehand,
    title: "Rejoignez Notre Voyage",
    text: "ipercom est plus qu'une simple entreprise ; c'est une famille de personnes passionnées et engagées. Si nos valeurs résonnent avec vous, nous vous invitons à en apprendre davantage sur notre équipe, nos projets, et comment vous pouvez faire partie de notre voyage."
  }
];

const OurCompanyValues = () => {
  return (
    <>
      <section className="container mt-4">
        <div className="d-md-flex align-items-start justify-content-between text-md-start text-center pt-1 pt-sm-3 pt-md-4 mb-5">
          <h2 className="mb-md-0">Les Valeurs de Notre Entreprise</h2>
        </div>
        <div className="row row-cols-1 row-cols-md-3 g-4 pb-lg-2 pb-xl-3">
          {items.map((item, index) => (
            <div className="col" key={index}>
              <div className="card card-hover border-0 bg-transparent">
                <img
                  src={item.image}
                  className="card-img-top rounded-3"
                  alt="img"
                  style={{ objectFit: 'cover', height: '200px' }}
                />
                <div className="card-body pt-3 ps-0">
                  <h3 className="d-inline-flex align-items-start">
                    {item.title}
                  </h3>
                  <p className="fs-sm text-body mb-0">{item.text}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default OurCompanyValues;
