import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";
import DescriptionBusiness from "components/modules/a-propos/carriere/business/DescriptionBusiness";
import Head from "components/modules/a-propos/carriere/business/Head";
import React from "react";

const PostSheetBusiness = () => {
  return (
    <>
      <Head />
      <Breadcrumbs />
      <DescriptionBusiness />
    </>
  );
};

export default PostSheetBusiness;
