import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import axios from "axios";

interface ModalCandidatureProps {
  showModal: boolean;
  handleClose: () => void;
}

const ModalCandidature: React.FC<ModalCandidatureProps> = ({ showModal, handleClose }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    siretNumber: "",
    companyName: "",
    idProductService: "",
    additionalDetails: ""
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);

    // Convert idProductService to an integer
    const productServiceId = parseInt(formData.idProductService, 10);

    // Prepare request payload
    const requestData = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      siretNumber: formData.siretNumber,
      companyName: formData.companyName,
      idProductService: productServiceId,
      additionalDetails: formData.additionalDetails
    };

    try {
      await axios.post("https://rct-backend.ipercom.io/api/CSoonRetailer", requestData, {
        headers: {
          "Content-Type": "application/json",
          "Accept": "text/plain"
        }
      });
      setIsSubmitted(true);
    } catch (error) {
      setError("Erreur lors de l'envoi du formulaire. Veuillez réessayer.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleModalClose = () => {
    setIsSubmitted(false);
    handleClose();
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      siretNumber: "",
      companyName: "",
      idProductService: "",
      additionalDetails: ""
    });
  };

  return (
    <Modal show={showModal} onHide={handleModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>Candidature</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <div className="alert alert-danger">{error}</div>}
        {!isSubmitted ? (
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="fn-revendeur">Prénom</Form.Label>
              <Form.Control
                type="text"
                id="fn-revendeur"
                required
                value={formData.firstName}
                onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
              />
              <Form.Control.Feedback type="invalid">Veuillez saisir votre prénom !</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="ln-revendeur">Nom de famille</Form.Label>
              <Form.Control
                type="text"
                id="ln-revendeur"
                required
                value={formData.lastName}
                onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
              />
              <Form.Control.Feedback type="invalid">Veuillez saisir votre nom de famille !</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="email-revendeur">Adresse e-mail</Form.Label>
              <Form.Control
                type="email"
                id="email-revendeur"
                required
                value={formData.email}
                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              />
              <Form.Control.Feedback type="invalid">Veuillez fournir une adresse e-mail valide !</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="need-revendeur">Candidature</Form.Label>
              <Form.Select
                id="need-revendeur"
                required
                value={formData.idProductService}
                onChange={(e) => setFormData({ ...formData, idProductService: e.target.value })}
              >
                <option value="">Choisissez une candidature</option>
                <option value="1">Développeur full stack /CDI</option>
                <option value="2">Dev ops /CDI</option>
                <option value="3">Chargé d'affaires /CDI</option>
                <option value="3">Candidature spontanée</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">Veuillez choisir une candidature dans la liste
                !</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="comment-candidature">Détails supplémentaires</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                id="comment-candidature"
                value={formData.additionalDetails}
                onChange={(e) => setFormData({ ...formData, additionalDetails: e.target.value })}
              />
            </Form.Group>
            <Button type="submit" variant="primary" size="lg" className="w-100" disabled={isSubmitting}>
              {isSubmitting ? "En cours..." : "Soumettre"}
            </Button>
          </Form>
        ) : (
          <div className="text-center">
            <h4>Merci pour votre intérêt!</h4>
            <p>Votre demande a été envoyée avec succès. Nous vous contacterons sous peu.</p>
            <Button variant="primary" onClick={handleModalClose}>
              Fermer
            </Button>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ModalCandidature;
