import result from "assets/img/ipercom/ressources/portfolio/projet realisé/result_16_11zon.webp";

const FinalResult = () => {
  return (
    <section className="container my-5">
      <div className="row">
        <div className="col-md-6 pb-2 pb-md-0 mb-4 mb-md-0 order-2">
          <div className="pe-lg-5 text-center">
            <img src={result} className="rounded-3" alt="Image" />
          </div>
        </div>
        <div className="col-md-6 order-1">
          <h2 className="mb-sm-4">Résultat final</h2>
          <p className="d-md-none d-xl-block pb-2 pb-md-3 mb-3">
            Le résultat final est un chef-d'œuvre digital qui dépasse les
            attentes à tous les niveaux. Le nouveau site web incarne
            parfaitement l'identité de la marque, tout en offrant une expérience
            utilisateur engageante et intuitive. Grâce à sa conception
            responsive, il garantit une accessibilité optimale sur tous les
            appareils, du bureau aux smartphones. <br></br>
            <br></br>De plus, notre approche SEO a permis d'augmenter la
            visibilité en ligne du client, attirant ainsi un trafic qualifié et
            générant des opportunités de croissance significatives. Le projet a
            été salué par le client et les utilisateurs, renforçant ainsi notre
            réputation en tant que partenaire de confiance pour la
            transformation digitale.
          </p>
        </div>
      </div>
    </section>
  );
};

export default FinalResult;
