import React from 'react';
import man from "assets/img/ipercom/services/ERP/collab/Mask group3_32_11zon.webp";
import manmanman from "assets/img/ipercom/services/ERP/collab/Mask group2.svg";
import manman from "assets/img/ipercom/services/ERP/collab/Mask group1.svg";
import wowomen from "assets/img/ipercom/services/ERP/collab/Mask group4_33_11zon.webp";
import wowomenmen from "assets/img/ipercom/services/ERP/collab/heleneAvatar_50_11zon.webp";

const Collaborater = () => {
  const collaborators = [
    {
      title: "Stéphane Degouve, Président",
      avatar: man,
      job: "Il est chargé de superviser les opérations, définir les objectifs stratégiques et de représenter l'entreprise auprès des parties prenantes. Ses responsabilités peuvent varier mais consistent généralement à prendre des décisions clés et à favoriser la croissance à long terme."
    },
    {
      title: "Hélène, Directrice administrative et financière",
      avatar: wowomenmen,
      job: "Elle supervise les activités administratives et financières. Ses responsabilités comprennent la gestion des opérations financières, la conformité réglementaire et l'optimisation des processus administratifs. En tant que membre clé de l'équipe de direction, elle participe à la prise de décisions stratégiques pour assurer la stabilité financière et la croissance de l'entreprise."
    },
    {
      title: "Benoit LEFEVRE, Directeur commercial",
      avatar: manmanman,
      job: "Il est responsable de la définition et de la mise en œuvre de la stratégie commerciale de l’entreprise. Il ou elle supervise les activités de vente, développe des relations clients durables, et veille à l’atteinte des objectifs de vente. Ce rôle implique une collaboration étroite avec les équipes de marketing, de produit, et de service à la clientèle pour garantir la satisfaction et la fidélisation des clients.il est chargé aussi du recrutement de sa force de vente pour les revendeurs."
    },
    {
      title: "Laura, Assistante des Ventes",
      avatar: wowomen,
      job: "Elle est chargée de fournir un soutien administratif en gérant la communication avec les clients, en préparant des documents commerciaux, en saisissant des commandes et en coordonnant les activités liées aux ventes. Elle collabore étroitement avec les représentants commerciaux pour garantir la satisfaction des clients et atteindre les objectifs de vente."
    },
    {
      title: "Ingénieur DevOps Niveau 2",
      avatar: manmanman,
      job: "Il est spécialisé dans l'architecture de réseau et sécurité certifié AWS et AZURE. Il possède une expertise avancée dans l'automatisation des processus de déploiement logiciel. Il est également capable de concevoir, mettre en œuvre et maintenir des pipelines d'intégration continue. Son rôle implique de travailler en collaboration avec les équipes de développement et d'exploitation pour améliorer l'efficacité et la qualité des logiciels."
    },
    {
      title: "Ingénieur VOIP Niveau 2",
      avatar: manmanman,
      job: "Il possède une expertise avancée dans la conception, la mise en œuvre et la maintenance des systèmes de téléphonie sur IP (VoIP). Il est spécialisé dans les outils Asterisk. Sa fonction comprend la résolution de problèmes complexes, la configuration avancée des équipements et logiciels VoIP, la gestion des performances et de la sécurité des systèmes, ainsi que la supervision des projets de déploiement VoIP."
    },
    {
      title: "Valentin, Développeur Full Stack",
      avatar: manmanman,
      job: "Il travaille sur tous les aspects d'un projet de développement web ou logiciel, incluant à la fois le front-end et le back-end. Son rôle exige des compétences dans plusieurs langages de programmation et frameworks pour assurer la création d'applications complètes et fonctionnelles, de la conception à la mise en production. Il est spécialisé sur les plateformes Node.js, ReactJS, PHP et HTML."
    },
    {
      title: "Damien, Développeur Full Stack",
      avatar: manmanman,
      job: "Il travaille sur tous les aspects d'un projet de développement web ou logiciel, incluant à la fois le front-end et le back-end. Son rôle exige des compétences dans plusieurs langages de programmation et frameworks pour assurer la création d'applications complètes et fonctionnelles, de la conception à la mise en production. Il est spécialisé sur les plateformes Node.js, ReactJS, PHP et HTML."
    },
  ];

  return (
    <section className="container mt-4">
      <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 gx-3 gx-md-4 mt-n2 mt-sm-0">
        {collaborators.map((collaborator, index) => (
          <div className="col mb-5" key={index}>
            <article className="card h-150 border-0 shadow-sm">
              <img
                src={collaborator.avatar}
                width={85}
                alt={`Fiche de Poste : ${collaborator.title}`}
              />
              <div className="card-body">
                <h2 className="h4">{collaborator.title}</h2>
              </div>
              <div className="card-footer" style={{ marginTop: "-1rem" }}>
                <p>
                  <span className="fs-sm text-muted">{collaborator.job}</span>
                </p>
              </div>
            </article>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Collaborater;
