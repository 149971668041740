import React from "react";
import Head from "components/modules/ressources/blog/Novelties/Concevoir un ERP Revolutionnaire/Head";
import Paragraph from "components/modules/ressources/blog/Novelties/Concevoir un ERP Revolutionnaire/Paragraph";
import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";

const DesignRevolutionaryERP = () => {
  return (
    <>
      <Breadcrumbs />
      <Head />
      <Paragraph />
    </>
  );
};

export default DesignRevolutionaryERP;
