import React from 'react';
import { Accordion } from 'react-bootstrap';

// Accordion component
const List = () => {
  return (
    <>
      <section className="container">
        <div className="bg-secondary rounded-3 py-5 px-3 px-sm-4 px-md-0">
          <div className="row justify-content-center pb-lg-4 pb-xl-5">
            <div className="col-xl-8 col-lg-9 col-md-10 pb-md-2">
              <Accordion>
                <Accordion.Item
                  className="item border-0 rounded-3 shadow-sm mb-3"
                  eventKey="0"
                >
                  <Accordion.Header>
                    Sécurité de Premier Plan :
                  </Accordion.Header>
                  <Accordion.Body>
                    {" "}
                    La sécurité de vos données est notre priorité absolue.
                    Héberger votre solution sur AWS signifie bénéficier des
                    protocoles de sécurité les plus stricts, des audits
                    réguliers et des mesures de conformité qui protègent vos
                    informations contre les menaces numériques. Nos équipes
                    travaillent en étroite collaboration avec AWS pour garantir
                    que votre solution bénéficie des dernières technologies de
                    sécurité.
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item
                  className="item border-0 rounded-3 shadow-sm mb-3"
                  eventKey="1"
                >
                  <Accordion.Header>
                    Réduction des Coûts et Flexibilité Financière :
                  </Accordion.Header>
                  <Accordion.Body>
                    En optimisant l'utilisation des ressources cloud, nous
                    aidons nos clients à réduire significativement leurs coûts
                    d'infrastructure IT. Le modèle de paiement à l'utilisation
                    d'AWS permet à nos clients de payer uniquement pour les
                    ressources qu'ils consomment, offrant une flexibilité
                    financière sans précédent.
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item
                  className="item border-0 rounded-3 shadow-sm mb-3"
                  eventKey="2"
                >
                  <Accordion.Header>Expertise AWS :</Accordion.Header>
                  <Accordion.Body>
                    Notre équipe d'experts AWS est formée aux meilleures
                    pratiques et aux dernières innovations technologiques
                    proposées par AWS. Nous offrons une gestion complète de
                    l'hébergement, de la configuration initiale à la maintenance
                    continue, en passant par l'optimisation des performances.
                    Cela vous permet de vous concentrer sur votre cœur de
                    métier, tout en nous laissant prendre soin de votre
                    infrastructure IT.
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item
                  className="item border-0 rounded-3 shadow-sm mb-3"
                  eventKey="3"
                >
                  <Accordion.Header>
                    Engagement pour l'Excellence :
                  </Accordion.Header>
                  <Accordion.Body>
                    {" "}
                    Chez ipercom, nous nous engageons à fournir des solutions
                    d'hébergement qui non seulement exploitent la puissance et
                    la flexibilité d'AWS mais sont également personnalisées pour
                    répondre spécifiquement aux besoins de votre entreprise.
                    Notre objectif est d'assurer que votre solution est toujours
                    disponible, sécurisée et performante..
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item
                  className="item border-0 rounded-3 shadow-sm mb-3"
                  eventKey="4"
                >
                  <Accordion.Header>
                    Découvrez l'Hébergement AWS avec ipercom :
                  </Accordion.Header>

                  <Accordion.Body>
                    {" "}
                    Transformez votre façon de faire des affaires avec
                    l'hébergement de solutions sur AWS par ipercom.
                    Contactez-nous aujourd'hui pour en savoir plus sur comment
                    nous pouvons vous aider à tirer le meilleur parti du cloud.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item
                  className="item border-0 rounded-3 shadow-sm mb-3"
                  eventKey="5"
                >
                  <Accordion.Header>
                    Engagez-vous avec ipercom :
                  </Accordion.Header>
                  <Accordion.Body>
                    {" "}
                    Choisir ipercom, c'est choisir un partenaire technologique
                    qui comprend l'importance de la qualité, de l'innovation et
                    de la personnalisation. Nous sommes dévoués à fournir des
                    solutions qui non seulement répondent aux défis
                    d'aujourd'hui mais vous préparent également pour les
                    opportunités de demain. Découvrez comment nos services
                    peuvent transformer votre entreprise. Contactez-nous pour une consultation personnalisée et voyez ce
                    qu'ipercom peut faire pour vous.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default List;
