import React from "react";
import { Col, Row } from "react-bootstrap";

const Avantages = () => {
  return (
    <>
      {/* Features (Icon boxes) */}
      <section className="container mb-5 pb-5 mt-3">
        <h2 className="text-center pt-1 pt-xl-3 mb-lg-4">
          Avantages pour tous les secteurs
        </h2>
        <Row>
          {/* Item */}
          <Col lg={3}>
            <div className="card h-100 card-body card-hover mx-2">
              <i
                className="bx bx-rocket display-5 fw-normal card-icon"
                style={{ color: "#b4b7c9" }}
              />
              <h3 className="h5 pt-3 pb-1 mb-2">
                Interconnectivité des systèmes
              </h3>
              <p className="mb-0">
                Maximisez l'efficacité de votre entreprise avec une
                interconnectivité système fluide et sécurisée. Simplifiez les
                processus et améliorez la productivité.{" "}
              </p>
            </div>
          </Col>
          {/* Item */}
          <Col lg={3}>
            <div className="card h-100 card-body card-hover mx-2">
              <i
                className="bx bx-like display-5 fw-normal card-icon"
                style={{ color: "#b4b7c9" }}
              />
              <h3 className="h5 pt-3 pb-1 mb-2">
                Réutilisation des fonctionnalités
              </h3>
              <p className="mb-0">
                Réutilisez les fonctionnalités éprouvées pour accélérer le
                développement et réduire les coûts. Optimisez vos ressources et
                gagnez en efficacité.{" "}
              </p>
            </div>
          </Col>
          {/* Item */}
          <Col lg={3}>
            <div className="card h-100 card-body card-hover mx-2">
              <i
                className="bx bx-time-five display-5 fw-normal card-icon"
                style={{ color: "#b4b7c9" }}
              />
              <h3 className="h5 pt-3 pb-1 mb-2">
                Facilitation du développement
              </h3>
              <p className="mb-0">
                Simplifiez le processus de développement avec des outils
                intuitifs et des méthodes efficaces. Boostez la productivité de
                votre équipe.{" "}
              </p>
            </div>
          </Col>
          {/* Item */}
          <Col lg={3}>
            <div className="card h-100 card-body card-hover mx-2">
              <i
                className="bx bx-shield display-5 fw-normal card-icon"
                style={{ color: "#b4b7c9" }}
              />
              <h3 className="h5 pt-3 pb-1 mb-2">Sécurité renforcée avancée</h3>
              <p className="mb-0">
                Protégez votre système avec une sécurité avancée et renforcée.
                Prévenez les cybermenaces et les attaques potentielles grâce à
                des solutions de sécurité robustes.
              </p>
            </div>
          </Col>
        </Row>
      </section>
    </>
  );
};

export default Avantages;
