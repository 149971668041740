import mobile from "assets/img/ipercom/services/Solution proposes/developpement mobile/mobile-app.svg";
import screen from "assets/img/ipercom/icons/screenOurCrm.svg";
import computer from "assets/img/ipercom/icons/computerOurCrm.webp";
import lauch from "assets/img/ipercom/services/ERP/company/icons/launch.svg";
import dollars from "assets/img/ipercom/services/logiciel-de-facturation/informatique/dollars.png";
import eyes from "assets/img/ipercom/services/logiciel-de-facturation/informatique/eyes.png";

const Boost = () => {
  return (
    <>
      {/* Services */}
      <section className="bg-secondary">
        <div
          className="d-none d-lg-block"
          style={{ marginTop: "-10px", paddingTop: 60 }}
        />
        <div className="container pb-4 pt-3">
          <h2 className="text-center text-md-start mb-lg-4 pt-1 pt-md-4">
            Boostez votre entreprise informatique grâce à l'intégration d'un
            système ERP
          </h2>
          <div className="col-md-8 text-center text-md-start">
            <p className="fs-md text-muted mb-md-3">
              Optimisez votre entreprise informatique en intégrant un système
              ERP. Gérez efficacement vos opérations, améliorez la productivité
              et prenez des décisions éclairées pour la croissance de votre
              entreprise.
            </p>
          </div>
          <div className="row row-cols-1 row-cols-md-2">
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <p
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 me-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={mobile}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="d-inline-flex align-items-center">
                    Établir et personnaliser ma stratégie de tarification
                    commerciale
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    ipercom offre une solution robuste pour la gestion des
                    licences informatiques, incluant l'achat et la revente, avec
                    prise en compte du prorata. Il permet également la gestion
                    des achats fournisseurs et de la facturation client sur une
                    même interface. Vous pouvez gérer vos contrats de ticketing
                    et d'hébergement cloud.
                  </p>
                </div>
              </p>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <p
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={lauch}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="d-inline-flex align-items-center">
                    Optimiser le processus de commande à partir des catalogues
                    fournisseurs
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    ipercom a développé une liaison EDI entre son ERP et les
                    catalogues des fournisseurs majeurs pour simplifier la
                    création de commandes. Cela garantit également une base
                    d'articles toujours actualisée dans artis.net.
                  </p>
                </div>
              </p>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <p
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={computer}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="d-inline-flex align-items-center">
                    Optimiser la supervision du parc informatique de mes clients
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    ipercom se lie à des solutions RMM de pointe telles que
                    Connectwise, Datto, N-able, OCS Inventory, RG System, et
                    ServiceNav, facilitant l'automatisation des alertes et la
                    gestion de l’inventaire via ERP. Cette intégration vous aide
                    à minimiser les délais d’intervention et à optimiser les
                    coûts sur le long terme, améliorant ainsi l'efficacité
                    opérationnelle.
                  </p>
                </div>
              </p>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <p
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={screen}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="d-inline-flex align-items-center">
                    Évaluer la rentabilité des clients de manière stratégique
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    ipercom intègre un module de suivi de rentabilité pour
                    évaluer les performances des clients, marques, machines et
                    contrats, incluant les revenus, la marge et les taux de
                    dépassement.
                  </p>
                </div>
              </p>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <p
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={dollars}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="d-inline-flex align-items-center">
                    Optimisez la gestion de votre portefeuille d'affaires avec
                    une surveillance proactive
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    ipercom ERP optimise l'efficacité des commerciaux en offrant
                    un outil de gestion des ventes alimenté par la Business
                    Intelligence. Accédez à des indicateurs clés pour surveiller
                    les performances de vos équipes de vente.
                  </p>
                </div>
              </p>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <p
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={eyes}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="d-inline-flex align-items-center">
                    Surveiller de près mon portefeuille d'affaires
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    L'application ERP ipercom stimule l'efficacité des
                    commerciaux en offrant un outil de suivi des ventes,
                    alimenté par une technologie de Business Intelligence.
                    Accédez à des données cruciales comme les rendez-vous, les
                    contrats, et les performances des vendeurs.
                  </p>
                </div>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Boost;
