import { Col, Row } from "react-bootstrap";
import imageBackground from "../../assets/img/account/signin-bg.webp";
import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";

const SignInForm = () => {
  return (
    <>
      {/*<Header />*/}
      <section className="position-relative mt-n5 pb-4">
        <Row className="justify-content-center">
          <Col lg={6}>
            <div className="container d-flex flex-wrap justify-content-center justify-content-xl-start h-100 pt-5">
              <div
                className="w-100 align-self-end pt-1 pt-md-4 pb-4 m-auto"
                style={{ maxWidth: 526 }}
              >
                <Breadcrumbs />
                <h1 className="text-center text-xl-start">Créer un compte</h1>
                <p className="text-center text-xl-start pb-3 mb-3">
                  Vous avez déjà un compte ?{" "}
                  <a href="login">Connectez-vous ici.</a>
                </p>
                <form className="needs-validation">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="position-relative mb-4">
                        <label htmlFor="name" className="form-label fs-base">
                          Nom complet
                        </label>
                        <input
                          type="text"
                          id="name"
                          className="form-control form-control-lg"
                        />
                        <div className="invalid-feedback position-absolute start-0 top-100">
                          Veuillez saisir votre nom !
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="position-relative mb-4">
                        <label htmlFor="email" className="form-label fs-base">
                          Email
                        </label>
                        <input
                          type="email"
                          id="email"
                          className="form-control form-control-lg"
                        />
                        <div className="invalid-feedback position-absolute start-0 top-100">
                          Veuillez saisir une adresse e-mail valide !
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="position-relative mb-4">
                        <label htmlFor="name" className="form-label fs-base">
                          Nom de l'entreprise
                        </label>
                        <input
                          type="text"
                          id="name"
                          className="form-control form-control-lg"
                        />
                        <div className="invalid-feedback position-absolute start-0 top-100">
                          Veuillez saisir le nom de l'entreprise !
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="position-relative mb-4">
                        <label htmlFor="name" className="form-label fs-base">
                          SIRET
                        </label>
                        <input
                          type="text"
                          id="name"
                          className="form-control form-control-lg"
                        />
                        <div className="invalid-feedback position-absolute start-0 top-100">
                          Veuillez saisir le SIRET !
                        </div>
                      </div>
                    </div>

                    <div className="col-12 mb-4">
                      <label htmlFor="password" className="form-label fs-base">
                        Mot de passe
                      </label>
                      <div className="password-toggle">
                        <input
                          type="password"
                          id="password"
                          className="form-control form-control-lg"
                        />
                        <label
                          className="password-toggle-btn"
                          aria-label="Afficher/masquer le mot de passe"
                        >
                          <input
                            className="password-toggle-check"
                            type="checkbox"
                          />
                          <span className="password-toggle-indicator" />
                        </label>
                        <div className="invalid-feedback position-absolute start-0 top-100">
                          Veuillez entrer un mot de passe !
                        </div>
                      </div>
                    </div>
                    <div className="col-12 mb-4">
                      <label
                        htmlFor="password-confirm"
                        className="form-label fs-base"
                      >
                        Confirmer le mot de passe
                      </label>
                      <div className="password-toggle">
                        <input
                          type="password"
                          id="password-confirm"
                          className="form-control form-control-lg"
                        />
                        <label
                          className="password-toggle-btn"
                          aria-label="Afficher/masquer le mot de passe"
                        >
                          <input
                            className="password-toggle-check"
                            type="checkbox"
                          />
                          <span className="password-toggle-indicator" />
                        </label>
                        <div className="invalid-feedback position-absolute start-0 top-100">
                          Veuillez entrer un mot de passe !
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        id="terms"
                        className="form-check-input"
                      />
                      <label
                        htmlFor="terms"
                        className="form-check-label fs-base"
                      >
                        J'accepte les <a href="/cgu">conditions générales</a>
                      </label>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary shadow-primary btn-lg w-100"
                    disabled={true}
                  >
                    S'inscrire
                  </button>
                </form>
                <hr className="my-4" />
                <h6 className="text-center mb-4">
                  Ou inscrivez-vous avec votre réseau social
                </h6>
                <div className="row row-cols-1 row-cols-sm-2">
                  <div className="col mb-3">
                    <a
                      href=""
                      className="btn btn-icon btn-secondary btn-google btn-lg w-100"
                      style={{pointerEvents:'none'}}  
                    >
                      <i className="bx bxl-google fs-xl me-2" />
                      Google
                    </a>
                  </div>
                  <div className="col mb-3">
                    <a
                      href=""
                      className="btn btn-icon btn-secondary btn-facebook btn-lg w-100"
                    style={{pointerEvents:'none'}}  
                    >
                    
                      <i className="bx bxl-facebook fs-xl me-2" />
                      Facebook
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={6} className="align-self-end pt-1 pt-md-4 pb-4 order-md-2">
            <img
              src={imageBackground}
              alt="Se connecter"
              className="img-fluid"
            />
          </Col>
        </Row>
      </section>
    </>
  );
};

export default SignInForm;
