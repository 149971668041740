import React from "react";

const BecomeReseller = () => {
  return (
    <>
      <section className="position-relative bg-secondary">
        <div className="container position-relative zindex-2">
          {/* Fil d'Ariane */}
          <nav className="pt-lg-4 pb-3 mb-2 mb-sm-3" aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <a href="http://82.223.216.1:2025/">
                  <i className="bx bx-home-alt fs-lg me-1" />
                  Accueil
                </a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Devenir revendeur
              </li>
            </ol>
          </nav>
          <div className="row">
            <div className="col-xl-8 offset-xl-2">
              <div className="card border-light shadow-lg py-3 p-sm-4 p-md-5">
                <div
                  className="bg-dark position-absolute top-0 start-0 w-100 h-100 rounded-3 d-none d-dark-mode-block" />
                <div className="card-body position-relative zindex-2">
                  <h1 className="card-title pb-3 mb-4">Devenir revendeur</h1>
                  <form className="row g-4 needs-validation">
                    {/* Prénom et Nom */}
                    <div className="col-md-6">
                      <label htmlFor="fn" className="form-label fs-base">
                        Prénom
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="fn"
                        required
                      />
                      <div className="invalid-feedback">
                        Veuillez entrer votre prénom !
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="ln" className="form-label fs-base">
                        Nom
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="ln"
                        required
                      />
                      <div className="invalid-feedback">
                        Veuillez entrer votre nom !
                      </div>
                    </div>
                    {/* Adresse et Ville */}
                    <div className="col-md-6">
                      <label htmlFor="adresse" className="form-label fs-base">
                        Adresse
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="adresse"
                        name="adresse"
                        required
                      />
                      <div className="invalid-feedback">Veuillez entrer votre adresse !</div>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="ville" className="form-label fs-base">
                        Ville
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="ville"
                        required
                      />
                      <div className="invalid-feedback">Veuillez entrer une ville !</div>
                    </div>
                    {/* Code Postal, Fonction */}
                    <div className="col-md-6">
                      <label htmlFor="codePostal" className="form-label fs-base">
                        Code Postal
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="codePostal"
                        name="codePostal"
                        required
                      />
                      <div className="invalid-feedback">Veuillez entrer votre code postal !</div>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="fonction" className="form-label fs-base">
                        Fonction
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="fonction"
                        name="fonction"
                        required
                      />
                      <div className="invalid-feedback">Veuillez entrer votre fonction !</div>
                    </div>
                    {/* Société et Siret */}
                    <div className="col-md-6">
                      <label htmlFor="societe" className="form-label fs-base">
                        Société
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="societe"
                        name="societe"
                        required
                      />
                      <div className="invalid-feedback">Veuillez entrer votre nom de société !</div>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="siret" className="form-label fs-base">
                        Siret
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="siret"
                        name="siret"
                        required
                      />
                      <div className="invalid-feedback">Veuillez entrer votre Siret !</div>
                    </div>
                    {/* Email et Téléphone */}
                    <div className="col-md-6">
                      <label htmlFor="email" className="form-label fs-base">
                        Adresse e-mail
                      </label>
                      <input
                        type="email"
                        className="form-control form-control-lg"
                        id="email"
                        required
                      />
                      <div className="invalid-feedback">
                        Veuillez fournir une adresse e-mail valide !
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="telephone" className="form-label fs-base">
                        Numéro de téléphone
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="telephone"
                        name="telephone"
                        required
                      />
                      <div className="invalid-feedback">Veuillez entrer votre numéro de téléphone !</div>
                    </div>
                    {/* Produits/Services */}
                    <div className="col-12">
                      <label htmlFor="need" className="form-label fs-base">
                        Produit/Service
                      </label>
                      <div className="form-check">
                        <input type="checkbox" className="form-check-input" id="iper-X"
                               value="iper-X solution de téléphonie IPBX" />
                        <label className="form-check-label" htmlFor="iper-X">iper-X solution de téléphonie IPBX</label>
                      </div>
                      <div className="form-check">
                        <input type="checkbox" className="form-check-input" id="i-pos"
                               value="i-pos logiciel de caisse" />
                        <label className="form-check-label" htmlFor="i-pos">i-pos logiciel de caisse</label>
                      </div>
                      <div className="form-check">
                        <input type="checkbox" className="form-check-input" id="CRM" value="CRM" />
                        <label className="form-check-label" htmlFor="CRM">CRM</label>
                      </div>
                      <div className="form-check">
                        <input type="checkbox" className="form-check-input" id="Devis" value="Devis" />
                        <label className="form-check-label" htmlFor="Devis">Devis</label>
                      </div>
                      <div className="form-check">
                        <input type="checkbox" className="form-check-input" id="Facturation" value="Facturation" />
                        <label className="form-check-label" htmlFor="Facturation">Facturation</label>
                      </div>
                      <div className="form-check">
                        <input type="checkbox" className="form-check-input" id="ERP" value="ERP" />
                        <label className="form-check-label" htmlFor="ERP">ERP</label>
                      </div>
                      <div className="form-check">
                        <input type="checkbox" className="form-check-input" id="iper-Cloud" value="iper-Cloud" />
                        <label className="form-check-label" htmlFor="iper-Cloud">iper-Cloud</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <label htmlFor="comment" className="form-label fs-base">
                        Détails supplémentaires
                      </label>
                      <textarea
                        id="comment"
                        name="comment"
                        rows={5}
                        cols={33}
                        className="form-control form-control-lg"
                      />
                    </div>
                    <div className="col-12 pt-2 pt-sm-3">
                      <button
                        type="submit"
                        className="btn btn-lg btn-primary w-100 w-sm-auto">
                        Soumettre
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="position-absolute bottom-0 start-0 w-100 bg-light"
          style={{ height: "8rem" }}
        />
      </section>
    </>
  );
};

export default BecomeReseller;
