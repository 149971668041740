import { currencyFormat } from 'helpers/utils';
import React from 'react';

const OrderSummaryDetails = () => {
  return (
    <div style={{ fontSize: "14px" }}>
      <div className="d-flex justify-content-between">
        <p className="text-900 fw-semi-bold">
          Total des articles hors taxe :
        </p>
        <p className="text-1100 fw-semi-bold">{currencyFormat(691)}</p>
      </div>
      <div className="d-flex justify-content-between lh-1">
        <p className="text-900 fw-semi-bold">Réduction :</p>
        <p className="text-danger fw-semi-bold">-{currencyFormat(59)}</p>
      </div>
      <div className="d-flex justify-content-between lh-1">
        <p className="text-900 fw-semi-bold">Taxe (TVA 20%) :</p>
        <p className="text-1100 fw-semi-bold">{currencyFormat(126.2)}</p>
      </div>
      <div className="d-flex justify-content-between lh-1">
        <p className="text-900 fw-semi-bold">Sous-total :</p>
        <p className="text-1100 fw-semi-bold">{currencyFormat(665)}</p>
      </div>
    </div>
  );
};

export default OrderSummaryDetails;
