import React from 'react';
import computer from 'assets/img/ipercom/services/Solution proposes/conception logiciel/computer4_21_11zon.webp';
import code from 'assets/img/ipercom/services/Solution proposes/conception logiciel/code_16_11zon.webp';

const Header = () => {
  return (
    <>
      <>
        <section className="position-relative">
          <div className="container position-relative zindex-5 pt-5">
            <div className="row mt-4 pt-5">
              <div className="col-xl-5 col-lg-5 text-center text-lg-start pb-3 pb-md-4 pb-lg-0">
                <h1 className="display-5 pb-md-2 pb-lg-4">
                  Conception de logiciels sur mesure
                </h1>
              </div>
              <div className="col-xl-5 col-lg-6  position-relative zindex-5 mb-5 mb-lg-0">
                <div
                  className="rellax card bg-primary border-0 shadow-primary p-sm-4 p-lg-2"
                  data-rellax-speed={-1}
                  data-disable-parallax-down="lg"
                >
                  <div className="card-body p-lg-3">
                    <h2 className="text-light pb-1 pb-md-3">
                      Logiciels sur mesure pour votre entreprise
                    </h2>
                    <p className="fs-lg text-light pb-2 pb-md-0 mb-4 mb-md-5">
                      Découvrez comment notre expertise en conception de
                      logiciels sur mesure peut répondre précisément à vos
                      besoins professionnels. Optimisez vos processus métiers
                      avec des solutions personnalisées et performantes.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="d-none d-lg-block"
              style={{ marginTop: "-110px" }}
            />
            <div className="row align-items-end mt-4">
              <div className="col-lg-6 d-none d-lg-block">
                <img
                  src={computer}
                  className="rellax rounded-3"
                  alt="Image"
                  data-rellax-speed="1.35"
                  data-disable-parallax-down="md"
                />
              </div>
            </div>
            <div className="col-lg-6 d-none d-lg-block">
              <img
                src={code}
                className="rellax rounded-3"
                alt="Image"
                style={{
                  zIndex: -1,
                  top: "0%",
                  position: "absolute",
                  left: "50%",
                  width: "800px"
                }}
              />
            </div>
          </div>
        </section>
      </>
    </>
  );
};

export default Header;
