import React, { useEffect, useState } from "react";
import { tabsData, tabsDataTest } from "data/pricingTab";
import { Button } from "react-bootstrap";
import ModalDevis from "pages/coming-soon/ModalDevis";

interface PackageProps {
  activeTab: string;
  billingCycle: string;
  commitmentYears: number;
  optionsValues: { [key: string]: number | string[] };
  dropdownVisibility: { [key: string]: boolean };
  toggleDropdown: (optionKey: string) => void;
}
type ModalType = "devis" | "demo" | "revendeur";

const Packages: React.FC<PackageProps> = ({
                                            activeTab,
                                            billingCycle,
                                            commitmentYears,
                                            optionsValues,
                                            dropdownVisibility,
                                            toggleDropdown
                                          }) => {
  const [numberOptionValues, setNumberOptionValues] = useState<{ [key: string]: number }>({});
  const [numberOptionSelectValues, setNumberOptionSelectValues] = useState<{ [key: string]: number }>({});
  const [formationValue, setFormationValue] = useState<{ [key: string]: number }>({});
  const [initialQuantityValues, setInitialQuantityValues] = useState<{ [key: string]: number }>({});
  const [featuresPrices, setFeaturesPrices] = useState<{ [key: string]: number }>({});

  const [showModal, setShowModal] = useState({
    devis: false,
    demo: false,
    revendeur: false
  });

  const handleShow = (modal: ModalType) =>
    setShowModal({ ...showModal, [modal]: true });
  const handleClose = (modal: ModalType) =>
    setShowModal({ ...showModal, [modal]: false });

  const initializeNumberValues = () => {
    const initialValues: { [key: string]: number } = {};
    const initialQuantityValues: { [key: string]: number } = {};
    const featuresPrices: { [key: string]: number } = {};
    const billingCheck: { [key: string]: boolean | undefined } = {}; // Modifier le type ici
  
    tabsData.forEach(tab => {
      tab.packages.forEach(packageItem => {
        packageItem.features.forEach(feature => {
          const optionKey = `${packageItem.name}-${feature.name}`;
          if (feature.prices && feature.verificateName === "journeyFormationValue") {
            const optionKey = `${packageItem.name}-${feature.verificateName}`;
            feature.prices.forEach(price => {
              initialValues[optionKey] = price.value || 0;
            });
          }
          packageItem.options.forEach(option => {
            const optionKey = `${packageItem.name}-${option.name}`;
            if (option.name === "journeyFormation") {
              initialQuantityValues[optionKey] = option.value || 0;
            }
            if (option.name === "numberOfUsers" || option.name === "simultaneousCalls") {
              featuresPrices[optionKey] = option.price || 0;
            }
          });
          billingCheck[optionKey] = feature.isCheck; // La valeur peut être undefined
        });
      });
    });
    setFeaturesPrices(featuresPrices); 
    setInitialQuantityValues(initialQuantityValues);
    setFormationValue(initialValues);
  };


  const initializeNumberOptionValues = () => {
    const initialValues: { [key: string]: number } = {};

    tabsData.forEach(tab => {
      tab.packages.forEach(packageItem => {
        packageItem.options.forEach(option => {
          if (option.type === "number") {
            const optionKey = `${packageItem.name}-${option.name}`;
            initialValues[optionKey] = option.value || 0;
          }
        });
      });
    });
    setNumberOptionValues(initialValues);
  };

  const initializeNumberOptionSelectValues = () => {
    const initialSelectValues: { [key: string]: number } = {};
    tabsData.forEach(tab => {
      tab.packages.forEach(packageItem => {
        packageItem.options.forEach(option => {
          if (option.type === "selectNumber" && option.options) {
            option.options.forEach(optionSelect => {
              const optionKey = `${packageItem.name}-${optionSelect.label}`;
              initialSelectValues[optionKey] = optionSelect.value || 0;
            });
          }
        });
      });
    });
    setNumberOptionSelectValues(initialSelectValues);
  };

  const moreValue = (optionKey: string, jumpValue: number | undefined, maxValue: number | undefined, price: number | undefined) => {
    const prices = price !== undefined ? price : 1;
    const jump = jumpValue !== undefined ? jumpValue : 1;
    const max = maxValue !== undefined ? maxValue : Number.MAX_VALUE;
    const maxPrice = prices * (max / jump);

    setFeaturesPrices(prevValues => ({
      ...prevValues,
      [optionKey]: Math.min(prevValues[optionKey] + prices, maxPrice)
    }));

    setNumberOptionValues(prevValues => ({
      ...prevValues,
      [optionKey]: Math.min(prevValues[optionKey] + jump, max)
    }));

    setInitialQuantityValues(prevQuantityValues => ({
      ...prevQuantityValues,
      [optionKey]: Math.min(prevQuantityValues[optionKey] + jump, max)
    }));
  };

  const lessValue = (optionKey: string, jumpValue: number | undefined, value: number | undefined, price: number | undefined) => {
    const jump = jumpValue !== undefined ? jumpValue : 1;
    const minValue = value !== undefined ? value : 0;
    const prices = price !== undefined ? price : 1;

    setFeaturesPrices(prevValues => ({
      ...prevValues,
      [optionKey]: Math.min(prevValues[optionKey] - prices, prices)
    }));

    setNumberOptionValues(prevValues => ({
      ...prevValues,
      [optionKey]: Math.max(prevValues[optionKey] - jump, minValue)
    }));

    setInitialQuantityValues(prevQuantityValues => ({
      ...prevQuantityValues,
      [optionKey]: Math.max(prevQuantityValues[optionKey] - jump, minValue)
    }));
  };

  useEffect(() => {
    initializeNumberOptionValues();
    initializeNumberOptionSelectValues();
    initializeNumberValues();
  }, []);

  return (
    <div className="table-responsive-xxl pt-md-3">
      <div className="row flex-nowrap pb-4">
        {tabsDataTest.find(tab => tab.id === activeTab)?.packages.map((packageItem, index) => (
          <div className="col fs-sm" key={index}>
            <div className="card border-0 shadow-sm p-xxl-2" style={{ minWidth: "18rem" }}>
              <div className="card-body p-1">
                <div className="d-flex align-items-center pb-2 pb-md-3 mb-4">
                  <div className="flex-shrink-0 bg-secondary rounded-3 p-2 align-top">
                    <img src={packageItem.image} width={84} alt={`${packageItem.name} Icon`} />
                  </div>
                  <div className="ps-4">
                    <h3 className="fs-6 fw-normal text-primary mb-2">
                      {packageItem.name}
                    </h3>
                    <h4 className="h3 lh-1 mb-0">
                      {/* {packageItem.options.length > 0 &&
                        <>
                          <span>A partir de</span><br></br> {billingCycle === "mois" ? packageItem.monthlyPrice + featuresPrices[`${packageItem.name}-${packageItem.options[0].name}`] : packageItem.annualPrice} € HT
                          / {billingCycle}
                        </>
                      } */}
                        <>
                          <span>A partir de</span><br></br> {billingCycle === "mois" ? packageItem.monthlyPrice : packageItem.annualPrice} € HT
                          / {billingCycle}
                        </>

                    </h4>
                    <p>
                      Engagement : {commitmentYears} {commitmentYears > 1 ? "ans" : "an"}
                    </p>
                  </div>
                </div>
                <ul className="list-unstyled fs-sm pb-md-3 mb-3" style={{ lineHeight: "1.5" }}>
                  {packageItem.features.map((feature, featureIndex) => {
                    const optionKey = `${packageItem.name}-${feature.verificateName}`;
                    let description = feature.description;
                    let optionValue = "";


                    if (feature.valueChanging && feature.verificateName) {
                      optionValue = String(numberOptionValues[optionKey] || "");
                      description = `${optionValue} ${description}`;
                    }

                    return (
                      <li key={featureIndex}>
                        <i
                          className={`bx ${feature.available ? "bx-check" : "bx-x"} fs-xl ${feature.available ? "text-primary" : ""} me-1`} />
                        <span dangerouslySetInnerHTML={{ __html: description }} />
                        {feature.priceLabel ?
                          <span className="text-primary">&nbsp;{feature.priceLabel} € HT</span> : null}

                        {feature.info && (
                          <i className="bx bx-info-circle ms-2 fs-5 text-primary rounded-circle"
                             data-bs-toggle="tooltip" data-bs-placement="bottom"
                             data-bs-delay='{"show": 0, "hide": 100}' title={feature.textInfo}
                             style={{ cursor: "pointer" }} />
                        )}

                        {feature.prices && feature.prices.map((price, priceIndex) => {
                          const optionKey = `${packageItem.name}-${feature.verificateName}`;
                          const optionKeyQuantity = `${packageItem.name}-journeyFormation`;
                          const initialValue = formationValue[optionKey];
                          const initialQuantityValue = initialQuantityValues[optionKeyQuantity];
                          const dayLabel = initialQuantityValue === 1 ? "journée de formation supplémentaire" : "journées de formation supplémentaire";

                          if (initialQuantityValue > 0) {
                            return (
                              <p key={priceIndex} className="m-0">
                                <i
                                  className={`bx ${feature.available ? "bx-check" : "bx-x"} fs-xl ${feature.available ? "text-primary" : ""} me-2`} />
                                {initialQuantityValue + " "}
                                {dayLabel} &nbsp;
                                {price.value !== undefined && (
                                  <span className="text-primary">{initialValue * initialQuantityValue} € HT </span>
                                )}
                                {feature.prices && feature.prices.map(info => (
                                  info.info && (
                                    <i className="bx bx-info-circle ms-2 fs-5 text-primary rounded-circle"
                                       data-bs-toggle="tooltip" data-bs-placement="bottom"
                                       data-bs-delay='{"show": 0, "hide": 100}' title={info.textInfo}
                                       style={{ cursor: "pointer" }} />
                                  )
                                ))}
                              </p>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </li>
                    );
                  })}
                </ul>


                {packageItem.options.map(option => {
                  const optionKey = `${packageItem.name}-${option.name}`;
                  if (option.type === "number") {
                    return (
                      <div
                        className="d-flex justify-content-between align-items-center my-2 rounded-2 border border-1 p-2"
                        key={optionKey} style={{ position: "relative" }}>
                        {option.info && (
                          <i className="bx bx-info-circle ms-2 fs-5 text-primary rounded-circle" data-toggle="tooltip"
                             data-bs-placement="bottom" title={option.textInfo} style={{ cursor: "pointer" }} />
                        )}
                        <span className="col-xs-12 col-sm-4 col-md-8 fs-md">
                          {option.label} : {numberOptionValues[optionKey]}
                        </span>
                        <div className="d-flex justify-content-between align-items-center">
                          <button className="btn btn-icon btn-secondary border-white btn-sm rounded-circle"
                                  onClick={() => lessValue(optionKey, option.jumpValue, option.value, option.price)}>
                            <i className="bx bx-minus"></i>
                          </button>
                          <button className="ms-1 btn btn-icon btn-secondary border-white btn-sm rounded-circle"
                                  onClick={() => moreValue(optionKey, option.jumpValue, option.maxValue, option.price)}>
                            <i className="bx bx-plus"></i>
                          </button>
                        </div>
                      </div>
                    );
                  } else if (option.type === "select") {
                    return (
                      <div
                        className="d-flex justify-content-around align-items-center my-2 rounded-2 border border-1 p-2"
                        key={optionKey} style={{ position: "relative" }}>
                        {option.info && (
                          <i className="bx bx-info-circle ms-2 fs-5 text-primary me-2 rounded-circle"
                             data-bs-toggle="tooltip" data-bs-placement="bottom" title={option.textInfo}
                             style={{ cursor: "pointer" }} />
                        )}
                        <span className="col-xs-12 col-sm-4 col-md-8 me-5">{option.label}</span>
                        <button
                          type="button"
                          className="btn btn-icon btn-secondary btn-sm rounded-circle"
                          onClick={() => {
                            toggleDropdown(optionKey);
                          }}
                        >
                          <i className={`bx ${dropdownVisibility[optionKey] ? "bx-chevron-up" : "bx-chevron-down"}`} />
                        </button>
                        {dropdownVisibility[optionKey] && (
                          <div className="dropdown-menu show rounded-2 border border-1 p-2" style={{
                            position: "absolute",
                            zIndex: 1000,
                            top: "calc(100% + 5px)",
                            left: "0",
                            right: "0",
                            boxShadow: "0 4px 6px rgba(0,0,0,.1)",
                            width: "auto"
                          }}>
                            {option.options?.map(moduleOption => (
                              <div key={`${optionKey}-${moduleOption}`} className="form-input" style={{
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                                width: "100%"
                              }}>
                                {moduleOption.info && (
                                  <i className="bx bx-info-circle mx-2 fs-5 text-primary rounded-circle"
                                     data-bs-toggle="tooltip" data-bs-placement="bottom" title={moduleOption.textInfo}
                                     style={{ cursor: "pointer" }} />
                                )}
                                <label className="form-check-label" htmlFor={`${optionKey}-${moduleOption}`}>
                                  {moduleOption.label}
                                </label>
                                <input
                                  type="checkbox"
                                  id={`${optionKey}-${moduleOption}`}
                                  className="form-check-input m-1"
                                />
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    );
                  } else if (option.type === "selectNumber") {
                    const optionKey = `${packageItem.name}-${option.label}`;
                    return (
                      <div
                        className="d-flex justify-content-around align-items-center my-2 rounded-2 border border-1 p-2"
                        key={optionKey} style={{ position: "relative" }}>
                        {option.info && (
                          <i className="bx bx-info-circle ms-2 fs-5 text-primary me-2 rounded-circle"
                             data-bs-toggle="tooltip" data-bs-placement="bottom" title={option.textInfo}
                             style={{ cursor: "pointer" }} />
                        )}
                        <span className="col-xs-12 col-sm-4 col-md-8 me-5">{option.label}</span>
                        <button
                          type="button"
                          className="btn btn-icon btn-secondary btn-sm rounded-circle"
                          onClick={() => {
                            toggleDropdown(optionKey);
                          }}
                        >
                          <i className={`bx ${dropdownVisibility[optionKey] ? "bx-chevron-up" : "bx-chevron-down"}`} />
                        </button>
                        {dropdownVisibility[optionKey] && (
                          <div className="dropdown-menu show rounded-2 border border-1 p-2" style={{
                            position: "absolute",
                            zIndex: 1000,
                            top: "calc(100% + 5px)",
                            left: "0",
                            right: "0",
                            boxShadow: "0 4px 6px rgba(0,0,0,.1)",
                            width: "auto"
                          }}>
                            {option.options?.map(moduleOption => (
                              <div key={`${optionKey}-${moduleOption}`} className="form-input" style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%"
                              }}>
                                {moduleOption.info && (
                                  <i className="bx bx-info-circle mx-2 fs-5 text-primary rounded-circle"
                                     data-bs-toggle="tooltip" data-bs-placement="bottom" title={moduleOption.textInfo}
                                     style={{ cursor: "pointer" }} />
                                )}
                                <label className="form-check-label">
                                  {moduleOption.label} : {numberOptionSelectValues[`${packageItem.name}-${moduleOption.label}`]}
                                </label>
                                <div className="d-flex justify-content-between align-items-center"
                                     style={{ marginLeft: "auto" }}>
                                  <button className="btn btn-icon btn-secondary border-white btn-sm rounded-circle m-1">
                                    <i className="bx bx-minus"></i>
                                  </button>
                                  <button className="m-1 btn btn-icon btn-secondary border-white btn-sm rounded-circle"
                                  >
                                    <i className="bx bx-plus"></i>
                                  </button>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    );
                  }
                })}
              </div>
              <Button
                  variant="primary"
                  className="btn btn-outline-primary w-100 text-secondary"
                  onClick={() => handleShow("devis")}
                >
                  Demander un devis
                </Button>
            </div>
          </div>
        ))}
      </div>
      <p className={"text-center pb-3 pb-md-4"}>
        Tous les téléphones SIP du marché, tels que Yealink, Fanvil, Cisco, Grandstream, Snom, Gigaset, etc., sont
        compatibles avec notre solution iper-X. <br /> Nous avons opté pour la marque Yealink et Fanvil en tant que partenaire
        privilégié. Si vous préférez utiliser vos propres téléphones SIP avec notre service, cela est tout à fait
        réalisable. Nous effectuerons un test de compatibilité avant d'initier le déploiement.
      </p>
      <ModalDevis showModal={showModal.devis} handleClose={() => handleClose("devis")} />
    </div>
  );
};

export default Packages;
