import React, { useState } from "react";

const FaqPage = () => {

  const [selectedCategory, setSelectedCategory] = useState("all");
  const [faqItems, setFaqItems] = useState([
    {
      category: "Services",
      question: "Quels types de services propose Ipercom ?",
      answer: "Ipercom propose des services de développement de logiciels personnalisés, de développement d'applications mobiles et de développement d'applications web.",
      color: "blue",
    },
    {
      category: "Services",
      question: "Ipercom développe-t-il des solutions sur mesure ?",
      answer: "Oui, Ipercom développe des solutions entièrement sur mesure adaptées aux besoins spécifiques de chaque client.",
      color: "blue",
    },
    {
      category: "Services",
      question: "Quels sont les délais moyens pour le développement d'une application ?",
      answer: "Les délais varient en fonction de la complexité du projet, mais la plupart des applications sont développées en quelques mois.",
      color: "blue",
    },
    {
      category: "Services",
      question: "Ipercom offre-t-il des services de maintenance ?",
      answer: "Oui, Ipercom offre des services de maintenance pour assurer la continuité et le bon fonctionnement des applications après leur déploiement.",
      color: "blue",
    },
    {
      category: "Services",
      question: "Quels secteurs d'activité Ipercom sert-il ?",
      answer: "Ipercom sert divers secteurs, y compris la santé, l'éducation, le commerce de détail, et bien d'autres.",
      color: "blue",
    },
    {
      category: "Services",
      question: "Ipercom offre-t-il des formations pour les utilisateurs finaux ?",
      answer: "Oui, Ipercom propose des formations pour aider les utilisateurs finaux à maîtriser les nouvelles solutions déployées.",
      color: "blue",
    },
    {
      category: "Services",
      question: "Quels types d'applications mobiles Ipercom développe-t-il ?",
      answer: "Ipercom développe des applications mobiles natives et hybrides pour iOS et Android.",
      color: "blue",
    },
    {
      category: "Services",
      question: "Ipercom offre-t-il des services de conception UX/UI ?",
      answer: "Oui, Ipercom propose des services de conception UX/UI pour garantir des interfaces utilisateurs efficaces et intuitives.",
      color: "blue",
    },
    {
      category: "Services",
      question: "Ipercom peut-il intégrer des solutions existantes ?",
      answer: "Oui, Ipercom peut intégrer de nouvelles solutions avec des systèmes existants pour une meilleure interopérabilité.",
      color: "blue",
    },
    {
      category: "Services",
      question: "Ipercom offre-t-il des services de cloud computing ?",
      answer: "Oui, Ipercom propose des solutions de cloud computing pour héberger et gérer les applications et données.",
      color: "blue",
    },
    {
      category: "Processus",
      question: "Comment se déroule le processus de développement chez Ipercom ?",
      answer: "Le processus de développement chez Ipercom inclut l'analyse des besoins, la conception, le développement, les tests et la mise en production.",
      color: "black",
    },
    {
      category: "Processus",
      question: "Quel est le rôle du client dans le processus de développement ?",
      answer: "Le client est impliqué à chaque étape du processus pour s'assurer que la solution répond à ses besoins spécifiques.",
      color: "black",
    },
    {
      category: "Processus",
      question: "Combien de temps dure la phase d'analyse des besoins ?",
      answer: "La phase d'analyse des besoins peut durer de quelques semaines à un mois, selon la complexité du projet.",
      color: "black",
    },
    {
      category: "Processus",
      question: "Quels outils de gestion de projet utilisez-vous ?",
      answer: "Ipercom utilise des outils de gestion de projet comme Jira, Trello et Asana pour suivre le progrès des projets.",
      color: "black",
    },
    {
      category: "Processus",
      question: "Comment Ipercom assure-t-il la qualité des solutions développées ?",
      answer: "Ipercom suit des pratiques rigoureuses de contrôle de qualité, y compris des tests automatisés et manuels.",
      color: "black",
    },
    {
      category: "Processus",
      question: "Qu'est-ce que la méthodologie agile ?",
      answer: "La méthodologie agile est une approche de gestion de projet qui favorise la flexibilité, l'itération rapide et la collaboration.",
      color: "black",
    },
    {
      category: "Processus",
      question: "Comment la communication est-elle gérée pendant le projet ?",
      answer: "La communication est assurée par des réunions régulières, des mises à jour de statut et des outils de collaboration en ligne.",
      color: "black",
    },
    {
      category: "Processus",
      question: "Quel est le processus de validation avant la mise en production ?",
      answer: "Le processus de validation inclut des tests approfondis, des révisions par les parties prenantes et des vérifications finales.",
      color: "black",
    },
    {
      category: "Processus",
      question: "Comment les modifications sont-elles gérées après le début du projet ?",
      answer: "Les modifications sont gérées par un processus de demande de changement, évaluant l'impact et ajustant le plan de projet en conséquence.",
      color: "black",
    },
    {
      category: "Processus",
      question: "Quelles sont les étapes clés du développement d'un projet ?",
      answer: "Les étapes clés incluent la planification, la conception, le développement, les tests, la mise en production et le suivi post-lancement.",
      color: "black",
    },
    {
      category: "Technologies",
      question: "Quelles technologies utilise Ipercom ?",
      answer: "Ipercom utilise des technologies de pointe comme React.js, C#, et diverses autres technologies modernes pour développer des solutions efficaces.",
      color: "red",
    },
    {
      category: "Technologies",
      question: "Ipercom utilise-t-il des technologies open source ?",
      answer: "Oui, Ipercom utilise et contribue aux technologies open source pour bénéficier de leur flexibilité et de leur communauté.",
      color: "red",
    },
    {
      category: "Technologies",
      question: "Quels frameworks front-end sont utilisés par Ipercom ?",
      answer: "Ipercom utilise des frameworks front-end comme React.js et Angular pour créer des interfaces utilisateur réactives et performantes.",
      color: "red",
    },
    {
      category: "Technologies",
      question: "Quels langages de programmation sont couramment utilisés ?",
      answer: "Les langages couramment utilisés incluent JavaScript, TypeScript, C#, Python, et Java.",
      color: "red",
    },
    {
      category: "Technologies",
      question: "Quelle base de données Ipercom utilise-t-il ?",
      answer: "Ipercom utilise diverses bases de données, y compris SQL Server, MySQL, MongoDB, et PostgreSQL, selon les besoins du projet.",
      color: "red",
    },
    {
      category: "Technologies",
      question: "Comment Ipercom assure-t-il la sécurité des applications ?",
      answer: "Ipercom implémente des pratiques de sécurité robustes, y compris le cryptage des données, les tests de pénétration et la conformité aux normes de sécurité.",
      color: "red",
    },
    {
      category: "Technologies",
      question: "Qu'est-ce que le développement mobile natif ?",
      answer: "Le développement mobile natif se réfère à la création d'applications spécifiques à une plateforme (iOS ou Android) en utilisant leurs langages et outils natifs.",
      color: "red",
    },
    {
      category: "Technologies",
      question: "Quels outils de CI/CD utilisez-vous ?",
      answer: "Ipercom utilise des outils de CI/CD comme Jenkins, GitLab CI, et CircleCI pour automatiser les déploiements et les tests.",
      color: "red",
    },
    {
      category: "Technologies",
      question: "Comment gérez-vous le versioning des applications ?",
      answer: "Le versioning est géré via des systèmes de contrôle de version comme Git, permettant de suivre les modifications et de collaborer efficacement.",
      color: "red",
    },
    {
      category: "Technologies",
      question: "Quelles sont les tendances technologiques actuelles suivies par Ipercom ?",
      answer: "Ipercom suit les tendances technologiques comme l'IA, le machine learning, les microservices, et le cloud computing.",
      color: "red",
    },
    { category: "Support", question: "Ipercom offre-t-il un support après la livraison ?", answer: "Oui, Ipercom offre un support complet après la livraison pour s'assurer que les solutions fonctionnent correctement et répondent aux besoins des clients.", color: "green" },
    { category: "Support", question: "Quels types de support sont disponibles ?", answer: "Ipercom propose un support technique, un support utilisateur et un support de maintenance pour répondre à tous les besoins après la livraison.", color: "green" },
    { category: "Support", question: "Comment puis-je contacter le support technique ?", answer: "Vous pouvez contacter le support technique par téléphone, email ou via le portail de support en ligne d'Ipercom.", color: "green" },
    { category: "Support", question: "Quels sont les horaires de disponibilité du support ?", answer: "Le support est disponible du lundi au vendredi de 9h à 18h, avec des options de support d'urgence en dehors de ces heures.", color: "green" },
    { category: "Support", question: "Le support est-il inclus dans le coût du projet ?", answer: "Oui, un certain niveau de support est inclus dans le coût du projet, avec des options pour des services de support supplémentaires.", color: "green" },
    { category: "Tarification", question: "Comment Ipercom détermine-t-il le coût d'un projet ?", answer: "Le coût d'un projet chez Ipercom est déterminé en fonction des exigences spécifiques, de la complexité et de la durée estimée du projet.", color: "black" },
    { category: "Tarification", question: "Existe-t-il des frais supplémentaires après la livraison ?", answer: "Non, tous les coûts sont discutés et convenus avant le début du projet, mais des services supplémentaires peuvent être ajoutés sur demande.", color: "black" },
    { category: "Tarification", question: "Offrez-vous des forfaits de tarification ?", answer: "Oui, Ipercom propose différents forfaits de tarification adaptés aux besoins et au budget des clients.", color: "black" },
    { category: "Tarification", question: "La tarification inclut-elle le support post-livraison ?", answer: "Un certain niveau de support post-livraison est inclus dans la tarification, avec des options pour des services de support supplémentaires.", color: "black" },
    { category: "Tarification", question: "Offrez-vous des réductions pour des projets à long terme ?", answer: "Oui, des réductions peuvent être offertes pour des engagements à long terme ou des projets de grande envergure.", color: "black" },
    { category: "Tarification", question: "Comment sont facturés les services de maintenance ?", answer: "Les services de maintenance peuvent être facturés mensuellement ou annuellement, selon les besoins du client.", color: "black" },
    { category: "Tarification", question: "Y a-t-il des coûts pour les mises à jour et les améliorations ?", answer: "Les coûts pour les mises à jour et les améliorations dépendent de l'ampleur des modifications et sont discutés au cas par cas.", color: "black" },
    { category: "Tarification", question: "Proposez-vous des options de paiement flexibles ?", answer: "Oui, Ipercom propose plusieurs options de paiement pour s'adapter aux préférences de ses clients.", color: "black" },
    { category: "Tarification", question: "Quels modes de paiement acceptez-vous ?", answer: "Ipercom accepte divers modes de paiement, y compris les virements bancaires, les cartes de crédit et PayPal.", color: "black" },
    { category: "Tarification", question: "Comment puis-je obtenir un devis pour mon projet ?", answer: "Vous pouvez obtenir un devis en contactant Ipercom via leur site web ou en appelant directement leur service commercial.", color: "black" }
  ]);

  const filteredFaqItems = selectedCategory === "all" ? faqItems : faqItems.filter(item => item.category === selectedCategory);

  const handleCategoryChange = (category: string) => {
    setSelectedCategory(category);
  };

  return (
    <section className="container mb-3">
      {/* Page title */}
      <div className="d-flex align-items-center justify-content-between mb-4 pb-1 pb-md-3">
        <h1 className="mb-0">FAQ</h1>
      </div>
      <ul
        className="nav nav-tabs flex-nowrap justify-content-lg-center overflow-auto pb-2 mb-3 mb-lg-4"
        role="tablist"
      >
        {["Services", "Processus", "Technologies", "Support", "Tarification"].map((category, index) => (
          <li className="nav-item" role="presentation" key={index}>
            <button
              className={`nav-link text-nowrap ${selectedCategory === category ? "active" : ""}`}
              onClick={() => handleCategoryChange(category)}
              type="button"
              role="tab"
            >
              {category}
            </button>
          </li>
        ))}
      </ul>

      {/* FAQ list */}
      <div className="row">
        <div className="col-xl-9 col-lg-8">
          {filteredFaqItems.map((item, index) => (
            <article className="card border-0 bg-transparent me-xl-5 mb-4" key={index}>
              <div className="row g-0">
                <div className="col-sm-8">
                  <div className="card-body px-0 pt-sm-0 ps-sm-4 pb-0 pb-sm-4">
                    <p
                      className="badge fs-sm text-white shadow-info text-decoration-none mb-3 opacity-60" style={{ backgroundColor: item.color }}
                    >
                      {item.category}
                    </p>
                    <h2 className="h4">{item.question}</h2>
                    <p className="mb-4">{item.answer}</p>
                  </div>
                </div>
              </div>
            </article>
          ))}
          <div className="pb-2 pb-lg-3" />
        </div>
      </div>
    </section>
  );
};

export default FaqPage;
