import React from "react";
import men from "assets/img/ipercom/services/logiciel-de-facturation/bureautique/men2_38_11zon.webp";
import InfoBouton from "components/modules/showcase/InfoBouton";

const ImportancySoftware = () => {
  return (
    <>
      <section className="container mt-5">
        <div className="row justify-content-around">
          <div className="col-md-5 pb-2 pb-md-0 mb-4 mb-md-0 order-2">
            <div className="pe-lg-5 text-center">
              <img src={men} className="rounded-3" alt="Image" />
            </div>
          </div>
          <div className="col-md-5 align-content-center order-1">
            <h2 className="mb-sm-4">
              L'importance des logiciels de facturation bureautique
            </h2>
            <p className="d-md-none d-xl-block pb-2 pb-md-3 mb-3">
              Les logiciels de facturation bureautique sont un pilier de la
              gestion financière efficace. En automatisant les tâches
              répétitives, ils libèrent du temps pour se concentrer sur des
              aspects plus stratégiques de l'entreprise.
            </p>
            <InfoBouton />
          </div>
        </div>
      </section>
    </>
  );
};

export default ImportancySoftware;
