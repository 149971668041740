import React from "react";
import People from "assets/img/ipercom/services/Solution proposes/maintenance and support tech/group-of-people.svg";

const Head = () => {
  return (
    <section
      className="jarallax bg-dark"
      data-jarallax=""
      data-speed="0.35"
      data-bs-theme="dark"
      style={{ height: 509 }}
    >
      <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-40" />
      <div
        className="jarallax-img opacity-70"
        style={{ backgroundImage: `url(${People})` }}
      />

      <div
        className="position-relative pb-5 text-center"
        style={{ top: 100, width: "50%", margin: "auto" }}
      >
        {/* Title */}
        <h1 style={{ fontSize: "60px" }}>Maintenance et support technique</h1>
        <p className="fs-xl text-light opacity-70">
          Avec notre service de maintenance et de support technique, vous
          bénéficiez d'une assistance professionnelle à tout moment. Restez
          concentré sur votre activité principale et laissez-nous gérer vos
          logiciels.
        </p>
        {/* Stats */}
      </div>
    </section>
  );
};

export default Head;
