import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";
import Avantages from "components/modules/services/softwareIperx/Avantages";
import HowToPresente from "components/modules/services/softwareIperx/HowToPresente";
import PartnerIperx from "components/modules/services/softwareIperx/PartnerIperx";
import Services from "components/modules/services/softwareIperx/Services";
import Solution from "components/modules/services/softwareIperx/Solution";

const PhoneSoftware = () => {
  return (
    <>
      <Breadcrumbs />
      <Solution />
      <HowToPresente />
      <Services />
      <Avantages />
      <PartnerIperx />
    </>
  );
};

export default PhoneSoftware;
