import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import NavbarBrand from "../navbars/nav-items/NavbarBrand";
import youtube from "assets/img/ipercom/landing/partners/youtubeLogo.svg";
import X from "assets/img/ipercom/logo/nouveau-logo-twitter-2023-x-fond-blanc-vecteur_1017-45422.avif";
import NewsletterForm from "./NewsletterForm";
import trustPilot from "assets/img/ipercom/logo/trustpilot-1.svg";

interface NavLink {
  href: string;
  name: string;
}

const Footer: React.FC<{ theme?: string }> = ({ theme = 'light' }) => {
  const [usefulLinksOpen, setUsefulLinksOpen] = useState(false);
  const [socialLinksOpen, setSocialLinksOpen] = useState(false);
  const [cgvLinksOpen, setCgvLinksOpen] = useState(false);

  const currentYear = new Date().getFullYear();
  const isDarkTheme = theme === 'dark';
  const footerClasses = `footer pb-4 pb-lg-5`;
  const textColorClasses = isDarkTheme ? 'text-light' : 'text-dark';
  const navLinkClasses = `nav-link d-inline-block px-0 pt-1 pb-2 ${textColorClasses}`;


  const renderNavLinkColumn = (
    title: string,
    links: NavLink[],
    isOpen: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
  ) => (
    <Col lg={3}>
      <h6 className={`mb-2 ${textColorClasses}`}>
        <a
          href={`#${title.replace(' ', '-').toLowerCase()}`}
          className={`d-block text-dark dropdown-toggle d-lg-none py-2 ${isOpen ? '' : 'collapsed'}`}
          onClick={() => setOpen(!isOpen)}
          aria-expanded={isOpen ? 'true' : 'false'}
        >
          {title}
        </a>
      </h6>
      <div
        className={`d-lg-block collapse ${isOpen ? 'show' : ''}`}
        id={`${title.replace(' ', '-').toLowerCase()}`}
      >
        <ul className="nav flex-column pb-lg-1 mb-lg-3">
          {links.map((link, index) => (
            <li key={index} className="nav-item">
              <a href={link.href} className={navLinkClasses}>
                {link.name}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </Col>
  );

  return (
    <>
      <script
        id="ze-snippet"
        src="https://static.zdassets.com/ekr/snippet.js?key=766b734c-adca-4191-ac3c-60b0c99a5863"
      ></script>

      <footer className={footerClasses}>
        <Container className="pt-lg-4">
          <Row>
            <Col lg={4} md={6} className="me-4">
              <div className="">
                <NavbarBrand version="secondary" width={150} />
              </div>
              <p className={`fs-sm opacity-70 mb-1 ${textColorClasses}`}>
                Notre équipe dédiée vous accompagne à chaque étape pour réaliser
                vos projets numériques sur mesure. Faites confiance à notre
                expertise pour une solution adaptée à vos besoins. Rejoignez
                ipercom.
              </p>
              <NewsletterForm />
            </Col>
            <Col lg={7} className="align-content-center pt-1">
              <Row id="footer-links" className="justify-content-around fs-xs">
                {renderNavLinkColumn(
                  'Contact',
                  [
                    {
                      href: 'mailto:contact@ipercom.com',
                      name: 'contact@ipercom.com'
                    },
                    { href: 'tel:0518222200', name: '05 18 222 200' },
                    { href: "adresse: 8", name: "1 Rue Jean Walton" },
                    { href: "adresse: 8", name: "33450 Montussan" },
                    { href: 'RCS: ', name: 'RCS : Bordeaux 509 795 829' }
                  ],
                  socialLinksOpen,
                  setSocialLinksOpen
                )}
                {renderNavLinkColumn(
                  'Mentions légales',
                  [
                    {
                      href: "/cgu", name: "CGU"
                    },
                    {
                      href: "/cgv-grossistes", name: "CGV Grossistes"

                    },
                    {
                      href: "/cgv-client-final", name: "CGV Client final"
                    },
                    {
                      href: "/cgv-distributeur-esn", name: "CGV Distributeur ESN"
                    }
                  ],
                  cgvLinksOpen,
                  setCgvLinksOpen
                )}
                {renderNavLinkColumn(
                  "Mentions légales bis",
                  [
                    {
                      href: "/politique-cookie", name: "Politique des cookies"
                    },
                    {
                      href: "/politique-confidentialite", name: "Politique de confidentialité"
                    },  
                    {
                      href: '/a-propos/faq',
                      name: 'FAQ'
                    },                
                  ],
                  cgvLinksOpen,
                  setCgvLinksOpen
                )}
                <div className="col-lg-6 mt-2">
                  <p className="fs-xs text-dark">
                    © Tous droits réservés. Créé par studio Ipercom 2024
                  </p>
                </div>
                <div className="col-lg-6">
                  <div className="d-flex align-items-center justify-content-start">
                    <a href="https://www.facebook.com/profile.php?id=61562798741022" className="me-2" style={{ width: "24px" }}>
                      <i className="bx bxl-facebook fs-3 text-primary"></i>
                    </a>
                    <a href="https://fr.linkedin.com/company/ipercom-com" className="me-2" style={{ width: "24px" }}>
                      <i className="bx bxl-linkedin fs-3 text-primary"></i>
                    </a>
                    <a href="https://x.com/ipercom_com" className="me-2" style={{ width: "24px" }}>
                      <img src={X} alt="X logo" style={{ width: "100%" }} />
                    </a>
                    {/* <a href="#" className="me-2" style={{ width: "24px" }}>
                      <i className="bx bxl-instagram fs-3 text-primary"></i>
                    </a> */}
                    <a href="https://g.page/r/CdYwk6lc6w-PEAE/review" className="me-2" style={{ width: "24px" }}>
                      <i className="bx bxl-google fs-3 text-primary"></i>
                    </a>
                    <a href="https://youtube.com/@ipercom?si=i3eUocJ72rtCmUfX" className="me-2 mb-1"
                       style={{ width: "60px" }}>
                      <img src={youtube} alt="youtube logo" style={{ width: "100%" }} />
                    </a>
                    <a href="https://fr.trustpilot.com/review/ipercom.com" className="me-2 mb-1"
                       style={{ width: "100px" }}>
                      <img src={trustPilot} alt="trusPilot logo" style={{ width: "100%" }} />
                    </a>
                  </div>
                </div>
              </Row>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
