import React, { useRef, useState } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useParallaxHooks from "hooks/useParallaxHooks";
import layer02 from "assets/img/ipercom/landing/hero/layer02.webp";
import brand07 from "assets/img/ipercom/partners/logo/iper-x_logo.svg";
import brand04 from "assets/img/ipercom/partners/logo/i-pos_logo.svg";
import brand02 from "assets/img/ipercom/partners/logo/PIGMA_logo.svg";
import brand05 from "assets/img/ipercom/partners/logo/directcopieur_logo.svg";
import { Button } from "react-bootstrap";
import ModalDevis from "pages/coming-soon/ModalDevis";

gsap.registerPlugin(ScrollTrigger);
type ModalType = "devis" | "demo" | "revendeur";

const Hero = () => {
  const containerRef = useRef(null);
  const parallaxElRef = useRef([]);

  useParallaxHooks(containerRef, parallaxElRef, {
    scrollTrigger: {
      trigger: '.gsap',
      start: '+=450 bottom'
    }
  });

  const [showModal, setShowModal] = useState({
    devis: false,
    demo: false,
    revendeur: false
  });

  const handleShow = (modal: ModalType) =>
    setShowModal({ ...showModal, [modal]: true });
  const handleClose = (modal: ModalType) =>
    setShowModal({ ...showModal, [modal]: false });

  return (
    <section className="position-relative overflow-hidden mb-3 md-mt-5">
      <div className="container pt-lg-3">
        <div className="row flex-lg-nowrap align-items-center">
          <div className="col-lg-6 col-xl-6 text-center text-lg-start md-pt-5 mt-xl-4">
            <h1 className="display-4 pt-4 pb-2 pb-lg-3 mt-sm-2 mt-lg-4">
              ipercom <br />
              Création de Logiciels et d'Applications
            </h1>
            <p className="fs-lg mb-4 mb-lg-5">
              Bienvenue chez ipercom, spécialiste en conception de logiciels et
              applications sur mesure, adaptés à vos besoins.
            </p>
            <h2 className="pt-xl-3 pb-4 pb-lg-4">
              Choisissez la différence, commençons à travailler ensemble :
            </h2>
            <div className="d-flex flex-column flex-lg-row align-items-lg-center">
              <Button
                  variant="primary"
                  className="btn btn-lg mb-3 mb-lg-0 me-lg-3"
                  onClick={() => handleShow("devis")}
                >
                  Demander un devis
                </Button>
              <a
                href="https://outlook.office.com/bookwithme/user/9e8387330e3644559680de311487211e%40ipercom.com/meetingtype/04c0ddc4-d8b0-4c28-a2fb-1853e564c7ef?anonymous"
                className="btn btn-third btn-lg"
              >
                Demander une démo
              </a>
            </div>
            <div className="pt-4 mt-xl-0">
              <h3 className="pt-xl-3 pb-1 pb-lg-4 pb-md-4 pb-sm-4">
                Ils nous font confiance{" "}
              </h3>
              <div className="d-flex justify-content-center justify-content-lg-start mx-xl-n2 ">
                <img
                  src={brand07}
                  alt="Iper-x"
                  width="135" // Width explicite
                  height="60" // Height explicite
                  style={{ height: "auto", maxWidth: "100%", width: "135px", marginRight:'2em' }}
                />
                <img
                  src={brand04}
                  alt="I-pos"
                  width="135" // Width explicite
                  height="60" // Height explicite
                  style={{ height: "auto", maxWidth: "100%", width: "135px", marginRight:'2em' }}
                />
                <img
                  src={brand02}
                  alt="Pigma"
                  width="135" // Width explicite
                  height="60" // Height explicite
                  style={{ height: "auto", maxWidth: "100%", width: "135px", marginRight:'2em' }}
                />
                <img
                  src={brand05}
                  alt="Directcopieur"
                  width="135" // Width explicite
                  height="60" // Height explicite
                  style={{ height: "auto", maxWidth: "100%", width: "135px", marginRight:'2em' }}
                />
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-xl-6 md-pt-4 pt-lg-0">
            <div className="row justify-content-center justify-content-lg-end mt-lg-5">
              <div className="col-md-10 offset-md-1 col-lg-12 offset-lg-0 col-xl-9 offset-xl-3">
                <div
                  className="p-lg-0 p-sm-3 p-2 rounded-4 overflow-hidden position-relative"
                  style={{
                    background:
                      "linear-gradient(153.32deg, rgba(255, 255, 255, .3) -65.62%, rgba(255, 255, 255, .1) 83.28%)",
                    boxShadow: "0 .25rem 1.5rem -.0625rem rgba(0, 0, 0, .2)",
                    width: "150%",
                    marginLeft: "20%" 
                  }}
                >
                  <img
                    src={layer02}
                    alt="Layer"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalDevis showModal={showModal.devis} handleClose={() => handleClose("devis")} />

    </section>
  );
};

export default Hero;
