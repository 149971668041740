import React, { useState } from 'react';
import mobile from 'assets/img/ipercom/services/Solution proposes/logiciel caisse/mobile.webp';
import ESN from 'assets/img/ipercom/ressources/portfolio/projet realisé/ESN_34_11zon.webp';
import histoAppel from 'assets/img/ipercom/ressources/portfolio/projet realisé/historique appel_1_11zon.webp';
import ERP from "assets/img/ipercom/ressources/portfolio/projet realisé/logoErp.webp";
import ipercloud from "assets/img/ipercom/ressources/portfolio/projet realisé/iper-cloud_12_11zon.webp";

const ProjectLits: React.FC = () => {

  
const [selectedCategory, setSelectedCategory] = useState("all");
const [project, setProject] = useState([
  {
    image: ESN,
    title: "Site web",
    url: "/ressources/portfolio/projets-realises/site-web",
    company: "Pigma",
    style: { width: "100%", height: "auto" }
  },
  {
    image: mobile,
    title: "Logiciel de caisse",
    url: "/ressources/portfolio/projets-realises/logiciel-de-caisse",
    company: "i-pos",
    style: { maxWidth: "33%", marginLeft: "33%" }
  },
  {
    image: histoAppel,
    title: "Solution de standard téléphonique pour entreprises",
    url: "/ressources/portfolio/projets-realises/ipbx",
    company: "iper-X",
    style: { width: "100%", height: "auto" }
  },
  {
    image: ERP,
    title: "ERP",
    url: "/ressources/portfolio/projets-realises/erp",
    company: "ipercom",
    style: { width: "100%", height: "auto" }
  },
  {
    image: ipercloud,
    title: "iper-cloud",
    url: "/ressources/portfolio/projets-realises/iper-cloud",
    company: "ipercom",
    style: { width: "100%", height: "auto" }
  }
]);

  const filteredArticles = selectedCategory === "all" ? project : project.filter(project => project.company === selectedCategory);

  console.log(project)

  const handleCategoryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCategory(e.target.value);
  };

  return (
    <>
    <section className="container my-4">
        <div className="row align-items-end gy-3 mb-4 pb-lg-3 pb-1">
          <div className="col-lg-5 col-md-4">
            <h1>Projets réalisés</h1>
          </div>
          <div className="col-lg-7 col-md-8">
            <div className="row gy-2">
              <div className="col-lg-5 col-sm-6">
                <div className="d-flex align-items-center">
                <select className="form-select" onChange={handleCategoryChange}>
                <option value="all">Toutes catégories</option>
                    <option value="ipercom">ipercom</option>
                    <option value="iper-X">iper-x</option>
                    <option value="i-pos">i-pos</option>
                    <option value="Pigma">Pigma</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    <section className="container">
      <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 gx-3 gx-md-4 mt-n2 mt-sm-0">
        {filteredArticles.map((project, index) => (
          <div className="col pb-1 pb-lg-3 mb-4" key={index}>
            <article className="card h-100 border-0 shadow-sm">
              <div className="position-relative">
                <img src={project.image} className="card-img-top" style={project.style} alt="Image" />
              </div>
              <div className="card-body pb-3">
                <h2 className="h5 mb-2"><a href={project.url}>{project.title}</a></h2>
                <p className="fs-sm mb-2">{project.company}</p>
              </div>
            </article>
          </div>
        ))}
      </div>
    </section>
    </>
  );
};

export default ProjectLits;
