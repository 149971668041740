import React from "react";
import mobile from "../../../../assets/img/ipercom/services/TelephonySolution/software/mobileCall_47_11zon.webp";

const Avantages = () => {
  return (
    <>
      <section className="container py-3">
        <div className="row">
          <div className="col-md-6 pb-2 pb-md-0 mb-4 mb-md-0">
            <div className="pe-lg-5 text-center">
              <img src={mobile} className="rounded-3" alt="Image" />
            </div>
          </div>
          <div className="col-md-6">
            <h2 className="mb-sm-4">Avantages de la solution</h2>
            <p className="d-md-none d-xl-block pb-2 pb-md-3 mb-3">
              Solution optimisée pour une efficacité maximale : explorez ses
              avantages dès aujourd'hui !
            </p>
            <div className="border rounded-3 mb-4 mb-lg-5">
              <div className="row row-cols-1 row-cols-sm-2 g-0">
                <div className="col d-flex align-items-center border-end-sm border-bottom p-3">
                  <i
                    className="bx bx-mobile-alt fs-1"
                    style={{ color: "#9397AD" }}
                  ></i>
                  <div className="ps-2 ms-1">
                    <h3 className="h6 mb-0">Téléphonie</h3>
                    <p className="fs-xs mb-0">
                      Outils pour télétravail : web & mobile
                    </p>
                  </div>
                </div>
                <div className="col d-flex align-items-center border-bottom p-3">
                  <i
                    className="bx bx-play-circle fs-1"
                    style={{ color: "#9397AD" }}
                  ></i>
                  <div className="ps-2 ms-1">
                    <h3 className="h6 mb-0">Vidéo</h3>
                    <p className="fs-xs mb-0">
                      Inclus, sans add-ons nécessaires
                    </p>
                  </div>
                </div>
                <div className="col d-flex align-items-center border-bottom border-end-sm p-3">
                  <i
                    className="bx bx-video fs-1"
                    style={{ color: "#9397AD" }}
                  ></i>
                  <div className="ps-2 ms-1">
                    <h3 className="h6 mb-0">Live Chat</h3>
                    <p className="fs-xs mb-0">Dialogue en temps réel</p>
                  </div>
                </div>
                <div className="col d-flex align-items-center border-bottom p-3">
                  <i
                    className="bx bx-mobile-alt fs-1"
                    style={{ color: "#9397AD" }}
                  ></i>

                  <div className="ps-2 ms-1">
                    <h3 className="h6 mb-0">SMS</h3>
                    <p className="fs-xs mb-0">Contact client instantané</p>
                  </div>
                </div>
                <div className="col d-flex align-items-center border-end-sm border-bottom p-3">
                  <i
                    className="bx bx-plus-circle fs-1"
                    style={{ color: "#9397AD" }}
                  ></i>
                  <div className="ps-2 ms-1">
                    <h3 className="h6 mb-0">Intégrations</h3>
                    <p className="fs-xs mb-0">CRM et Microsoft 365</p>
                  </div>
                </div>
                <div className="col d-flex align-items-center border-bottom p-3">
                  <i
                    className="bx bx-slider fs-1"
                    style={{ color: "#9397AD" }}
                  ></i>
                  <div className="ps-2 ms-1">
                    <h3 className="h6 mb-0">Flexible</h3>
                    <p className="fs-xs mb-0">
                      Gardez vos numéros, choisissez vos téléphones
                    </p>
                  </div>
                </div>
                <div className="col d-flex align-items-center border-end-sm p-3">
                  <i
                    className="bx bx-shield fs-1"
                    style={{ color: "#9397AD" }}
                  ></i>
                  <div className="ps-2 ms-1">
                    <h3 className="h6 mb-0">Sécurisé</h3>
                    <p className="fs-xs mb-0">
                      Sécurité renforcée pour votre entreprise
                    </p>
                  </div>
                </div>
                <div className="col d-flex align-items-center p-3">
                  <i
                    className="bx bx-download fs-1"
                    style={{ color: "#9397AD" }}
                  ></i>
                  <div className="ps-2 ms-1">
                    <h3 className="h6 mb-0">Installable n’importe où</h3>
                    <p className="fs-xs mb-0">Hébergé en France</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Avantages;
