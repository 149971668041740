import React from "react";
import ModulesContent from "./ModulesContent";
import Packages from "./Packages";

const Modules = () => {


  return (
    <section className="container my-5">
      <ModulesContent />
    </section>
  );
}

export default Modules;