import React from 'react';
import lunette from 'assets/img/ipercom/services/Solution proposes/integration logiciel/lunette_25_11zon.webp';

const BoostYourBusiness = () => {
  return (
    <section>
      <div className="position-relative overflow-hidden">
        <div className="container-fluid position-relative position-lg-absolute top-0 start-0 h-100">
          <div className="row h-100 me-n4 me-n2">
            <div className="col-lg-7 position-relative">
              <div
                className="jarallax position-absolute top-0 start-0 w-100 h-100 rounded-3 rounded-start-0 overflow-hidden"
                data-jarallax=""
                data-speed="0.3"
              >
                <div
                  className="jarallax-img"
                  style={{
                    backgroundImage: `url(${lunette})`
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container position-relative pt-lg-5 px-0 px-lg-3">
          <div className="row mb-5 pb-5">
            <div className="col-xl-6 col-lg-7 offset-lg-5 offset-xl-6 mb-5 pb-5">
              <div className="card bg-dark border-light overflow-hidden px-4">
                <span
                  className="position-absolute"
                  style={{ backgroundColor: "rgba(255,255,255,.05)" }}
                />
                <div className="card-body position-relative">
                  <p className="fs-lg fw-bold text-primary text-uppercase">
                    Dynamisez votre entreprise
                  </p>
                  <h1 className="text-light">
                    Intégration de solutions logicielles
                  </h1>
                  <p className="fs-md text-light opacity-70">
                    Maximisez la performance grâce à notre intégration de
                    solutions logicielles. Des processus fluides et une
                    productivité optimale.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BoostYourBusiness;
