import React, { useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useParallaxHooks from "hooks/useParallaxHooks";

import calendar_ipbx from "assets/img/ipercom/landing/ipbx/calendar_ipbx.webp";

gsap.registerPlugin(ScrollTrigger);

const Dashboard = () => {
  const containerRef = useRef(null);
  const parallaxElRef = useRef([]);

  useParallaxHooks(containerRef, parallaxElRef, {
    scrollTrigger: {
      trigger: '.gsap',
      start: '+=450 bottom'
    }
  });

  return (
    <section className="container pt-3 pt-md-4 pt-lg-5 pb-2 mt-lg-2 mt-xl-4">
      <div className="row align-items-center">
        <div
          className="rellax col-md-7"
          data-rellax-percentage="0.5"
          data-rellax-speed="-0.6"
          data-disable-parallax-down="lg"
        >
          <img
            src={calendar_ipbx}
            className="d-block mx-auto"
            width={746}
            height={420}
            alt="Image"
          />
        </div>
        <div
          className="rellax col-md-5 col-xl-4 offset-xl-1 d-flex d-sm-block flex-column"
          data-rellax-percentage="0.5"
          data-rellax-speed="0.8"
          data-disable-parallax-down="lg"
        >
          <h2 className="pb-3 pt-2 pt-md-0">Gérez votre travail</h2>
          <ul className="list-unstyled pb-2">
            <li className="d-flex align-items-center pb-1 mb-2">
              <i className="bx bx-check-circle text-primary fs-xl me-2" />
              Optimisation du processus de gestion de projet
            </li>
            <li className="d-flex align-items-center pb-1 mb-2">
              <i className="bx bx-check-circle text-primary fs-xl me-2" />
              Bonnes pratiques pour une gestion transparente des équipes
            </li>
            <li className="d-flex align-items-center pb-1 mb-2">
              <i className="bx bx-check-circle text-primary fs-xl me-2" />
              Priorisation des tâches
            </li>
            <li className="d-flex align-items-center pb-1 mb-2">
              <i className="bx bx-check-circle text-primary fs-xl me-2" />
              Suivez vos progrès avec des graphiques interactifs
            </li>
            <li className="d-flex align-items-center pb-1 mb-2">
              <i className="bx bx-check-circle text-primary fs-xl me-2" />
              Suivi du temps de travail simplifié
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Dashboard;
