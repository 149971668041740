import React from 'react';
import Keyboard from "assets/img/ipercom/services/TelephonySolution/HandOnAKeyboard.webp";

const ConnectingIPBX = () => {
  return (
    <section className="mb-5" style={{ paddingTop: 80 }}>
      <div className="bg-secondary  pb-4">
        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-lg-6" style={{ marginTop: "-80px" }}>
              <div className="swiper-tabs mx-auto" style={{ maxWidth: 526 }}>
                <div id="image-1" className="swiper-tab active">
                  <img
                    src={Keyboard}
                    className="Image"
                    alt="Communication network visualization"
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-5 col-lg-6 offset-xl-1 align-content-center">
              <div className="text-center text-lg-start">
                <h2 className="mb-4">La liaison de l'IPBX au réseau externe</h2>
                <p className="pb-2 pb-xl-0 mb-4 mb-xl-5">
                  De base, un IPBX offre la possibilité de créer un réseau
                  téléphonique privé au sein d'une entreprise. Cela signifie que
                  les employés peuvent uniquement communiquer avec d'autres
                  membres présents sur ce réseau interne.
                  <br />
                  <br />
                  Toutefois, pour élargir leurs possibilités de communication,
                  les entreprises doivent recourir à un trunk SIP (Session
                  Initiation Protocol). Ce dispositif centralise les lignes
                  téléphoniques et assure la transmission des appels, tant
                  sortants qu'entrants, entre le réseau interne de l’entreprise
                  et le réseau téléphonique public. En d'autres termes, le trunk
                  SIP établit la connexion entre l'IPBX et le réseau de
                  téléphonie externe, comprenant les lignes fixes et mobiles.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ConnectingIPBX;
