import React from "react";
import Description from "./Description";
import LastNews from "components/modules/showcase/LastNews";
import Head from "./Head";

const AppMobileFormationIPERX = () => {
  return (
    <>
      <Head />
      <Description />
      <LastNews />
    </>
  );
};

export default AppMobileFormationIPERX;
