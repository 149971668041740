import React from "react";

const SolutionBilling = () => {
  return (
    <>
      <section className="container pb-4 mb-md-1 mb-lg-3 mb-xl-4">
      <div className="row pb-4 mb-md-1 mb-lg-3 mb-xl-4">
          <div className="col-lg-6">
            <h1 className=" display-3 mb-0">Solution Facturation</h1>
          </div>
          <div className="col-lg-6 col-xl-5 offset-xl-1 pt-3 pt-sm-4 pt-lg-3">
            <p className="fs-md pb-4 mb-1 mb-md-2 mb-xl-3">
              Découvrez notre Solution Facturation, un outil intuitif et
              efficace pour gérer vos transactions en toute simplicité. Grâce à
              notre logiciel, gérez vos <a href="/services/erp/creation-de-devis">devis</a> et factures en un clin
              d'œil, pour
              une gestion financière optimale.
            </p>
            <a
              href="/abonnements"
              className="btn btn-lg btn-primary shadow-primary me-2 py-3"
            >
              Démarrez votre projet
            </a>
            <a
              className="btn btn-lg btn-primary shadow-primary"
              href="https://outlook.office.com/bookwithme/user/9e8387330e3644559680de311487211e%40ipercom.com/meetingtype/04c0ddc4-d8b0-4c28-a2fb-1853e564c7ef?anonymous"
            >
              Demander une démo
            </a>
          </div>
        </div>
        <hr />
      </section>
    </>
  );
};

export default SolutionBilling;
