import React from "react";
import woman from "assets/img/ipercom/services/logiciel-de-facturation/bureautique/board_10_11zon.webp";

const Automate = () => {
  return (
    <>
      <section className="container mt-5 pb-lg-5 pb-md-4 pb-3">
        <div className="row">
          <div className="col-md-6 pb-2 pb-md-0 mb-4 mb-md-0 order-1">
            <div className="pe-lg-5 text-center">
              <img src={woman} className="rounded-3" alt="Image" />
            </div>
          </div>
          <div className="col-md-6 align-content-center order-2">
            <h2 className="mb-sm-4">
              Automatisez l'interface entre notre solution de gestion
              d’impressions et l'ERP ipercom
            </h2>
            <p className="d-md-none d-xl-block pb-2 pb-md-3 mb-3">
              La plateforme ipercom facilite l'intégration et le suivi
              automatisé des compteurs grâce aux solutions de services
              d’impressions gérés. ipercom est compatible avec diverses
              solutions comme kdfm,KPAX, Print Fleet et Kyocera Print Services.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Automate;
