import send from "assets/img/ipercom/ressources/portfolio/projet realisé/ipercloudimagesend_16_11zon.webp";

const HowDidWeProceed = () => {
  return (
    <section className="container my-5">
      <div className="row">
        <div className="col-md-6 pb-2 pb-md-0 mb-4 mb-md-0 order-1">
          <div className="pe-lg-5 text-center">
            <img src={send} className="rounded-3" alt="Image" style={{ width: "100%" }} />
          </div>
        </div>
        <div className="col-md-6 order-2">
          <h2 className="mb-sm-4">Comment avons-nous procédé ?</h2>
          <p className="d-md-none d-xl-block pb-2 pb-md-3 mb-3">
            Nous avons commencé par analyser les besoins spécifiques du client en termes de stockage de données et de
            collaboration. Ensuite, nous avons proposé iper-cloud comme solution, mettant en avant ses fonctionnalités
            flexibles d'hébergement, sa personnalisation selon les besoins, sa protection intégrale des données et sa
            compatibilité avec les réglementations en matière de confidentialité et de sécurité.
            <br></br><br></br>
            Une fois que le client a accepté la solution proposée, nous avons procédé à l'implémentation d'iper-cloud en
            personnalisant l'environnement selon les exigences de l'entreprise. Cela incluait la configuration des
            options d'hébergement, la mise en place de mesures de sécurité avancées pour protéger les données sensibles,
            et la formation des équipes sur l'utilisation de la plateforme.
          </p>
        </div>
      </div>
    </section>
  );
};

export default HowDidWeProceed;
