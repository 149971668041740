import React from "react";
import ERP from "assets/img/ipercom/ressources/portfolio/projet realisé/ERPImage_33_11zon.webp";

const FinalResult = () => {
  return (
    <section className="container my-5">
      <div className="row">
        <div className="col-md-6 pb-2 pb-md-0 mb-4 mb-md-0 order-2">
          <div className="pe-lg-5 text-center">
            <img src={ERP} className="rounded-3" alt="Image" style={{ width: "100%" }} />
          </div>
        </div>
        <div className="col-md-6 order-1">
          <h2 className="mb-sm-4">Résultat final</h2>
          <p className="d-md-none d-xl-block pb-2 pb-md-3 mb-3">
            L'intégration du système ERP a permis un accès rapide aux données centralisées, évitant ainsi les doublons.
            L'automatisation des tâches répétitives a libéré du temps pour des activités à haute valeur ajoutée.
            Grâce aux outils d'analyse et de reporting, la visibilité et la transparence ont été améliorées,
            facilitant des décisions basées sur des données en temps réel. La simplification des processus a rationalisé
            les opérations et optimisé la productivité.
            Les petites entreprises ont bénéficié d'une solution ERP accessible financièrement, parfaitement adaptée à
            leurs besoins spécifiques,
            augmentant ainsi leur compétitivité sur le marché.
          </p>
        </div>
      </div>
    </section>
  );
};

export default FinalResult;
