import React from "react";
import logo from "assets/img/ipercom/services/Solution proposes/iperx/ipercom logo__Horizontal_Full 1.svg";

const BusinessEstimate = () => {
  return (
    <>
      <section className="container pt-xl-3 pb-2">
        <div className="row pb-2 pb-md-4 pb-lg-5">
          <div className="col-xl-5 d-flex flex-column order-2 order-lg-1 me-5 pe-5">
            <h2 className="pb-4 mb-1 mb-lg-3">
              Entreprise que nous servons
            </h2>
            <div className="mb-md-n2 mb-xxl-n3">
              <div className="swiper-wrapper text-center text-lg-start">
                <div className="swiper-slide">
                  <h4 className="h4 mb-4">
                    <p className="nav-link justify-content-center justify-content-lg-start fw-bold p-0">
                      iper-X
                      <i className="bx bx-right-arrow-alt text-primary fs-3 fw-normal ms-2 mt-1" />
                    </p>
                  </h4>
                  <p>
                    Nous croyons en l'importance de la collaboration avec nos
                    clients. En travaillant en étroite collaboration avec vous,
                    nous sommes en mesure de comprendre vos besoins spécifiques,
                    vos objectifs commerciaux et vos valeurs fondamentales. Cela
                    nous permet de créer des solutions personnalisées qui
                    répondent à vos besoins uniques et qui vous aident à
                    atteindre vos objectifs de croissance et de succès
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 align-content-center order-1 order-lg-2">
            <div className="mt-lg-n5">
              <div className="swiper-tab active">
                <img
                  src={logo}
                  className="rounded-3"
                  alt="Image"
                  style={{ height: "60px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BusinessEstimate;
