import classNames from "classnames";
import Footer from "components/footers/Footer";
import { useMainLayoutContext } from "providers/old/MainLayoutProvider";
import { Container } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import AuthNavbar from "../components/navbars/AuthNavbar";

const AuthLayout = () => {
  const { contentClass } = useMainLayoutContext();

  return (
    <Container fluid className="px-0">
      <div className={classNames(contentClass, "page-wrapper")} style={{}}>
        <AuthNavbar />
        <Outlet />
        <Footer />
      </div>
    </Container>
  );
};

export default AuthLayout;
