import React from "react";
import headphone from "assets/img/ipercom/services/Solution proposes/maintenance and support tech/cms.svg";
import screen from "assets/img/ipercom/icons/screenOurCrm.svg";
import graph from "assets/img/ipercom/services/ERP/company/icons/graph.svg";
import lauch from "assets/img/ipercom/services/ERP/company/icons/launch.svg";
import door from "assets/img/ipercom/services/logiciel-de-facturation/telecom/door.png";
import interogation from "assets/img/ipercom/services/logiciel-de-facturation/telecom/interogation.png";

const Extends = () => {
  return (
    <>
      {/* Services */}
      <section className="bg-secondary pb-md-2 pb-lg-5 mb-3">
        <div
          className="d-none d-lg-block"
          style={{ marginTop: "-60px", paddingTop: 60 }}
        />
        <div className="container pb-4 pt-5">
          <h2 className="text-center text-md-start mb-lg-4 pt-1 pt-md-4">
            Étendez votre entreprise dans le domaine des télécommunications
            grâce à l'ERP ipercom
          </h2>
          <div className="col-md-8 text-center text-md-start">
            <p className="fs-md text-muted mb-md-3">
              Explorez de nouveaux horizons dans les télécommunications avec
              l'ERP ipercom pour étendre votre entreprise.
            </p>
          </div>
          <div className="row row-cols-1 row-cols-md-2">
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <p
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 me-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={graph}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="d-inline-flex align-items-center">
                    Optimisez votre autonomie dans votre stratégie commerciale
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    Développez vos offres personnalisées (Mobile, Centrex, VoIP,
                    Data…) et gérez la facturation de vos contrats télécoms en
                    marque blanche via ipercom. Ainsi, vous renforcez votre
                    indépendance vis-à-vis des opérateurs télécoms et valorisez
                    votre marque auprès de vos clients.
                  </p>
                </div>
              </p>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <p
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={lauch}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="d-inline-flex align-items-center">
                    Contrôlez la facturation des contrats télécoms avec
                    précision
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    ipercom automatise la collecte et le contrôle des fichier
                    CDR, ainsi que la gestion des lignes téléphoniques avec les
                    principaux opérateurs télécoms comme Alphalink, Dstny,
                    Sewan, Keyyo, Networth, Unyc, etc.
                  </p>
                </div>
              </p>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <p
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={door}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="d-inline-flex align-items-center">
                    Simplification des commandes à partir des catalogues
                    fournisseurs
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    ipercom a développé une interface EDI reliant son système
                    ERP aux catalogues des fabricants et grossistes (comme
                    Alliance.com, Itancia, Edox, etc.), facilitant ainsi la
                    création d'articles et l'émission des commandes. Ce système
                    garantit une base d'articles constamment actualisée sur
                    ipercom.
                  </p>
                </div>
              </p>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <p
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={screen}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="d-inline-flex align-items-center">
                    Booster la commercialisation de services et solutions
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    ipercom conçu pour la gestion de projets et services, permet
                    d'optimiser la création et le suivi des interventions dès la
                    commande, assure le suivi des livraisons et simplifie la
                    facturation des services fournis.
                  </p>
                </div>
              </p>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <p
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={interogation}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="d-inline-flex align-items-center">
                    Améliorer la gestion et le suivi des tickets
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    ipercom propose une application Android intuitive pour smartphones et tablettes.
                    Elle offre aux techniciens un accès instantané à leurs outils professionnels :
                    planification des interventions, gestion des articles, accès à l'historique technique,
                    consultation des stocks, transmission des rapports d’intervention et signature électronique.
                  </p>
                </div>
              </p>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <p
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={headphone}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="d-inline-flex align-items-center">
                    Fournir un service clientèle exceptionnel
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    ipercom offre un extranet client intégré à l'ERP pour une gestion
                    efficace des interventions ainsi qu'une visualisation des consommations
                    et factures télécoms. Accès rapide pour les clients à tous leurs documents
                    en un lieu, avec une meilleure réception grâce au couplage téléphone-ERP.
                  </p>
                </div>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Extends;
