import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";
import CareerList from "components/modules/a-propos/carriere/carriere/CareerList";
import Head from "components/modules/a-propos/carriere/carriere/Head";
import LastNews from "components/modules/showcase/LastNews";
import React from "react";

const Career = () => {
  return (
    <>
      <Breadcrumbs />
      <Head />
      <CareerList />
      <LastNews />
    </>
  );
};

export default Career;
