import React from "react";
import peopleWorking from "assets/img/ipercom/a-propos/historyAgence/peopleWorking.webp";

const WhyChooseIpercom = () => {
  return (
    <>
      <section
        style={{ width: "80%", margin: "auto" }}
        className="pb-5 mt-5 pt-5"
      >
        <div className="row">
          <div className="col-lg-4 text-center text-lg-start pb-3 pb-lg-0 mb-4 mb-lg-0 ">
            <h2 className=" mb-lg-4 col-lg-8">Pourquoi choisir ipercom?</h2>
            <p className="pb-4 mb-0 mb-lg-3 col-lg-10">
              Avec 14 ans d'expérience dans le secteur de l'IT, ipercom se
              distingue par sa capacité à offrir des solutions complètes et
              personnalisées qui répondent aux besoins uniques de chaque client.
              Notre engagement envers l'excellence, l'innovation et la
              satisfaction client nous a permis de bâtir des relations de longue
              durée avec nos partenaires et clients. Nous sommes dédiés à rester
              à la pointe de la technologie pour vous fournir les services les
              plus avancés et efficaces du marché.
              <br />
              <br />
              Contactez-nous aujourd'hui pour découvrir comment ipercom peut
              transformer votre entreprise grâce à des solutions IT sur mesure.
            </p>
            <img src={peopleWorking} alt="people working" />
          </div>
          <div className="col-lg-8 ">
            <div className="row">
              <div className="col">
                <div className="card card-hover bg-secondary border-0 mb-4">
                  <div className="card-body d-flex align-items-start">
                    <div className="ps-4">
                      <h3 className="h5 pb-2 mb-1">
                        Hébergement de notre Solutions sur AWS :
                      </h3>
                      <p className="pb-2 mb-1">
                        Chez ipercom, nous sommes fiers de proposer à nos
                        clients un hébergement de solutions de premier plan
                        grâce à notre partenariat avec Amazon Web Services
                        (AWS), le leader mondial des fournisseurs de cloud. En
                        tirant parti de la puissance, de la flexibilité et de la
                        sécurité d'AWS, nous sommes en mesure d'offrir des
                        solutions d'hébergement qui non seulement répondent aux
                        exigences de nos clients mais les surpassent.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card card-hover bg-secondary border-0 mb-4">
                  <div className="card-body d-flex align-items-start">
                    <div className="ps-4">
                      <h3 className="h5 pb-2 mb-1">
                        Performance et Scalabilité :
                      </h3>
                      <p className="pb-2 mb-1">
                        Grâce à AWS, nous proposons des solutions d'hébergement
                        hautement performantes et évolutives qui peuvent
                        s'adapter dynamiquement à la croissance de votre
                        entreprise. Que vous connaissiez un pic d'activité
                        temporaire ou une croissance constante, notre
                        infrastructure peut évoluer pour répondre à vos besoins
                        sans interruption de service.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card card-hover bg-secondary border-0 mb-4">
                  <div className="card-body d-flex align-items-start">
                    <div className="ps-4">
                      <h3 className="h5 pb-2 mb-1"> Solutions Métiers :</h3>
                      <p className="pb-2 mb-1">
                        Nos solutions métiers sont conçues pour optimiser les
                        processus spécifiques à votre secteur d'activité. En
                        combinant notre expertise technique avec une
                        compréhension approfondie de divers domaines d'activité,
                        nous proposons des solutions qui améliorent la
                        productivité, la gestion des ressources et la prise de
                        décisions stratégiques.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="card card-hover bg-secondary border-0 mb-4">
                  <div className="card-body d-flex align-items-start">
                    <div className="ps-4">
                      <h3 className="h5 pb-2 mb-1">
                        Bureautique et Informatique :
                      </h3>
                      <p className="pb-2 mb-1">
                        Nous offrons une large gamme de solutions bureautiques
                        et informatiques pour améliorer l'efficacité de votre
                        espace de travail. De la fourniture de matériel
                        informatique de pointe à la mise en place de systèmes de
                        gestion électronique des documents, nos solutions sont
                        conçues pour faciliter votre quotidien professionnel.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card card-hover bg-secondary border-0 mb-4">
                  <div className="card-body d-flex align-items-start">
                    <div className="ps-4">
                      <h3 className="h5 pb-2 mb-1">Téléphonie :</h3>
                      <p className="pb-2 mb-1">
                        Notre expertise en téléphonie englobe des solutions de
                        télécommunication avancées, y compris la VoIP, les
                        systèmes de conférence, et les solutions de téléphonie
                        mobile pour entreprises. Nous vous aidons à rester
                        connecté, que ce soit au bureau ou en déplacement, avec
                        des solutions de communication fiables et de haute
                        qualité.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card card-hover bg-secondary border-0 mb-4">
                  <div className="card-body d-flex align-items-start">
                    <div className="ps-4">
                      <h3 className="h5 pb-2 mb-1">
                        Notre Équipe de Développement :
                      </h3>
                      <p className="pb-2 mb-1">
                        Au cœur d'ipercom, notre équipe de développement est
                        composée de talents exceptionnels dans le domaine de la
                        technologie. Avec une passion pour l'innovation et une
                        expertise approfondie dans diverses stacks
                        technologiques, notre équipe travaille en étroite
                        collaboration avec chaque client pour créer des
                        solutions qui non seulement répondent à leurs besoins
                        mais les dépassent. Du front-end au back-end, de la
                        conception UX/UI au développement mobile, nous couvrons
                        tous les aspects pour vous fournir des solutions
                        complètes et performantes.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhyChooseIpercom;
