import React from 'react';

import animationData from 'assets/json/animation-financial-landing.json';
import Lottie from "lottie-react"; // Adjust the import path as needed

const ResponseSection = () => {
  // Lottie options
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <section className="container">
      <div
        className="card bg-white border-primary shadow-sm px-4 px-lg-0"
        data-bs-theme="light"
      >
        <div className="row align-items-center py-3">
          <div className="col-xl-5 col-md-6 offset-lg-1 text-center text-md-start py-4 py-lg-5">
            <h2>Notre réponse aux formations</h2>
            <h3 className="h4 text-primary">Profitez d'un accompagnement</h3>
            <p className="mb-2">
              Optez pour une formation à distance sur la solution accessible et
              personnalisée, pour répondre à vos besoins spécifiques.
              <br />
              <br />
            </p>
            <a href="/services/services-proposes/formation" className="btn btn-primary">
              En savoir plus
            </a>
          </div>
          <div className="col-xl-6 col-lg-5 col-md-6">
            {/* Lottie animation */}
            <div className="mx-auto" style={{ maxWidth: '416px' }}>
              <Lottie {...defaultOptions} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResponseSection;
