import React from "react";
import Message from "assets/img/ipercom/services/Solution proposes/developpement mobile/message.svg";
import device from "assets/img/ipercom/services/Solution proposes/logiciel caisse/device_28_11zon.webp";
import InfoBouton from "components/modules/showcase/InfoBouton";

const Possibility = () => {
  return (
    <section className="container">
      <div className="d-flex align-items-center ">
        <div className="order-md-2 align-content-center">
          <img
            src={Message}
            alt="Analytiques de notre CRM"
            className="img-fluid"
            style={{ width: "48%" }}
          />
          <img
            src={device}
            alt="Analytiques de notre CRM"
            className="img-fluid mb-4"
            style={{ width: "50%" }}
          />
        </div>
        <div className="col-md-6 col-xl-5 order-md-1">
          <h2 className="pb-2 pb-lg-3">
            Les possibilités offertes par les applications mobiles
          </h2>
          <p className="pb-2 mb-4 mb-lg-5 ">
            Explorez les innombrables possibilités offertes par les applications
            mobiles pour dynamiser votre entreprise et atteindre de nouveaux
            sommets.
          </p>
          <InfoBouton />
        </div>
      </div>
    </section>
  );
};

export default Possibility;
