import final from "assets/img/ipercom/ressources/portfolio/projet realisé/iper-cloudApp.webp";
import { useParams } from "react-router-dom";

const WhatTheProject = () => {
  const { name } = useParams();
  return (
    <>
      <section className="container my-5">
        <h1 className="pb-3">
          iper-cloud
        </h1>
        <h2 className="mb-5">{name}</h2>
        <div className="row">
          <div className="col-md-6 pb-2 pb-md-0 mb-4 mb-md-0 order-1">
            <div className="pe-lg-5 text-center">
              <img src={final} className="rounded-3" alt="Image" style={{ width: "74%" }} />
            </div>
          </div>
          <div className="col-md-6 order-2">
            <h2 className="mb-sm-4">Quel était le projet ?</h2>
            <p className="d-md-none d-xl-block pb-2 pb-md-3 mb-3">
              Le projet consistait à optimiser la collaboration et le stockage de données pour une entreprise en
              utilisant la solution iper-cloud. L'objectif était de transformer les processus de travail en fournissant
              des capacités avancées de stockage de données et de collaboration sécurisée évolutive.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhatTheProject;
