import React from 'react';


const FindReseller = () => {
  return (
    <div className="card border-light shadow-lg p-sm-4 p-md-5">
      <div className="bg-dark position-absolute top-0 start-0 w-100 h-100 rounded-3 d-none d-dark-mode-block" />
      <div className="card-body position-relative zindex-2">
        <h1 className="card-title mb-4">Trouver un revendeur</h1>
        <form className="row g-4 needs-validation align-items-start" noValidate>
          <div className="col-12">
            <label htmlFor="adresse" className="form-label fs-base">
              Adresse
            </label>
            <input
              type="text"
              className="form-control form-control-lg"
              id="adresse"
              required
            />
            <div className="invalid-feedback">Veuillez entrer une adresse !</div>
          </div>
          <div className="col-12">
            <label htmlFor="ville" className="form-label fs-base">
              Ville
            </label>
            <input
              type="text"
              className="form-control form-control-lg"
              id="ville"
              required
            />
            <div className="invalid-feedback">Veuillez entrer une ville !</div>
          </div>
          <div className="col-12">
            <label htmlFor="pays" className="form-label fs-base">
              Pays
            </label>
            <input
              type="text"
              className="form-control form-control-lg"
              id="pays"
              required
            />
            <div className="invalid-feedback">Veuillez entrer un pays !</div>
          </div>
          <div className="col-12">
            <label htmlFor="region" className="form-label fs-base">
              Région
            </label>
            <input
              type="text"
              className="form-control form-control-lg"
              id="region"
              required
            />
            <div className="invalid-feedback">Veuillez entrer une région !</div>
          </div>
          <div className="col-12 pt-2 pt-sm-3">
            <button
              type="submit"
              className="btn btn-lg btn-primary w-100 w-sm-auto"
              style={{pointerEvents: "none"}}
            >
              Rechercher
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FindReseller;
