import React from "react";
import appel from 'assets/img/ipercom/services/TelephonySolution/software/appelLobby_7_11zon.webp';
import appLight from 'assets/img/ipercom/services/TelephonySolution/software/appstore-light.svg';
import appDark from 'assets/img/ipercom/services/TelephonySolution/software/appstore-dark.svg';
import googleLight from 'assets/img/ipercom/services/TelephonySolution/software/googleplay-light.svg';
import googleDark from 'assets/img/ipercom/services/TelephonySolution/software/googleplay-dark.svg';
import logo from "assets/img/ipercom/services/Solution proposes/iperx/ipercom logo__Horizontal_Full 1.svg";

const Solution = () => {
  return (
    <>
      <section className="container">
        <div className="d-flex align-items-center justify-content-between">
          <h1 className="pb-2 pb-lg-3">Logiciel de téléphonie iper-X</h1>
          <img
            src={logo}
            className="ms-auto"
            alt="logo"
            style={{ height: "60px" }}
          />
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="pe-lg-5">
              <img src={appel} className="rounded-3" alt="Image" />
            </div>
          </div>
          <div className="col-md-6  align-content-center">
            <h2 className="mb-sm-4">Solution iper-X</h2>
            <p className="d-md-none d-xl-block pb-2 pb-md-3 mb-3">
              iper-X représente une plateforme de communication robuste et
              complète, offrant la possibilité d'interagir avec vos partenaires
              professionnels et votre clientèle, sans contrainte géographique ni
              temporelle.
            </p>
            <div className="d-flex flex-column flex-sm-row justify-content-center justify-content-md-start">
              <a
                href="https://play.google.com/store/apps/dev?id=6388058322571838005"
                className="btn btn-dark btn-lg px-3 py-2 me-sm-3 me-lg-4 mb-3"
              >
                <img
                  src={appLight}
                  className="light-mode-img"
                  width={124}
                  alt="App Store"
                />
                <img
                  src={appDark}
                  className="dark-mode-img"
                  width={124}
                  alt="App Store"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/dev?id=6388058322571838005"
                className="btn btn-dark btn-lg px-3 py-2 mb-3"
              >
                <img
                  src={googleLight}
                  className="light-mode-img"
                  width={139}
                  alt="Google Play"
                />
                <img
                  src={googleDark}
                  className="dark-mode-img"
                  width={139}
                  alt="Google Play"
                />
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Solution;
