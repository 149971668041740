import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";
import HowWeWork from "components/modules/services/inventory-management/HowWeWork";
import Inventory from "components/modules/services/inventory-management/Inventory";
import PersonalizationInventory from "components/modules/services/inventory-management/PersonalizationInventory";
import WhatWeOffert from "components/modules/services/inventory-management/WhatWeOffert ";
import React from "react";

const InventoryMgmt = () => {
  return (
    <>
      <Breadcrumbs />
      <Inventory />
      <WhatWeOffert />
      <HowWeWork />
      <PersonalizationInventory />
    </>
  );
};

export default InventoryMgmt;
