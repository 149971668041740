import React from "react";
import { Col, Row } from "react-bootstrap";
import logo from "assets/img/ipercom/logo/ipercom.png";

const BillingsDetails = () => {
  return (
    <section className="container">
      <h1 className="me-3">Facturation</h1>
      <Row className="py-4 bg-secondary fs-md justify-content-around">
        <Col lg={3} className="d-flex flex-column my-4 align-content-center">
          <img
            src={logo}
            alt="Logo Ipercom"
            style={{ width: "70%" }}
            className="mb-3"
          />
          <p className="my-1">
            <span className="fw-bold">Numéro de facture :</span> #FLR978282
          </p>
          <p className="my-1">
            <span className="fw-bold">Date de facture :</span> 19.06.2019
          </p>
        </Col>
        <Col lg={6} className="">
          <div className="mt-2 d-flex">
            <p className="me-5">
              <span className="fw-bold ">
                Vendu par :<br></br>
              </span>{" "}
              Ipercom <br></br> 8 allée cantillac, <br></br>Pompignac
            </p>
            <p className="ms-5">
              <span className="fw-bold">
                PAN No : <br></br>{" "}
              </span>{" "}
              XVCJ963782008
            </p>
          </div>
          <div className="mt-2 d-flex justify-content-between">
            <p>
              <span className="fw-bold">
                Numéro d'enregistrement à la TPS :<br></br>
              </span>{" "}
              IX9878123TC
            </p>
            <p>
              <span className="fw-bold">
                N° de commande :<br></br>
              </span>{" "}
              A-8934792734
            </p>
            <p>
              <span className="fw-bold">
                Date de commande :<br></br>
              </span>{" "}
              19.06.2019
            </p>
          </div>
        </Col>
        <Col lg={2} className="flex-column">
          <address>
            <strong>Adresse de facturation :</strong>
            <br />
            John Doe,
            <br />
            36, Green Downtown, Golden road, FL
            <br />
            johndoe@jeemail.com
            <br />
            +334933029030
          </address>
        </Col>
      </Row>
    </section>
  );
};

export default BillingsDetails;
