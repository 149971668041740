import React from "react";
import computer from "assets/img/ipercom/services/Solution proposes/iperx/computer_1_11zon.webp";

const Head = () => {
  return (
    <section
      className="jarallax bg-dark pt-2 pt-lg-3 pb-lg-5"
      data-jarallax=""
      data-speed="0.35"
      data-bs-theme="dark"
      style={{ height: 609 }}
    >
      <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-40" />
      <div
        className="jarallax-img"
        style={{ backgroundImage: `url(${computer})` }}
      />

      <div className="position-relative pb-5 container " style={{ top: 150 }}>
        {/* Title */}
        <h1> Fiche de Poste : Développeur full stack</h1>
        <p className="fs-xl text-light opacity-70">
          ipercom est une entreprise innovante évoluant dans le secteur de la
          création de logiciels et d’application. Notre mission est d’améliorer
          l’expérience utilisateur. Nous recherchons actuellement un développeur
          full stack passionné pour rejoindre notre équipe dynamique et
          contribuer à la réalisation de nos projets ambitieux.
        </p>
        {/* Stats */}
      </div>
    </section>
  );
};

export default Head;
