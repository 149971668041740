import React from 'react';

const cardImageStyle = {
  maxWidth: "100%",
  height: "auto"
};

const cardStyle = {
  maxWidth: "285px",
  margin: "auto"
};

const Head = () => {
  return (
    <>
      <section className="container">
        <div className="row align-items-end gy-3 mb-4 pb-lg-3 pb-1">
          <div className="col-lg-5 col-md-4">
            <h1 className="mb-2 mb-md-0">Abonnements</h1>
          </div>
        </div>
      </section>
    </>
  );
};

export default Head;
