import AdvicesLit from "components/modules/ressources/blog/Advices/conseils pratiques/AdvicesList";
import React from "react";
import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";
import ClientTalk from "components/modules/showcase/ClientTalk";
import Partners from "components/modules/showcase/Partners";

const Advices = () => {
  return (
    <>
      <Breadcrumbs />
      <AdvicesLit />
      <ClientTalk />
      <Partners />
    </>
  );
};

export default Advices;
