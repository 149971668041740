import React from 'react';

const RegisterElement = () => {
  return (
    <div className="col-md-8 offset-lg-1 pb-5 mb-lg-2 pt-md-5 mt-n3 mt-md-0">
      <div className="ps-md-3 ps-lg-0 mt-md-2 pt-md-4 pb-md-2">
        <div className="d-flex align-items-center justify-content-between pt-xl-1 mb-3 pb-3">
          <h1 className="h2 mb-0">Éléments enregistrés</h1>
          <button type="button" className="btn btn-outline-danger px-3 px-sm-4">
            <i className="bx bx-trash-alt fs-xl me-sm-2" />
            <span className="d-none d-sm-inline">Supprimer tout</span>
          </button>
        </div>
        <div className="row row-cols-1 row-cols-sm-2 gx-3 gx-lg-4">
          {/* Item */}
          <div className="col pb-lg-2 mb-4">
            <article className="card h-100 border-0 shadow-sm">
              <div className="position-relative">
                <a
                  href=""
                  className="d-block position-absolute w-100 h-100 top-0 start-0"
                  aria-label="Image de couverture"
                ></a>
                <a
                  href="#"
                  className="btn btn-icon btn-light bg-white border-white btn-sm rounded-circle position-absolute top-0 end-0 zindex-2 me-3 mt-3"
                  data-bs-toggle="tooltip"
                  data-bs-placement="left"
                  title="Supprimer"
                  aria-label="Supprimer"
                >
                  <i className="bx bxs-bookmark" />
                </a>
              </div>
              <div className="card-body pb-3">
                <h3 className="h5 mb-2">
                  <a href="">
                    Créateur de Personnage Blender v2.0 pour la Conception de
                    Jeux Vidéo
                  </a>
                </h3>
                <p className="fs-sm mb-2">Par Ralph Edwards</p>
                <p className="fs-lg fw-semibold text-primary mb-0">
                  1250€/Jour hors frais*{" "}
                </p>
              </div>
              <div className="card-footer d-flex align-items-center fs-sm text-muted py-4">
                <div className="d-flex align-items-center me-4">
                  <i className="bx bx-time fs-xl me-1" />
                  160 heures
                </div>
                <div className="d-flex align-items-center">
                  <i className="bx bx-like fs-xl me-1" />
                  93% (4.5K)
                </div>
              </div>
            </article>
          </div>
          {/* Item */}
          <div className="col pb-lg-2 mb-4">
            <article className="card h-100 border-0 shadow-sm">
              <div className="position-relative">
                <a
                  href=""
                  className="d-block position-absolute w-100 h-100 top-0 start-0"
                  aria-label="Image de couverture"
                ></a>
                <span className="badge bg-success position-absolute top-0 start-0 zindex-2 mt-3 ms-3">
                  Meilleure vente
                </span>
                <a
                  href="#"
                  className="btn btn-icon btn-light bg-white border-white btn-sm rounded-circle position-absolute top-0 end-0 zindex-2 me-3 mt-3"
                  data-bs-toggle="tooltip"
                  data-bs-placement="left"
                  title="Supprimer"
                  aria-label="Supprimer"
                >
                  <i className="bx bxs-bookmark" />
                </a>
              </div>
              <div className="card-body pb-3">
                <h3 className="h5 mb-2">
                  <a href="">
                    Cours de Développeur Web Fullstack à Partir de Zéro
                  </a>
                </h3>
                <p className="fs-sm mb-2">Par Albert Flores</p>
                <p className="fs-lg fw-semibold text-primary mb-0">
                  1250€/Jour hors frais*{" "}
                </p>
              </div>
              <div className="card-footer d-flex align-items-center fs-sm text-muted py-4">
                <div className="d-flex align-items-center me-4">
                  <i className="bx bx-time fs-xl me-1" />
                  220 heures
                </div>
                <div className="d-flex align-items-center">
                  <i className="bx bx-like fs-xl me-1" />
                  94% (4.2K)
                </div>
              </div>
            </article>
          </div>
          {/* Item */}
          <div className="col pb-lg-2 mb-4">
            <article className="card h-100 border-0 shadow-sm">
              <div className="position-relative">
                <a
                  href=""
                  className="d-block position-absolute w-100 h-100 top-0 start-0"
                  aria-label="Image de couverture"
                ></a>
                <a
                  href="#"
                  className="btn btn-icon btn-light bg-white border-white btn-sm rounded-circle position-absolute top-0 end-0 zindex-2 me-3 mt-3"
                  data-bs-toggle="tooltip"
                  data-bs-placement="left"
                  title="Supprimer"
                  aria-label="Supprimer"
                >
                  <i className="bx bxs-bookmark" />
                </a>
              </div>
              <div className="card-body pb-3">
                <h3 className="h5 mb-2">
                  <a href="">
                    Apprenez JMETER à Partir de Zéro sur des Applications en
                    Direct-Test de Performance
                  </a>
                </h3>
                <p className="fs-sm mb-2">Par Jenny Wilson</p>
                <p className="fs-lg fw-semibold text-primary mb-0">
                  1250€/Jour hors frais*{" "}
                </p>
              </div>
              <div className="card-footer d-flex align-items-center fs-sm text-muted py-4">
                <div className="d-flex align-items-center me-4">
                  <i className="bx bx-time fs-xl me-1" />
                  120 heures
                </div>
                <div className="d-flex align-items-center">
                  <i className="bx bx-like fs-xl me-1" />
                  92% (3.8K)
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterElement;
