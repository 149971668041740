import React from "react";
import message from "assets/img/ipercom/ressources/portfolio/projet realisé/message_45_11zon.webp";

const HowDidWeProceed = () => {
  return (
    <section className="container my-5">
      <div className="row">
        <div className="col-md-6 pb-2 pb-md-0 mb-4 mb-md-0 order-1">
          <div className="pe-lg-5 text-center">
            <img src={message} className="rounded-3" alt="Image" style={{ width: "35%" }} />
          </div>
        </div>
        <div className="col-md-6 order-2">
          <h2 className="mb-sm-4">Comment avons-nous procédé ?</h2>
          <p className="d-md-none d-xl-block pb-2 pb-md-3 mb-3">
            Pour mener à bien ce projet, nous avons d'abord réalisé une analyse approfondie des besoins spécifiques de
            l'entreprise, prenant en compte le nombre d'utilisateurs, les fonctionnalités nécessaires, et les
            contraintes budgétaires. En collaboration avec le client, nous avons ensuite sélectionné une solution IPBX
            adaptée, offrant la flexibilité nécessaire pour intégrer des services comme le CRM et les outils de gestion
            des appels. L'installation et la configuration du matériel IPBX ont été effectuées par notre équipe
            technique, incluant la mise en place des trunks SIP pour les communications externes. La migration des
            anciennes lignes téléphoniques vers la nouvelle solution a été soigneusement planifiée et exécutée,
            accompagnée de tests exhaustifs pour assurer la qualité des appels et la stabilité du système. Enfin, nous
            avons assuré la formation des employés et fourni un <a
            href="/services/services-proposes/maintenance-et-support-technique">support technique</a> continu pour
            garantir une adaptation
            fluide à la nouvelle technologie.
          </p>
        </div>
      </div>
    </section>
  );
};

export default HowDidWeProceed;
