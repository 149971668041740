import { useAppContext } from "providers/AppProvider";
import React from "react";

const ThemeToggler = () => {
  const {
    config: { theme },
    toggleTheme
  } = useAppContext();

  return (
    <div
      className="form-check form-switch mode-switch pe-lg-1 me-4"
      data-bs-toggle="mode"
    >
      <input
        type="checkbox"
        className="form-check-input"
        id="theme-mode"
        checked={theme === 'dark'}
        onChange={toggleTheme}
      />
      <label
        className="form-check-label d-none d-sm-block"
        htmlFor="theme-mode"
      >
        Jour
      </label>
      <label
        className="form-check-label d-none d-sm-block"
        htmlFor="theme-mode"
      >
        Nuit
      </label>
      {/*<label*/}
      {/*  className="form-check-label d-none d-sm-block"*/}
      {/*  htmlFor="theme-mode"*/}
      {/*>*/}
      {/*  {theme === 'dark' ? 'Nuit' : 'Jour'}*/}
      {/*</label>*/}
    </div>
  );
};

export default ThemeToggler;
