import LottiePlayer from 'lottie-react';
import React from 'react';

const OurRespEstimate = () => {
  return (
    <>
      <section className="container">
        <div
          className="card bg-white shadow-sm px-4 px-lg-0"
          data-bs-theme="light"
        >
          <div
            className="row align-items-center py-3"
            style={{ width: "80%", margin: "auto" }}
          >
            <div className="col-lg-7 col-md-6 text-center text-md-start py-4 py-lg-5">
              <h2 className="mb-3">Notre réponse aux formations</h2>
              <h3 className="h5 text-primary mb-3">
                Profitez d'un accompagnement
              </h3>
              <p className="mb-4">
                Optez pour une formation à distance sur la solution accessible
                et personnalisée, pour répondre à vos besoins spécifiques.
              </p>
              <p className="mb-2">
                ipercom est certifié qualiopi (en cours d'agrément)
              </p>
              <a href="/services/services-proposes/formation" className="btn btn-primary">
                En savoir plus
              </a>
            </div>
            <div className="col-lg-5 col-md-6">
              <LottiePlayer
                className="mx-auto"
                animationData={require("assets/json/animation-financial-landing.json")}
                loop={true}
                autoPlay={true}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurRespEstimate;
