import React from "react";
import computer from "assets/img/ipercom/services/Solution proposes/iperx/computer_1_11zon.webp";

const Head = () => {
  return (
    <section
      className="jarallax bg-dark pt-2 pt-lg-3 pb-lg-5"
      data-jarallax=""
      data-speed="0.35"
      data-bs-theme="dark"
      style={{ height: 609 }}
    >
      <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-40" />
      <div
        className="jarallax-img"
        style={{ backgroundImage: `url(${computer})` }}
      />

      <div className="position-relative pb-5 container " style={{ top: 150 }}>
        {/* Title */}
        <h1>Fiche de Poste : Chargé d’affaires</h1>
        <p className="fs-xl text-light opacity-70">
          Développer et optimiser les ventes à travers des réseaux de
          distribution et de partenariat à l'échelle nationale. Assurer la mise
          en œuvre de la stratégie commerciale de l'entreprise auprès des
          partenaires et distributeurs pour atteindre ou dépasser les objectifs
          de ventes (Auvergne-Rhône-Alpes, Bourgogne-Franche-Comté, Bretagne,
          Centre-Val de Loire, Grand Est, Hauts-de-France, Ile-de-France,
          Normandie, Nouvelle-Aquitaine, Occitanie, Pays de la Loire, Corse Côte
          d’Azur).
        </p>
        {/* Stats */}
      </div>
    </section>
  );
};

export default Head;
