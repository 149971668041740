import React, { useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useParallaxHooks from "hooks/useParallaxHooks";
import hierarchyERP from "assets/img/ipercom/landing/about_erp/diagramHierarchyERP.svg";

gsap.registerPlugin(ScrollTrigger);

const ERPhierarchySection = () => {
  const containerRef = useRef(null);
  const parallaxElRef = useRef([]);

  useParallaxHooks(containerRef, parallaxElRef, {
    scrollTrigger: {
      trigger: '.gsap',
      start: '+=450 bottom'
    }
  });

  return (
    <section className="container mt-3 mt-sm-4 mt-xl-5">
      <h2 className="h1  pb-3 pb-lg-4">La hiérarchie de notre ERP</h2>

      <div className="d-flex justify-content-center align-items-center mx-xl-n2 mt-n4 px-sm-3">
        <img
          src={hierarchyERP}
          alt="Iper-x"
          style={{ height: "auto", maxWidth: "100%", width: "auto" }}
        />
      </div>
    </section>
  );
};

export default ERPhierarchySection;
