import React from "react";
import livre from 'assets/img/ipercom/ressources/Documentation/livres blancs/livre_22_11zon.webp';

const Head = () => {
  return (
    <section>
      <div className="position-relative overflow-hidden">
        <div className="container-fluid position-relative position-lg-absolute top-0 start-0 h-100">
          <div className="row h-100 me-n4 me-n2">
            <div className="col-lg-7 position-relative">
              <div
                className="jarallax position-absolute top-0 start-0 w-100 h-100 rounded-3 rounded-start-0 overflow-hidden"
                data-jarallax=""
                data-speed="0.3"
              >
                <div
                  className="jarallax-img"
                  style={{
                    backgroundImage: `url(${livre})`
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container position-relative pt-lg-5 px-0 px-lg-3">
          <div className="row mb-5 pb-5">
            <div className="col-xl-6 col-lg-7 offset-lg-5 offset-xl-6 mb-5 pb-5">
              <div className="card bg-dark border-light overflow-hidden px-4">
                <span
                  className="position-absolute"
                  style={{ backgroundColor: "rgba(255,255,255,.05)" }}
                />
                <div className="card-body position-relative">
                  <h1 className="text-light">Livre blanc</h1>
                  <p className="fs-md text-light opacity-70">
                    Téléchargez notre livre blanc pour obtenir des informations
                    précieuses et des conseils stratégiques pour votre
                    entreprise.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Head;
