import ModalDevis from "pages/coming-soon/ModalDevis";
import React, { useState } from "react";
import { Button } from "react-bootstrap";

type ModalType = "devis" | "demo" | "revendeur";


const Description = () => {

  const [showModal, setShowModal] = useState({
    devis: false,
    demo: false,
    revendeur: false
  });

  const handleShow = (modal: ModalType) =>
    setShowModal({ ...showModal, [modal]: true });
  const handleClose = (modal: ModalType) =>
    setShowModal({ ...showModal, [modal]: false });

  return (
    <section className="container my-4">
      <div className="row">
        {/* Sidebar (Course summary) */}
        <aside className="col-lg-4 col-md-5 offset-xl-1 order-md-2 mb-5">
          <div style={{ marginTop: "-96px" }} />
          <div className="pt-5">
            <div className="pt-5 mt-md-3">
              <div className="card shadow-sm p-sm-3">
                <div className="card-body">
                  <h2 className="mb-4">Ce cours comprend :</h2>
                  <ul className="list-unstyled pb-3">
                    <li className="d-flex align-items-center mb-2">
                      <i className="bx bx-file fs-xl text-muted me-2 pe-1" />
                      18 documents
                    </li>
                    <li className="d-flex align-items-center mb-2">
                      <i className="bx bx-download fs-xl text-muted me-2 pe-1" />
                      25 ressources téléchargeables
                    </li>
                    <li className="d-flex align-items-center mb-2">
                      <i className="bx bx-trophy fs-xl text-muted me-2 pe-1" />
                      Certificat d'achèvement
                    </li>
                  </ul>
                  <div className="h3 mb-0">1250€/Jour hors frais*</div>
      
                  <Button
                  variant="primary"
                  className="btn btn-primary btn-lg shadow-primary mt-2"
                  onClick={() => handleShow("devis")}
                >
                  Demander un devis
                </Button>
                </div>
              </div>
              <p className="text-muted">
                *Hors frais de déplacement pour les formations en présentielle{" "}
              </p>
            </div>
          </div>
        </aside>
        {/* Content */}
        <div className="col-xl-7 col-lg-8 col-md-7 order-md-1">
          <h2>Description de la formation</h2>
          <p className="pb-2 mb-3">
            La formation commerciale "Promouvoir efficacement notre solution <a href="/services/solution-telephonie/logiciel-de-telephonie-iper-x"> 
            iper-X</a>" a pour objectif de doter les participants des compétences et
            des stratégies nécessaires pour commercialiser avec succès notre
            solution iper-X sur le marché. Cette formation pratique et axée sur
            les résultats fournira aux participants les outils, les techniques
            et les connaissances indispensables pour présenter, promouvoir et
            vendre la solution iper-X de manière convaincante et efficace.{" "}
          </p>
          <h3 className="mb-4">Ce que vous apprendrez</h3>
          <ul className="list-unstyled mb-5">
            <li className="d-flex align-items-center mb-2">
              <i className="bx bx-check-circle text-primary fs-xl me-2" />
              Comprendre en profondeur les caractéristiques, les avantages et la
              proposition de valeur de la solution iper-X.
            </li>
            <li className="d-flex align-items-center mb-2">
              <i className="bx bx-check-circle text-primary fs-xl me-2" />
              Apprendre à identifier et à cibler les prospects potentiels
              susceptibles de bénéficier le plus de notre solution.
            </li>
            <li className="d-flex align-items-center mb-2">
              <i className="bx bx-check-circle text-primary fs-xl me-2" />
              Maîtriser les techniques de présentation et de communication pour
              articuler clairement les avantages de iper-X.
            </li>
            <li className="d-flex align-items-center mb-2">
              <i className="bx bx-check-circle text-primary fs-xl me-2" />
              Acquérir les compétences nécessaires pour gérer efficacement le
              processus de vente, de la prospection à la conclusion de
              l'affaire.
            </li>
            <li className="d-flex align-items-center mb-2">
              <i className="bx bx-check-circle text-primary fs-xl me-2" />
              Explorer les meilleures pratiques pour développer et entretenir
              des relations solides avec les clients, favorisant ainsi la
              fidélisation et la croissance des ventes.
            </li>
          </ul>
          <h2 className="mb-4">Détails du programme</h2>
          <div className="steps steps-sm">
            <div className="step d-flex align-items-start">
              <div className="step-number">
                <div className="step-number-inner">1</div>
              </div>
              <div className="step-body">
                <h3 className="h4 mb-2">Session 1 : Introduction à la solution d'iper-X</h3>
                <ul className="mb-0">
                  <li>Présentation détaillée de la solution iper-X, de ses fonctionnalités et de ses avantages.</li>
                  <li>Compréhension de la proposition de valeur de iper-X par rapport aux besoins des clients potentiels.</li>
                </ul>
              </div>
            </div>
            <div className="step d-flex align-items-start">
              <div className="step-number">
                <div className="step-number-inner">2</div>
              </div>
              <div className="step-body">
                <h3 className="h4 mb-2">Session 2 : Identification des Clients Potentiels</h3>
                <ul className="mb-0">
                  <li>Analyse du marché et identification des segments de clientèle cibles.</li>
                  <li>Développement de profils de clients idéaux et de stratégies de ciblage efficaces.</li>
                </ul>
              </div>
            </div>
            <div className="step d-flex align-items-start">
              <div className="step-number">
                <div className="step-number-inner">3</div>
              </div>
              <div className="step-body">
                <h3 className="h4 mb-2">Session 3 : Techniques de Présentation et de Communication</h3>
                <ul className="mb-0">
                  <li>Apprentissage des techniques de présentation efficaces pour mettre en valeur les fonctionnalités et les avantages d'iper-X.</li>
                  <li>Développement de compétences en communication pour communiquer de manière persuasive avec les prospects.</li>
                </ul>
              </div>
            </div>
            <div className="step d-flex align-items-start">
              <div className="step-number">
                <div className="step-number-inner">4</div>
              </div>
              <div className="step-body">
                <h3 className="h4 mb-2">Session 4 : Gestion du Processus de Vente</h3>
                <ul className="mb-0">
                  <li>Étapes du processus de vente et meilleures pratiques pour chaque étape.</li>
                  <li>Techniques de négociation pour surmonter les objections et conclure avec succès les transactions.</li>
                </ul>
              </div>
            </div>
            <div className="step d-flex align-items-start">
              <div className="step-number">
                <div className="step-number-inner">5</div>
              </div>
              <div className="step-body">
                <h3 className="h4 mb-2">Session 5 : Développement des Relations Clients</h3>
                <ul className="mb-0">
                  <li>Importance de la fidélisation client et stratégies pour maintenir des relations durables avec les clients.</li>
                  <li>Utilisation d'iper-X comme outil pour fournir un soutien continu et répondre aux besoins changeants des clients.</li>
                </ul>
              </div>
            </div>
            <div className="step d-flex align-items-start">
              <div className="step-number">
                <div className="step-number-inner">6</div>
              </div>
              <div className="step-body">
                <h3 className="h4 mb-2">Session 6 : Évaluation et Certification</h3>
                <ul className="mb-0">
                  <li>Évaluation des compétences et des connaissances acquises tout au long de la formation.</li>
                  <li>Certification de réussite pour les participants ayant satisfait aux critères d'évaluation.</li>
                </ul>
                <div className="mb-4"></div>
                <p>
                  Cette formation est conçue pour les professionnels de la vente et du marketing désireux de maximiser l'impact de leur promotion de la solution iper-X. En combinant des connaissances approfondies de la solution avec des compétences commerciales avancées, cette formation préparera les participants à réussir dans un environnement commercial dynamique et concurrentiel.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalDevis showModal={showModal.devis} handleClose={() => handleClose("devis")} />

    </section>
  );
};

export default Description;
