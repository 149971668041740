import working from "../../../../../assets/img/ipercom/services/Solution proposes/integration logiciel/working_2_11zon.webp";

const MethodWorking = () => {
  return (
    <>
      <section className="bg-secondary px-2 pb-5 mb-5 ">
        <div
          className="container row pt-xl-3"
          style={{ width: "80%", margin: "auto" }}
        >
          <div className="col-md-5 col-lg-6 text-center text-md-start pb-5 order-2 align-content-center">
            <h2 className="mb-4">Méthode de travail</h2>
            <p className="fs-lg pb-lg-3 mb-4">
              Avec notre approche agile et notre attention aux détails, nous
              vous garantissons une expérience fluide et une évolutivité
              maximale pour répondre à tous vos besoins en matière d'intégration
              logicielle. Confiez-nous la création de vos API et propulsez votre
              entreprise vers de nouveaux sommets technologiques.{" "}
            </p>
          </div>
          <div className="col-lg-6 col-md-7 order-1">
            <img
              src={working}
              className="rounded-3 shadow-sm mx-auto d-block"
              alt="meeting"
              style={{ marginTop: "-80px" }}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default MethodWorking;
