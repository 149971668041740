import React, { useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useParallaxHooks from "hooks/useParallaxHooks";
import hierarchy2 from "assets/img/ipercom/landing/about_erp/PAGE POUR SITE IPERCOM V2.webp";

gsap.registerPlugin(ScrollTrigger);

const ERPhierarchy = () => {
  const containerRef = useRef(null);
  const parallaxElRef = useRef([]);

  useParallaxHooks(containerRef, parallaxElRef, {
    scrollTrigger: {
      trigger: '.gsap',
      start: '+=450 bottom'
    }
  });

  return (
    <section className="container pt-5 mt-3 mt-sm-4 mt-xl-5">
      <h2 className="h1 text-center pb-3 pb-lg-4">
        La hiérarchie de notre ERP
      </h2>

      <div className="d-flex justify-content-center align-items-center mx-xl-n2 mt-n4 px-sm-3">
        <img
          src={hierarchy2}
          alt="Iper-x"
          width="800" // Ajouter la largeur explicite
          height="600" // Ajouter la hauteur explicite
          style={{
            marginLeft: "20%",
          }}
        />
      </div>
    </section>
  );
};

export default ERPhierarchy;
