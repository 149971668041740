import { Row, Col } from "react-bootstrap";
import CartSummaryCard from "./card/CartSummaryCard";
import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";
import CartTable from "./card/CartTable";

const Card = () => {
  return (
    <>
      <Breadcrumbs />
      <div className="m-auto my-5 pb-5" style={{ width: "85%" }}>
        <h1>Panier</h1>
        <Row>
          <Col xl={9}>
            <CartTable />
          </Col>
          <Col xl={3}>
            <CartSummaryCard />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Card;
