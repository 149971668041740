import React from "react";
import Image from "assets/img/ipercom/services/logiciel-de-facturation/telecom/mensWorking_40_11zon.webp";

const Head = () => {
  return (
    <section
      className="jarallax bg-dark"
      data-jarallax=""
      data-speed="0.35"
      data-bs-theme="dark"
      style={{ height: 509 }}
    >
      <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-40" />
      <div
        className="jarallax-img opacity-70"
        style={{ backgroundImage: `url(${Image})` }}
      />

      <div
        className="position-relative pb-5 text-center"
        style={{ top: 110, width: "50%", margin: "auto" }}
      >
        {/* Title */}
        <h1 style={{ fontSize: "62px" }}>Logiciel de facturation télécom</h1>
        <p className="fs-xl text-light opacity-70">
          Gérez facilement vos transactions télécom avec un logiciel de
          facturation spécialisé. Simplifiez vos opérations financières et
          maximisez votre rentabilité.
        </p>
        {/* Stats */}
      </div>
    </section>
  );
};

export default Head;
