import React from "react";
import Impact from "components/modules/services/company/middle/Impact";
import Solution from "components/modules/services/company/middle/Solution";
import Head from "components/modules/services/company/middle/Head";
import Avantages from "components/modules/services/company/middle/Avantages";
import Partners from "components/modules/showcase/Partners";
import ClientTalk from "components/modules/showcase/ClientTalk";
import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";

const ErpForMiddleCompany = () => {
  return (
    <>
      <Head />
      <Breadcrumbs />
      <Impact />
      <Solution />
      <Avantages />
      <ClientTalk />
      <Partners />
    </>
  );
};

export default ErpForMiddleCompany;
