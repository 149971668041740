import mobile from "assets/img/ipercom/services/Solution proposes/developpement mobile/mobile-app.svg";
import screen from "assets/img/ipercom/icons/screenOurCrm.svg";
import localisation from "assets/img/ipercom/services/logiciel-de-facturation/edi/localisation_23_11zon.webp";
import computer from "assets/img/ipercom/icons/computerOurCrm.webp";

const Solution = () => {
  return (
    <>
      {/* Services */}
      <section className="bg-secondary pb-md-2 pb-lg-5 mb-3">
        <div
          className="d-none d-lg-block"
          style={{ marginTop: "-60px", paddingTop: 60 }}
        />
        <div className="container pb-4 pt-5">
          <h2 className="text-center text-md-start mb-lg-4 pt-1 pt-md-4">
            Les solutions EDI les plus utilisées
          </h2>
          <div className="col-md-8 text-center text-md-start">
            <p className="fs-md text-muted mb-md-3">
              Découvrez les solutions EDI incontournables pour optimiser vos
              échanges de données B2B. Elles garantissent sécurité, rapidité et
              précision, s'adaptant à divers secteurs d'activité.
            </p>
          </div>
          <div className="row row-cols-1 row-cols-md-2">
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <p
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 me-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={computer}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="d-inline-flex align-items-center">
                    EDI cloud pour une intégration complète{" "}
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    Les services EDI <a href="/services/services-proposes/solution-iper-cloud">cloud</a> hébergent le logiciel sur un serveur
                    distant géré par le prestataire. Avec juste une connexion
                    internet et un navigateur, les utilisateurs accèdent,
                    configurent et supervisent facilement leurs outils d'échange
                    de données électroniques pour une solution d'intégration EDI
                    efficace.
                  </p>
                </div>
              </p>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <p
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={localisation}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="d-inline-flex align-items-center">
                    EDI en local{" "}
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    L'installation d'une solution EDI sur site nécessite de
                    réserver un espace dans l'entreprise pour établir
                    l'infrastructure physique et logicielle requise pour activer
                    l'architecture EDI B2B.
                  </p>
                </div>
              </p>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <p
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={mobile}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="d-inline-flex align-items-center">
                    Web EDI
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    Pour un démarrage rapide et économique des communications
                    EDI, surtout quand le volume de documents échangés n'est pas
                    conséquent, les solutions non intégrées sont idéales. Elles
                    offrent un espace web permettant aux utilisateurs de gérer
                    aisément tous leurs échanges de messages électroniques avec
                    leurs partenaires.
                  </p>
                </div>
              </p>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <p
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={screen}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="d-inline-flex align-items-center">
                    Externalisation EDI
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    Cette approche consiste en l'externalisation des services
                    gérés, où toutes les opérations requises pour configurer,
                    surveiller, et entretenir l'intégration des données du
                    client sont confiées à un prestataire externe. Ainsi,
                    l'utilisateur n'a pas à interagir directement avec la
                    plateforme EDI, puisqu'un expert du fournisseur de services
                    s'occupe de tout pour garantir une performance optimale.
                  </p>
                </div>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Solution;
