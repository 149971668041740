import React, { useRef, useState } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { RouteItems, routes } from "../../sitemap";
import ThemeToggler from "../common/ThemeToggler";
import NavbarBrand from "./nav-items/NavbarBrand";

interface DropdownLinkProps {
  route: RouteItems;
}

const DropdownLink: React.FC<DropdownLinkProps> = ({ route }) => {
  const [isOpen, setOpen] = useState(false); // State is now internal

  const toggleDropdown = () => setOpen(!isOpen); // Toggle for dropdown

  return (
    <>
      {!route.labelDisabled && (
        <Nav.Link
          href={route.path}
          className={route.pages?.length ? "dropdown-toggle" : ""}
          data-bs-toggle="dropdown"
          aria-current="page"
          aria-expanded={isOpen}
          onClick={toggleDropdown} // Using toggle function here
        >
          {route.name}
        </Nav.Link>
      )}
      {route.pages && (
        <div
          title={route.name}
          className={`dropdown-menu p-0 ${isOpen ? "show" : ""}`}
        >
          <div className="d-lg-flex p-3">
            <div className="mega-dropdown-column" style={{ width: "300px" }}>
              <ul className="list-unstyled mb-0">
                <li className="py-1 fs-5 fw-bold">{route.name}</li>
                <p className="">{route.description}</p>
              </ul>
            </div>
            {route.pages.map((sroute, index) => {
              if (index % 2 === 0) {
                const nextSRoute = route.pages[index + 1];
                return (
                  <div className="mega-dropdown-column" key={index}>
                    <div className="px-3 mb-2">
                      <div className="d-flex align-items-center">
                        <i
                          className={`bx bx-${sroute.icon} fs-bold bg-secondary rounded-circle p-1 mr-2 me-1`}
                        />
                        <span className="text-dark fw-bold">{sroute.name}</span>
                      </div>
                    </div>
                    <ul className="list-unstyled mb-3">
                      {sroute.pages?.map(ssroute => (
                        <li key={ssroute.name}>
                          <a href={ssroute.path} className="dropdown-item py-1">
                            {ssroute.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                    {nextSRoute && (
                      <div>
                        <div className="px-3 mb-2">
                          <div className="d-flex align-items-center">
                            <i
                              className={`bx bx-${sroute.icon} fs-bold bg-secondary rounded-circle p-1 mr-2 me-1`}
                            />
                            <span className="text-dark">
                              <b>{nextSRoute.name}</b>
                            </span>
                          </div>
                        </div>
                        <ul className="list-unstyled mb-3">
                          {nextSRoute.pages?.map(ssroute => (
                            <li key={ssroute.name}>
                              <a
                                href={ssroute.path}
                                className="dropdown-item py-1"
                              >
                                {ssroute.name}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                );
              }
              return null;
            })}
          </div>
        </div>
      )}
    </>
  );
};
const MainNavbar: React.FC = () => {
  const navbarRef = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const [cartItems, setCartItems] = useState(1);

  // Fonction pour ajouter un article au panier
  const addToCart = () => {
    setCartItems(prevCount => prevCount + 1);
  };

  return (
    <>
      <section>
        <header className="header navbar navbar-expand-lg bg-light shadow-sm fixed-top">
          <Navbar
            ref={navbarRef}
            expand="lg"
            sticky="top"
            className="navbar navbar-expand-lg navbar-sticky py-0 container-fluid d-flex justify-content-between px-2"
          >
            <NavbarBrand />
            <Navbar.Toggle aria-controls="navbarNav" />
            <Navbar.Collapse id="navbarNav" className="offcanvas offcanvas-end">
              <div className="offcanvas-header border-bottom">
                <h5 className="offcanvas-title">Menu</h5>
                <Navbar.Toggle>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  />
                </Navbar.Toggle>
              </div>
              <div className="offcanvas-body m-auto">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                  {routes.map((route, index) => (
                    <Nav.Item
                      key={index}
                      className={route.pages && route.pages.length ? "dropdown" : ""}
                    >
                      <DropdownLink route={route} />
                    </Nav.Item>
                  ))}
                </ul>
                <div className="d-lg-none d-flex flex-column align-items-center text-center">
                  {/* Visible seulement sur mobile */}
                  <ThemeToggler />
                  <a
                    href="/login"
                    className="btn btn-secondary btn-sm fs-8 rounded my-2 mx-auto"  
                    rel="noopener"
                  >
                    <i className="bx bx-log-in fs-5 lh-1 me-1" />
                    &nbsp;Se connecter
                  </a>
                  {/* <a
                    href="/signIn"
                    className="btn btn-primary btn-sm fs-8 rounded my-2 mx-auto"  
                    rel="noopener"
                  >
                    <i className="bx bx-user fs-5 lh-1 me-1" />
                    &nbsp;S'inscrire
                  </a> */}
                </div>

              </div>
            </Navbar.Collapse>
            <div className="d-none d-lg-flex align-items-center"> {/* Visible only on large screens */}
              <ThemeToggler />
              <a
                href="/login"
                className="btn btn-secondary btn-sm fs-8 rounded mx-3"
                rel="noopener"
              >
                <i className="bx bx-log-in fs-5 lh-1 me-1" />
                &nbsp;Se connecter
              </a>
              {/* <a
                href="/signIn"
                className="btn btn-primary btn-sm fs-8 rounded mx-3"
                rel="noopener"
              >
                <i className="bx bx-user fs-5 lh-1 me-1" />
                &nbsp;S'inscrire
              </a> */}
            </div>
          </Navbar>
        </header>
      </section>
    </>
  );
};

export default MainNavbar;