import calculate from "assets/img/ipercom/services/logiciel-de-facturation/informatique/calculate_12_11zon.webp";
import InfoBouton from "components/modules/showcase/InfoBouton";

const ImportancySoftware = () => {
  return (
    <>
      <section className="container mt-5">
        <div className="row justify-content-around">
          <div className="col-md-5 order-2">
            <div className="pe-lg-5 text-center">
              <img src={calculate} className="rounded-3" alt="Image" />
            </div>
          </div>
          <div className="col-md-5 order-1">
            <h2 className="mb-sm-4">
              L'importance des logiciels de facturation informatique
            </h2>
            <p>
              ipercom innove pour les acteurs IT en développant des solutions <a href="/services/erp/guide-pour-un-erp">ERP </a>
              adaptées spécifiquement aux revendeurs, intégrateurs, distributeurs
              et fournisseurs de solutions informatiques. Ces fonctionnalités
              ciblées répondent précisément aux exigences opérationnelles de
              ces entreprises. Ce modèle, basé sur la gestion à distance et
              proactive des systèmes informatiques clients à un tarif fixe,
              promet une activité plus stable et prévisible, boostant
              ainsi la rentabilité.
              <br />
              <br />
              ipercom soutient cette transition des sociétés IT vers les
              services gérés en intégrant des fonctionnalités spécialisées
              directement dans son système ERP, facilitant ainsi leur adaptation
              à ce modèle d'affaires innovant.
            </p>
            <InfoBouton />
          </div>
        </div>
      </section>
    </>
  );
};

export default ImportancySoftware;
