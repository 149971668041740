import Hero from "../components/modules/showcase/Hero";
import OurApps from "../components/modules/showcase/OurApps";
import ERPhierarchy from "../components/modules/showcase/ERPhierarchy";
import Features from "../components/modules/showcase/Features";
import Dashboard from "../components/modules/showcase/Dashboard";
import Testimonials from "../components/modules/showcase/Testimonials";
import SignupCTA from "../components/modules/showcase/SignupCTA";
import Integrations from "../components/modules/showcase/Integrations";
import Partners from "../components/modules/showcase/Partners";
import ScrollToTopButton from "../components/modules/showcase/ScrollToTopButton";
import Modules from "components/modules/showcase/PrincingTabs/Modules";
import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";

const Showcase = () => {
  return (
    <>
        <Breadcrumbs />
      <Hero />
      <ERPhierarchy />
      <OurApps />
      <Features />
      <Dashboard />
      <Modules />
      <Testimonials />
      <Partners />
      <Integrations />
      <SignupCTA />
      <ScrollToTopButton />
    </>
  );
};

export default Showcase;
