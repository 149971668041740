import React from "react";

import computer from "assets/img/ipercom/icons/computerOurCrm.webp";
import screen from "assets/img/ipercom/icons/screenOurCrm.svg";
import square from "assets/img/ipercom/services/ERP/company/icons/square.svg";
import agent from "assets/img/ipercom/services/ERP/company/icons/agent.svg";
import graph from "assets/img/ipercom/services/ERP/company/icons/graph.svg";
import refresh from "assets/img/ipercom/services/ERP/company/icons/refresh.svg";

const Solution = () => {
  return (
    <>
      {/* Services */}
      <section className="bg-secondary pb-md-2 pb-lg-5 mb-3">
        <div
          className="d-none d-lg-block"
          style={{ marginTop: "-30px", paddingTop: 60 }}
        />
        <div className="container pb-4 pt-2">
          <h2 className="text-center text-md-start mb-lg-4 pt-1 pt-md-4">
            Solution ERP pour les PME
          </h2>
          <div className="col-md-8 text-center text-md-start">
            <p className="fs-lg text-muted mb-md-3">
              Gérez efficacement votre PME avec notre solution ERP personnalisée
              pour répondre à vos besoins spécifiques.
            </p>
          </div>
          <div className="row row-cols-1 row-cols-md-2">
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <p
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 me-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={computer}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="h4 d-inline-flex align-items-center">
                    Personnalisation
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    Découvrez comment notre solution ERP pour PME offre une
                    personnalisation approfondie pour répondre à vos exigences
                    uniques. Simplifiez votre gestion avec une solution flexible
                    et adaptée.{" "}
                  </p>
                </div>
              </p>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <p
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={screen}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="h4 d-inline-flex align-items-center">
                    Gestion intégrée
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    Notre solution ERP intègre la gestion de toutes les
                    opérations essentielles pour les PME. Simplifiez vos
                    processus avec une approche intégrée, optimisée pour la
                    croissance.{" "}
                  </p>
                </div>
              </p>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <p
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={square}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="h4 d-inline-flex align-items-center">
                    Évolutivité
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    L'évolutivité est au cœur de notre solution ERP pour PME,
                    vous permettant d'ajouter de nouvelles fonctionnalités et de
                    développer votre système en fonction de vos besoins
                    changeants.
                  </p>
                </div>
              </p>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <p
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={refresh}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="h4 d-inline-flex align-items-center">
                    Automatisation des processus
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    Notre solution ERP pour PME intègre une automatisation des
                    processus pour simplifier les opérations quotidiennes,
                    permettant aux entreprises de gagner en efficacité et en
                    compétitivité sur le marché.
                  </p>
                </div>
              </p>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <p
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={graph}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="h4 d-inline-flex align-items-center">
                    Prise de décision basée sur les données
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    La prise de décision basée sur les données dans notre
                    solution ERP pour PME permet une analyse approfondie,
                    garantissant des décisions stratégiques pertinentes et une
                    croissance durable.
                  </p>
                </div>
              </p>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <p
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={agent}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="h4 d-inline-flex align-items-center">
                    Support client
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    Bénéficiez d'un support client de qualité supérieure avec
                    notre solution ERP pour PME, vous permettant de surmonter
                    les défis avec confiance et tranquillité d'esprit.
                  </p>
                </div>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Solution;
