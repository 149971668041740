import ImportancySoftware from "components/modules/services/billing-software/computer-science/ImportancySoftware";
import Boost from "components/modules/services/billing-software/computer-science/Boost";
import CommerceActivity from "components/modules/services/billing-software/computer-science/CommerceActivity";
import Head from "components/modules/services/billing-software/computer-science/Head";
import ClientTalk from "components/modules/showcase/ClientTalk";
import Partners from "components/modules/showcase/Partners";
import RGSystem from "components/modules/services/billing-software/computer-science/RGSystem";
import UseERP from "components/modules/services/billing-software/computer-science/UseERP";
import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";
const BillingsIT = () => {
  return (
    <>
      <Head />
      <Breadcrumbs />
      <ImportancySoftware />
      <Boost />
      <UseERP />
      <CommerceActivity />
      <RGSystem />
      <ClientTalk />
      <Partners />
    </>
  );
};

export default BillingsIT;
