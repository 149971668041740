import React from "react";
import image from "assets/img/ipercom/services/Solution proposes/developpement web/project_6_11zon.webp";
import client from "assets/img/ipercom/services/Solution proposes/developpement web/projectClient_8_11zon.webp";
import cal from "assets/img/ipercom/services/Solution proposes/developpement web/projectcal_7_11zon.webp";
import paul from "assets/img/ipercom/services/Solution proposes/developpement web/projectPaul_9_11zon.webp";

const ProjectAppWeb = () => {
  return (
    <>
      {/* Portfolio list */}
      <section className="container pb-2 pb-lg-3">
        <div className="ps-md-4 ms-md-2 text-center">
          <h2>Projets applications web</h2>
          <p className="d-md-none d-lg-block pb-3 mb-2 mb-md-3 m-auto">
            Découvrez nos projets <a href="/ressources/portfolio/projets-realises/site-web">d'applications web innovants </a>, conçus sur
            mesure pour répondre à vos besoins spécifiques. Notre équipe
            expérimentée vous accompagne à chaque étape, de la conception à la
            mise en œuvre, pour garantir des solutions performantes et
            évolutives.
          </p>
        </div>
        {/* Item */}
        <div className="d-flex">
          <div className="col-md-4">
            <img src={image} alt="" className="img-fluid" />
          </div>
          <div className="d-flex flex-column col-md-8">
            <div className="d-flex">
              <img src={paul} alt="" className="col-6" />
              <img src={client} alt="" className="col-6" />
            </div>
            <img src={cal} alt="" className="img-fluid" />
          </div>
        </div>
      </section>
    </>
  );
};

export default ProjectAppWeb;
