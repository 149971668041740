import main from "assets/img/ipercom/services/Solution proposes/conception logiciel/main_27_11zon.webp";

const TechUsed = () => {
  return (
    <>
      <section className="bg-secondary pb-md-2 pb-lg-5 mb-3 mt-5">
        <div
          className="container row pt-xl-3 justify-content-between"
          style={{ width: "80%", margin: "auto" }}
        >
          <div className="col-md-5 col-lg-6 text-center text-md-start pb-5 order-2 align-content-center">
            <h2 className="h2 mb-4">Technologies utilisées</h2>
            <p className="fs-lg pb-lg-3 mb-4">
              Notre expertise en conception de logiciels sur mesure nous permet
              de nous adapter à toutes les technologies. Que vous ayez besoin
              d'une application <a href="/services/services-proposes/developpement-application-web">web</a>, <a
              href="/services/services-proposes/developpement-application-mobile">mobile</a> ou desktop, nous sommes là
              pour vous
              fournir une solution personnalisée répondant à vos exigences les
              plus pointues.
            </p>
          </div>
          <div className="col-lg-5 col-md-7 order-1">
            <img
              src={main}
              className="rounded-3 shadow-sm mx-auto d-block"
              alt="meeting"
              style={{ marginTop: "-80px" }}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default TechUsed;
