import Impt1 from 'assets/img/ipercom/services/TelephonySolution/IMPORTANT 1_7_11zon.webp';

const WhatIsAnIPBX = () => {
  return (
    <>
      {/* Portfolio list */}
      <section className="container">
        {/* Item */}
        <div className="row pb-5 mb-md-4 mb-lg-5">
          <div
            className="rellax col-md-6 pb-1 mb-3 pb-md-0 mb-md-0"
            data-rellax-percentage="0.5"
            data-rellax-speed="0.8"
            data-disable-parallax-down="md"
          >
            <a href="portfolio-single-project.html">
              <img src={Impt1} className="rounded-3" width={600} alt="Image" />
            </a>
          </div>
          <div
            className="rellax col-md-6"
            data-rellax-percentage="0.5"
            data-rellax-speed="-0.6"
            data-disable-parallax-down="md"
          >
            <div className="ps-md-4 ms-md-2">
              <h2>Ce qu'est un IPBX</h2>
              <p className="d-md-none d-lg-block pb-3 mb-2 mb-md-3">
                Dans le domaine des télécommunications, un IPBX (Internet
                Protocol Private Branch eXchange) est un système téléphonique
                utilisant Internet pour gérer les appels internes d'une
                entreprise. Techniquement, il s'agit d'un autocommutateur
                téléphonique privé basé sur le protocole Internet (IP) pour la
                transmission vocale. Contrairement au PABX, qui utilise le
                réseau téléphonique traditionnel, l'IPBX progresse, anticipant
                la fin du RTC en 2023. À noter que l'IPBX peut également être
                désigné sous les termes de PABX IP ou PBX IP.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhatIsAnIPBX;
