import React from "react";
import computer from "assets/img/ipercom/icons/computerOurCrm.webp";
import agent from "assets/img/ipercom/services/ERP/company/icons/agent.svg";
import graph from "assets/img/ipercom/services/ERP/company/icons/graph.svg";
import launch from "assets/img/ipercom/services/ERP/company/icons/launch.svg";
import diagramme from "assets/img/ipercom/services/ERP/company/icons/diagramme.svg";

const Solution = () => {
  return (
    <>
      {/* Services */}
      <section className="bg-secondary pb-md-2 pb-lg-5 mb-3">
        <div
          className="d-none d-lg-block"
          style={{ marginTop: "-60px", paddingTop: 60 }}
        />
        <div className="container pb-4 pt-5">
          <h2 className="text-center text-md-start mb-lg-4 pt-1 pt-md-4">
            Solution ERP pour les petites entreprises
          </h2>
          <div className="col-md-8 text-center text-md-start mb-5">
            <p className="fs-lg text-muted">
              Simplifiez la gestion de votre petite entreprise avec une solution 
              <a href="/services/erp/qu-est-ce-qu-un-erp"> ERP</a> sur mesure, conçue pour répondre aux défis spécifiques
              auxquels vous êtes confrontés.
            </p>
          </div>
          <div className="row row-cols-1 row-cols-md-2">
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <p
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 me-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={computer}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="h4 d-inline-flex align-items-center">
                    Adaptabilité
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    L'adaptabilité d'une solution ERP pour les petites
                    entreprises permet une évolution fluide. Elle s'ajuste aux
                    besoins changeants et à la croissance de votre entreprise,
                    garantissant une gestion efficace.
                  </p>
                </div>
              </p>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <p
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={launch}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="h4 d-inline-flex align-items-center">
                    Simplification des processus
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    La simplification des processus avec notre solution ERP pour
                    les petites entreprises, permet d'automatiser les tâches
                    répétitives, de rationaliser les opérations et d'optimiser
                    la productivité
                  </p>
                </div>
              </p>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <p
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={diagramme}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="h4 d-inline-flex align-items-center">
                    Accessibilité financière
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    Notre solution ERP pour les petites entreprises offre une
                    accessibilité financière inégalée, vous permettant de
                    bénéficier de fonctionnalités puissantes sans dépasser votre
                    budget.
                  </p>
                </div>
              </p>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <p
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={computer}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="h4 d-inline-flex align-items-center">
                    Prise de décision basée sur les données
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    Grâce à notre solution ERP adaptée aux PME, la prise de
                    décision basée sur les données devient facile et efficace,
                    vous permettant de rester compétitif sur le marché.
                  </p>
                </div>
              </p>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <p
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={graph}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="h4 d-inline-flex align-items-center">
                    Amélioration de l'efficacité
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    Avec notre solution ERP, les petites entreprises peuvent
                    améliorer leur efficacité en rationalisant les workflows, en
                    éliminant les tâches redondantes et en optimisant les
                    ressources disponibles.
                  </p>
                </div>
              </p>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <p
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={agent}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="h4 d-inline-flex align-items-center">
                    Support client
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    Notre solution ERP pour les petites entreprises inclut un
                    support client dédié, assurant une assistance personnalisée
                    pour une expérience sans tracas.
                  </p>
                </div>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Solution;
