import React from "react";
import projetERP from "assets/img/ipercom/ressources/portfolio/projet realisé/queletaitprojetERP_11_11zon.webp";
import { useParams } from "react-router-dom";

const WhatTheProject = () => {
  const { name } = useParams();
  return (
    <>
      <section className="container my-5">
        <h1 className="pb-3">
          ERP
        </h1>
        <h2 className="mb-5">{name}</h2>
        <div className="row">
          <div className="col-md-6 pb-2 pb-md-0 mb-4 mb-md-0 order-1">
            <div className="pe-lg-5 text-center">
              <img src={projetERP} className="rounded-3" alt="Image" style={{ width: "64%" }} />
            </div>
          </div>
          <div className="col-md-6 order-2">
            <h2 className="mb-sm-4">Quel était le projet ?</h2>
            <p className="d-md-none d-xl-block pb-2 pb-md-3 mb-3">
              Le projet visait à augmenter l'efficacité opérationnelle de l'entreprise en intégrant un système ERP.
              Un ERP combine plusieurs modules pour simplifier la gestion des ressources et améliorer la prise de
              décision.
              Pour les petites entreprises, l'ERP centralise les données, réduit les coûts opérationnels et stimule la
              productivité, un atout indispensable.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhatTheProject;
