import React from "react";

const SupportProcess = () => {
  return (
    <section className="container my-4">
      <h2 className="pb-2 pb-md-0 mb-4 mb-md-5">Processus de support </h2>
      <div className="steps steps-sm steps-horizontal-md pb-2 pb-sm-3">
        <div className="step">
          <div className="step-number">
            <div className="step-number-inner">1</div>
          </div>
          <div className="step-body">
            <h3 className="mb-3">Demande d'assistance</h3>
            <p className="mb-0">
              Notre processus de support optimisé vous permet de soumettre
              rapidement vos demandes d'assistance.
            </p>
          </div>
        </div>
        <div className="step">
          <div className="step-number">
            <div className="step-number-inner">2</div>
          </div>
          <div className="step-body">
            <h3 className="mb-3">Analyse et diagnostic</h3>
            <p className="mb-0">
              Bénéficiez d'une analyse détaillée et d'un diagnostic précis grâce
              à notre processus de support expert.
            </p>
          </div>
        </div>
        <div className="step">
          <div className="step-number">
            <div className="step-number-inner">3</div>
          </div>
          <div className="step-body">
            <h3 className="mb-3">Résolution du problème</h3>
            <p className="mb-0">
              Obtenez une résolution rapide et fiable de vos demandes grâce à
              notre processus de support optimisé.
            </p>
          </div>
        </div>
        <div className="step">
          <div className="step-number">
            <div className="step-number-inner">4</div>
          </div>
          <div className="step-body">
            <h3 className="mb-3">Suivi et clôture</h3>
            <p className="mb-0">
              Le suivi rigoureux et la clôture efficace font partie intégrante
              de notre processus de support, assurant une résolution optimale de
              vos demandes.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SupportProcess;
