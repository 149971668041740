import React, { useState } from 'react';
import computer from 'assets/img/ipercom/services/Solution proposes/computer_19_11zon.webp';
import womanWithHeadphone from 'assets/img/ipercom/ressources/blog/Advices/conseils pratiques/woman.webp';
import hand from 'assets/img/ipercom/ressources/blog/Advices/conseils pratiques/hand.webp';
import manwhiteshirt from 'assets/img/ipercom/ressources/blog/Advices/conseils pratiques/menWhiteshirt.webp';
import logo_ipercom from "assets/img/ipercom/services/ERP/Group.svg";



const cardImageStyle = {
  maxWidth: "100%",
  height: "auto"
};

const cardStyle = {
  maxWidth: "285px",
  margin: "auto"
};

const AdvicesList = () => {

  const [selectedCategory, setSelectedCategory] = useState("all");
  const [articles, setArticles] = useState([
    {
      image: womanWithHeadphone,
      categorie: 'Business',
      date: 'May 19, 2021',
      titre: 'Principes fondamentaux pour une utilisation efficace de React.js',
      projet: 'Service développement',
      url: '/ressources/blog/conseils-pratiques-pour-les-entreprises/principes-fondamentaux-pour-une-utilisation-efficace-de-react'
    },
    {
      image: computer,
      categorie: 'Digital',
      date: 'Sep 28, 2021',
      titre: 'Quelle est l\'importance de la conception UX ?',
      projet: 'Service développement',
      url: '/ressources/blog/conseils-pratiques-pour-les-entreprises/quelle-est-l-importance-de-la-conception-ux-'
    },
    {
      image: manwhiteshirt,
      categorie: 'Business',
      date: 'Sep 16, 2021',
      titre: 'Génération de bons de commande et de signatures en ligne',
      projet: 'Service développement',
      url: '/ressources/blog/conseils-pratiques-pour-les-entreprises/generation-de-bons-de-commande-et-de-signatures-en-ligne'
    },
    {
      image: hand,
      categorie: 'Process',
      date: 'Oct 9, 2021',
      titre: 'Intégration de l\'intelligence artificielle avec les technologies',
      projet: 'Service développement',
      url: '/ressources/blog/conseils-pratiques-pour-les-entreprises/integration de-l-intelligence artificielle avec les technologies'
    }
  ]);

  const filteredArticles = selectedCategory === "all" ? articles : articles.filter(article => article.categorie === selectedCategory);

  const handleCategoryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCategory(e.target.value);
  };
  return (
    <>
      <section className="container mb-3">
        <div className="d-flex align-items-center justify-content-between mb-4 pb-1 pb-md-3">
        <h1 className="mb-0 me-5">
        Conseils pratiques pour les entreprises
        </h1>
        <div className="col-lg-7 col-md-8">
          <form className="row gy-2">
            <div className="col-lg-5 col-sm-6">
              <div className="d-flex align-items-center">
                <select className="form-select" onChange={handleCategoryChange}>
                  <option value="all">Toutes catégories</option>
                  <option value="Digital">
                    Digital
                  </option>
                  <option value="Business">Business</option>
                  <option value="Process">Process</option>
                </select>
              </div>
            </div>
          </form>
        </div>
      </div>
      </section>
      <section className="container">
      <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 gx-3 gx-md-4 mt-n2 mt-sm-0">
        {filteredArticles.map((article, index) => (
          <div className="col pb-1 pb-lg-3 mb-4" key={index}>
            <article className="card h-100 border-0 shadow-sm">
              <img src={article.image} alt={article.titre} />
              <div className="card-body pb-3">
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <a href="#" className="badge fs-sm text-nav bg-secondary text-decoration-none">
                    {article.categorie}
                  </a>
                  <span className="fs-sm text-muted">{article.date}</span>
                </div>
                <h2 className="h5 mb-2">
                  <a href={article.url} >
                    {article.titre}
                  </a>
                </h2>
              </div>
              <div className="card-footer py-4">
                <p className="d-flex align-items-center fw-bold text-dark">
                  <img src={logo_ipercom} className="me-3" width={40} alt="Avatar" />
                  {article.projet}
                </p>
              </div>
            </article>
          </div>
        ))}
      </div>
    </section>
    </>
  );
};

export default AdvicesList;
