import platinum from "assets/img/ipercom/ranks/platinum.svg";
import silver from "assets/img/ipercom/ranks/silver.svg";
import gold from "assets/img/ipercom/ranks/gold.svg";

export interface PackagesData {
  id: string;
  title: string;
  packages: Package[];
}

export interface Package {
  name: string;
  monthlyPrice: number;
  annualPrice: number;
  features: PackageFeature[];
  image: string;
  options: PackageOption[];
}

export interface PackageFeature {
  name?: string;
  verificateName?: string;
  valueChanging?: boolean;
  available: boolean;

  description: string;
  info?: boolean;
  textInfo?: string;

  initialValue?: number;
  priceLabel?: number;

  isCheck?: boolean;

  prices?: InfoPrice[];
}

export interface OptionData {
  label: string;
  value?: number;
  jumpValue?: number;
  maxValue?: number;
  info?: boolean;
  textInfo?: string;
  price?: number;
  verificateName?: string;

}

export interface InfoPrice {
  label: string;
  value?: number;
  jumpValue?: number;
  maxValue?: number;
  info?: boolean;
  textInfo?: string;
  available: boolean;
}

export interface PackageOption {
  type: "select" | "number" | "checkbox" | "selectNumber";
  name: string;
  label: string;
  info: boolean;
  textInfo: string;
  options?: OptionData[];
  value?: number;
  jumpValue?: number;
  maxValue?: number;
  price?: number;
}


export const tabsData: PackagesData[] = [
  {
    id: "#ipbx",
    title: "iper-X solution de téléphonie ipbx",
    packages: [
      {
        name: "Pack TPE ipbx",
        monthlyPrice: 100,
        annualPrice: 1000,
        features: [
          {
            description: "Support inclus",
            available: true
          },
          {
            description: "utilisateurs",
            valueChanging: true,
            verificateName: "numberOfUsers",
            available: true,
            initialValue: 1
          },
          {
            description: "Jusqu’à 4 appels simultanés",
            available: true,
            info: true,
            textInfo: "Capacité de gérer plusieurs appels téléphoniques en même temps sur une même ligne"
          },
          {
            description: "Ligne téléphonique",
            available: true,
            info: true,
            textInfo: "Appels illimités vers les mobiles et fixes en France (hors numéros spéciaux)",
            valueChanging: true,
            verificateName: "linePhone"
          },
          {
            description: "Formation utilisateur 1 heure",
            name: "formation",
            available: true
          },
          // {
          //   description: "Intégration et déploiement 1/2 journée",
          //   priceLabel: 339,
          //   info: true,
          //   textInfo: "Sera facturé en supplément",
          //   available: true
          // },
          {
            description: "Téléphone sans fil",
            valueChanging: true,
            verificateName: "CordlessPhone",
            available: true
          },
          {
            description: "Téléphone fixe",
            valueChanging: true,
            verificateName: "fixe",
            available: true
          }
        ],
        image: silver,
        options: [
          {
            type: "number",
            name: "numberOfUsers",
            info: true,
            textInfo: "1458 IPBX utilisateurs",
            label: "Ajouter des utilisateurs",
            price: 50,
            value: 1,
            jumpValue: 1,
            maxValue: 4
          },
          {
            type: "selectNumber",
            name: "CordlessPhone",
            info: true,
            textInfo: "Téléchargement d'une documentation pour les 3",
            label: "Ajouter un téléphone et accessoire",
            options: [
              {
                label: "Téléphone standardiste",
                value: 0,
                jumpValue: 1,
                maxValue: 10,
                info: true,
                textInfo: "info"
              },
              {
                label: "Téléphone utilisateur",
                value: 0,
                jumpValue: 1,
                maxValue: 10,
                info: true,
                textInfo: "info"
              },
              {
                label: "Ajouter un casque",
                value: 0,
                jumpValue: 1,
                maxValue: 10,
                info: true,
                textInfo: "info"
              },
              {
                label: "Téléphone direction",
                value: 0,
                jumpValue: 1,
                maxValue: 10,
                info: true,
                textInfo: "info"
              },

              {
                label: "Ajouter un téléphone sans fil",
                value: 0,
                jumpValue: 1,
                maxValue: 10,
                info: true,
                textInfo: "info"
              }
            ],
            value: 0,
            jumpValue: 1,
            maxValue: 5
          }
        ]
      },
      {
        name: "Pack PME ipbx",
        monthlyPrice: 625,
        annualPrice: 7500,
        features: [
          {
            description: "Support inclus",
            available: true
          },
          {
            description: "utilisateurs",
            valueChanging: true,
            verificateName: "numberOfUsers",
            available: true,
            initialValue: 6
          },
          {
            description: "appels simultanés",
            available: true,
            info: true,
            valueChanging: true,
            textInfo: "De 8 à 48 appels simultanés",
            verificateName: "simultaneousCalls",
            initialValue: 8

          },
          {
            description: "Ligne téléphonique",
            available: true,
            info: true,
            textInfo: "Appels illimités vers les mobiles et fixes en France (hors numéros spéciaux)",
            valueChanging: true,
            verificateName: "linePhone"
          },
          {
            name: "formation",
            description: "1 journée de formation utilisateur",
            available: true,
            valueChanging: true,
            verificateName: "journeyFormationValue",
            initialValue: 0,
            prices: [
              {
                label: "journées de formation supplémentaire",
                value: 200,
                jumpValue: 150,
                available: true,
                info: true,
                textInfo: "Sera facturé en supplément"

              }]
          },
          // {
          //   description: "Intégration et déploiement 1 journée",
          //   priceLabel: 339,
          //   info: true,
          //   textInfo: "Sera facturé en supplément",
          //   available: true
          // },
          {
            description: "Téléphone sans fil",
            valueChanging: true,
            verificateName: "CordlessPhone",
            available: true
          },
          {
            description: "Téléphone fixe",
            valueChanging: true,
            verificateName: "fixe",
            available: true
          }
        ],
        image: gold,
        options: [
          {
            type: "number",
            name: "numberOfUsers",
            info: true,
            textInfo: "1458 IPBX utilisateur",
            label: "Ajouter des utilisateurs",
            price: 50,
            value: 6,
            jumpValue: 6,
            maxValue: 48
          },
          {
            type: "number",
            name: "simultaneousCalls",
            info: true,
            textInfo: "Capacité de gérer plusieurs appels téléphoniques en même temps sur une même ligne",
            label: "Ajouter des appels simultanés",
            price: 50,
            value: 8,
            jumpValue: 8,
            maxValue: 48
          },

          {
            type: "number",
            name: "journeyFormation",
            info: true,
            textInfo: "info a distance , si sur site frais supplémentaire ",
            label: "Ajouter des journées de formation",
            value: 0,
            jumpValue: 1,
            maxValue: 9
          },
          {
            type: "selectNumber",
            name: "CordlessPhone",
            info: true,
            textInfo: "Téléchargement d'une documentation pour les 3",
            label: "Ajouter un téléphone et accessoire",
            options: [
              {
                label: "Téléphone standardiste",
                value: 0,
                jumpValue: 1,
                maxValue: 10,
                info: true,
                textInfo: "info"
              },
              {
                label: "Téléphone utilisateur",
                value: 0,
                jumpValue: 1,
                maxValue: 10,
                info: true,
                textInfo: "info"
              },
              {
                label: "Ajouter un casque",
                value: 0,
                jumpValue: 1,
                maxValue: 10,
                info: true,
                textInfo: "info"
              },
              {
                label: "Téléphone direction",
                value: 0,
                jumpValue: 1,
                maxValue: 10,
                info: true,
                textInfo: "info"
              },

              {
                label: "Ajouter un téléphone sans fil",
                value: 0,
                jumpValue: 1,
                maxValue: 10,
                info: true,
                textInfo: "info"
              }
            ],
            value: 0,
            jumpValue: 1,
            maxValue: 5
          }
        ]
      },
      {
        name: "Pack Grande entreprise ipbx",
        monthlyPrice: 1458,
        annualPrice: 17500,
        features: [
          {
            description: "Support inclus",
            available: true
          },
          {
            description: "utilisateurs",
            valueChanging: true,
            verificateName: "numberOfUsers",
            available: true,
            initialValue: 50
          },
          {
            description: "appels simultanés",
            available: true,
            info: true,
            valueChanging: true,
            textInfo: "De 50 à 256 appels simultanés",
            verificateName: "simultaneousCalls",
            initialValue: 50
          },
          {
            description: "Ligne téléphonique",
            available: true,
            info: true,
            textInfo: "Appels illimités vers les mobiles et fixes en France (hors numéros spéciaux)",
            valueChanging: true,
            verificateName: "linePhone"
          },
          {
            description: "2 journées de formation utilisateur",
            name: "formation",
            available: true,
            valueChanging: true,
            verificateName: "journeyFormationValue",
            initialValue: 0,
            prices: [
              {
                label: "journées de formation supplémentaire",
                value: 150,
                jumpValue: 150,
                available: true,
                info: true,
                textInfo: "Sera facturé en supplément"
              }]
          },
          // {
          //   description: "Intégration et déploiement 2 journées",
          //   priceLabel: 339,
          //   info: true,
          //   textInfo: "Sera facturé en supplément",
          //   available: true
          // },
          {
            description: "Téléphone sans fil",
            valueChanging: true,
            verificateName: "CordlessPhone",
            available: true
          },
          {
            description: "Téléphone fixe",
            valueChanging: true,
            verificateName: "fixe",
            available: true
          }
        ],
        image: platinum,
        options: [
          {
            type: "number",
            name: "numberOfUsers",
            info: true,
            textInfo: "1458 IPBX utilisateur",
            label: "Ajouter des utilisateurs",
            price: 100,
            value: 50,
            jumpValue: 50,
            maxValue: 1000
          },
          {
            type: "number",
            name: "simultaneousCalls",
            info: true,
            textInfo: "Capacité de gérer plusieurs appels téléphoniques en même temps sur une même ligne",
            label: "Ajouter des appels simultanés",
            price: 50,
            value: 50,
            jumpValue: 50,
            maxValue: 1000
          },

          {
            type: "number",
            name: "journeyFormation",
            info: true,
            textInfo: "info a distance , si sur site frais supplémentaire ",
            label: "Ajouter des journées de formation",
            value: 0,
            jumpValue: 1,
            maxValue: 9
          },
          {
            type: "selectNumber",
            name: "CordlessPhone",
            info: true,
            textInfo: "Téléchargement d'une documentation pour les 3",
            label: "Ajouter un téléphone et accessoire",
            options: [
              {
                label: "Téléphone standardiste",
                value: 0,
                jumpValue: 1,
                maxValue: 10,
                info: true,
                textInfo: "info"
              },
              {
                label: "Téléphone utilisateur",
                value: 0,
                jumpValue: 1,
                maxValue: 10,
                info: true,
                textInfo: "info"
              },
              {
                label: "Ajouter un casque",
                value: 0,
                jumpValue: 1,
                maxValue: 10,
                info: true,
                textInfo: "info"
              },
              {
                label: "Téléphone direction",
                value: 0,
                jumpValue: 1,
                maxValue: 10,
                info: true,
                textInfo: "info"
              },

              {
                label: "Ajouter un téléphone sans fil",
                value: 0,
                jumpValue: 1,
                maxValue: 10,
                info: true,
                textInfo: "info"
              }
            ],
            value: 0,
            jumpValue: 1,
            maxValue: 5
          }
        ]
      }
    ]
  },
  {
    id: "#pos",
    title: "i-pos logiciel de caisse",
    packages: [
      {
        name: "Pack TPE ipos",
        monthlyPrice: 100,
        annualPrice: 1000,
        features: [
          { description: "Support inclus", available: true },
          {
            description: "utilisateurs",
            valueChanging: true,
            verificateName: "numberOfUsers",
            available: true,
            initialValue: 1
          },
          // {
          //   description: "Intégration et déploiement 1/2 journée",
          //   priceLabel: 339,
          //   info: true,
          //   textInfo: "Sera facturé en supplément",
          //   available: true
          // }
        ],
        image: silver,
        options: [
          {
            type: "number",
            name: "numberOfUsers",
            info: true,
            textInfo: "1458 ipbx utilisateur",
            label: "Ajouter des utilisateurs",
            price: 50,
            value: 1,
            jumpValue: 1,
            maxValue: 5
          }
        ]
      },
      {
        name: "Pack PME ipos",
        monthlyPrice: 625,
        annualPrice: 7500,
        features: [
          { description: "Support inclus", available: true },
          {
            description: "utilisateurs",
            valueChanging: true,
            verificateName: "numberOfUsers",
            available: true,
            initialValue: 6
          },
          // {
          //   description: "Intégration et déploiement 1/2 journée",
          //   priceLabel: 339,
          //   info: true,
          //   textInfo: "Sera facturé en supplément",
          //   available: true
          // },
          {
            description: "1 journée de formation utilisateur",
            available: true,
            valueChanging: true,
            verificateName: "journeyFormationValue",
            initialValue: 0,
            prices: [
              {
                label: "journées de formation supplémentaire",
                value: 150,
                jumpValue: 150,
                available: true,
                info: true,
                textInfo: "Sera facturé en supplément"
              }]
          }
        ],
        image: gold,
        options: [
          {
            type: "number",
            name: "numberOfUsers",
            info: true,
            textInfo: "1458 ipbx utilisateur",
            label: "Ajouter des utilisateurs",
            price: 50,
            value: 6,
            jumpValue: 6,
            maxValue: 48
          },
          {
            type: "number",
            name: "journeyFormation",
            info: true,
            textInfo: "info a distance , si sur site frais supplémentaire ",
            label: "Ajouter des journées de formation",
            value: 0,
            jumpValue: 1,
            maxValue: 9
          }
        ]
      },
      {
        name: "Pack Grande entreprise ipos",
        monthlyPrice: 1458,
        annualPrice: 17500,
        features: [
          { description: "Support inclus", available: true },
          {
            description: "utilisateurs",
            valueChanging: true,
            verificateName: "numberOfUsers",
            available: true,
            initialValue: 50
          },
          // {
          //   description: "Intégration et déploiement 1/2 journée",
          //   priceLabel: 339,
          //   info: true,
          //   textInfo: "Sera facturé en supplément",
          //   available: true
          // },
          {
            description: "2 journées de formation utilisateur",
            name: "formation",
            available: true,
            valueChanging: true,
            verificateName: "journeyFormationValue",
            initialValue: 2,
            prices: [
              {
                label: "journées de formation supplémentaire",
                value: 150,
                jumpValue: 150,
                available: true,
                info: true,
                textInfo: "Sera facturé en supplément"
              }]
          }
        ],
        image: platinum,
        options: [
          {
            type: "number",
            name: "numberOfUsers",
            info: true,
            textInfo: "info",
            label: "Ajouter des utilisateurs",
            price: 50,
            value: 50,
            jumpValue: 50,
            maxValue: 249
          },
          {
            type: "number",
            name: "journeyFormation",
            info: true,
            textInfo: "info a distance , si sur site frais supplémentaire ",
            label: "Ajouter des journées de formation",
            value: 0,
            jumpValue: 1,
            maxValue: 9
          }


        ]
      }
    ]
  },
  {
    id: "#cloud",
    title: "iper-cloud stockage collaboratif",
    packages: [
      {
        name: "Pack TPE iper-cloud",
        monthlyPrice: 100,
        annualPrice: 1000,
        features: [
          {
            description: "Support inclus",
            available: true
          },
          {
            description: "utilisateurs",
            valueChanging: true,
            verificateName: "numberOfUsers",
            available: true,
            initialValue: 1
          },
          {
            description: "Go",
            valueChanging: true,
            info: true,
            textInfo: "1000 Go = 1 To",
            verificateName: "numberOfSpace",
            available: true,
            initialValue: 500
          },
          // {
          //   description: "Intégration et déploiement 1/2 journée",
          //   priceLabel: 339,
          //   info: true,
          //   textInfo: "Sera facturé en supplément",
          //   available: true
          // }
        ],
        image: silver,
        options: [

          {
            type: "number",
            name: "numberOfUsers",
            info: true,
            textInfo: "1458 ipbx utilisateur",
            label: "Ajouter des utilisateurs",
            price: 50,
            value: 1,
            jumpValue: 1,
            maxValue: 5
          },
          {
            type: "number",
            name: "numberOfSpace",
            price: 100,
            info: true,
            textInfo: "Données stockées en data center à paris en France",
            label: "Espace de stockage en Go",
            value: 500,
            jumpValue: 100,
            maxValue: 1000
          }
        ]
      },
      {
        name: "Pack PME iper-cloud",
        monthlyPrice: 625,
        annualPrice: 7500,
        features: [
          { description: "Support inclus", available: true },
          {
            description: "utilisateurs",
            valueChanging: true,
            verificateName: "numberOfUsers",
            available: true,
            initialValue: 6
          },
          {
            description: "To",
            valueChanging: true,
            info: true,
            textInfo: "info",
            verificateName: "numberOfSpace",
            available: true,
            initialValue: 500
          }
        ],
        image: gold,
        options: [
          {
            type: "number",
            name: "numberOfUsers",
            info: true,
            textInfo: "info",
            label: "Ajouter des utilisateurs",
            price: 50,
            value: 6,
            jumpValue: 6,
            maxValue: 48
          },
          {
            type: "number",
            name: "numberOfSpace",
            price: 100,
            info: true,
            textInfo: "Données stockées en data center à paris en France",
            label: "Espace de stockage en To",
            value: 1,
            jumpValue: 1,
            maxValue: 5
          }
        ]
      },
      {
        name: "Pack Grande entreprise iper-cloud",
        monthlyPrice: 1458,
        annualPrice: 17500,
        features: [
          { description: "Support inclus", available: true },
          {
            description: "utilisateurs",
            valueChanging: true,
            verificateName: "numberOfUsers",
            available: true,
            initialValue: 50
          },
          {
            description: "To",
            valueChanging: true,
            info: true,
            textInfo: "info",
            verificateName: "numberOfSpace",
            available: true,
            initialValue: 6
          }
        ],
        image: platinum,
        options: [
          {
            type: "number",
            name: "numberOfUsers",
            info: true,
            textInfo: "info",
            label: "Ajouter des utilisateurs",
            price: 50,
            value: 50,
            jumpValue: 50,
            maxValue: 1000
          },
          {
            type: "number",
            name: "numberOfSpace",
            price: 100,
            info: true,
            textInfo: "Données stockées en data center à paris en France",
            label: "Espace de stockage en To",
            value: 5,
            jumpValue: 5,
            maxValue: 20
          }
        ]
      }
    ]
  },
  {
    id: "#erp",
    title: " ipercom erp logiciel de gestion collaboratif",
    packages: [
      {
        name: "Pack TPE ERP (CRM/Achat/Vente/Stock)",
        monthlyPrice: 100,
        annualPrice: 1000,
        features: [
          { description: "Support inclus", available: true },
          {
            description: "utilisateurs",
            valueChanging: true,
            verificateName: "numberOfUsers",
            available: true,
            initialValue: 1
          },
          // {
          //   description: "Intégration et déploiement 1/2 journée",
          //   priceLabel: 339,
          //   info: true,
          //   textInfo: "Sera facturé en supplément",
          //   available: true
          // },
          {
            description: "Formation utilisateur 1 heure",
            name: "formation",
            available: true
          },
          {
            description: "Facturation télécom",
            name: "formation",
            available: true,
            isCheck: false,
            verificateName: "telecom"
          },
          {
            description: "Facturation bureautique",
            name: "formation",
            available: true,
            isCheck: false,
            verificateName: "bureautique"
          }, {
            description: "Module CTI* (Click & Call et remontée de fiche)",
            name: "formation",
            available: true,
            isCheck: false,
            verificateName: "cti"
          }
        ],
        image: silver,
        options: [
          {
            type: "number",
            name: "numberOfUsers",
            info: true,
            textInfo: "info",
            label: "Ajouter des utilisateurs",
            price: 50,
            value: 1,
            jumpValue: 1,
            maxValue: 5
          },
          {
            type: "select",
            name: "facturationModules",
            info: true,
            textInfo: "info",
            label: "Personnalisé votre facturation",
            options: [{
              label: "Facturation télécom", info: true,
              textInfo: "info", verificateName: "telecom"
            }, {
              label: "Facturation bureautique", info: true,
              textInfo: "info", verificateName: "bureautique"
            }, {
              label: "Module CTI* (Click & Call et remontée de fiche)", info: true,
              textInfo: "info", verificateName: "cti"
            }]
          },
        ]
      },
      {
        name: "Pack PME ERP (CRM/Achat/Vente/Stock)",
        monthlyPrice: 625,
        annualPrice: 7500,
        features: [
          { description: "Support inclus", available: true },
          {
            description: "utilisateurs",
            valueChanging: true,
            verificateName: "numberOfUsers",
            available: true,
            initialValue: 6
          },
          // {
          //   description: "Intégration et déploiement 1/2 journée",
          //   priceLabel: 339,
          //   info: true,
          //   textInfo: "Sera facturé en supplément",
          //   available: true
          // },
          {
            name: "formation",
            description: "1 journée de formation utilisateur",
            available: true,
            valueChanging: true,
            verificateName: "journeyFormationValue",
            initialValue: 0,
            prices: [
              {
                label: "journées de formation supplémentaire",
                value: 200,
                jumpValue: 150,
                available: true,
                info: true,
                textInfo: "Sera facturé en supplément"

              }]
          },
          {
            description: "Facturation télécom",
            name: "formation",
            available: true,
            isCheck: false,
            verificateName: "telecom"
          },
          {
            description: "Facturation bureautique",
            name: "formation",
            available: true,
            isCheck: false,
            verificateName: "bureautique"
          }, {
            description: "Module CTI* (Click & Call et remontée de fiche)",
            name: "formation",
            available: true,
            isCheck: false,
            verificateName: "cti"
          }
        ],
        image: gold,
        options: [
          {
            type: "number",
            name: "numberOfUsers",
            info: true,
            textInfo: "info",
            label: "Ajouter des utilisateurs",
            price: 50,
            value: 6,
            jumpValue: 6,
            maxValue: 48
          },
          {
            type: "select",
            name: "facturationModules",
            info: true,
            textInfo: "info",
            label: "Personnalisé votre facturation",
            options: [{
              label: "Facturation télécom", info: true,
              textInfo: "info", verificateName: "telecom"
            }, {
              label: "Facturation bureautique", info: true,
              textInfo: "info", verificateName: "bureautique"
            }, {
              label: "Module CTI* (Click & Call et remontée de fiche)", info: true,
              textInfo: "info", verificateName: "cti"
            }]
          },
          {
            type: "number",
            name: "journeyFormation",
            info: true,
            textInfo: "info a distance , si sur site frais supplémentaire ",
            label: "Ajouter des journées de formation",
            value: 0,
            jumpValue: 1,
            maxValue: 9
          }
        ]
      },
      {
        name: "Pack Grande entreprise ERP (CRM/Achat/Vente/Stock)",
        monthlyPrice: 1458,
        annualPrice: 17500,
        features: [
          { description: "Support inclus", available: true },
          {
            description: "utilisateurs",
            valueChanging: true,
            verificateName: "numberOfUsers",
            available: true,
            initialValue: 50
          },
          // {
          //   description: "Intégration et déploiement 1/2 journée",
          //   priceLabel: 339,
          //   info: true,
          //   textInfo: "Sera facturé en supplément",
          //   available: true
          // },
          {
            description: "2 journées de formation utilisateur",
            name: "formation",
            available: true,
            valueChanging: true,
            verificateName: "journeyFormationValue",
            initialValue: 0,
            prices: [
              {
                label: "journées de formation supplémentaire",
                value: 150,
                jumpValue: 150,
                available: true,
                info: true,
                textInfo: "Sera facturé en supplément"
              }]
          },
          {
            description: "Facturation télécom",
            name: "formation",
            available: true,
            isCheck: false,
            verificateName: "telecom"
          },
          {
            description: "Facturation bureautique",
            name: "formation",
            available: true,
            isCheck: false,
            verificateName: "bureautique"
          }, {
            description: "Module CTI* (Click & Call et remontée de fiche)",
            name: "formation",
            available: true,
            isCheck: false,
            verificateName: "cti"
          }
        ],
        image: platinum,
        options: [
          {
            type: "number",
            name: "numberOfUsers",
            info: true,
            textInfo: "info",
            label: "Ajouter des utilisateurs",
            price: 50,
            value: 50,
            jumpValue: 50,
            maxValue: 249
          },
          {
            type: "select",
            name: "facturationModules",
            info: true,
            textInfo: "info",
            label: "Personnalisé votre facturation",
            options: [{
              label: "Facturation télécom", info: true,
              textInfo: "info", verificateName: "telecom"
            }, {
              label: "Facturation bureautique", info: true,
              textInfo: "info", verificateName: "bureautique"
            }, {
              label: "Module CTI* (Click & Call et remontée de fiche)", info: true,
              textInfo: "info", verificateName: "cti"
            }]
          },
          {
            type: "number",
            name: "journeyFormation",
            info: true,
            textInfo: "info a distance , si sur site frais supplémentaire ",
            label: "Ajouter des journées de formation",
            value: 0,
            jumpValue: 1,
            maxValue: 9
          }
        ]
      }
    ]
  }
];
export const tabsDataTest: PackagesData[] = [
  {
    id: "#ipbx",
    title: "iper-X solution de téléphonie ipbx",
    packages: [
      {
        name: "Pack TPE ipbx",
        monthlyPrice: 32,
        annualPrice: 390,
        features: [
          {
            description: "Support inclus",
            available: true
          },
          {
            description: "utilisateur",
            valueChanging: true,
            verificateName: "numberOfUsers",
            available: true,
            initialValue: 1
          },
          {
            description: "Jusqu’à 4 appels simultanés",
            available: true,
            info: true,
            textInfo: "Capacité de gérer plusieurs appels téléphoniques en même temps sur une même ligne"
          },
          {
            description: "Ligne téléphonique",
            available: true,
            info: true,
            textInfo: "Appels illimités vers les mobiles et fixes en France (hors numéros spéciaux)",
            valueChanging: true,
            verificateName: "linePhone"
          },
          {
            description: "Formation utilisateur 1 heure",
            name: "formation",
            available: true
          },
          // {
          //   description: "Intégration et déploiement 1/2 journée",
          //   priceLabel: 339,
          //   info: true,
          //   textInfo: "Sera facturé en supplément",
          //   available: true
          // },
          {
            description: "Téléphone sans fil",
            valueChanging: true,
            verificateName: "CordlessPhone",
            available: true
          },
          {
            description: "Téléphone fixe",
            valueChanging: true,
            verificateName: "fixe",
            available: true
          }
        ],
        image: silver,
        options: [
          // {
          //   type: "number",
          //   name: "numberOfUsers",
          //   info: true,
          //   textInfo: "1458 IPBX utilisateurs",
          //   label: "Ajouter des utilisateurs",
          //   price: 50,
          //   value: 1,
          //   jumpValue: 1,
          //   maxValue: 4
          // },
          // {
          //   type: "selectNumber",
          //   name: "CordlessPhone",
          //   info: true,
          //   textInfo: "Téléchargement d'une documentation pour les 3",
          //   label: "Ajouter un téléphone et accessoire",
          //   options: [
          //     {
          //       label: "Téléphone standardiste",
          //       value: 0,
          //       jumpValue: 1,
          //       maxValue: 10,
          //       info: true,
          //       textInfo: "info"
          //     },
          //     {
          //       label: "Téléphone utilisateur",
          //       value: 0,
          //       jumpValue: 1,
          //       maxValue: 10,
          //       info: true,
          //       textInfo: "info"
          //     },
          //     {
          //       label: "Ajouter un casque",
          //       value: 0,
          //       jumpValue: 1,
          //       maxValue: 10,
          //       info: true,
          //       textInfo: "info"
          //     },
          //     {
          //       label: "Téléphone direction",
          //       value: 0,
          //       jumpValue: 1,
          //       maxValue: 10,
          //       info: true,
          //       textInfo: "info"
          //     },

          //     {
          //       label: "Ajouter un téléphone sans fil",
          //       value: 0,
          //       jumpValue: 1,
          //       maxValue: 10,
          //       info: true,
          //       textInfo: "info"
          //     }
          //   ],
          //   value: 0,
          //   jumpValue: 1,
          //   maxValue: 5
          // }
        ]
      },
      {
        name: "Pack PME ipbx",
        monthlyPrice: 39,
        annualPrice: 1050,
        features: [
          {
            description: "Support inclus",
            available: true
          },
          {
            description: "utilisateurs",
            valueChanging: true,
            verificateName: "numberOfUsers",
            available: true,
            initialValue: 6
          },
          {
            description: "appels simultanés",
            available: true,
            info: true,
            valueChanging: true,
            textInfo: "De 8 à 48 appels simultanés",
            verificateName: "simultaneousCalls",
            initialValue: 8

          },
          {
            description: "Ligne téléphonique",
            available: true,
            info: true,
            textInfo: "Appels illimités vers les mobiles et fixes en France (hors numéros spéciaux)",
            valueChanging: true,
            verificateName: "linePhone"
          },
          {
            name: "formation",
            description: "1 journée de formation utilisateur",
            available: true,
            valueChanging: true,
            verificateName: "journeyFormationValue",
            initialValue: 0,
            prices: [
              {
                label: "journées de formation supplémentaire",
                value: 200,
                jumpValue: 150,
                available: true,
                info: true,
                textInfo: "Sera facturé en supplément"

              }]
          },
          // {
          //   description: "Intégration et déploiement 1 journée",
          //   priceLabel: 339,
          //   info: true,
          //   textInfo: "Sera facturé en supplément",
          //   available: true
          // },
          {
            description: "Téléphone sans fil",
            valueChanging: true,
            verificateName: "CordlessPhone",
            available: true
          },
          {
            description: "Téléphone fixe",
            valueChanging: true,
            verificateName: "fixe",
            available: true
          }
        ],
        image: gold,
        options: [
          // {
          //   type: "number",
          //   name: "numberOfUsers",
          //   info: true,
          //   textInfo: "1458 IPBX utilisateur",
          //   label: "Ajouter des utilisateurs",
          //   price: 50,
          //   value: 6,
          //   jumpValue: 6,
          //   maxValue: 48
          // },
          // {
          //   type: "number",
          //   name: "simultaneousCalls",
          //   info: true,
          //   textInfo: "Capacité de gérer plusieurs appels téléphoniques en même temps sur une même ligne",
          //   label: "Ajouter des appels simultanés",
          //   price: 50,
          //   value: 8,
          //   jumpValue: 8,
          //   maxValue: 48
          // },

          // {
          //   type: "number",
          //   name: "journeyFormation",
          //   info: true,
          //   textInfo: "info a distance , si sur site frais supplémentaire ",
          //   label: "Ajouter des journées de formation",
          //   value: 0,
          //   jumpValue: 1,
          //   maxValue: 9
          // },
          // {
          //   type: "selectNumber",
          //   name: "CordlessPhone",
          //   info: true,
          //   textInfo: "Téléchargement d'une documentation pour les 3",
          //   label: "Ajouter un téléphone et accessoire",
          //   options: [
          //     {
          //       label: "Téléphone standardiste",
          //       value: 0,
          //       jumpValue: 1,
          //       maxValue: 10,
          //       info: true,
          //       textInfo: "info"
          //     },
          //     {
          //       label: "Téléphone utilisateur",
          //       value: 0,
          //       jumpValue: 1,
          //       maxValue: 10,
          //       info: true,
          //       textInfo: "info"
          //     },
          //     {
          //       label: "Ajouter un casque",
          //       value: 0,
          //       jumpValue: 1,
          //       maxValue: 10,
          //       info: true,
          //       textInfo: "info"
          //     },
          //     {
          //       label: "Téléphone direction",
          //       value: 0,
          //       jumpValue: 1,
          //       maxValue: 10,
          //       info: true,
          //       textInfo: "info"
          //     },

          //     {
          //       label: "Ajouter un téléphone sans fil",
          //       value: 0,
          //       jumpValue: 1,
          //       maxValue: 10,
          //       info: true,
          //       textInfo: "info"
          //     }
          //   ],
          //   value: 0,
          //   jumpValue: 1,
          //   maxValue: 5
          // }
        ]
      },
      {
        name: "Pack Grande entreprise ipbx",
        monthlyPrice: 249,
        annualPrice: 3010,
        features: [
          {
            description: "Support inclus",
            available: true
          },
          {
            description: "utilisateurs",
            valueChanging: true,
            verificateName: "numberOfUsers",
            available: true,
            initialValue: 50
          },
          {
            description: "appels simultanés",
            available: true,
            info: true,
            valueChanging: true,
            textInfo: "De 50 à 256 appels simultanés",
            verificateName: "simultaneousCalls",
            initialValue: 50
          },
          {
            description: "Ligne téléphonique",
            available: true,
            info: true,
            textInfo: "Appels illimités vers les mobiles et fixes en France (hors numéros spéciaux)",
            valueChanging: true,
            verificateName: "linePhone"
          },
          {
            description: "2 journées de formation utilisateur",
            name: "formation",
            available: true,
            valueChanging: true,
            verificateName: "journeyFormationValue",
            initialValue: 0,
            prices: [
              {
                label: "journées de formation supplémentaire",
                value: 150,
                jumpValue: 150,
                available: true,
                info: true,
                textInfo: "Sera facturé en supplément"
              }]
          },
          // {
          //   description: "Intégration et déploiement 2 journées",
          //   priceLabel: 339,
          //   info: true,
          //   textInfo: "Sera facturé en supplément",
          //   available: true
          // },
          {
            description: "Téléphone sans fil",
            valueChanging: true,
            verificateName: "CordlessPhone",
            available: true
          },
          {
            description: "Téléphone fixe",
            valueChanging: true,
            verificateName: "fixe",
            available: true
          }
        ],
        image: platinum,
        options: [
          // {
          //   type: "number",
          //   name: "numberOfUsers",
          //   info: true,
          //   textInfo: "1458 IPBX utilisateur",
          //   label: "Ajouter des utilisateurs",
          //   price: 100,
          //   value: 50,
          //   jumpValue: 50,
          //   maxValue: 1000
          // },
          // {
          //   type: "number",
          //   name: "simultaneousCalls",
          //   info: true,
          //   textInfo: "Capacité de gérer plusieurs appels téléphoniques en même temps sur une même ligne",
          //   label: "Ajouter des appels simultanés",
          //   price: 50,
          //   value: 50,
          //   jumpValue: 50,
          //   maxValue: 1000
          // },

          // {
          //   type: "number",
          //   name: "journeyFormation",
          //   info: true,
          //   textInfo: "info a distance , si sur site frais supplémentaire ",
          //   label: "Ajouter des journées de formation",
          //   value: 0,
          //   jumpValue: 1,
          //   maxValue: 9
          // },
          // {
          //   type: "selectNumber",
          //   name: "CordlessPhone",
          //   info: true,
          //   textInfo: "Téléchargement d'une documentation pour les 3",
          //   label: "Ajouter un téléphone et accessoire",
          //   options: [
          //     {
          //       label: "Téléphone standardiste",
          //       value: 0,
          //       jumpValue: 1,
          //       maxValue: 10,
          //       info: true,
          //       textInfo: "info"
          //     },
          //     {
          //       label: "Téléphone utilisateur",
          //       value: 0,
          //       jumpValue: 1,
          //       maxValue: 10,
          //       info: true,
          //       textInfo: "info"
          //     },
          //     {
          //       label: "Ajouter un casque",
          //       value: 0,
          //       jumpValue: 1,
          //       maxValue: 10,
          //       info: true,
          //       textInfo: "info"
          //     },
          //     {
          //       label: "Téléphone direction",
          //       value: 0,
          //       jumpValue: 1,
          //       maxValue: 10,
          //       info: true,
          //       textInfo: "info"
          //     },

          //     {
          //       label: "Ajouter un téléphone sans fil",
          //       value: 0,
          //       jumpValue: 1,
          //       maxValue: 10,
          //       info: true,
          //       textInfo: "info"
          //     }
          //   ],
          //   value: 0,
          //   jumpValue: 1,
          //   maxValue: 5
          // }
        ]
      }
    ]
  },
  {
    id: "#pos",
    title: "i-pos logiciel de caisse",
    packages: [
      {
        name: "Pack TPE ipos",
        monthlyPrice: 32,
        annualPrice: 390,
        features: [
          { description: "Support inclus", available: true },
          {
            description: "utilisateur",
            valueChanging: true,
            verificateName: "numberOfUsers",
            available: true,
            initialValue: 1
          },
          // {
          //   description: "Intégration et déploiement 1/2 journée",
          //   priceLabel: 339,
          //   info: true,
          //   textInfo: "Sera facturé en supplément",
          //   available: true
          // }
        ],
        image: silver,
        options: [
          // {
          //   type: "number",
          //   name: "numberOfUsers",
          //   info: true,
          //   textInfo: "1458 ipbx utilisateur",
          //   label: "Ajouter des utilisateurs",
          //   price: 50,
          //   value: 1,
          //   jumpValue: 1,
          //   maxValue: 5
          // }
        ]
      },
      {
        name: "Pack PME ipos",
        monthlyPrice: 39,
        annualPrice: 1050,
        features: [
          { description: "Support inclus", available: true },
          {
            description: "utilisateurs",
            valueChanging: true,
            verificateName: "numberOfUsers",
            available: true,
            initialValue: 6
          },
          // {
          //   description: "Intégration et déploiement 1/2 journée",
          //   priceLabel: 339,
          //   info: true,
          //   textInfo: "Sera facturé en supplément",
          //   available: true
          // },
          {
            description: "1 journée de formation utilisateur",
            available: true,
            valueChanging: true,
            verificateName: "journeyFormationValue",
            initialValue: 0,
            prices: [
              {
                label: "journées de formation supplémentaire",
                value: 150,
                jumpValue: 150,
                available: true,
                info: true,
                textInfo: "Sera facturé en supplément"
              }]
          }
        ],
        image: gold,
        options: [
          // {
          //   type: "number",
          //   name: "numberOfUsers",
          //   info: true,
          //   textInfo: "1458 ipbx utilisateur",
          //   label: "Ajouter des utilisateurs",
          //   price: 50,
          //   value: 6,
          //   jumpValue: 6,
          //   maxValue: 48
          // },
          // {
          //   type: "number",
          //   name: "journeyFormation",
          //   info: true,
          //   textInfo: "info a distance , si sur site frais supplémentaire ",
          //   label: "Ajouter des journées de formation",
          //   value: 0,
          //   jumpValue: 1,
          //   maxValue: 9
          // }
        ]
      },
      {
        name: "Pack Grande entreprise ipos",
        monthlyPrice: 249,
        annualPrice: 3010,
        features: [
          { description: "Support inclus", available: true },
          {
            description: "utilisateurs",
            valueChanging: true,
            verificateName: "numberOfUsers",
            available: true,
            initialValue: 50
          },
          // {
          //   description: "Intégration et déploiement 1/2 journée",
          //   priceLabel: 339,
          //   info: true,
          //   textInfo: "Sera facturé en supplément",
          //   available: true
          // },
          {
            description: "2 journées de formation utilisateur",
            name: "formation",
            available: true,
            valueChanging: true,
            verificateName: "journeyFormationValue",
            initialValue: 2,
            prices: [
              {
                label: "journées de formation supplémentaire",
                value: 150,
                jumpValue: 150,
                available: true,
                info: true,
                textInfo: "Sera facturé en supplément"
              }]
          }
        ],
        image: platinum,
        options: [
          // {
          //   type: "number",
          //   name: "numberOfUsers",
          //   info: true,
          //   textInfo: "info",
          //   label: "Ajouter des utilisateurs",
          //   price: 50,
          //   value: 50,
          //   jumpValue: 50,
          //   maxValue: 249
          // },
          // {
          //   type: "number",
          //   name: "journeyFormation",
          //   info: true,
          //   textInfo: "info a distance , si sur site frais supplémentaire ",
          //   label: "Ajouter des journées de formation",
          //   value: 0,
          //   jumpValue: 1,
          //   maxValue: 9
          // }


        ]
      }
    ]
  },
  {
    id: "#cloud",
    title: "iper-cloud stockage collaboratif",
    packages: [
      {
        name: "Pack TPE iper-cloud",
        monthlyPrice: 30,
        annualPrice: 348,
        features: [
          {
            description: "Support inclus",
            available: true
          },
          {
            description: "utilisateur",
            valueChanging: true,
            verificateName: "numberOfUsers",
            available: true,
            initialValue: 1
          },
          {
            description: "Go",
            valueChanging: true,
            info: true,
            textInfo: "1000 Go = 1 To",
            verificateName: "numberOfSpace",
            available: true,
            initialValue: 500
          },
          // {
          //   description: "Intégration et déploiement 1/2 journée",
          //   priceLabel: 339,
          //   info: true,
          //   textInfo: "Sera facturé en supplément",
          //   available: true
          // }
        ],
        image: silver,
        options: [

          // {
          //   type: "number",
          //   name: "numberOfUsers",
          //   info: true,
          //   textInfo: "1458 ipbx utilisateur",
          //   label: "Ajouter des utilisateurs",
          //   price: 50,
          //   value: 1,
          //   jumpValue: 1,
          //   maxValue: 5
          // },
          // {
          //   type: "number",
          //   name: "numberOfSpace",
          //   price: 100,
          //   info: true,
          //   textInfo: "Données stockées en data center à paris en France",
          //   label: "Espace de stockage en Go",
          //   value: 500,
          //   jumpValue: 100,
          //   maxValue: 1000
          // }
        ]
      },
      {
        name: "Pack PME iper-cloud",
        monthlyPrice: 60,
        annualPrice: 732,
        features: [
          { description: "Support inclus", available: true },
          {
            description: "utilisateurs",
            valueChanging: true,
            verificateName: "numberOfUsers",
            available: true,
            initialValue: 6
          },
          {
            description: "To",
            valueChanging: true,
            info: true,
            textInfo: "info",
            verificateName: "numberOfSpace",
            available: true,
            initialValue: 500
          }
        ],
        image: gold,
        options: [
          // {
          //   type: "number",
          //   name: "numberOfUsers",
          //   info: true,
          //   textInfo: "info",
          //   label: "Ajouter des utilisateurs",
          //   price: 50,
          //   value: 6,
          //   jumpValue: 6,
          //   maxValue: 48
          // },
          // {
          //   type: "number",
          //   name: "numberOfSpace",
          //   price: 100,
          //   info: true,
          //   textInfo: "Données stockées en data center à paris en France",
          //   label: "Espace de stockage en To",
          //   value: 1,
          //   jumpValue: 1,
          //   maxValue: 5
          // }
        ]
      },
      {
        name: "Pack Grande entreprise iper-cloud",
        monthlyPrice: 189,
        annualPrice: 2244,
        features: [
          { description: "Support inclus", available: true },
          {
            description: "utilisateurs",
            valueChanging: true,
            verificateName: "numberOfUsers",
            available: true,
            initialValue: 50
          },
          {
            description: "To",
            valueChanging: true,
            info: true,
            textInfo: "info",
            verificateName: "numberOfSpace",
            available: true,
            initialValue: 6
          }
        ],
        image: platinum,
        options: [
          // {
          //   type: "number",
          //   name: "numberOfUsers",
          //   info: true,
          //   textInfo: "info",
          //   label: "Ajouter des utilisateurs",
          //   price: 50,
          //   value: 50,
          //   jumpValue: 50,
          //   maxValue: 1000
          // },
          // {
          //   type: "number",
          //   name: "numberOfSpace",
          //   price: 100,
          //   info: true,
          //   textInfo: "Données stockées en data center à paris en France",
          //   label: "Espace de stockage en To",
          //   value: 5,
          //   jumpValue: 5,
          //   maxValue: 20
          // }
        ]
      }
    ]
  },
  {
    id: "#erp",
    title: " ipercom erp logiciel de gestion collaboratif",
    packages: [
      {
        name: "Pack TPE ERP (CRM/Achat/Vente/Stock)",
        monthlyPrice: 69,
        annualPrice: 390,
        features: [
          { description: "Support inclus", available: true },
          {
            description: "utilisateur",
            valueChanging: true,
            verificateName: "numberOfUsers",
            available: true,
            initialValue: 1
          },
          // {
          //   description: "Intégration et déploiement 1/2 journée",
          //   priceLabel: 339,
          //   info: true,
          //   textInfo: "Sera facturé en supplément",
          //   available: true
          // },
          {
            description: "Formation utilisateur 1 heure",
            name: "formation",
            available: true
          },
          {
            description: "Facturation télécom",
            name: "formation",
            available: true,
            isCheck: false,
            verificateName: "telecom"
          },
          {
            description: "Facturation bureautique",
            name: "formation",
            available: true,
            isCheck: false,
            verificateName: "bureautique"
          }, {
            description: "Module CTI* (Click & Call et remontée de fiche)",
            name: "formation",
            available: true,
            isCheck: false,
            verificateName: "cti"
          }
        ],
        image: silver,
        options: [
          // {
          //   type: "number",
          //   name: "numberOfUsers",
          //   info: true,
          //   textInfo: "info",
          //   label: "Ajouter des utilisateurs",
          //   price: 50,
          //   value: 1,
          //   jumpValue: 1,
          //   maxValue: 5
          // },
          // {
          //   type: "select",
          //   name: "facturationModules",
          //   info: true,
          //   textInfo: "info",
          //   label: "Personnalisé votre facturation",
          //   options: [{
          //     label: "Facturation télécom", info: true,
          //     textInfo: "info", verificateName: "telecom"
          //   }, {
          //     label: "Facturation bureautique", info: true,
          //     textInfo: "info", verificateName: "bureautique"
          //   }, {
          //     label: "Module CTI* (Click & Call et remontée de fiche)", info: true,
          //     textInfo: "info", verificateName: "cti"
          //   }]
          // },
        ]
      },
      {
        name: "Pack PME ERP (CRM/Achat/Vente/Stock)",
        monthlyPrice: 109,
        annualPrice: 1050,
        features: [
          { description: "Support inclus", available: true },
          {
            description: "utilisateurs",
            valueChanging: true,
            verificateName: "numberOfUsers",
            available: true,
            initialValue: 6
          },
          // {
          //   description: "Intégration et déploiement 1/2 journée",
          //   priceLabel: 339,
          //   info: true,
          //   textInfo: "Sera facturé en supplément",
          //   available: true
          // },
          {
            name: "formation",
            description: "1 journée de formation utilisateur",
            available: true,
            valueChanging: true,
            verificateName: "journeyFormationValue",
            initialValue: 0,
            prices: [
              {
                label: "journées de formation supplémentaire",
                value: 200,
                jumpValue: 150,
                available: true,
                info: true,
                textInfo: "Sera facturé en supplément"

              }]
          },
          {
            description: "Facturation télécom",
            name: "formation",
            available: true,
            isCheck: false,
            verificateName: "telecom"
          },
          {
            description: "Facturation bureautique",
            name: "formation",
            available: true,
            isCheck: false,
            verificateName: "bureautique"
          }, {
            description: "Module CTI* (Click & Call et remontée de fiche)",
            name: "formation",
            available: true,
            isCheck: false,
            verificateName: "cti"
          }
        ],
        image: gold,
        options: [
          // {
          //   type: "number",
          //   name: "numberOfUsers",
          //   info: true,
          //   textInfo: "info",
          //   label: "Ajouter des utilisateurs",
          //   price: 50,
          //   value: 6,
          //   jumpValue: 6,
          //   maxValue: 48
          // },
          // {
          //   type: "select",
          //   name: "facturationModules",
          //   info: true,
          //   textInfo: "info",
          //   label: "Personnalisé votre facturation",
          //   options: [{
          //     label: "Facturation télécom", info: true,
          //     textInfo: "info", verificateName: "telecom"
          //   }, {
          //     label: "Facturation bureautique", info: true,
          //     textInfo: "info", verificateName: "bureautique"
          //   }, {
          //     label: "Module CTI* (Click & Call et remontée de fiche)", info: true,
          //     textInfo: "info", verificateName: "cti"
          //   }]
          // },
          // {
          //   type: "number",
          //   name: "journeyFormation",
          //   info: true,
          //   textInfo: "info a distance , si sur site frais supplémentaire ",
          //   label: "Ajouter des journées de formation",
          //   value: 0,
          //   jumpValue: 1,
          //   maxValue: 9
          // }
        ]
      },
      {
        name: "Pack Grande entreprise ERP (CRM/Achat/Vente/Stock)",
        monthlyPrice: 249,
        annualPrice: 3010,
        features: [
          { description: "Support inclus", available: true },
          {
            description: "utilisateurs",
            valueChanging: true,
            verificateName: "numberOfUsers",
            available: true,
            initialValue: 50
          },
          // {
          //   description: "Intégration et déploiement 1/2 journée",
          //   priceLabel: 339,
          //   info: true,
          //   textInfo: "Sera facturé en supplément",
          //   available: true
          // },
          {
            description: "2 journées de formation utilisateur",
            name: "formation",
            available: true,
            valueChanging: true,
            verificateName: "journeyFormationValue",
            initialValue: 0,
            prices: [
              {
                label: "journées de formation supplémentaire",
                value: 150,
                jumpValue: 150,
                available: true,
                info: true,
                textInfo: "Sera facturé en supplément"
              }]
          },
          {
            description: "Facturation télécom",
            name: "formation",
            available: true,
            isCheck: false,
            verificateName: "telecom"
          },
          {
            description: "Facturation bureautique",
            name: "formation",
            available: true,
            isCheck: false,
            verificateName: "bureautique"
          }, {
            description: "Module CTI* (Click & Call et remontée de fiche)",
            name: "formation",
            available: true,
            isCheck: false,
            verificateName: "cti"
          }
        ],
        image: platinum,
        options: [
          // {
          //   type: "number",
          //   name: "numberOfUsers",
          //   info: true,
          //   textInfo: "info",
          //   label: "Ajouter des utilisateurs",
          //   price: 50,
          //   value: 50,
          //   jumpValue: 50,
          //   maxValue: 249
          // },
          // {
          //   type: "select",
          //   name: "facturationModules",
          //   info: true,
          //   textInfo: "info",
          //   label: "Personnalisé votre facturation",
          //   options: [{
          //     label: "Facturation télécom", info: true,
          //     textInfo: "info", verificateName: "telecom"
          //   }, {
          //     label: "Facturation bureautique", info: true,
          //     textInfo: "info", verificateName: "bureautique"
          //   }, {
          //     label: "Module CTI* (Click & Call et remontée de fiche)", info: true,
          //     textInfo: "info", verificateName: "cti"
          //   }]
          // },
          // {
          //   type: "number",
          //   name: "journeyFormation",
          //   info: true,
          //   textInfo: "info a distance , si sur site frais supplémentaire ",
          //   label: "Ajouter des journées de formation",
          //   value: 0,
          //   jumpValue: 1,
          //   maxValue: 9
          // }
        ]
      }
    ]
  }
];