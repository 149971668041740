import React from "react";
import phone from "assets/img/ipercom/services/TelephonySolution/phoneIPBX.webp";
import coffee from "assets/img/ipercom/services/TelephonySolution/manWithCofeeAndComputerIPBX.webp";

const Head = () => {
  return (
    <>
      <section className="position-relative">
        <div className="container position-relative zindex-5 pt-5">
          <div className="row mt-4 pt-5">
            <div className="col-xl-5 col-lg-5 text-center text-lg-start pb-3 pb-md-4 pb-lg-0">
              <h1 className="display-5 pb-md-2 pb-lg-4">
                Qu'est-ce qu'un Standard téléphonique (IPBX) ?
              </h1>
            </div>
            <div className="col-xl-5 col-lg-6  position-relative zindex-5 mb-5 mb-lg-0">
              <div
                className="rellax card bg-primary border-0 shadow-primary py-2 p-sm-4 p-lg-5"
                data-rellax-speed={-1}
                data-disable-parallax-down="lg"
              >
                <div className="card-body p-lg-3">
                  <h2 className="text-light pb-1 pb-md-3">
                    L’intérêt de l'IPBX
                  </h2>
                  <p className="fs-lg text-light pb-2 pb-md-0 mb-4 mb-md-5">
                    L'IPBX, ou Internet Protocol Private Branch eXchange,
                    révolutionne la téléphonie d'entreprise en exploitant les
                    technologies Internet pour offrir une communication interne
                    efficace et économique.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="d-none d-lg-block" style={{ marginTop: "-110px" }} />
          <div className="row align-items-end mt-4">
            <div className="col-lg-6 d-none d-lg-block">
              <img
                src={coffee}
                className="rellax rounded-3"
                alt="Image"
                data-rellax-speed="1.35"
                data-disable-parallax-down="md"
              />
            </div>
          </div>
          <div className="col-lg-6 d-none d-lg-block">
            <img
              src={phone}
              className="rellax rounded-3"
              alt="Image"
              style={{
                zIndex: -1,
                top: "0%",
                position: 'absolute',
                left: '50%',
                width: '950px'
              }}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default Head;
