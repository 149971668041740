import React from "react";

const FormulaireDevOps = () => {
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    // Do something with formData, like sending it to a server
  };

  return (
    <>
      {/* Liens + Formulaire de contact */}
      <section className="position-relative bg-secondary ">
        <div className="container position-relative zindex-2 ">
          {/* Fil d'Ariane */}
          <nav className="pt-lg-4 pb-3 mb-2 mb-sm-3" aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <a href="http://82.223.216.1:2025/">
                  <i className="bx bx-home-alt fs-lg me-1" />
                  Accueil
                </a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Postuler
              </li>
            </ol>
          </nav>
          <div className="row">
            {/* Liens de contact */}
            <div className="col-xl-4 col-lg-5 pb-4 pb-sm-5 mb-2 mb-sm-0">
              <div className="pe-lg-4 pe-xl-0">
                <h1 className="pb-3 pb-md-4 mb-lg-5">Carrières</h1>
                <div className="d-flex align-items-start pb-3 mb-sm-1 mb-md-3">
                  <div className="bg-light text-primary rounded-circle flex-shrink-0 fs-3 lh-1 p-3">
                    <i className="bx bx-envelope" />
                  </div>
                  <div className="ps-3 ps-sm-4">
                    <h2 className="h4 pb-1 mb-2">E-mail </h2>
                    <p className="mb-2">
                      N'hésitez pas à nous écrire. Nous vous répondrsons dans les plus brefs délais.
                    </p>
                    <div className="btn btn-link btn-lg px-0">
                      Laisser un message
                      <i className="bx bx-right-arrow-alt lead ms-2" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Formulaire de contact */}
            <div className="col-xl-6 col-lg-7 offset-xl-2">
              <div className="card border-light shadow-lg py-2 px-2">
                <div className="card-body">
                  <h2 className="card-title pb-3">Formulaire de poste : Developpeur dev ops</h2>
                  <form className="row g-4 needs-validation" onSubmit={handleSubmit}>
                    <div className="col-12 mt-2">
                      <label htmlFor="fn" className="form-label fs-base">
                        *Nom
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="fn"
                        name="nom"
                        required
                      />
                      <div className="invalid-feedback">Veuillez entrer votre nom !</div>
                    </div>
                    <div className="col-12 mt-2">
                      <label htmlFor="ln" className="form-label fs-base">
                        *Prénom
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="ln"
                        name="prenom"
                        required
                      />
                      <div className="invalid-feedback">Veuillez entrer votre prénom !</div>
                    </div>
                    <div className="col-12 mt-2">
                      <label htmlFor="email" className="form-label fs-base">
                        *E-mail
                      </label>
                      <input
                        type="email"
                        className="form-control form-control-lg"
                        id="email"
                        name="email"
                        required
                      />
                      <div className="invalid-feedback">Veuillez fournir une adresse e-mail valide !</div>
                    </div>
                    <div className="col-12 mt-2">
                      <label htmlFor="adresse" className="form-label fs-base">
                        *Adresse
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="adresse"
                        name="adresse"
                        required
                      />
                      <div className="invalid-feedback">Veuillez entrer votre adresse !</div>
                    </div>
                    <div className="col-12 mt-2">
                      <label htmlFor="codePostal" className="form-label fs-base">
                        *Code Postal
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="codePostal"
                        name="codePostal"
                        required
                      />
                      <div className="invalid-feedback">Veuillez entrer votre code postal !</div>
                    </div>
                    <div className="col-12 mt-2">
                      <label htmlFor="cv" className="form-label fs-base">
                        *CV
                      </label>
                      <input
                        type="file"
                        className="form-control form-control-lg"
                        id="cv"
                        name="cv"
                        required
                      />
                      <div className="invalid-feedback">Veuillez sélectionner un fichier pour votre CV !</div>
                    </div>
                    <div className="col-12 mt-2">
                      <label htmlFor="commentaire" className="form-label fs-base">
                        Commentaire
                      </label>
                      <textarea
                        id="commentaire"
                        name="commentaire"
                        rows={5}
                        className="form-control form-control-lg"
                      />
                    </div>
                    <div className="col-12 mt-2 pt-2 pt-sm-3">
                      <button type="submit" className="btn btn-lg btn-primary w-100 w-sm-auto">
                        Envoyer
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="position-absolute bottom-0 start-0 w-100 bg-light" style={{ height: "8rem" }} />
      </section>
      {/* Succursales */}
      <section className="container py-2 py-lg-4 py-xl-5 mb-2 mb-md-3">
        <div className="row py-5">
          <div className="col-lg-6 mb-5 mb-lg-0">
            <div className="d-flex flex-column h-100 shadow-sm rounded-3 overflow-hidden">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5654.7188521385215!2d-0.4146552234184406!3d44.875333972535316!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd5530f62c4ca3d5%3A0x589b3fdecca9a3fb!2s1%20Rue%20Jean%20Walton%2C%2033450%20Montussan!5e0!3m2!1sfr!2sfr!4v1719827058540!5m2!1sfr!2sfr"
                width={600}
                height={450}
                style={{ border: 0 }}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </div>
          </div>
          <div className="col-lg-5 offset-lg-1">
            <h2 className="h4 mb-4">Coordonnées</h2>
            <ul className="list-unstyled">
              <li className="d-flex pb-1 mb-2">
                <i
                  className="bx bx-map text-primary fs-xl me-2"
                  style={{ marginTop: ".125rem" }}
                />
                1 Rue Jean Walton, 33450, Montussan
              </li>
              <li className="d-flex pb-1 mb-2">
                <i
                  className="bx bx-phone-call text-primary fs-xl me-2"
                  style={{ marginTop: ".125rem" }}
                />
                05 18 22 22 00
              </li>
              <li className="d-flex">
                <i
                  className="bx bx-time-five text-primary fs-xl me-2"
                  style={{ marginTop: ".125rem" }}
                />
                <div>
                  <strong className="text-nav">Lun - Ven :</strong> 8:00 - 18:00
                </div>
              </li>
            </ul>
            <div className="d-flex">
              <a
                href="#"
                className="btn btn-icon btn-secondary btn-facebook me-3"
                aria-label="Facebook"
              >
                <i className="bx bxl-facebook" />
              </a>
              <a
                href="#"
                className="btn btn-icon btn-secondary btn-instagram me-3"
                aria-label="Instagram"
              >
                <i className="bx bxl-instagram" />
              </a>
              <a
                href="https://x.com/ipercom_com"
                className="btn btn-icon btn-secondary btn-twitter me-3"
                aria-label="Twitter"
              >
                <i className="bx bxl-twitter" />
              </a>
              <a
                href="https://youtube.com/@ipercom?si=i3eUocJ72rtCmUfX"
                className="btn btn-icon btn-secondary btn-youtube"
                aria-label="YouTube"
              >
                <i className="bx bxl-youtube" />
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FormulaireDevOps;
