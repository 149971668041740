import React from "react";
import mobile from "assets/img/ipercom/icons/mobileIconeOurCrm.svg";
import computer from "assets/img/ipercom/icons/computerOurCrm.webp";
import screen from "assets/img/ipercom/icons/screenOurCrm.svg";
import cog from "assets/img/ipercom/icons/bx-cogOurCrm.webp";
import launch from "assets/img/ipercom/icons/launchOurCrm.svg";
import shield from "assets/img/ipercom/icons/shieldOurCrm.svg";

const OurCRMAdvantages = () => {
  return (
    <>
      {/* Services */}
      <section className="bg-secondary mb-2">
        <div className="d-none d-lg-block" />
        <div className="container pb-4">
          <h2 className="text-center text-md-start mb-lg-4 pt-1 pt-md-4">
            Les avantages de notre solution CRM
          </h2>
          <div className="row row-cols-1 row-cols-md-2">
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <p
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 me-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={mobile}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="h4 d-inline-flex align-items-center">
                    Gestion des campagnes marketing
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    Utiliser une solution qui vous permet de planifier, de
                    suivre et d'analyser les campagnes marketing, ce qui permet
                    d'améliorer leur efficacité et leur retour sur
                    investissement.
                  </p>
                </div>
              </p>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <p
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={computer}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="h4 d-inline-flex align-items-center">
                    Prévision des ventes
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    En analysant les données sur les ventes passées et les
                    tendances du marché, un CRM peut aider à prévoir les ventes
                    futures avec précision.
                  </p>
                </div>
              </p>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <p
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={screen}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="h4 d-inline-flex align-items-center">
                    Suivi des indicateurs clés de performance
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    Suivez et analysez les indicateurs clés de performance liés
                    aux ventes, au marketing et au service client, ce qui permet
                    d'identifier les domaines à améliorer.
                  </p>
                </div>
              </p>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <p
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={cog}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="h4 d-inline-flex align-items-center">
                    Adaptation aux besoins de l'entreprise
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    La personnalisation et la configuration sont adaptées aux
                    besoins spécifiques de votre entreprise, en ajoutant des
                    fonctionnalités personnalisées et en intégrant des systèmes
                    existants.
                  </p>
                </div>
              </p>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <p
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={launch}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="h4 d-inline-flex align-items-center">
                    Accessibilité depuis n'importe où
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    Optez pour un CRM basé sur le cloud permettant d'y accéder
                    depuis n'importe quel appareil connecté à Internet,
                    favorisant ainsi la mobilité des équipes.
                  </p>
                </div>
              </p>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <p
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={shield}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="h4 d-inline-flex align-items-center">
                    Sécurité des données
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    Notre CRM offrent des fonctionnalités avancées de sécurité
                    des données, telles que le chiffrement et la gestion des
                    autorisations, ce qui garantit la confidentialité et
                    l'intégrité des informations clients.
                  </p>
                </div>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurCRMAdvantages;
