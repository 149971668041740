import React from "react";
import app from "assets/img/ipercom/services/Solution proposes/developpement mobile/app_5_11zon.webp";
import apple from "assets/img/ipercom/services/Solution proposes/developpement mobile/bxl-apple.svg";
import android from "assets/img/ipercom/services/Solution proposes/developpement mobile/bxl-android.svg";

const SupportedPlatforms = () => {
  return (
    <>
      <section className="container mt-2">
        <div className="row">
          <div className="col-md-6 pb-2 pb-md-0 mb-4 mb-md-0">
            <div className="pe-lg-5 text-center">
              <img src={app} className="rounded-3" alt="Image" />
            </div>
          </div>
          <div className="col-md-6 align-content-center">
            <h2 className="mb-sm-4">Plateformes prises en charge </h2>
            <p className="d-md-none d-xl-block pb-2 pb-md-3 mb-3">
              Nous développons des applications mobiles performantes et
              compatibles avec iOS et Android, offrant ainsi une expérience
              utilisateur cohérente sur toutes les plateformes.
            </p>
            <div className="border rounded-3 mb-4 mb-lg-5">
              <div className="row row-cols-1 row-cols-sm-2 g-0">
                <div className="col d-flex align-items-center border-end-sm border-bottom p-3">
                  <img src={apple} alt="Icon" style={{ width: "40px" }} />
                  <div className="ps-2 ms-1">
                    <h3 className="h6 mb-0">IOS</h3>
                  </div>
                </div>
                <div className="col d-flex align-items-center border-bottom p-3">
                  <img src={android} alt="Icon" style={{ width: "40px" }} />
                  <div className="ps-2 ms-1">
                    <h3 className="h6 mb-0">Android</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SupportedPlatforms;
