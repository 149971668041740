import Paragraph from "components/modules/ressources/blog/LastNews/IaBusinessStrategies/Paragraph";
import Head from "components/modules/ressources/blog/LastNews/IaBusinessStrategies/Head";
import React from "react";

const IaBusinessStrategies = () => {
  return (
    <>
      <Head />
      <Paragraph />
    </>
  );
};

export default IaBusinessStrategies;