import React from "react";
import table from "assets/img/ipercom/ressources/Documentation/livres blancs/table with computer_29_11zon.webp";

const WhatsAWhiteBook = () => {
  return (
    <>
      <section className="container mt-5 mb-5">
        <div className="row justify-content-around">
          <div className="col-md-5 order-2">
            <div className="pe-lg-5 text-center">
              <img src={table} className="rounded-3" alt="Image" />
            </div>
          </div>
          <div className="col-md-5 order-1">
            <h2 className="mb-sm-4">
              Qu'est-ce qu'un livre blanc ? Explications et applications{" "}
            </h2>
            <p>
              Les livres blancs, documents informatifs et persuasifs, sont des
              outils incontournables dans le marketing digital. Ils permettent
              de présenter des problématiques spécifiques et de proposer des
              solutions pertinentes. Généralement longs de 5 à 20 pages, ils
              servent à éduquer les lecteurs tout en établissant l'expertise de
              l'auteur dans son domaine. Ces documents abordent divers sujets,
              des tendances du marché aux meilleures pratiques sectorielles. Ils
              sont utilisés pour générer des leads, offrant une valeur ajoutée
              en échange des coordonnées des lecteurs. Distribués gratuitement
              en ligne ou lors d'événements professionnels, ils sont également
              promus sur les réseaux sociaux et dans les campagnes
              publicitaires. En résumé, les livres blancs sont des outils
              puissants pour influencer les décisions des prospects tout en
              renforçant la crédibilité de l'entreprise.
            </p>
            <a href="/uploads/documentation/Livre-Blanc.pdf" className="btn btn-primary" download="Livre_Blanc.pdf">
              Télécharger notre livre blanc
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhatsAWhiteBook;
