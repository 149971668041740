import React from "react";
import revendeur from "assets/img/ipercom/services/TelephonySolution/software/revendeur_19_11zon.webp";
import income from "assets/img/ipercom/services/TelephonySolution/software/icons/income.svg";
import asset from "assets/img/ipercom/services/TelephonySolution/software/icons/asset-management.svg";
import { Link } from "react-router-dom";

const PartnerIperx = () => {
  return (
    <section className="bg-secondary mt-5 pb-lg-5 pb-md-4 pb-3 ">
      <div
        className="pb-4 pt-5 row m-auto align-items-center justify-content-end"
        style={{ width: "70%" }}
      >
        <div className="col-md-6">
          <h2 className="mb-sm-4">Devenir partenaire iper-X</h2>
          <p className="d-md-none d-xl-block pb-2 pb-md-3 mb-3">
            Commencez dès aujourd'hui à devenir partenaire ou revendeur de la solution iper-X:
          </p>
          <div className="border rounded-3 mb-4 mb-lg-5">
            <div className="row row-cols-1 row-cols-sm-2 g-0">
              <div className="col d-flex align-items-center border-end-sm border-bottom p-3">
                <img
                  src={income}
                  width="50"
                  alt="Icon"
                  style={{ color: "#9397AD" }}
                ></img>
                <div className="ps-2 ms-1">
                  <h3 className="h6 mb-0">2 clés de licence NFR</h3>
                  <p className="fs-xs mb-0">
                    Pour tester et faire des démos d’iper-X
                  </p>
                </div>
              </div>
              <div className="col d-flex align-items-center border-bottom p-3">
                <img
                  src={asset}
                  width="50"
                  alt="Icon"
                  style={{ color: "#9397AD" }}
                ></img>
                <div className="ps-2 ms-1">
                  <h3 className="h6 mb-0">Remises</h3>
                  <p className="fs-xs mb-0">
                    Remises pouvant aller jusqu'à 35%
                  </p>
                </div>
              </div>
              <div className="col d-flex align-items-center border-bottom border-end-sm p-3">
                <i
                  className="bx bx-briefcase fs-1"
                  style={{ color: "#9397AD" }}
                ></i>
                <div className="ps-2 ms-1">
                  <h3 className="h6 mb-0">Formations</h3>
                  <p className="fs-xs mb-0">Dialogues en temps réel</p>
                </div>
              </div>
              <div className="col d-flex align-items-center border-bottom p-3">
                <i
                  className="bx bx-calculator fs-1"
                  style={{ color: "#9397AD" }}
                ></i>
                <div className="ps-2 ms-1">
                  <h3 className="h6 mb-0">Certifications </h3>
                  <p className="fs-xs mb-0">Contact client instantané</p>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column flex-sm-row">
            <Link to="/a-propos/devenir-revendeur" className="btn btn-primary mb-3 mb-sm-0 me-sm-3">
              Devenir revendeur
            </Link>
          </div>
        </div>
        <div className="col-md-6 pb-2 pb-md-0 mb-4 mb-md-0">
          <div className="pe-lg-5 text-center">
            <img src={revendeur} className="rounded-3" alt="Image" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default PartnerIperx;
