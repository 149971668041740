import React from "react";


const ProjectAppWeb = () => {
  return (
    <>
      {/* Portfolio list */}
      <section className="container pb-2 pb-lg-3 pt-5">
        <div className="ps-md-4 ms-md-2 text-center">
          <h2>Gestion des flux de travail avec iper-cloud</h2>
          <p className="d-md-none d-lg-block pb-3 mb-2 mb-md-3 m-auto">
            Avec iper-cloud, les administrateurs système ont la capacité de surveiller et de guider le flux de données
            entre les utilisateurs, que ce soit sur les serveurs ou entre eux. En utilisant des balises de fichiers
            basées sur des règles et en réagissant à ces balises ainsi qu'à d'autres déclencheurs tels que l'emplacement
            physique, le groupe d'utilisateurs, les propriétés du fichier et le type de demande, les administrateurs
            peuvent spécifiquement autoriser ou refuser l'accès aux données, les convertir, les supprimer ou les
            conserver selon les exigences commerciales ou légales. iper-cloud offre une solution permettant au service
            informatique de gérer les données des utilisateurs de manière sécurisée.
          </p>
        </div>
      </section>
    </>
  );
};

export default ProjectAppWeb;
