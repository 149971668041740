import React from 'react';
import appLight from "assets/img/ipercom/services/TelephonySolution/software/appstore-light.svg";
import googleLight from "assets/img/ipercom/services/TelephonySolution/software/googleplay-light.svg";
import linux from "assets/img/ipercom/logo/linux.webp";
import { Col, Row } from 'react-bootstrap';

const Telechargement = () => {
  return (
    <div className="container py-5">
      {/* Section pour le téléchargement sur ordinateur */}
      <Row className=" align-items-center text-center">
        <div className="col-lg-3 text-center mb-4 mb-lg-0">
          <img
            decoding="async"
            src="/static/media/analytics.2d77f830a2cf78eda99e09bf6fff1fc5.svg"
            alt="Ordinateur"
            style={{ marginBottom: "10px", display: "block", margin: "0 auto" }}
          />
          <h2 className="h6" style={{ marginTop: "10px" }}>Téléchargement pour ordinateur de bureau</h2>
          <p style={{ margin: "10px 0" }}>
            Accédez à votre iper-cloud depuis un système Windows, macOS ou Linux.
          </p>
        </div>
        <div className="col-lg-9 d-flex justify-content-center justify-content-md-start">
          <Col>
          <a
            href="#"
            className="btn btn-dark btn-lg me-3 mb-3 rounded"
            style={{ minWidth: "200px" }}
          >
            <i className="bx bxl-windows me-2"></i>Windows
          </a>
          <a
            href="#"
            className="btn btn-dark btn-lg me-3 mb-3 rounded"
            style={{ minWidth: "200px" }}
          >
            <img src={linux} width={"20px"} alt="Linux" />Linux
          </a>
          <a
            href="#"
            className="btn btn-dark btn-lg me-3 mb-3 rounded"
            style={{ minWidth: "200px" }}
          >
            <i className="bx bxl-apple me-2"></i>Mac
          </a>
          </Col>
        </div>
      </Row>

      {/* Section pour les boutons de téléchargement d'application */}
      <Row className="mt-5 align-items-center text-center">
        <div className="col-lg-3 text-center mb-4 mb-lg-0">
          <img
            decoding="async"
            src="/static/media/analytics.2d77f830a2cf78eda99e09bf6fff1fc5.svg"
            alt="Applications"
            style={{ marginBottom: "10px", display: "block", margin: "0 auto" }}
          />
          <h2 className="h6" style={{ marginTop: "10px" }}>Téléchargement d'application</h2>
          <p style={{ margin: "10px 0" }}>
            Téléchargez nos applications mobiles pour accéder à iper-cloud.
          </p>
        </div>
        <div className="col-lg-9 d-flex justify-content-center justify-content-md-start">
        <Col>
          <a
            href="https://play.google.com/store/apps/dev?id=6388058322571838005"
            className="btn btn-dark btn-lg me-3 mb-3 rounded"

          >
            <img
              src={appLight}
              className="light-mode-img"
              width={124}
              alt="App Store"
       
            />
          </a>
          <a
            href="https://play.google.com/store/apps/dev?id=6388058322571838005"
            className="btn btn-dark btn-lg me-3 mb-3 rounded"
          >
            <img
              src={googleLight}
              className="light-mode-img"
              width={139}
              alt="Google Play"
       
            />
          </a>
          </Col>
        </div>
      </Row>
    </div>
  );
};

export default Telechargement;
