import React from 'react';
import { Navigation, Pagination, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import logo_ipercom from "assets/img/ipercom/services/ERP/Group.svg";



const Testimonials = () => {
  const testimonials = [
    {
      quote:
        "ipercom a révolutionné notre façon de gérer l'entreprise. Ses fonctionnalités avancées et son support client exceptionnel en font un choix incontournable!",
      rating: 3,
      avatar: logo_ipercom,
      name: "Service développement",
      title: "Co-fondateur de la société Lorem"
    },
    {
      quote:
        "Nous sommes ravis de notre expérience avec ipercom. Sa flexibilité et son adaptabilité à nos besoins spécifiques en font un partenaire de confiance pour notre croissance future.",
      rating: 5,
      avatar: logo_ipercom,
      name: "Service développement",
      title: "PDG de la société Ipsum"
    },
    {
      quote:
        "Ce logiciel métiers a révolutionné notre façon de travailler. Personnalisable, intuitif, parfait pour notre secteur!",
      rating: 4,
      avatar: logo_ipercom,
      name: "Service développement",
      title: "Fondateur de l'Agence"
    },
    {
      quote:
        "ipercom a révolutionné notre façon de gérer l'entreprise. Ses fonctionnalités avancées et son support client exceptionnel en font un choix incontournable!",
      rating: 3,
      avatar: logo_ipercom,
      name: "Service développement",
      title: "Co-fondateur de la société Lorem"
    },
    {
      quote:
        "Nous sommes ravis de notre expérience avec ipercom. Sa flexibilité et son adaptabilité à nos besoins spécifiques en font un partenaire de confiance pour notre croissance future.",
      rating: 4,
      avatar: logo_ipercom,
      name: "Service développement",
      title: "PDG de la société Ipsum"
    }
  ];

  return (
    <section className="container py-5 mb-2 mt-md-2 mb-md-4 mt-lg-4 mb-lg-5">
      <div className="row pt-xl-1 pb-xl-3">
        <div className="col-lg-3 col-md-4">
          <h2
            className="text-center text-md-start mx-auto mx-md-0 pt-md-2"
            style={{ maxWidth: 300 }}
          >
            Ce que nos <br className="d-none d-md-inline" />
            clients disent <br className="d-none d-md-inline" />
            de nous :
          </h2>
          <div className="d-flex justify-content-center justify-content-md-start pb-4 mb-2 pt-2 pt-md-4 mt-md-5">
            <button
              id={"btn-prev"}
              type="button"
              className="btn-prev btn-icon btn-sm me-2"
              aria-label="Previous"
            >
              <i className="bx bx-chevron-left"></i>
            </button>
            <button
              id={"btn-next"}
              type="button"
              className="btn-next btn-icon btn-sm ms-2"
              aria-label="Next"
            >
              <i className="bx bx-chevron-right"></i>
            </button>
          </div>
        </div>

        <div className="col-lg-9 col-md-8">
          <Swiper
            modules={[Navigation, Pagination, A11y]}
            slidesPerView={1}
            spaceBetween={8}
            loop={true}
            navigation={{
              prevEl: ".btn-prev",
              nextEl: ".btn-next"
            }}
            breakpoints={{
              500: {
                slidesPerView: 2
              },
              1000: {
                slidesPerView: 2
              },
              1200: {
                slidesPerView: 3
              }
            }}
          >
            {testimonials.map((testimonial, index) => (
              <SwiperSlide key={index}>
                <div className="h-auto pt-4" style={{ width: 300 }}>
                  <figure className="d-flex flex-column h-100 px-2 px-sm-0 mb-0 mx-2">
                    <div className="card h-100 position-relative border-0 shadow-sm pt-4">
                      <span
                        className="btn btn-icon btn-primary shadow-primary pe-none position-absolute top-0 start-0 translate-middle-y ms-4">
                        <i className="bx bxs-quote-left"></i>
                      </span>
                      <blockquote className="card-body pb-3 mb-0">
                        <p className="mb-0">{testimonial.quote}</p>
                      </blockquote>
                      <div className="card-footer border-0 text-nowrap pt-0">
                        {[...Array(testimonial.rating)].map((_, i) => (
                          <i key={i} className="bx bxs-star text-warning"></i>
                        ))}
                        {[...Array(5 - testimonial.rating)].map((_, i) => (
                          <i
                            key={i}
                            className="bx bx-star text-muted opacity-75"
                          ></i>
                        ))}
                      </div>
                    </div>
                    <figcaption className="d-flex align-items-center ps-4 pt-4">
                      <img
                        src={testimonial.avatar}
                        width="48"
                        className="rounded-circle"
                        alt={testimonial.name}
                      />
                      <div className="ps-3">
                        <h6 className="fs-sm fw-semibold mb-0">
                          {testimonial.name}
                        </h6>
                      </div>
                    </figcaption>
                  </figure>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
