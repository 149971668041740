import React from "react";
import headphone from "assets/img/ipercom/services/Solution proposes/maintenance and support tech/cms.svg";
import clock from "assets/img/ipercom/services/Solution proposes/maintenance and support tech/clock.svg";
import mobile from "assets/img/ipercom/services/Solution proposes/developpement mobile/mobile-app.svg";
import screen from "assets/img/ipercom/icons/screenOurCrm.svg";
import graph from "assets/img/ipercom/services/ERP/company/icons/graph.svg";
import like from "assets/img/ipercom/services/Solution proposes/maintenance and support tech/like.svg";

const BenefitsTechSupport = () => {
  return (
    <>
      {/* Services */}
      <section className="bg-secondary">
        <div
          className="d-none d-lg-block"
          style={{ marginTop: "-60px", paddingTop: 60 }}
        />
        <div className="container pb-4 pt-5">
          <h2 className="text-center text-md-start mb-lg-4 pt-1 pt-md-4">
            Avantages du support technique
          </h2>
          <div className="col-md-8 text-center text-md-start">
            <p className="fs-md text-muted mb-md-3">
              Le support technique professionnel assure une assistance
              personnalisée et efficace pour maximiser la productivité.
            </p>
          </div>
          <div className="row row-cols-1 row-cols-md-2">
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <div
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 me-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={headphone}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="h4 d-inline-flex align-items-center">
                    Assistance en temps réel
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    Le support technique fournit une assistance immédiate pour
                    résoudre les problèmes techniques rencontrés par les
                    utilisateurs.
                  </p>
                </div>
              </div>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <div
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={clock}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="h4 d-inline-flex align-items-center">
                    Disponibilité 24/7
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    De nombreux services de support technique offrent une
                    assistance 24 heures sur 24, 7 jours sur 7, garantissant une
                    assistance disponible en tout temps, même en dehors des
                    heures de bureau.
                  </p>
                </div>
              </div>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <div
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={mobile}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="h4 d-inline-flex align-items-center">
                    Expertise spécialisée
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    Les équipes de support technique sont composées de
                    professionnels qualifiés et expérimentés, capables de
                    traiter une variété de problèmes techniques complexes.
                  </p>
                </div>
              </div>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <div
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={screen}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="h4 d-inline-flex align-items-center">
                    Documentation et ressources
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    Le support technique fournit souvent une documentation
                    détaillée et des ressources en ligne pour aider les
                    utilisateurs à résoudre les problèmes par eux-mêmes et à
                    mieux comprendre les produits ou les services.
                  </p>
                </div>
              </div>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <div
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={like}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="h4 d-inline-flex align-items-center">
                    Transparence et communication
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    Les équipes de support technique maintiennent une
                    communication transparente avec les utilisateurs, les tenant
                    informés de l'état de leurs demandes d'assistance et des
                    actions prises pour les résoudre.
                  </p>
                </div>
              </div>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <div
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={graph}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="h4 d-inline-flex align-items-center">
                    Évolution et adaptation
                  </h3>
                  <p className="fs-sm text-body mb-0">
                  Le support technique aide les entreprises à s'adapter aux changements technologiques
                  et aux nouvelles exigences des utilisateurs en fournissant une assistance pour
                  la mise en œuvre de nouvelles fonctionnalités.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BenefitsTechSupport;
