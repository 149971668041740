// Importing React and the image as per the instruction
import React from 'react';
import { Parallax } from 'react-parallax';
import table from 'assets/img/ipercom/a-propos/commitmentAndValues/table.webp';

interface ContactItemProps {
  iconClass: string;
  text: string;
  link?: string;
}

// HeroSection component
const HeroSection = () => {
  return (
    <section className="">
      <div className="position-relative overflow-hidden">
        <HeroImage />
        <HeroContent />
      </div>
    </section>
  );
};

// HeroImage component for the background image section
const HeroImage: React.FC = () => {
  return (
    <div className="container-fluid position-relative position-lg-absolute top-0 start-0 h-100">
      <div className="row h-100 me-n4 me-n2">
        <div className="col-lg-7 position-relative">
          <Parallax
            bgImage={table}
            className="position-absolute top-0 start-0 w-100 h-100 rounded-3 rounded-start-0"
          >
            <div style={{ height: '100%' }}></div>
          </Parallax>
        </div>
      </div>
    </div>
  );
};

// HeroContent component for the main content section
const HeroContent = () => {
  return (
    <div className="container position-relative zindex-5 pt-lg-5 px-0 px-lg-3">
      <div className="row pt-lg-5 mx-n4 mx-lg-n3">
        <ContentCard />
        <Contacts />
      </div>
    </div>
  );
};

// ContentCard component for the card with text and buttons
const ContentCard = () => {
  return (
    <div className="col-xl-5 col-lg-7 offset-lg-5 offset-xl-6 pb-5">
      <div className="card bg-dark border-light overflow-hidden">
        <span
          className="position-absolute top-0 start-0 w-100 h-100"
          style={{ backgroundColor: "rgba(255,255,255,.05)" }}
        ></span>
        <div className="card-body position-relative zindex-4">
          <h1 className="text-light">Engagements et valeurs </h1>
          <p className="fs-md text-light opacity-70 mb-5">
            Découvrez nos engagements et nos valeurs, véritables piliers de
            notre entreprise, guidant chaque action et renforçant notre relation
            avec vous.
          </p>
        </div>
      </div>
    </div>
  );
};

const Contacts: React.FC = () => {
  return (
    <div className="col-xxl-3 col-lg-4 offset-lg-8 offset-xxl-9 pt-lg-5 mt-xxl-5">
      <ul className="list-unstyled mb-0 px-4 px-lg-0">
        <ContactItem iconClass="" text="" />
        <ContactItem iconClass="" text="" />
        <ContactItem iconClass="" text="" />
        <ContactItem iconClass="" text="" />
      </ul>
    </div>
  );
};

const ContactItem: React.FC<ContactItemProps> = ({ iconClass, text, link }) => {
  return (
    <li className="d-flex align-items-center pb-1 mb-2">
      <i className={`bx ${iconClass} fs-xl text-primary me-2`}></i>
      {link ? (
        <a href={link} className="nav-link fw-normal p-0">
          {text}
        </a>
      ) : (
        <span>{text}</span>
      )}
    </li>
  );
};

export default HeroSection;
