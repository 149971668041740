import React from "react";
import woman from "assets/img/ipercom/services/Solution proposes/developpement mobile/appNum_9_11zon.webp";

const TechLangUsed = () => {
  return (
    <>
      <section className="container">
        <div className="row">
          <div className="col-md-6 pb-2 pb-md-0 mb-4 mb-md-0 order-2">
            <div className="pe-lg-5 text-center">
              <img src={woman} className="rounded-3" alt="Image" />
            </div>
          </div>
          <div className="col-md-6 align-content-center order-1">
            <h2 className="mb-sm-4">Technologies et langages utilisés</h2>
            <p className="d-md-none d-xl-block pb-2 pb-md-3 mb-3">
              Nous privilégions les technologies Microsoft telles que C# et .NET
              Core, accompagnées du dernier ASP.NET, pour offrir des solutions
              logicielles modernes et performantes. Notre expertise garantit des
              applications web et mobiles de haute qualité, adaptées à vos
              besoins spécifiques. Optez pour notre savoir-faire et bénéficiez
              de solutions innovantes conçues pour optimiser votre activité.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default TechLangUsed;
