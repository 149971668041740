// Importing React and the image as per the instruction
import React from 'react';
import { Parallax } from 'react-parallax';
import headImage from "assets/img/ipercom/services/ERP/womenWithACalculator.webp";

interface ContactItemProps {
  iconClass: string;
  text: string;
  link?: string;
}

// HeroSection component
const HeroSection = () => {
  return (
    <section className="">
      <div className="position-relative overflow-hidden">
        <HeroImage />
        <HeroContent />
      </div>
    </section>
  );
};

// HeroImage component for the background image section
const HeroImage: React.FC = () => {
  return (
    <div className="container-fluid position-relative position-lg-absolute top-0 start-0 h-100">
      <div className="row h-100 me-n4 me-n2">
        <div className="col-lg-7 position-relative">
          <Parallax
            bgImage={headImage}
            className="position-absolute top-0 start-0 w-100 h-100 rounded-3 rounded-start-0"
          >
            <div style={{ height: '100%' }}></div>
          </Parallax>
        </div>
      </div>
    </div>
  );
};

// HeroContent component for the main content section
const HeroContent = () => {
  return (
    <div className="container position-relative zindex-5 pt-lg-5 px-0 px-lg-3">
      <div className="row pt-lg-5 mx-n4 mx-lg-n3">
        <ContentCard />
        <Contacts />
      </div>
    </div>
  );
};

// ContentCard component for the card with text and buttons
const ContentCard = () => {
  return (
    <div className="col-xl-5 col-lg-7 offset-lg-5 offset-xl-6 pb-5">
      <div className="card bg-dark border-light overflow-hidden">
        <span
          className="position-absolute top-0 start-0 w-100 h-100"
          style={{ backgroundColor: "rgba(255,255,255,.05)" }}
        ></span>
        <div className="card-body position-relative zindex-4">
          <p className="fs-md fw-bold text-primary text-uppercase mb-3">
            Dynamisez votre entreprise
          </p>
          <h1 className="text-light">Création de Devis</h1>
          <p className="fs-md text-light opacity-70 mb-5">
            Établissez vos devis avec facilité pour une gestion commerciale
            optimale.
          </p>
          <div className="d-flex flex-column flex-sm-row">
            <a
              href="/abonnements"
              className="btn btn-primary shadow-primary btn-lg mb-3 mb-sm-0 me-sm-4"
            >
              Commencer
            </a>
            <a
              href="https://outlook.office.com/bookwithme/user/9e8387330e3644559680de311487211e%40ipercom.com/meetingtype/04c0ddc4-d8b0-4c28-a2fb-1853e564c7ef?anonymous"
              className="btn btn-outline-light btn-lg"
            >
              Demander une démo
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const Contacts: React.FC = () => {
  return (
    <div className="col-xxl-3 col-lg-4 offset-lg-8 offset-xxl-9 pt-lg-5 mt-xxl-5">
      <ul className="list-unstyled mb-0 px-4 px-lg-0">
        <ContactItem iconClass="" text="" />
        <ContactItem iconClass="" text="" />
        <ContactItem iconClass="" text="" />
        <ContactItem iconClass="" text="" />
      </ul>
    </div>
  );
};

const ContactItem: React.FC<ContactItemProps> = ({ iconClass, text, link }) => {
  return (
    <li className="d-flex align-items-center pb-1 mb-2">
      <i className={`bx ${iconClass} fs-xl text-primary me-2`}></i>
      {link ? (
        <a href={link} className="nav-link fw-normal p-0">
          {text}
        </a>
      ) : (
        <span>{text}</span>
      )}
    </li>
  );
};

export default HeroSection;
