import React from "react";
import avatar19 from '../../assets/img/avatar/19.jpg';
import avatar20 from '../../assets/img/avatar/20.jpg';
import avatar21 from '../../assets/img/avatar/21.jpg';
import avatar22 from '../../assets/img/avatar/22.jpg';
import avatar23 from '../../assets/img/avatar/23.jpg';
import avatar24 from '../../assets/img/avatar/24.jpg';
import avatar25 from '../../assets/img/avatar/25.jpg';
import avatar26 from '../../assets/img/avatar/26.jpg';


const Messages = () => {
  return (
    <div className="col-md-8 col-lg-9 pb-5 mb-lg-2 mb-lg-4 pt-md-5 mt-n3 mt-md-0">
      <div className="ps-md-3 mt-md-2 pt-md-4 pb-md-2">
        <div className="row g-0 border rounded-3 shadow-sm position-relative overflow-hidden">
          {/* Liste des contacts */}
          <div className="col-lg-4">
            <div
              id="contactsList"
              className="offcanvas-lg offcanvas-start position-absolute position-lg-relative h-100 bg-secondary shadow-none border-end"
              data-bs-scroll="true"
              data-bs-backdrop="false"
              style={{ maxHeight: 712 }}
            >
              <div className="card-header w-100 border-0 p-4">
                <div className="d-flex align-items-center justify-content-between mb-4">
                  <h1 className="h3 mb-0">Messages</h1>
                  <button
                    type="button"
                    className="btn btn-link nav-link bg-faded-primary-hover d-lg-none py-2 ps-2 pe-3 me-n3"
                    data-bs-dismiss="offcanvas"
                    data-bs-target="#contactsList"
                  >
                    <i className="bx bx-x fs-xl me-1" />
                    Fermer
                  </button>
                </div>
                <div className="position-relative">
                  <input
                    type="text"
                    className="form-control pe-5"
                    placeholder="Rechercher"
                  />
                  <i className="bx bx-search fs-xl text-nav position-absolute top-50 end-0 translate-middle-y me-3" />
                </div>
              </div>
              <div
                className="card-body swiper scrollbar-hover overflow-hidden w-100 p-0"
                data-swiper-options='{
                "direction": "vertical",
                "slidesPerView": "auto",
                "freeMode": true,
                "scrollbar": {
                  "el": ".swiper-scrollbar"
                },
                "mousewheel": true
              }'
              >
                <div className="swiper-wrapper">
                  <div className="swiper-slide h-auto">
                    {/* Contact */}
                    <a
                      href="#"
                      className="d-flex align-items-start border-bottom text-decoration-none bg-faded-primary-hover py-3 px-4"
                    >
                      <img
                        src={avatar19}
                        className="rounded-circle"
                        width={40}
                        alt="Devon Lane"
                      />
                      <div className="w-100 ps-2 ms-1">
                        <div className="d-flex align-items-center justify-content-between mb-1">
                          <h6 className="mb-0 me-2">Devon Lane</h6>
                          <span className="fs-xs text-muted">18:02</span>
                        </div>
                        <p className="fs-sm text-body mb-0">
                          Dolor, quam habitant...
                        </p>
                      </div>
                    </a>
                    {/* Contact (actif) */}
                    <a
                      href="#"
                      className="position-relative d-flex align-items-start border-bottom text-decoration-none bg-light pe-none py-3 px-4"
                    >
                      <div className="position-absolute top-0 start-0 bg-primary w-2 h-100" />
                      <img
                        src={avatar20}
                        className="rounded-circle"
                        width={40}
                        alt="Albert Flores"
                      />
                      <div className="w-100 ps-2 ms-1">
                        <div className="d-flex align-items-center justify-content-between mb-1">
                          <h6 className="mb-0 me-2">Albert Flores</h6>
                          <span className="fs-xs text-muted">17:33</span>
                        </div>
                        <p className="fs-sm text-body mb-0">Bienvenue 😊</p>
                      </div>
                    </a>
                    {/* Contact */}
                    <a
                      href="#"
                      className="d-flex align-items-start border-bottom text-decoration-none bg-faded-primary-hover py-3 px-4"
                    >
                      <img
                        src={avatar21}
                        className="rounded-circle"
                        width={40}
                        alt="Bessie Cooper"
                      />
                      <div className="w-100 ps-2 ms-1">
                        <div className="d-flex align-items-center justify-content-between mb-1">
                          <h6 className="mb-0 me-2">Bessie Cooper</h6>
                          <span className="fs-xs text-muted">12:04</span>
                        </div>
                        <p className="fs-sm text-body mb-0">
                          En train de taper...
                        </p>
                      </div>
                    </a>
                    {/* Contact */}
                    <a
                      href="#"
                      className="d-flex align-items-start border-bottom text-decoration-none bg-faded-primary-hover py-3 px-4"
                    >
                      <img
                        src={avatar22}
                        className="rounded-circle"
                        width={40}
                        alt="Ronald Richards"
                      />
                      <div className="w-100 ps-2 ms-1">
                        <div className="d-flex align-items-center justify-content-between mb-1">
                          <h6 className="mb-0 me-2">Ronald Richards</h6>
                          <span className="fs-xs text-muted">08:00</span>
                        </div>
                        <p className="fs-sm text-body mb-0">D'accord 👌</p>
                      </div>
                    </a>
                    {/* Contact */}
                    <a
                      href="#"
                      className="d-flex align-items-start border-bottom text-decoration-none bg-faded-primary-hover py-3 px-4"
                    >
                      <img
                        src={avatar23}
                        className="rounded-circle"
                        width={40}
                        alt="Robert Fox"
                      />
                      <div className="w-100 ps-2 ms-1">
                        <div className="d-flex align-items-center justify-content-between mb-1">
                          <h6 className="mb-0 me-2">Robert Fox</h6>
                          <span className="fs-xs text-muted">14 Nov</span>
                        </div>
                        <p className="fs-sm text-body mb-0">Praesent ut mi.</p>
                      </div>
                    </a>
                    {/* Contact */}
                    <a
                      href="#"
                      className="d-flex align-items-start border-bottom text-decoration-none bg-faded-primary-hover py-3 px-4"
                    >
                      <img
                        src={avatar24}
                        className="rounded-circle"
                        width={40}
                        alt="Jerome Bell"
                      />
                      <div className="w-100 ps-2 ms-1">
                        <div className="d-flex align-items-center justify-content-between mb-1">
                          <h6 className="mb-0 me-2">Jerome Bell</h6>
                          <span className="fs-xs text-muted">8 Oct</span>
                        </div>
                        <p className="fs-sm text-body mb-0">
                          In pretium a eget nunc...
                        </p>
                      </div>
                    </a>
                    {/* Contact */}
                    <a
                      href="#"
                      className="d-flex align-items-start border-bottom text-decoration-none bg-faded-primary-hover py-3 px-4"
                    >
                      <img
                        src={avatar25}
                        className="rounded-circle"
                        width={40}
                        alt="Kathryn Murphy"
                      />
                      <div className="w-100 ps-2 ms-1">
                        <div className="d-flex align-items-center justify-content-between mb-1">
                          <h6 className="mb-0 me-2">Kathryn Murphy</h6>
                          <span className="fs-xs text-muted">16 Sep</span>
                        </div>
                        <p className="fs-sm text-body mb-0">Tempor amet?</p>
                      </div>
                    </a>
                    {/* Contact */}
                    <a
                      href="#"
                      className="d-flex align-items-start border-bottom text-decoration-none bg-faded-primary-hover py-3 px-4"
                    >
                      <img
                        src={avatar26}
                        className="rounded-circle"
                        width={40}
                        alt="Dianne Russell"
                      />
                      <div className="w-100 ps-2 ms-1">
                        <div className="d-flex align-items-center justify-content-between mb-1">
                          <h6 className="mb-0 me-2">Dianne Russell</h6>
                          <span className="fs-xs text-muted">27 Aug</span>
                        </div>
                        <p className="fs-sm text-body mb-0">
                          Vehicula accumsan leo.
                        </p>
                      </div>
                    </a>
                    {/* Contact */}
                    <a
                      href="#"
                      className="d-flex align-items-start border-bottom text-decoration-none bg-faded-primary-hover py-3 px-4"
                    >
                      <img
                        src={avatar21}
                        className="rounded-circle"
                        width={40}
                        alt="Cody Fisher"
                      />
                      <div className="w-100 ps-2 ms-1">
                        <div className="d-flex align-items-center justify-content-between mb-1">
                          <h6 className="mb-0 me-2">Cody Fisher</h6>
                          <span className="fs-xs text-muted">18 Aug</span>
                        </div>
                        <p className="fs-sm text-body mb-0">
                          Erat cum amet viverra...
                        </p>
                      </div>
                    </a>
                    {/* Contact */}
                    <a
                      href="#"
                      className="d-flex align-items-start text-decoration-none bg-faded-primary-hover py-3 px-4"
                    >
                      <img
                        src={avatar21}
                        className="rounded-circle"
                        width={40}
                        alt="Darlene Robertson"
                      />
                      <div className="w-100 ps-2 ms-1">
                        <div className="d-flex align-items-center justify-content-between mb-1">
                          <h6 className="mb-0 me-2">Darlene Robertson</h6>
                          <span className="fs-xs text-muted">13 Jul</span>
                        </div>
                        <p className="fs-sm text-body mb-0">
                          Orci convallis eget sit...
                        </p>
                      </div>
                    </a>
                  </div>
                </div>
                <div className="swiper-scrollbar end-0" />
              </div>
            </div>
          </div>
          {/* Fenêtre de chat */}
          <div className="col-lg-8" style={{ maxHeight: 712 }}>
            <div className="card h-100 border-0 bg-transparent pb-3">
              {/* En-tête */}
              <div className="navbar card-header d-flex align-items-center justify-content-between w-100 p-sm-4 p-3">
                <div className="d-flex align-items-center pe-3">
                  <button
                    type="button"
                    className="navbar-toggler d-lg-none me-3"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#contactsList"
                    aria-controls="contactsList"
                    aria-label="Basculer la liste des contacts"
                  >
                    <span className="navbar-toggler-icon" />
                  </button>
                  <img
                    src={avatar20}
                    className="rounded-circle"
                    width={40}
                    alt="Albert Flores"
                  />
                  <h6 className="mb-0 px-1 mx-2">Albert Flores</h6>
                  <div
                    className="bg-success rounded-circle"
                    style={{ width: 8, height: 8 }}
                  />
                </div>
                <div className="d-flex">
                  <div className="dropdown me-sm-2">
                    <button
                      type="button"
                      className="btn btn-outline-primary px-2 px-sm-3"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="bx bx-dots-horizontal-rounded fs-xl mx-1 mx-sm-0 me-xl-2" />
                      <span className="d-none d-xl-inline">Plus</span>
                    </button>
                    <div className="dropdown-menu dropdown-menu-end my-1">
                      <a href="#" className="dropdown-item">
                        Voir le profil
                      </a>
                      <a href="#" className="dropdown-item">
                        Désactiver les notifications
                      </a>
                      <a href="#" className="dropdown-item">
                        Modifier le contact
                      </a>
                      <a href="#" className="dropdown-item">
                        Supprimer le contact
                      </a>
                      <a href="#" className="dropdown-item">
                        Supprimer le chat
                      </a>
                      <a href="#" className="dropdown-item">
                        Effacer l'historique
                      </a>
                      <a href="#" className="dropdown-item">
                        Bloquer l'utilisateur
                      </a>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="btn btn-outline-danger d-none d-sm-inline-flex px-2 px-sm-3 ms-1"
                  >
                    <i className="bx bx-trash-alt fs-xl me-xl-2" />
                    <span className="d-none d-xl-inline">Effacer le chat</span>
                  </button>
                </div>
              </div>
              {/* Messages */}
              <div
                className="card-body swiper scrollbar-hover overflow-hidden w-100 pb-0"
                data-swiper-options='{
                "direction": "vertical",
                "slidesPerView": "auto",
                "freeMode": true,
                "scrollbar": {
                  "el": ".swiper-scrollbar"
                },
                "mousewheel": true
              }'
              >
                <div className="swiper-wrapper">
                  <div className="swiper-slide h-auto">
                    <div className="fs-sm text-muted text-center mb-3">
                      27 novembre 2023
                    </div>
                    {/* Message de l'utilisateur */}
                    <div className="d-flex align-items-start mb-3">
                      <img
                        src={avatar20}
                        className="rounded-circle"
                        width={40}
                        alt="Albert Flores"
                      />
                      <div className="ps-2 ms-1" style={{ maxWidth: 348 }}>
                        <div
                          className="bg-secondary p-3 mb-1"
                          style={{
                            borderTopRightRadius: ".5rem",
                            borderBottomRightRadius: ".5rem",
                            borderBottomLeftRadius: ".5rem"
                          }}
                        >
                          Tellus, ipsum, commodo, dui ac. Ultrices mi arcu orci
                          aliquam et.
                        </div>
                        <div className="fs-sm text-muted">09:04 am</div>
                      </div>
                    </div>
                    {/* Message propre */}
                    <div className="d-flex align-items-start justify-content-end mb-3">
                      <div className="pe-2 me-1" style={{ maxWidth: 348 }}>
                        <div
                          className="bg-primary text-light p-3 mb-1"
                          style={{
                            borderTopLeftRadius: ".5rem",
                            borderBottomRightRadius: ".5rem",
                            borderBottomLeftRadius: ".5rem"
                          }}
                        >
                          Orci convallis eget sit ultricies rutrum.
                        </div>
                        <div className="d-flex justify-content-end align-items-center fs-sm text-muted">
                          10:36 am
                          <i className="bx bx-check-double fs-xl text-primary ms-2" />
                        </div>
                      </div>
                      <img
                        src={avatar21}
                        className="rounded-circle"
                        width={40}
                        alt="Albert Flores"
                      />
                    </div>
                    {/* Message de l'utilisateur */}
                    <div className="d-flex align-items-start mb-3">
                      <img
                        src={avatar20}
                        className="rounded-circle"
                        width={40}
                        alt="Albert Flores"
                      />
                      <div className="ps-2 ms-1" style={{ maxWidth: 348 }}>
                        <div
                          className="bg-secondary p-3 mb-1"
                          style={{
                            borderTopRightRadius: ".5rem",
                            borderBottomRightRadius: ".5rem",
                            borderBottomLeftRadius: ".5rem"
                          }}
                        >
                          Blandit tempus, erat cum amet viverra pharetra, morbi.
                          Vivamus pretium tristique amet, nulla aenean sed
                          blandit?
                        </div>
                        <div className="fs-sm text-muted">14:48 pm</div>
                      </div>
                    </div>
                    {/* Message propre */}
                    <div className="d-flex align-items-start justify-content-end mb-3">
                      <div className="pe-2 me-1" style={{ maxWidth: 348 }}>
                        <div
                          className="bg-primary text-light p-3 mb-1"
                          style={{
                            borderTopLeftRadius: ".5rem",
                            borderBottomRightRadius: ".5rem",
                            borderBottomLeftRadius: ".5rem"
                          }}
                        >
                          Vel enim lacus, ac, fermentum, id. Aliquet faucibus
                          pellentesque egestas.
                        </div>
                        <div
                          className="bg-primary text-light p-3 mb-1"
                          style={{
                            borderTopLeftRadius: ".5rem",
                            borderBottomRightRadius: ".5rem",
                            borderBottomLeftRadius: ".5rem"
                          }}
                        >
                          Merci!
                        </div>
                        <div className="d-flex justify-content-end align-items-center fs-sm text-muted">
                          15:12 pm
                          <i className="bx bx-check-double fs-xl text-primary ms-2" />
                        </div>
                      </div>
                      <img
                        src={avatar21}
                        className="rounded-circle"
                        width={40}
                        alt="Albert Flores"
                      />
                    </div>
                  </div>
                </div>
                <div className="swiper-scrollbar end-0" />
              </div>
              {/* Zone de saisie */}
              <div className="card-footer d-block w-100">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Écrivez un message..."
                  />
                  <button
                    className="btn btn-primary"
                    type="button"
                    id="button-addon2"
                  >
                    <i className="bx bx-send fs-lg" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Messages;
