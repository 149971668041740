import React from "react";
import paper from 'assets/img/ipercom/ressources/blog/Articles/optimize/paper.webp';

const Head = () => {
  return (
    <>
      <section className="container mt-4 pt-lg-2 pb-3">
        <h1 className="pb-3">
          Optimiser l'Expérience Utilisateur (UX) : Les Clés d'une Interface
          Utilisateur (UI) Efficace
        </h1>
        <div className="d-flex flex-md-row flex-column align-items-md-center justify-content-md-between mb-3">
          <div className="d-flex align-items-center flex-wrap text-muted mb-md-0 mb-4">
            <div className="fs-xs pe-3 me-3 mb-2">
              <span className="badge bg-faded-primary text-primary fs-base">
                Digital
              </span>
            </div>
          </div>
        </div>
      </section>
      {/* Post image (parallax) */}
      <div
        className="jarallax mb-lg-5 mb-4"
        data-jarallax=""
        data-speed="0.35"
        style={{ height: "36.45vw", minHeight: 300 }}
      >
        <div
          className="jarallax-img"
          style={{ backgroundImage: `url(${paper})` }}
        />
      </div>
    </>
  );
};

export default Head;
