import React from "react";
import Pexels from "assets/img/ipercom/services/TelephonySolution/pexels-andrea-piacquadio-859264.webp";
import Card from 'assets/img/ipercom/services/TelephonySolution/credit-card.svg';
import Shield from 'assets/img/ipercom/services/TelephonySolution/shield.svg';
import Bars from 'assets/img/ipercom/services/TelephonySolution/bars-graphic.svg';
import Communication from 'assets/img/ipercom/services/TelephonySolution/communication.svg';
import Wallet from 'assets/img/ipercom/services/TelephonySolution/wallet.svg';
import Shield2 from 'assets/img/ipercom/services/TelephonySolution/shield2.svg';

const InnovativeFeatures1 = () => {
  return (
    <>
      <section id="features" className="container">
        <div className="row pb-xl-3">
          <div className="col-lg-5 d-none d-lg-block">
            <div className="position-relative" style={{ maxWidth: 500 }}>
              <img src={Pexels} alt="Image" />
              <div
                className="rellax position-absolute top-0"
                data-rellax-vertical-scroll-axis="x"
                data-rellax-horizontal-speed="0.75"
                data-disable-parallax-down="lg"
                style={{ maxWidth: 348, right: "-2.75rem" }}
              ></div>
            </div>
          </div>
          <div className="col-lg-7">
            <h2 className="text-center text-md-start mb-4">
              Des fonctionnalités innovantes
            </h2>
            <p className="fs-lg text-muted text-center text-md-start mb-4 mb-xl-5">
              Grâce à un système IPBX, les appels sont dirigés et traités via un
              logiciel spécifique, généralement installé sur l'ordinateur de
              l'utilisateur. Cela permet à l'entreprise de connecter son
              infrastructure informatique à son réseau téléphonique, offrant
              ainsi une gamme étendue de fonctionnalités pour améliorer les
              communications téléphoniques :
            </p>
            <div className="row row-cols-1 row-cols-sm-2 pt-2 pt-sm-3 pt-xl-2">
              <div className="mb-3">
                <div className="d-flex align-items-start pe-xl-3">
                  <div className="d-table bg-secondary rounded-3 flex-shrink-0 p-3 mb-3">
                    <img src={Card} alt="Icon" />
                  </div>
                  <div className="ps-4 ps-sm-3 ps-md-4">
                    <h3 className="h5 pb-1 mb-2">Enregistrement des appels</h3>
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <div className="d-flex align-items-start ps-xl-3">
                  <div className="d-table bg-secondary rounded-3 flex-shrink-0 p-3 mb-3">
                    <img src={Shield} alt="Icon" />
                  </div>
                  <div className="ps-4 ps-sm-3 ps-md-4">
                    <h3 className="h5 pb-1 mb-2">
                      Service de permanence téléphonique
                    </h3>
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <div className="d-flex align-items-start pe-xl-3">
                  <div className="d-table bg-secondary rounded-3 flex-shrink-0 p-3 mb-3">
                    <img src={Bars} alt="Icon" />
                  </div>
                  <div className="ps-4 ps-sm-3 ps-md-4">
                    <h3 className="h5 pb-1 mb-2">Routage équitable d'appels</h3>
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <div className="d-flex align-items-start ps-xl-3">
                  <div className="d-table bg-secondary rounded-3 flex-shrink-0 p-3 mb-3">
                    <img src={Communication} alt="Icon" />
                  </div>
                  <div className="ps-4 ps-sm-3 ps-md-4">
                    <h3 className="h5 pb-1 mb-2">
                      Transfert avec accompagnement
                    </h3>
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <div className="d-flex align-items-start pe-xl-3">
                  <div className="d-table bg-secondary rounded-3 flex-shrink-0 p-3 mb-3">
                    <img src={Wallet} alt="Icon" />
                  </div>
                  <div className="ps-4 ps-sm-3 ps-md-4">
                    <h3 className="h5 pb-1 mb-2">
                      Possibilité de double écoute
                    </h3>
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <div className="d-flex align-items-start ps-xl-3">
                  <div className="d-table bg-secondary rounded-3 flex-shrink-0 p-3 mb-3">
                    <img src={Shield2} alt="Icon" />
                  </div>
                  <div className="ps-4 ps-sm-3 ps-md-4">
                    <h3 className="h5 pb-1 mb-2">
                      Cryptage sécurisation des données
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default InnovativeFeatures1;
