import client from "assets/img/ipercom/ressources/portfolio/projet realisé/client_14_11zon.webp";

const WhoIsTheClient = () => {
  return (
    <section className="container my-5">
      <div className="row">
        <div className="col-md-6 pb-2 pb-md-0 mb-4 mb-md-0 order-2">
          <div className="pe-lg-5 text-center">
            <img src={client} className="rounded-3" alt="Image" />
          </div>
        </div>
        <div className="col-md-6 order-1">
          <h2 className="mb-sm-4">Qui est le client ?</h2>
          <p className="d-md-none d-xl-block pb-2 pb-md-3 mb-3">
            Notre client est une entreprise soucieuse de leur image de marque
            et de leur communication professionnelle. Que vous soyez une startup en
            plein essor ou une grande entreprise établie, une signature mail
            bien élaborée peut faire toute la différence dans la manière dont
            vous êtes perçu par vos destinataires.
          </p>
        </div>
      </div>
    </section>
  );
};

export default WhoIsTheClient;
