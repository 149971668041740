import React from "react";

const HowWeWork = () => {
  return (
    <section className="container mb-4">
      <h2 className="pb-2 pb-md-0 mb-4 mb-md-5">Comment travaillons-nous ?</h2>
      <div className="steps steps-sm steps-horizontal-md pb-2 pb-sm-3">
        <div className="step">
          <div className="step-number">
            <div className="step-number-inner">1</div>
          </div>
          <div className="step-body">
            <h3 className="h4 mb-3">Inventaire des produits</h3>
            <p className="mb-0">
              Contrôlez vos niveaux de stock pour répondre efficacement à la
              demande du marché.
            </p>
          </div>
        </div>
        <div className="step">
          <div className="step-number">
            <div className="step-number-inner">2</div>
          </div>
          <div className="step-body">
            <h3 className="h4 mb-3">Mouvements de stock</h3>
            <p className="mb-0">
              Suivez attentivement les mouvements de stock pour anticiper les
              besoins de réapprovisionnement.
            </p>
          </div>
        </div>
        <div className="step">
          <div className="step-number">
            <div className="step-number-inner">3</div>
          </div>
          <div className="step-body">
            <h3 className="h4 mb-3">Niveaux de stock</h3>
            <p className="mb-0">
              Adoptez une approche proactive pour gérer vos niveaux de stock et
              maximiser votre rentabilité.
            </p>
          </div>
        </div>
        <div className="step">
          <div className="step-number">
            <div className="step-number-inner">4</div>
          </div>
          <div className="step-body">
            <h3 className="h4 mb-3">Commandes en attente</h3>
            <p className="mb-0">
              Identifiez les causes des commandes en attente et mettez en place
              des solutions pour les résoudre.
            </p>
          </div>
        </div>
      </div>
      <div className="steps steps-sm steps-horizontal-md pb-2 pb-sm-3">
        <div className="step">
          <div className="step-number">
            <div className="step-number-inner">5</div>
          </div>
          <div className="step-body">
            <h3 className="h4 mb-3">Suivi des expéditions</h3>
            <p className="mb-0">
              Analysez les données de suivi des expéditions pour identifier les
              opportunités d'amélioration.
            </p>
          </div>
        </div>
        <div className="step">
          <div className="step-number">
            <div className="step-number-inner">6</div>
          </div>
          <div className="step-body">
            <h3 className="h4 mb-3">Gestion des entrepôts</h3>
            <p className="mb-0">
              Optimisez l'espace de stockage dans vos entrepôts pour maximiser
              l'efficacité opérationnelle.
            </p>
          </div>
        </div>
        <div className="step">
          <div className="step-number">
            <div className="step-number-inner">7</div>
          </div>
          <div className="step-body">
            <h3 className="h4 mb-3">Gestion des retours</h3>
            <p className="mb-0">
              Optimisez le processus de gestion des retours pour améliorer la
              satisfaction client.
            </p>
          </div>
        </div>
        <div className="step">
          <div className="step-number">
            <div className="step-number-inner">8</div>
          </div>
          <div className="step-body">
            <h3 className="h4 mb-3">Sécurité et accès</h3>
            <p className="mb-0">
              Garantissez la sécurité des données sensibles avec des protocoles
              d'accès stricts et des pare-feu robustes.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowWeWork;
