import ModalCandidature from 'pages/coming-soon/ModalCandidature';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

type ModalType = "devis" | "demo" | "revendeur" | "candidature";

const DescriptionBusiness = () => {

  const [showModal, setShowModal] = useState({
    devis: false,
    demo: false,
    revendeur: false,
    candidature: false,
  });

  const handleShow = (modal: ModalType) =>
    setShowModal({ ...showModal, [modal]: true });
  const handleClose = (modal: ModalType) =>
    setShowModal({ ...showModal, [modal]: false });

  return (
    <section className="container pt-5 mt-2 mt-lg-4 mt-xl-5">
      <div className="row">
        {/* Sidebar (Course summary) */}
        <aside className="col-lg-4 col-md-5 offset-xl-1 order-md-2 mb-5">
          <div style={{ marginTop: "-96px" }} />
          <div className="pt-5">
            <div className="pt-5 mt-md-3">
              <div className="card shadow-sm p-sm-3">
                <div className="card-body">
                  <h2 className="mb-4 h3">Critères de candidature</h2>
                  <ul className="list-unstyled">
                    <li>
                      <p>CV</p>
                    </li>
                    <li>
                      <p>Lettre de motivation</p>
                    </li>
                    <li>
                      <p>Entretiens</p>
                    </li>
                  </ul>
                  <Button
                  variant="primary"
                  className="btn btn-primary btn-lg shadow-primary mt-2"
                  onClick={() => handleShow("candidature")}
                >
                  Postuler
                </Button>
                </div>
              </div>
            </div>
          </div>
        </aside>
        <div className="col-xl-7 col-lg-8 col-md-7 order-md-1 mb-5">
          <h2 className="mb-4">Missions :</h2>
          <ul className="mb-4 pb-2 ps-4">
            <li>
              Identifier et recruter de nouveaux partenaires commerciaux afin
              d'étendre le réseau de distribution sur le plan national.
            </li>
            <li>
              Former les partenaires aux caractéristiques et avantages des
              produits ou services, ainsi qu'aux outils de vente.
            </li>
            <li>
              Accompagner les partenaires dans le développement de leurs
              compétences commerciales pour maximiser les ventes.
            </li>
            <li>
              Mettre en place des actions marketing ciblées avec les partenaires
              pour stimuler la demande.
            </li>
            <li>
              Suivre les performances des partenaires, analyser les résultats et
              proposer des plans d'action pour atteindre les objectifs de vente.
            </li>
            <li>
              Assurer une veille concurrentielle sur le marché et proposer des
              ajustements stratégiques si nécessaire.
            </li>
            <li>
              Participer à des salons professionnels et organiser des événements
              avec les partenaires pour promouvoir les produits/services.
            </li>
          </ul>
          <h3 className="mb-4">Profil recherché :</h3>
          <ul className="mb-4 pb-2 ps-4">
            <li>
              Solide expérience dans la vente et le développement de réseaux de
              partenaires, idéalement dans le même secteur d'activité.
            </li>
            <li>Maîtrise des techniques de vente et de négociation.</li>
            <li>Bonne connaissance du marché et de ses acteurs.</li>
            <li>
              Aisance avec les outils CRM et les technologies de l'information.
            </li>
          </ul>
          <h3 className="mb-4">Expérience :</h3>
          <ul className="mb-4 pb-2 ps-4">
            <li>
              Au moins 3 ans d'expérience dans un rôle de Chargé d'affaires ou similaire.
            </li>
          </ul>
          <h3 className="mb-4">Qualités personnelles :</h3>
          <ul className="mb-4 pb-2 ps-4">
            <li>Excellentes capacités relationnelles et de communication.</li>
            <li>
              Capacité à travailler en autonomie et à prendre des initiatives.
            </li>
            <li>
              Fort esprit d'équipe et capacité à travailler en collaboration
              avec différentes fonctions de l'entreprise.
            </li>
            <li>Rigueur, organisation et gestion du temps.</li>
            <li>
              Adaptabilité et flexibilité face aux changements et aux
              déplacements fréquents.
            </li>
          </ul>
          <h2>Pourquoi nous rejoindre ?</h2>
          <ul className="list-unstyled mb-5">
            <li>
              <i className="bx bx-check-circle text-primary fs-xl me-2" />
              Environnement de travail stimulant au sein d'une équipe
              talentueuse et passionnée.
            </li>
            <li>
              <i className="bx bx-check-circle text-primary fs-xl me-2" />
              Projets variés et challengeants dans différents secteurs
              d'activité.
            </li>
            <li>
              <i className="bx bx-check-circle text-primary fs-xl me-2" />
              Opportunités de formation continue et de développement
              professionnel.
            </li>
            <li>
              <i className="bx bx-check-circle text-primary fs-xl me-2" />
              Avantages sociaux compétitifs et possibilité de télétravail.
            </li>
          </ul>
        </div>
      </div>
      <ModalCandidature showModal={showModal.candidature} handleClose={() => handleClose("candidature")} />

    </section>
  );
};

export default DescriptionBusiness;
