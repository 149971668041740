import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";
import NoveltiesList from "components/modules/ressources/blog/Novelties/NoveltiesList";
import React from "react";

const News = () => {
  return (
    <>
      <Breadcrumbs />
      <NoveltiesList />
    </>
  );
};

export default News;
