import React from "react";
import woman from "assets/img/ipercom/services/Solution proposes/logiciel caisse/woman pink_40_11zon.webp";
import appLight from 'assets/img/ipercom/services/TelephonySolution/software/appstore-light.svg';
import appDark from 'assets/img/ipercom/services/TelephonySolution/software/appstore-dark.svg';
import googleLight from 'assets/img/ipercom/services/TelephonySolution/software/googleplay-light.svg';
import googleDark from 'assets/img/ipercom/services/TelephonySolution/software/googleplay-dark.svg';
import ipos from "assets/img/ipercom/i-pos.png";

const Logiciel = () => {
  return (
    <section className="container">
      <div className="row align-items-center justify-content-around">
        {/* Parallax gfx */}
        <div
          className="col-xl-4 col-md-6 d-flex justify-content-center justify-content-md-end overflow-hidden order-md-2 mt-n5 mt-sm-n4 mt-md-0">
          <div className="position-relative mt-5">
            <div className="bg-gradient-primary position-absolute start-0 bottom-0 rounded-3 opacity-15" />
            <div className="parallax zindex-2">
              <div className="parallax-layer zindex-2 mb-n3" data-depth="0.1">
                <img src={woman} alt="woman pink" />
              </div>
            </div>
          </div>
        </div>
        {/* Text */}
        <div className="col-xl-6 col-md-6 d-flex flex-column order-md-1 align-content-center">
          <div className="text-center text-md-start pt-4 pt-sm-5 pt-xl-0 mt-2 mt-sm-0 mt-lg-auto">
            <h1 className=" display-4 mb-1">
              Logiciel et application de caisse
            </h1>
            <p className="fs-md mb-0 d-md-none d-xl-block">
              Notre logiciel et application de caisse i-pos offre une solution
              complète pour gérer les ventes, les stocks et les paiements en
              toute simplicité.
            </p>
            <img
              src={ipos}
              className="light-mode-img my-1 col-7"
              alt="App Store"
            />
            <div className="d-flex flex-column flex-sm-row justify-content-center justify-content-md-start mt-3">
              <a
                href="https://play.google.com/store/apps/dev?id=6388058322571838005"
                className="btn btn-dark btn-lg px-3 py-2 me-sm-3 me-lg-4 mb-3"
              >
                <img
                  src={appLight}
                  className="light-mode-img"
                  width={124}
                  alt="App Store"
                />
                <img
                  src={appDark}
                  className="dark-mode-img"
                  width={124}
                  alt="App Store"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/dev?id=6388058322571838005"
                className="btn btn-dark btn-lg px-3 py-2 mb-3"
              >
                <img
                  src={googleLight}
                  className="light-mode-img"
                  width={139}
                  alt="Google Play"
                />
                <img
                  src={googleDark}
                  className="dark-mode-img"
                  width={139}
                  alt="Google Play"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Logiciel;
