import mobile from "assets/img/ipercom/services/Solution proposes/logiciel caisse/mobile.webp";

const FinalResult = () => {
  return (
    <section className="container my-5">
      <div className="row">
        <div className="col-md-6 pb-2 pb-md-0 mb-2 mb-md-0 order-2">
          <div className="pe-lg-5 text-center">
            <img src={mobile} className="rounded-3" alt="Image" style={{ width: "37%", marginTop: "-38px" }} />
          </div>
        </div>
        <div className="col-md-6 order-1">
          <h2 className="mb-sm-4">Résultat final</h2>
          <p className="d-md-none d-xl-block pb-2 pb-md-3 mb-1">
            Le déploiement du logiciel de caisse i-pos a permis à notre client d'optimiser la gestion des ventes et des
            stocks, améliorant ainsi l'efficacité opérationnelle et l'expérience client grâce à une interface intuitive
            et des fonctionnalités avancées. Les rapports détaillés générés par i-pos ont fourni des insights précieux
            sur les performances des produits et les tendances des clients, facilitant des décisions stratégiques basées
            sur des données précises. La sécurité des données sensibles a été renforcée grâce à des fonctionnalités de
            sécurité avancées. De plus, l'architecture SaaS du logiciel a offert une flexibilité et une évolutivité
            permettant une utilisation depuis n'importe où avec une connexion Internet, et s'adaptant à la croissance
            future de l'entreprise. En résumé, l'intégration du logiciel de caisse i-pos a transformé les opérations
            quotidiennes de la chaîne de magasins, augmentant l'efficacité, la sécurité et la satisfaction client, tout
            en offrant une solution évolutive et toujours à jour.
          </p>
        </div>
      </div>
    </section>
  );
};

export default FinalResult;
