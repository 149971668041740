import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";
import Head from "components/modules/ressources/blog/Articles/backstage start up/Head";
import LastNews from "components/modules/showcase/LastNews";
import Paragraph from "components/modules/ressources/blog/Articles/backstage start up/Paragraph";
import React from "react";

const BackstageStratUp = () => {
  return (
    <>
      <Breadcrumbs />
      <Head />
      <Paragraph />
      <LastNews />
    </>
  );
};

export default BackstageStratUp;
