import React from 'react';
import optimizeImage from "assets/img/ipercom/services/ERP/analyticsOurCrm.webp";
import InfoBouton from "components/modules/showcase/InfoBouton";

const OurCRMOptimize = () => {
  return (
    <section className="container mb-2 mt-4">
      <div className="row align-items-center">
        <div className="col-md-6 col-xl-5 order-md-2 text-center text-md-end mb-5 mb-md-0">
          <img
            src={optimizeImage}
            alt="Analytiques de notre CRM"
            className="img-fluid ms-5"
          />
        </div>
        <div className="col-md-6 col-xl-5 order-md-1">
          <h2 className="pb-2 pb-lg-3">
            Optimisez la gestion de vos relations clients avec notre CRM
            performant
          </h2>
          <p className="pb-2 mb-2 mb-lg-2 ">
            Découvrez pourquoi notre CRM est la solution idéale pour votre
            entreprise. Avec une interface conviviale, des fonctionnalités
            avancées de gestion des clients, des leads et des opportunités,
            notre CRM vous permet de suivre et de gérer efficacement votre
            pipeline de ventes. Grâce à son intégration transparente avec
            d'autres outils et sa capacité à fournir des analyses détaillées,
            notre CRM vous aide à prendre des décisions éclairées et à améliorer
            la productivité de votre équipe. Faites passer votre gestion des
            relations clients au niveau supérieur avec notre CRM fiable et
            puissant.
          </p>
          <InfoBouton />
        </div>
      </div>
    </section>
  );
};

export default OurCRMOptimize;
