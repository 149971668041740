import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";
import WorkFlow from "components/modules/services/solution-billing/WorkFlow";
import Personalization from "components/modules/services/solution-billing/Personalization";
import SolutionBilling from "components/modules/services/solution-billing/SolutionBilling";
import WhatWeOffert from "components/modules/services/solution-billing/WhatWeOffert";
import Partners from "components/modules/showcase/Partners";
import React from "react";

const QuoteCreation = () => {
  return (
    <>
      <div className="pt-4"></div>
      <Breadcrumbs />
      <SolutionBilling />
      <WhatWeOffert />
      <WorkFlow />
      <Personalization />
      <Partners />
    </>
  );
};

export default QuoteCreation;
