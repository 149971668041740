import React from 'react';
import office from 'assets/img/ipercom/a-propos/historyAgence/office.webp';

const HistoryAgency = () => {
  return (
    <>
      <section className="container mt-5 pb-5">
        <div className="row">
          <div className="col-md-6 pb-2 pb-md-0 mb-4 mb-md-0 order-2 align-content-center">
            <div className="pe-lg-5 text-center">
              <img src={office} className="rounded-3" alt="Image" />
            </div>
          </div>
          <div className="col-md-6 order-1">
            <h2 className=" mb-sm-4">Histoire de l’agence</h2>
            <p className="d-md-none d-xl-block pb-2 pb-md-3 mb-3">
              Fort de 15 ans d’expérience en tant qu'opérateur télécom mais
              aussi spécialisé dans les domaines de la bureautique, de
              l'informatique et de la sécurité, j'ai apprécié travailler avec un
              réseau de revendeurs, et c'est tout naturellement que je m'adresse
              à vous aujourd'hui, pour promouvoir notre toute nouvelle solution
              de télécom ; inédite ; made in France. <br />
              <br />
              Permettez-moi de vous présenter ses atouts majeurs et ainsi de
              vous différencier en élargissant votre offre auprès de vos clients
              habituels. Notre agence de développement de logiciels et
              d'applications est à votre disposition pour vous proposer des
              offres personnalisées à la demande.
              <br />
              <br />
              Fondateur ipercom <br />
              Stéphane DEGOUVE
              <br />
              Bienvenue chez ipercom, spécialiste en conception de logiciels et
              applications sur mesure, adaptés à vos besoins.
              <br />
              <br />
              Choisissez la différence, commençons à travailler ensemble :
            </p>
          </div>
        </div>
      </section>
    </>
  );
};
export default HistoryAgency;
