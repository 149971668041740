import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";
import FinalResult from "components/modules/ressources/portfolio/completed-projects/SiteWeb/FinalResult";
import HowDidWeProceed from "components/modules/ressources/portfolio/completed-projects/SiteWeb/HowDidWeProceed";
import WhatTheProject from "components/modules/ressources/portfolio/completed-projects/SiteWeb/WhatTheProject";
import WhoIsTheClient from "components/modules/ressources/portfolio/completed-projects/SiteWeb/WhoIsTheClient";
import React from "react";

const SiteWeb = () => {
  return (
    <>
      <Breadcrumbs />
      <WhatTheProject />
      <WhoIsTheClient />
      <HowDidWeProceed />
      <FinalResult />
    </>
  );
};

export default SiteWeb;
