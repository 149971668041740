import React from "react";
import mobile from "assets/img/ipercom/services/Solution proposes/developpement mobile/mobile-app.svg";
import screen from "assets/img/ipercom/icons/screenOurCrm.svg";
import graph from "assets/img/ipercom/services/ERP/company/icons/graph.svg";
import box from "assets/img/ipercom/services/logiciel-de-facturation/bureautique/box_11_11zon.webp";
import lauch from "assets/img/ipercom/services/ERP/company/icons/launch.svg";
import computer from "assets/img/ipercom/icons/computerOurCrm.webp";

const Opportunity = () => {
  return (
    <>
      {/* Services */}
      <section className="bg-secondary pb-md-2 pb-lg-5 mb-3">
        <div
          className="d-none d-lg-block"
          style={{ marginTop: "-60px", paddingTop: 60 }}
        />
        <div className="container pb-4 pt-5">
          <h2 className="text-center text-md-start mb-lg-4 pt-1 pt-md-4">
            Élargissez vos opportunités dans le secteur de la bureautique en
            adoptant l'ERP ipercom
          </h2>
          <div className="col-md-8 text-center text-md-start">
            <p className="fs-md text-muted mb-md-3">
              Libérez votre plein potentiel dans la bureautique en intégrant
              l'<a href="/services/erp/qu-est-ce-qu-un-erp">ERP</a> ipercom, une solution innovante pour dynamiser vos
              performances commerciales.
            </p>
          </div>
          <div className="row row-cols-1 row-cols-md-2">
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <div
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 me-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={computer}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="d-inline-flex align-items-center">
                    Élaborer la stratégie de tarification contractuelle
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    Avec ipercom, personnalisez vos règles de facturation selon
                    des contrats prédéfinis en toute sécurité. Choisissez parmi
                    différents modes de <a href="/services/erp/solution-facturation">facturation</a> tels que la
                    consommation
                    réelle, l'engagement avec régularisation ou les prix
                    dégressifs, incluant même des forfaits globaux avec
                    maintenance et financement intégrés.
                  </p>
                </div>
              </div>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <div
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={lauch}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="d-inline-flex align-items-center">
                    Optimiser la rentabilité de mes contrats face aux
                    fluctuations du marché
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    ipercom facture la surconsommation d’encres selon les termes
                    contractuels, préservant vos marges malgré les fluctuations.
                    Intégré à des outils de gestion d'inventaire comme KPAX de
                    Bluemega.
                  </p>
                </div>
              </div>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <div
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={graph}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="d-inline-flex align-items-center">
                    Évaluer la profitabilité de mes clients
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    ipercom offre un module intégré dédié au suivi de
                    rentabilité des clients, marques, machines, ou contrats. Ce
                    module permet d'analyser divers indicateurs tels que les
                    revenus des contrats et des clients, les marges sur les
                    contrats et les équipements, ainsi que les taux de
                    dépassement de projet.
                  </p>
                </div>
              </div>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <div
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={screen}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="d-inline-flex align-items-center">
                    Accroître la commercialisation de solutions et de services
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    ipercom offre une solution ERP complète incluant la gestion
                    des services et des projets. Avec notre plateforme, vous
                    pouvez améliorer l'efficacité de la génération et du suivi
                    des demandes d'intervention à partir des commandes, suivre
                    précisément les livraisons et gérer efficacement la
                    facturation de vos prestations.
                  </p>
                </div>
              </div>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <div
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={mobile}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="d-inline-flex align-items-center">
                    Améliorer la communication avec mes clients et faciliter les
                    interactions
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    Découvrez l'extranet client complet d'ipercom, intégré à
                    votre ERP pour gérer efficacement les demandes, le suivi des
                    interventions, les commandes de consommables, la saisie des
                    compteurs et les factures dématérialisées. Vos clients
                    bénéficient d'un accès rapide et centralisé à leurs
                    documents.
                  </p>
                </div>
              </div>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <div
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={box}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="d-inline-flex align-items-center">
                    Perfectionner la gestion des toners afin de réduire au
                    minimum les inventaires
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    ipercom utilise la fonctionnalité M2M en collaboration avec
                    Kpax Manage pour évaluer automatiquement les besoins en
                    toners en se basant sur les niveaux d'encre des machines.
                    Cette approche optimise le réapprovisionnement des
                    consommables de manière efficace.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Opportunity;
