import React from "react";

const Map = () => {
  return (
    <div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5654.7188521385215!2d-0.4146552234184406!3d44.875333972535316!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd5530f62c4ca3d5%3A0x589b3fdecca9a3fb!2s1%20Rue%20Jean%20Walton%2C%2033450%20Montussan!5e0!3m2!1sfr!2sfr!4v1719827058540!5m2!1sfr!2sfr"
        width="600"
        height="550"
        loading="lazy"></iframe>
    </div>
  );
};

export default Map;
