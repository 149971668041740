import Paragraph from "components/modules/ressources/blog/LastNews/Blockchain/Paragraph";
import Head from "components/modules/ressources/blog/LastNews/Blockchain/Head";
import React from "react";

const Blockchain = () => {
  return (
    <>
      <Head />
      <Paragraph />
    </>
  );
};

export default Blockchain;