import users from 'assets/img/ipercom/services/ERP/users_35_11zon.webp';
import tickets from 'assets/img/ipercom/services/ERP/tickets_32_11zon.webp';
import retailer_details from 'assets/img/ipercom/services/ERP/retailer_details_16_11zon.webp';

const OurSoftware = () => {
  return (
    <section className=" container mb-2 pb-lg-2 pb-md-2 pb-2">
      <div className="text-start">
        <h2>Notre logiciel CRM</h2>
      </div>
      <div className="d-flex" style={{ maxWidth: "80%" }}>
        <div className="d-flex flex-column col-6">
          <img src={tickets} alt="Image 1" className="img-fluid" />
          <img src={users} alt="Image 2" className="img-fluid" />
        </div>
        <img src={retailer_details} alt="Image 3" className="img-fluid" />
      </div>
    </section>
  );
};

export default OurSoftware;
