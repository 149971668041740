import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";
import FinalResult from "components/modules/ressources/portfolio/completed-projects/ERP/FinalResult";
import HowDidWeProceed from "components/modules/ressources/portfolio/completed-projects/ERP/HowDidWeProceed";
import WhatTheProject from "components/modules/ressources/portfolio/completed-projects/ERP/WhatTheProject";
import WhoIsTheClient from "components/modules/ressources/portfolio/completed-projects/ERP/WhoIsTheClient";
import React from "react";

const ERP = () => {
  return (
    <>
      <Breadcrumbs />
      <WhatTheProject />
      <WhoIsTheClient />
      <HowDidWeProceed />
      <FinalResult />
    </>
  );
};

export default ERP;