import React from "react";
import whiteboard from "assets/img/ipercom/ressources/portfolio/projet realisé/white board_37_11zon.webp";

const HowDidWeProceed = () => {
  return (
    <section className="container my-5">
      <div className="row">
        <div className="col-md-6 pb-2 pb-md-0 mb-4 mb-md-0 order-1">
          <div className="pe-lg-5 text-center">
            <img src={whiteboard} className="rounded-3" alt="Image" />
          </div>
        </div>
        <div className="col-md-6 order-2">
          <h2 className="mb-sm-4">Comment avons-nous procédé ?</h2>
          <p className="d-md-none d-xl-block pb-2 pb-md-3 mb-3">
            Pour concrétiser la vision de notre client, nous avons adopté une
            approche stratégique et collaborative. À chaque étape du processus,
            nous avons travaillé en étroite collaboration avec l'équipe du
            client pour comprendre leurs besoins spécifiques, leurs valeurs et
            leurs objectifs. Notre équipe multidisciplinaire de concepteurs,
            développeurs et spécialistes du marketing digital a ensuite mis en
            œuvre des stratégies innovantes pour créer une expérience
            utilisateur exceptionnelle.{" "}
          </p>
        </div>
      </div>
    </section>
  );
};

export default HowDidWeProceed;
