import React from 'react';

const HeadWhatIsCRM = () => {
  return (
    <section className="container mb-2">
      <div className="text-start mb-2 mb-md-3 mx-auto">
        <h1>Qu'est-ce qu'un CRM ?</h1>
      </div>
      <hr /> {/* Barre horizontale */}
      <div className="row mt-4">
        <div className="col-md-6">
          {/* Bloc de texte à gauche */}
          <p>
            Un CRM, ou Customer Relationship Management, est un outil essentiel
            pour toute entreprise souhaitant gérer efficacement ses relations
            avec ses clients. Mais qu'est-ce qu'un CRM exactement ?
            Contrairement à ce que son nom pourrait laisser penser, un CRM ne se
            limite pas à la gestion des relations avec les clients. Il s'agit en
            réalité d'un système complet qui englobe la collecte, l'organisation
            et l'analyse de toutes les informations clients. Cela inclut les
            données démographiques, les historiques{" "}
          </p>
        </div>
        <div className="col-md-6">
          {/* Bloc de texte à droite */}
          <p>
            d'achat, les interactions avec le service client et bien plus
            encore. Grâce à un CRM, les entreprises peuvent mieux comprendre les
            besoins et les préférences de leurs clients, personnaliser leurs
            interactions et offrir un service plus réactif et pertinent. En fin de compte, un CRM est un outil
            stratégique, qui favorise la croissance et la fidélisation client, devenu indispensable dans le paysage
            commercial moderne.
          </p>
        </div>
      </div>
    </section>
  );
};

export default HeadWhatIsCRM;
