import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";

const PolitiqueConfidentialite = () => {
  return (
    <>
    <Breadcrumbs />
    <div className="container my-5">
      <h1 className="text-center text-primary mb-4">Politique de Confidentialité</h1>
      <div>
        <h2>POLITIQUE DE CONFIDENTIALITE</h2>
        <p>
          La présente politique a pour objectif de vous expliquer comment nous, la société IPERCOM, (ci-après
          « IPERCOM »), collectons, utilisons, communiquons et stockons les informations que vous nous
          fournissez dans le cadre de vos commandes et via le site internet www.--- (ci-après « le Site »)
          proposant nos services, ainsi que lors de l’utilisation de nos services.
        </p>
        <p>
          Nous prenons les dispositions nécessaires afin d'assurer un niveau de sécurité de vos données
          personnelles, en lien et en conformité avec la loi n°78-17 du 6 janvier 1978, dite « Informatique et
          Libertés » et la réglementation européenne, à savoir le RGPD (Règlement Général sur la Protection des
          Données n°2016/79 du 27 avril 2016), applicable depuis le 25 mai 2018.
        </p>
        <p>
          Cette Politique de confidentialité décrit nos pratiques en matière de données personnelles et vous
          procure des réponses aux interrogations suivantes :
        </p>
        <ol>
          <li>Quelles sont les données personnelles que nous collectons ?</li>
          <li>A quel moment collectons-nous vos données personnelles ?</li>
          <li>Comment utiliserons-nous vos données personnelles ?</li>
          <li>Sur quelle base légale ?</li>
          <li>Qui seront les destinataires de vos données personnelles ?</li>
          <li>Où sont stockées vos données personnelles et sont-elles en sécurité ?</li>
          <li>Vos données personnelles seront-elles transférées hors de l’Union Européenne ?</li>
          <li>Quels sont vos droits concernant vos données personnelles ?</li>
          <li>Quelle est la durée de conservation de vos données personnelles ?</li>
          <li>Quelles sont les modalités de modification de la présente Politique ?</li>
        </ol>
        
        <h3>DONNEES TRAITEES, FONDEMENTS ET FINALITES DU TRAITEMENT</h3>
        <p>
          Les données personnelles vous concernant collectées et traitées (ci-après, vos « Données ») sont principalement,
        </p>
        <ul>
          <li>
            <strong>Données collectées lors de la navigation sur le Site internet ou lors de la communication par courriel </strong> <br/>
            Lors de votre navigation sur notre Site internet, nous collectons votre adresse électronique, vos nom et prénom lorsque vous remplissez notre formulaire de contact. La base légale de ce traitement est le consentement.
          </li>
          <li>
            <strong>Données collectées lors de la prise de commande </strong><br/>
            Lors de la prise de commande, nous collectons les données suivantes : vos nom, prénom, adresse email, adresse postale, lieu de résidence, numéro de téléphone, numéro SIRENE, numéro de TVA intracommunautaire, poste occupé au sein de la société, pièce d’identité, et données bancaires (RIB), relevé d’identité opérateur (RIO), justificatif de domicile, mandat de portabilité. Ces Données sont collectées sur la base de votre consentement explicite, ont pour finalité de permettre la prise de commandes tant d’un point de vue juridique que pratique.
          </li>
          <li>
            <strong>Données collectées lors de l’exécution du Service </strong><br/>
            IPERCOM collecte diverses Données Personnelles dans le cadre de l’exécution du contrat :
          </li>
          <ul>
            <li>Identification de l’Utilisateur (nom, prénom, adresse email, adresse postale, lieu de résidence, numéro de téléphone, intracommunautaire, poste occupé au sein de la société). Cela résulte d’une obligation légale et a pour finalité de lutter contre la fraude et de gérer/exécuter le contrat.</li>
            <li>Trafic (numéro appelés ou appelants, durée et date des communications). Cela résulte d’une obligation légale et a pour finalité de lutter contre la fraude et de gérer/exécuter le contrat, ainsi que de fournir des services à valeur ajoutée, assurer la sécurité des réseaux….</li>
            <li>Données techniques permettant d'identifier la source de la connexion ou celles relatives aux équipements terminaux utilisés (adresses IP). Cela résulte d’une obligation légale et a pour finalité de lutter contre la fraude et de gérer/exécuter le contrat.</li>
            <li>Le contenu des appels, SMS, MMS, messages répondeurs. La base légale de ce traitement est le consentement, car, sauf réquisition légales, ces données ne sont sauvegardées qu’à la discrétion du Client, selon les choix paramétrés dans son espace client.</li>
            <li>Contenus importés par l’Utilisateur (contacts téléphoniques, tags, messages d’accueil, musique d’attente…). La base légale de ce traitement est le consentement, car ces données ne sont sauvegardées qu’à la discrétion du Client, selon les choix paramétrés dans son espace client.</li>
            <li>Parrainage, identifiants de connexion, …</li>
          </ul>
        </ul>
        
        <h3>SECURISATION DES DONNEES</h3>
        <p>
          IPERCOM sécurise vos données personnelles par le biais de mesures techniques et organisationnelles par chiffrement TLS. En cas de violation des données personnelles susceptible d’engendrer un risque élevé pour leurs droits et libertés, IPERCOM s’engage à informer les personnes concernées et à mettre en œuvre des mesures pour remédier à cette violation et à notifier la CNIL, conformément aux dispositions des articles 33 et 34 du RGPD.
        </p>

        <h3>DESTINATAIRES DES DONNEES</h3>
        <p>
          Certaines de vos données peuvent être partagées avec nos prestataires dans la limite de leurs attributions respectives.
        </p>
        <ul>
          <li>Prestataires techniques sous-traitants ultérieurs intervenant notamment dans le cadre de l’hébergement du site Internet;</li>
          <li>Prestataires techniques intervenant pour le paiement des souscriptions aux services;</li>
        </ul>
        <p>
          Les autres Utilisateurs pourront avoir accès à des suggestions automatisées de noms d’autres Utilisateurs pour augmenter la fluidité du Service.
        </p>
        <p>
          IPERCOM pourra être amenée à communiquer à des tiers vos Données lorsqu’une telle communication est requise par la loi, une disposition réglementaire ou une décision judiciaire, ou si cette communication est nécessaire pour assurer la protection et la défense de ses droits.
        </p>
        <p>
          Vos Données ne seront cédées ou rendues accessibles à aucun autre tiers sous réserve de toute restructuration d’IPERCOM, y compris cession totale ou partielle d’actifs, fusion, absorption acquisition, scission et plus généralement toute opération de réorganisation.
        </p>
        <p>
          Les autres Utilisateurs pourront avoir accès à des suggestions automatisées de noms d’autres Utilisateurs pour augmenter la fluidité du Service.
        </p>

        <h3>TRANSFERTS DE DONNEES A L’ETRANGER</h3>
        <p>
          Les destinataires de vos Données peuvent se situer à l’étranger, y compris en dehors de l’Union Européenne. Tout transfert de vos Données en dehors de l’Union Européenne est effectué moyennant des garanties appropriées, notamment par le biais de clauses contractuelles types adoptées par la Commission Européenne, en conformité avec la réglementation applicable en matière de protection des données personnelles.
        </p>

        <h3>HEBERGEMENT DE VOS DONNEES PERSONNELLES</h3>
        <p>
          Vos données personnelles sont hébergées chez AWS, localisé dans l’Union Européenne.
        </p>

        <h3>VOS DROITS</h3>
        <p>
          Conformément à la réglementation en vigueur, vous disposez d’un droit d'accès et de rectification de vos Données, ainsi que de celui d’en demander l’effacement, de vous opposer à leur traitement et d’en obtenir la limitation ou la portabilité dans la mesure où cela est applicable, sous réserve des motifs légitimes impérieux dont pourrait justifier IPERCOM pour conserver vos Données.
        </p>
        <p>
          Par ailleurs, vous pouvez à tout moment :
        </p>
        <ul>
          <li>Demander à ne plus recevoir nos communications relatives à nos offres, actualités et évènements en utilisant le lien hypertexte prévu à cet effet dans chaque courriel que nous vous adressons;</li>
          <li>Vous opposer à ce que vos Données soient utilisées aux fins de dresser votre profil client; dans ce cas vous ne pourrez plus bénéficier d’offres ou de services personnalisés.</li>
          <li>Retirer votre consentement lorsque les données personnelles sont traitées sur la base de leur consentement.</li>
        </ul>
        <p>
          Vous disposez également du droit de nous donner des instructions spécifiques concernant le sort de vos Données après votre décès.
        </p>
        <p>
          Ces droits peuvent être exercés à tout moment en adressant un courrier ou un email aux adresses suivantes :
        </p>
        <ul>
          <li>Un courrier à l'adresse suivante IPERCOM, 8 Allée Cantillac – ZA de Gachet – 33370 POMPIGNAC;</li>
          <li>Un e-mail à _______________.</li>
        </ul>
        <p>
          Un justificatif d’identité sera nécessaire.
        </p>
        <p>
          L’exercice de vos droits d’opposition, de limitation ou d’effacement ne saurait remettre en cause la licéité des traitements opérés avant celui-ci.
        </p>

        <h3>DUREE DE CONSERVATION DE VOS DONNEES</h3>
        <p>
          La durée de conservation de vos données à caractère personnel diffère selon la finalité de leurs collectes. Elles seront conservées pendant toute la durée nécessaire à la réalisation des finalités pour lesquelles elles ont été collectées, et la durée légale requise.
        </p>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Nature des traitements</th>
              <th>Durée de conservation</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Données de facturation</td>
              <td>Conservées pendant 5 ans à compter de la fin du contrat</td>
            </tr>
            <tr>
              <td>Gestion de notre fichier clients</td>
              <td>Conservation pendant un délai de 1 an suivant la fin de la souscription d’abonnement</td>
            </tr>
            <tr>
              <td>Gestion fichier prospect</td>
              <td>Conservation pendant un délai de 3 ans à compter de votre dernier contact avec nous (ex. : un clic sur un lien hypertexte contenu dans un courrier électronique) ou jusqu’au retrait de votre consentement.</td>
            </tr>
            <tr>
              <td>Vos questions adressées à la société</td>
              <td>Conservation pour la durée nécessaire au traitement de votre demande. S’agissant des enregistrements des conversations téléphoniques avec notre service client, ils sont conservés pendant un délai limité (6 mois au maximum) sauf en cas d’exception (par exemple à des fins d’investigation, de fraude ou pour un motif légal).</td>
            </tr>
            <tr>
              <td>Contenus appels, SMS, MMS</td>
              <td>A la discrétion du Client, défini dans son espace client, 1 an maximum</td>
            </tr>
          </tbody>
        </table>

        <h3>MODIFICATION DE NOTRE POLITIQUE DE CONFIDENTIALITE</h3>
        <p>
          IPERCOM peut être amenée à modifier la présente politique. Nous vous invitons à vérifier cette page régulièrement afin de vous assurer de votre accord avec toute modification.
        </p>
        <p>
          Nous veillerons à ce que vous soyez informés de ces modifications soit par une mention spéciale sur notre Site, soit par un avertissement sur votre espace personnel. Nous sommes également susceptibles de vous informer des modifications de cette politique par e-mail.
        </p>

        <h3>RESPONSABLE DE TRAITEMENT</h3>
        <p>
          Le Site est édité par la société IPERCOM, société par actions simplifiée unipersonnelle, immatriculée au RCS de Bordeaux sous le numéro 509 795 829, dont le siège social est situé 8 Allée Cantillac – ZA de Gachet – 33370 POMPIGNAC, ayant la qualité de responsable de traitement au sens de la réglementation applicable en matière de données personnelles y compris la loi n°78-17 « Informatique et Libertés » du 6 janvier 1978 modifiée et le Règlement nᵒ 2016/679, dit règlement général sur la protection des données.
        </p>

        <h3>COORDONNEES DU DELEGUE A LA PROTECTION DES DONNEES (DPO) ET DROIT D’INTRODUIRE UNE RECLAMATION</h3>
        <p>
          Pour toute question en lien avec la collecte et le traitement de vos Données par IPERCOM sur le Site, vous pouvez contacter par courriel à l’adresse suivante : _____________
        </p>
        <p>
          Vous disposez également du droit de saisir la Commission Nationale de l’Informatique et des Libertés (CNIL), 3 place de Fontenoy – TSA 80715 – 75334 PARIS CEDEX 07, de toute réclamation se rapportant à la manière dont IPERCOM collecte et traite vos Données.
        </p>
      </div>
    </div>
    </>
  );
};

export default PolitiqueConfidentialite;
