import React from "react";
import explosion from "assets/img/ipercom/services/logiciel-de-facturation/edi/explosion_35_11zon.webp";

const ImportancySoftware = () => {
  return (
    <>
      <section className="container mt-5">
        <div className="row justify-content-around">
          <div className="col-md-5 pb-2 pb-md-0 mb-4 mb-md-0 order-2">
            <div className="pe-lg-5 text-center">
              <img src={explosion} className="rounded-3" alt="Image" />
            </div>
          </div>
          <div className="col-md-5 align-content-center order-1">
            <h2 className="mb-sm-4">L'importance de la solution EDI</h2>
            <p className="d-md-none d-xl-block pb-2 pb-md-3 mb-3">
              L'EDI, ou échange de données informatisé, est largement adopté
              dans des industries clés comme la santé, le commerce de détail, la
              logistique et l'automobile. C'est la norme dominante pour les
              communications B2B, facilitant l'intégration des systèmes
              d'informations entre partenaires commerciaux.
              <br />
              <br />
              Cette technologie favorise l'intégration interentreprises grâce à
              l'automatisation des échanges de données électroniques, propulsant
              ainsi le développement de solutions personnalisées. Ces solutions
              sont conçues pour s'adapter à la taille, aux capacités
              technologiques et aux secteurs d'activité spécifiques des
              entreprises, rendant l'EDI accessible à tous les partenaires
              commerciaux.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default ImportancySoftware;
