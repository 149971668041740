import ClientTalk from "components/modules/showcase/ClientTalk";
import Head from "components/modules/a-propos/engagements-et-valeurs/Head";
import OurCompanyValues from "components/modules/a-propos/engagements-et-valeurs/OurCompanyValues";
import Partners from "components/modules/showcase/Partners";
import React from "react";
import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";

const CommitmentAndValues = () => {
  return (
    <>
      <Head />
      <Breadcrumbs />
      <OurCompanyValues />
      <ClientTalk />
      <Partners />
    </>
  );
};

export default CommitmentAndValues;
