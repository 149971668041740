import React from "react";
import { Autoplay } from "swiper/modules";
import SwiperCore from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";

import google from "assets/img/ipercom/landing/integrations/Google.svg";
import zoom from "assets/img/ipercom/landing/integrations/Zoom.svg";
import slack from "assets/img/ipercom/landing/integrations/Slack.svg";
import gmail from "assets/img/ipercom/landing/integrations/gmail.svg";
import trello from "assets/img/ipercom/landing/integrations/Trello.svg";
import mailchimp from "assets/img/ipercom/landing/integrations/MailChimp.svg";
import dropbox from "assets/img/ipercom/landing/integrations/Dropbox.svg";
import evernote from "assets/img/ipercom/landing/integrations/Evernote.svg";

const integrationImages = [google, zoom, slack, gmail, trello, mailchimp, dropbox, evernote];


SwiperCore.use([Autoplay]);

const Integrations = () => {
  return (
    <section className="container my-4">
      <div className="row justify-content-center pt-1 mb-md-3 mb-lg-4">
        <div className="col-lg-8 col-md-9 text-center">
          <h2 className="h2 mb-4">Intégration d'outils de travail</h2>
          <p className="fs-lg text-muted mb-0">
            L'intégration des meilleurs outils de travail booste l'efficacité.
            Découvrez les solutions adaptées à vos besoins professionnels.
            Simplifiez vos processus et augmentez votre productivité.
          </p>
        </div>
      </div>
      <Swiper
        spaceBetween={20}
        slidesPerView={"auto"}
        centeredSlides={true}
        loop={true}
        autoplay={{ delay: 2000, disableOnInteraction: false }}
      >
        {integrationImages.map((imgSrc, index) => (
          <SwiperSlide key={index} className="w-auto py-2">
            <div className="card card-hover border-0 shadow-sm d-flex justify-content-center align-items-center mx-2"
                 style={{ width: "200px", height: "100px" }}>
              <div className="card-body">
                <img src={imgSrc} className="d-block mx-auto img-fluid"
                     style={{ maxHeight: "100%", objectFit: "contain" }} alt={`Integration ${index}`} />
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default Integrations;
