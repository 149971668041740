import React, { useState } from "react";
import final from "assets/img/ipercom/ressources/portfolio/projet realisé/iper-cloudApp.webp";
import ModalDevis from "pages/coming-soon/ModalDevis";
import { Button } from "react-bootstrap";

type ModalType = "devis" | "demo" | "revendeur";

const Presentation = () => {

  
  const [showModal, setShowModal] = useState({
    devis: false,
    demo: false,
    revendeur: false
  });

  const handleShow = (modal: ModalType) =>
    setShowModal({ ...showModal, [modal]: true });
  const handleClose = (modal: ModalType) =>
    setShowModal({ ...showModal, [modal]: false });

  return (
    <section className="container mt-5 pb-lg-5 pb-md-4 pb-3">
      <div className="row align-items-center">
        <div className="col-md-8 position-relative" style={{ left: "-30px" }}> {/* Adjusted position */}
          <img src={final} className="rounded-3 img-fluid mx-auto d-block" alt="Image"
               style={{ width: "130%" }} /> {/* Adjusted width */}
        </div>
        <div className="col-md-4 text-center d-flex flex-column justify-content-center">
          <div>
            <h2 style={{ whiteSpace: "nowrap" }}>Maîtrisez Vos Données</h2> {/* Added nowrap */}
            <p>Simplifiez la Collaboration à Distance</p>
            <ul style={{ listStyleType: "none", padding: 0, textAlign: "left" }}>
              <li>✔Hébergement flexibles, sur site ou dans le cloud</li>
              <li>✔Personnalisable et évolutif selon vos besoins</li>
              <li>✔Protection intégrale des données, sans aucune perte</li>
            </ul>
            <div className="d-flex justify-content-center mt-3">
            <Button
                  variant="primary"
                  className="btn btn-primary btn-lg me-3"
                  onClick={() => handleShow("devis")}
                >
                  Demander un devis
                </Button>
              <Button
                href="https://outlook.office.com/bookwithme/user/9e8387330e3644559680de311487211e%40ipercom.com/meetingtype/04c0ddc4-d8b0-4c28-a2fb-1853e564c7ef?anonymous"
                className="btn btn-third btn-lg me-3"
                style={{ minWidth: "200px" }}
              >
                Demander une démo
              </Button>
            </div>
          </div>
        </div>
      </div>
      <ModalDevis showModal={showModal.devis} handleClose={() => handleClose("devis")} />

    </section>
  );
};

export default Presentation;
