import ModalDevis from "pages/coming-soon/ModalDevis";
import React, { useState } from "react";
import { Button } from "react-bootstrap";


type ModalType = "devis" | "demo" | "revendeur";


const Description = () => {


  const [showModal, setShowModal] = useState({
    devis: false,
    demo: false,
    revendeur: false
  });

  const handleShow = (modal: ModalType) =>
    setShowModal({ ...showModal, [modal]: true });
  const handleClose = (modal: ModalType) =>
    setShowModal({ ...showModal, [modal]: false });

  return (
    <section className="container my-4">
      <div className="row">
        {/* Sidebar (Course summary) */}
        <aside className="col-lg-4 col-md-5 offset-xl-1 order-md-2 mb-5">
          <div style={{ marginTop: "-96px" }} />
          <div className="pt-5">
            <div className="pt-5 mt-md-3">
              <div className="card shadow-sm p-sm-3">
                <div className="card-body">
                  <h2 className="mb-4">Ce cours comprend :</h2>
                  <ul className="list-unstyled pb-3">
                    <li className="d-flex align-items-center mb-2">
                      <i className="bx bx-file fs-xl text-muted me-2 pe-1" />
                      18 documents
                    </li>
                    <li className="d-flex align-items-center mb-2">
                      <i className="bx bx-download fs-xl text-muted me-2 pe-1" />
                      25 ressources téléchargeables
                    </li>
                    <li className="d-flex align-items-center mb-2">
                      <i className="bx bx-trophy fs-xl text-muted me-2 pe-1" />
                      Certificat d'achèvement
                    </li>
                  </ul>
                  <div className="h3 mb-0">1250€/Jour hors frais*</div>
                  <Button
                  variant="primary"
                  className="btn btn-primary btn-lg shadow-primary mt-2"
                  onClick={() => handleShow("devis")}
                >
                  Demander un devis
                </Button>
                </div>
              </div>
              <p className="text-muted">
                *Hors frais de déplacement pour les formations en présentielle{" "}
              </p>
            </div>
          </div>
        </aside>
        {/* Content */}
        <div className="col-xl-7 col-lg-8 col-md-7 order-md-1 mt-4">
          <h2>Description de la formation</h2>
          <p className="pb-2 mb-3">
            La formation technique pour apprendre à utiliser notre solution  <a href="/services/solution-telephonie/logiciel-de-telephonie-iper-x">iper-X </a>
               est conçue pour fournir aux participants les connaissances et
            les compétences nécessaires pour maîtriser l'utilisation de notre
            plateforme iper-X de manière efficace et optimale. Cette formation
            approfondie combine à la fois des aspects théoriques et pratiques,
            offrant une expérience d'apprentissage immersive qui permettra aux
            participants de devenir des utilisateurs experts de notre solution
            iper-X.
          </p>
          <h3 className="mb-4">Ce que vous apprendrez</h3>
          <ul className="list-unstyled mb-5">
            <li className="d-flex align-items-center mb-2">
              <i className="bx bx-check-circle text-primary fs-xl me-2" />
              Comprendre les fonctionnalités clés de la solution iper-X et leurs
              utilités dans différents contextes d'application.
            </li>
            <li className="d-flex align-items-center mb-2">
              <i className="bx bx-check-circle text-primary fs-xl me-2" />
              Apprendre à naviguer à travers l'interface utilisateur de manière
              fluide et à utiliser les outils de manière efficace.
            </li>
            <li className="d-flex align-items-center mb-2">
              <i className="bx bx-check-circle text-primary fs-xl me-2" />
              Maîtriser les techniques avancées pour maximiser l'efficacité et
              la productivité lors de l'utilisation de la solution iper-X.
            </li>
            <li className="d-flex align-items-center mb-2">
              <i className="bx bx-check-circle text-primary fs-xl me-2" />
              Acquérir les compétences nécessaires pour résoudre les problèmes
              techniques courants et optimiser les performances de la
              plateforme.
            </li>
            <li className="d-flex align-items-center mb-2">
              <i className="bx bx-check-circle text-primary fs-xl me-2" />
              Explorer les meilleures pratiques et les astuces pour tirer le
              meilleur parti de la solution iper-X dans votre environnement de
              travail spécifique.
            </li>
          </ul>
          <h2 className="mb-4">Détails du programme</h2>
          <div className="steps steps-sm">
            <div className="step d-flex align-items-start">
              <div className="step-number">
                <div className="step-number-inner">1</div>
              </div>
              <div className="step-body">
                <h3 className="h4 mb-2">Session 1 : Introduction à iper-X</h3>
                <ul className="mb-0">
                  <li>Présentation de la solution iper-X et de ses principales fonctionnalités.</li>
                  <li>Vue d'ensemble de l'architecture et des composants de la plateforme.</li>
                  <li>Installation et configuration initiale d'iper-X.</li>
                </ul>
              </div>
            </div>
            <div className="step d-flex align-items-start">
              <div className="step-number">
                <div className="step-number-inner">2</div>
              </div>
              <div className="step-body">
                <h3 className="h4 mb-2">Session 2 : Navigation et Utilisation de l'Interface</h3>
                <ul className="mb-0">
                  <li>Exploration de l'interface utilisateur d'iper-X.</li>
                  <li>Navigation à travers les différents modules et sections.</li>
                  <li>Prise en main des outils et des fonctionnalités essentielles.</li>
                </ul>
              </div>
            </div>
            <div className="step d-flex align-items-start">
              <div className="step-number">
                <div className="step-number-inner">3</div>
              </div>
              <div className="step-body">
                <h3 className="h4 mb-2">Session 3 : Fonctionnalités Avancées</h3>
                <ul className="mb-0">
                  <li>Approfondissement des fonctionnalités avancées de la solution iper-X.</li>
                  <li>Utilisation des outils de surveillance et de gestion des performances.</li>
                  <li>Configuration avancée et personnalisation de la plateforme selon les besoins spécifiques.</li>
                </ul>
              </div>
            </div>
            <div className="step d-flex align-items-start">
              <div className="step-number">
                <div className="step-number-inner">4</div>
              </div>
              <div className="step-body">
                <h3 className="h4 mb-2">Session 4 : Résolution de Problèmes et Dépannage</h3>
                <ul className="mb-0">
                  <li>Identification et résolution des problèmes techniques courants.</li>
                  <li>Techniques de dépannage pour optimiser les performances d'iper-X.</li>
                  <li>Gestion des mises à jour et des correctifs.</li>
                </ul>
              </div>
            </div>
            <div className="step d-flex align-items-start">
              <div className="step-number">
                <div className="step-number-inner">5</div>
              </div>
              <div className="step-body">
                <h3 className="h4 mb-2">Session 5 : Bonnes Pratiques et Astuces</h3>
                <ul className="mb-0">
                  <li>Partage des meilleures pratiques pour une utilisation efficace d'iper-X.</li>
                  <li>Astuces pour maximiser la productivité et l'efficacité.</li>
                  <li>Études de cas et exemples concrets d'utilisation d'iper-X dans différents scénarios.</li>
                </ul>
              </div>
            </div>
            <div className="step d-flex align-items-start">
              <div className="step-number">
                <div className="step-number-inner">6</div>
              </div>
              <div className="step-body">
                <h3 className="h4 mb-2">Session 6 : Évaluation et Certification</h3>
                <ul className="mb-0">
                  <li>Évaluation des connaissances acquises tout au long de la formation.</li>
                  <li>Certification de réussite pour les participants ayant satisfait aux critères d'évaluation.</li>
                </ul>
                <div className="mb-4"></div>
                <p>
                  Cette formation est conçue pour les professionnels techniques souhaitant exploiter pleinement le potentiel de notre solution iper-X dans leurs projets et leurs opérations quotidiennes. Que vous soyez novice ou utilisateur expérimenté, cette formation vous fournira les compétences et les connaissances nécessaires pour devenir un expert d'iper-X.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalDevis showModal={showModal.devis} handleClose={() => handleClose("devis")} />
    </section>
  );
};

export default Description;
