import React from "react";

const Discover = () => {
  return (
    <section className="container text-center py-5">
      <h2 className="pt-1 mb-4">Découvrez l'IPBX</h2>
      <div className="row justify-content-center mb-md-2 mb-lg-5">
        <div className="col-lg-6 col-md-8">
          <p className="fs-lg text-muted mb-lg-0">
            Un IPBX est un système téléphonique interne à l'entreprise, basé sur
            le protocole Internet, contrairement au PABX qui utilise le réseau
            téléphonique traditionnel. De plus, il peut se connecter à
            l'extérieur via un trunk SIP.
          </p>
        </div>
      </div>
      <div className="position-relative rounded-3 overflow-hidden mb-3">
        <iframe height="515" src="https://www.youtube.com/embed/hc_MObPjlTg?si=Ed7CipV3h2qPnNn2"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
        ></iframe>
      </div>
    </section>
  );
};

export default Discover;
