import React from 'react';
import meetingHeader from "assets/img/ipercom/services/ERP/company/headerTiny_49_11zon.webp";

const Head = () => {
  return (
    <section
      className="jarallax bg-dark pt-2 pt-lg-3 pb-lg-5"
      data-jarallax=""
      data-speed="0.35"
      data-bs-theme="dark"
      style={{ height: 609 }}
    >
      <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-40" />
      <div
        className="jarallax-img"
        style={{ backgroundImage: `url(${meetingHeader})` }}
      />

      <div
        className="container position-relative col-6"
        style={{ top: 150, textAlign: "center" }}
      >
        {/* Title */}
        <h1 className="display-2">ERP pour les petites entreprises</h1>
        <p className="fs-lg text-light opacity-70">
          Nous proposons à nos clients la plus large gamme de services et de
          solutions informatiques de haute qualité, améliorant ainsi leur
          activité.
        </p>
        {/* Stats */}
      </div>
    </section>
  );
};

export default Head;
