import React from "react";
import graph from "assets/img/ipercom/services/ERP/company/icons/computergraph.svg";
import diagramme from "assets/img/ipercom/services/ERP/company/icons/computerdiagra.svg";
import refresh from "assets/img/ipercom/services/ERP/company/icons/tinyRefresh.svg";
import square from "assets/img/ipercom/services/ERP/company/icons/squareIcon.svg";

const Avantages = () => {
  return (
    <section className="container py-2">
      <h2 className="text-center pt-1 pt-xl-2 mb-4">
        Avantages de l'ERP pour les petites entreprises :
      </h2>
      <p className="fs-lg text-muted text-center pb-4 mb-2 mb-lg-3">
        L'ERP pour TPE simplifie la gestion, optimise l'efficacité et favorise
        la croissance.
      </p>
      <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-4 g-4 pb-xl-3">
        <div className="col text-center border-end">
          <div className="card d-table border-0 rounded-circle shadow-sm p-3 mx-auto mb-4">
            <img src={diagramme} width={33} alt="Icon" />
          </div>
          <h3 className="h5 mb-2 mb-lg-0">Centralisation des données</h3>
        </div>
        <div className="col text-center border-end">
          <div className="card d-table border-0 rounded-circle shadow-sm p-3 mx-auto mb-4">
            <img src={refresh} width={33} alt="Icon" />
          </div>
          <h3 className="h5 mb-2 mb-lg-0">Automatisation des processus</h3>
        </div>
        <div className="col text-center border-end">
          <div className="card d-table border-0 rounded-circle shadow-sm p-3 mx-auto mb-4">
            <img src={graph} width={33} alt="Icon" />
          </div>
          <h3 className="h5 mb-2 mb-lg-0">
            Amélioration de la visibilité et du reporting
          </h3>
        </div>
        <div className="col text-center">
          <div className="card d-table border-0 rounded-circle shadow-sm p-3 mx-auto mb-4">
            <img src={square} width={33} alt="Icon" />
          </div>
          <h3 className="h5 mb-2 mb-lg-0">Évolutivité et adaptation</h3>
        </div>
      </div>
    </section>
  );
};

export default Avantages;
