import ConnectingIPBX from "components/modules/services/WhatPhoneSolutionIPBX/ConnectingIPBX";
import DefaultsIPBX from "components/modules/services/WhatPhoneSolutionIPBX/DefaultsIPBX";
import Discover from "components/modules/services/WhatPhoneSolutionIPBX/Discover";
import Head from "components/modules/services/WhatPhoneSolutionIPBX/Head";
import HowVoIPWorks from "components/modules/services/WhatPhoneSolutionIPBX/HowVoIPWorks";
import InnovativeFeatures1 from "components/modules/services/WhatPhoneSolutionIPBX/InnovativeFeatures1";
import InnovativeFeatures2 from "components/modules/services/WhatPhoneSolutionIPBX/InnovativeFeatures2";
import LastNews from "components/modules/showcase/LastNews";
import NetworkIPBX from "components/modules/services/WhatPhoneSolutionIPBX/NetworkIPBX";
import WhatBenefitsIPBX from "components/modules/services/WhatPhoneSolutionIPBX/WhatBenefitsIPBX";
import WhatIsAnIPBX from "components/modules/services/WhatPhoneSolutionIPBX/WhatIsAnIPBX";
import React from "react";
import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";

const SolutionIPBX = () => {
  return (
    <>
      <Breadcrumbs />
      <Head />
      <Discover />
      <WhatIsAnIPBX />
      <HowVoIPWorks />
      <ConnectingIPBX />
      <WhatBenefitsIPBX />
      <InnovativeFeatures1 />
      <InnovativeFeatures2 />
      <DefaultsIPBX />
      <NetworkIPBX />
      <LastNews />
    </>
  );
};

export default SolutionIPBX;
