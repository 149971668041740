import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";
import Head from "components/modules/ressources/blog/Articles/guideReact&C/Head";
import LastNews from "components/modules/showcase/LastNews";
import Paragraph from "components/modules/ressources/blog/Articles/guideReact&C/Paragraph";
import React from "react";


const GuideReactC = () => {
  return (
    <>
      <Breadcrumbs />
      <Head />
      <Paragraph />
      <LastNews />
    </>
  );
};

export default GuideReactC;
