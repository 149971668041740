import ServicesSection from "../../../components/modules/services/guide-erp/ServicesSection";
import ERPhierarchySection from "../../../components/modules/services/guide-erp/ERPhierarchySection";
import ClientTalk from "components/modules/showcase/ClientTalk";

const GuideERP = () => {
  return (
    <>
      <ServicesSection />
      <ERPhierarchySection />
      <ClientTalk />
    </>
  );
};

export default GuideERP;
