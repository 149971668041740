import React from "react";
import Discussion from "assets/img/ipercom/services/TelephonySolution/discussion.svg";

const FinalResult = () => {
  return (
    <section className="container my-5">
      <div className="row">
        <div className="col-md-6 pb-2 pb-md-0 mb-4 mb-md-0 order-2">
          <div className="pe-lg-5 text-center">
            <img src={Discussion} className="rounded-3" alt="Image" style={{ width: "100%" }} />
          </div>
        </div>
        <div className="col-md-6 order-1">
          <h2 className="mb-sm-4">Résultat final</h2>
          <p className="d-md-none d-xl-block pb-2 pb-md-3 mb-3">
            Le résultat final a été une transformation notable des communications internes et externes de l'entreprise.
            Grâce à l'IPBX, le client a bénéficié d'une réduction significative des coûts téléphoniques,
            particulièrement pour les appels longue distance et internationaux. La qualité des appels s'est nettement
            améliorée, offrant une fiabilité accrue grâce à une connexion Internet stable et à la technologie VoIP. De
            plus, l'intégration transparente avec le CRM de l'entreprise a permis un suivi et une gestion des appels
            plus efficaces, avec de nouvelles fonctionnalités comme l'enregistrement des appels, le transfert avec
            accompagnement et le routage équitable, ce qui a considérablement amélioré la productivité. La possibilité
            d'évoluer vers une solution totalement dématérialisée a également offert une plus grande flexibilité et
            mobilité pour les employés, renforçant ainsi l'efficacité opérationnelle et la satisfaction des employés.
          </p>
        </div>
      </div>
    </section>
  );
};

export default FinalResult;
