import React from "react";
import Discussion from 'assets/img/ipercom/services/TelephonySolution/discussion.svg';

const NetworkIPBX = () => {
  return (
    <>
      <section className="container">
        <div className="row pb-4">
          <div
            className="rellax col-md-6 pb-1 mb-3 pb-md-0 mb-md-0"
            data-rellax-percentage="0.5"
            data-rellax-speed="0.8"
            data-disable-parallax-down="md"
          >
            <a href="portfolio-single-project.html">
              <img
                src={Discussion}
                className="rounded-3"
                width={600}
                alt="Image"
              />
            </a>
          </div>
          <div
            className="rellax col-md-6"
            data-rellax-percentage="0.5"
            data-rellax-speed="-0.6"
            data-disable-parallax-down="md"
          >
            <div className="ps-md-4 ms-md-2">
              <h3 className="h2">
                Un réseau interne par défaut est une configuration standard
              </h3>
              <p className="d-md-none d-lg-block pb-3 mb-2 mb-md-3">
                Si l'IPBX n'est pas connecté à un trunk SIP, il permet
                uniquement d'établir un réseau téléphonique interne à
                l'entreprise. Cela signifie que les employés peuvent uniquement
                communiquer avec d'autres personnes présentes sur le même
                réseau, sans avoir la possibilité de réaliser des appels vers
                l'extérieur, que ce soit avec des clients, des fournisseurs, ou
                d'autres contacts externes.
                <br></br>
                <br></br>
                Dans le cas où il n'y a pas de connexion à un trunk SIP, l'IPBX
                doit être intégré à une autre solution pour permettre les appels
                sortants en dehors du réseau de l'entreprise. Une alternative
                consiste à utiliser une solution basée sur le réseau de
                téléphonie traditionnel, par exemple.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NetworkIPBX;
