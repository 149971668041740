import React from "react";
import meeting from "assets/img/ipercom/services/ERP/company/reseaux_14_11zon.webp";
import InfoBouton from "components/modules/showcase/InfoBouton";

const Interest = () => {
  return (
    <section className="container mt-5">
      <div className="row g-5 justify-content-center">
        <div className="col-md-5 pb-2 pb-md-0 mb-4 mb-md-0 order-2 ms-5 pt-5">
          <div className="pe-lg-5 text-center">
            <img
              src={meeting}
              className="rounded-3"
              alt="Image"
              style={{ height: "250px", width: "700px" }}
            />
          </div>
        </div>
        <div className="col-md-5 order-1 me-5">
          <h2 className="mb-sm-4">L'impact essentiel de l'ERP pour les PME</h2>
          <p className="d-md-none d-xl-block pb-5 pb-md-3 mb-3">
            Boostez la productivité de votre PME avec notre solution <a href="/services/erp/qu-est-ce-qu-un-erp"> ERP</a> sur
            mesure. Simplifiez vos opérations dès aujourd'hui !
          </p>
          <InfoBouton />
        </div>
      </div>
    </section>
  );
};

export default Interest;
