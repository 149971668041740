import device from "assets/img/ipercom/services/Solution proposes/logiciel caisse/device_28_11zon.webp";

const WhoIsTheClient = () => {
  return (
    <section className="container my-5">
      <div className="row">
        <div className="col-md-6 pb-2 pb-md-0 mb-4 mb-md-0 order-2">
          <div className="pe-lg-5 text-center">
            <img src={device} className="rounded-3" alt="Image"
                 style={{ width: "56%", marginTop: "-240px" }} /> {/* Ajoutez une marge négative en haut de l'image */}
          </div>
        </div>
        <div className="col-md-6 order-1">
          <h2 className="mb-sm-4">Qui est le client ?</h2>
          <p className="d-md-none d-xl-block pb-2 pb-md-3 mb-1">
            Notre client principal est une chaîne de magasins de détail spécialisée dans la vente de produits
            électroniques et électroménagers. Cette entreprise cherchait à moderniser ses systèmes de caisse pour
            optimiser ses opérations, réduire les coûts et améliorer l'expérience client en magasin.
          </p>
        </div>
      </div>
    </section>
  );
};


export default WhoIsTheClient;
