import React from "react";
import computer from "assets/img/ipercom/icons/computerOurCrm.webp";
import square from "assets/img/ipercom/services/ERP/company/icons/square.svg";
import graph from "assets/img/ipercom/services/ERP/company/icons/graph.svg";
import refresh from "assets/img/ipercom/services/ERP/company/icons/refresh.svg";
import user from "assets/img/ipercom/services/ERP/company/icons/user.svg";
import shield from "assets/img/ipercom/services/ERP/company/icons/shield.svg";

const Solution = () => {
  return (
    <>
      {/* Services */}
      <section className="bg-secondary pb-md-2 pb-lg-5 mb-3">
        <div
          className="d-none d-lg-block"
          style={{ marginTop: "-60px", paddingTop: 60 }}
        />
        <div className="container pb-2">
          <h2 className="text-center text-md-start mb-lg-4 pt-1 pt-md-4">
            Solution ERP pour les grandes entreprises :
          </h2>
          <div className="col-md-8 text-center text-md-start">
            <p className="fs-lg text-muted mb-md-3">
              Notre solution <a href="/services/erp/qu-est-ce-qu-un-erp"> ERP</a> pour les grandes entreprises offre une gestion
              intégrée des processus, optimisant l'efficacité opérationnelle et
              stimulant la croissance.
            </p>
          </div>
          <div className="row row-cols-1 row-cols-md-2">
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <p
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 me-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={computer}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="h4 d-inline-flex align-items-center">
                    Analyse Stratégique
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    L'analyse stratégique avec notre solution ERP pour les
                    grandes entreprises offre des données précises en temps
                    réel, permettant des décisions éclairées pour la croissance
                    et la compétitivité.{" "}
                  </p>
                </div>
              </p>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <p
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={square}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="h4 d-inline-flex align-items-center">
                    Évolutivité Sans Limite
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    Grâce à une architecture flexible et évolutive, notre
                    solution ERP pour les grandes entreprises offre une
                    évolutivité sans limite, garantissant que votre système peut
                    répondre aux exigences croissantes de votre entreprise sans
                    aucun problème.{" "}
                  </p>
                </div>
              </p>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <p
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={shield}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="h4 d-inline-flex align-items-center">
                    Sécurité Renforcée
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    Avec une sécurité renforcée, notre solution ERP pour les
                    grandes entreprises garantit l'intégrité, la confidentialité
                    et la disponibilité des données critiques, offrant ainsi une
                    protection inégalée contre les cyberattaques et les
                    violations de données.
                  </p>
                </div>
              </p>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <p
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={refresh}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="h4 d-inline-flex align-items-center">
                    Intégration Facile
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    L'intégration facile de notre solution ERP pour les grandes
                    entreprises vous permet de connecter rapidement et
                    facilement tous vos systèmes et applications, assurant une
                    circulation fluide des données.
                  </p>
                </div>
              </p>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <p
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={user}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="h4 d-inline-flex align-items-center">
                    Collaboration Améliorée
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    Notre solution encourage une collaboration améliorée en
                    offrant des espaces de travail virtuels, des forums de
                    discussion et des outils de planification, permettant aux
                    équipes de collaborer efficacement et de réaliser leurs
                    objectifs communs.
                  </p>
                </div>
              </p>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <p
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={graph}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="h4 d-inline-flex align-items-center">
                    Innovation Continue
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    L'innovation continue est au cœur de notre solution ERP pour
                    les grandes entreprises, offrant des fonctionnalités
                    évolutives et des capacités de personnalisation pour
                    répondre aux besoins changeants de votre entreprise dans un
                    environnement en constante évolution.
                  </p>
                </div>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Solution;
