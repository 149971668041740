import React from "react";
import Solution from "components/modules/services/company/tiny/Solution";
import Avantages from "components/modules/services/company/tiny/Avantages";
import ClientTalk from "components/modules/showcase/ClientTalk";
import Partners from "components/modules/showcase/Partners";
import Head from "components/modules/services/company/tiny/Head";
import Importancy from "components/modules/services/company/tiny/Importancy";
import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";

const ErpForCompany = () => {
  return (
    <>
      <Head />
      <Breadcrumbs />
      <Importancy />
      <Solution />
      <Avantages />
      <ClientTalk />
      <Partners />
    </>
  );
};

export default ErpForCompany;
