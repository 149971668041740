// Importing React and the image as per the instruction
import React from 'react';
import { Parallax } from 'react-parallax';
import working from 'assets/img/ipercom/services/ERP/working_20_11zon.webp';

interface ContactItemProps {
  iconClass: string;
  text: string;
  link?: string; // Making 'link' optional
}

// HeroSection component
const HeroSection = () => {
  return (
    <section className="">
      <div className="position-relative overflow-hidden">
        <HeroImage />
        <HeroContent />
      </div>
    </section>
  );
};

// HeroImage component for the background image section
const HeroImage: React.FC = () => {
  return (
    <div className="container-fluid position-relative position-lg-absolute top-0 start-0 h-100">
      <div className="row h-100 me-n4 me-n2">
        <div className="col-lg-7 position-relative">
          <div
            className="d-none d-sm-block d-lg-none"
            style={{ height: "400px" }}
          ></div>
          <div className="d-sm-none" style={{ height: '300px' }}></div>
          {/* Implementing the parallax effect */}
          <Parallax
            bgImage={working}
            className="position-absolute top-0 start-0 w-100 h-100 rounded-3 rounded-start-0 overflow-hidden"
          >
            <div style={{ height: '100%' }}></div>
          </Parallax>
        </div>
      </div>
    </div>
  );
};

// HeroContent component for the main content section
const HeroContent = () => {
  return (
    <div className="container position-relative zindex-5 pt-lg-5 px-0 px-lg-3">
      <div className="row pt-lg-5 mx-n4 mx-lg-n3">
        <ContentCard />
        <Contacts />
      </div>
    </div>
  );
};

// ContentCard component for the card with text and buttons
const ContentCard = () => {
  return (
    <div className="col-xl-5 col-lg-7 offset-lg-5 offset-xl-6 pb-5">
      <div className="card bg-dark border-light overflow-hidden">
        <span
          className="position-absolute top-0 start-0 w-100 h-100"
          style={{ backgroundColor: "rgba(255,255,255,.05)" }}
        ></span>
        <div className="card-body position-relative zindex-4">
          <h1 className="text-light">Notre CRM</h1>
          <p className="fs-md text-light opacity-70 mb-5">
            Explorez notre CRM robuste et intuitif conçu pour optimiser la
            gestion de vos relations clients. Avec des fonctionnalités avancées
            telles que la gestion des leads, le suivi des ventes et
            l'automatisation des tâches.
          </p>
        </div>
      </div>
    </div>
  );
};

const Contacts: React.FC = () => {
  return (
    <div className="col-xxl-3 col-lg-4 offset-lg-8 offset-xxl-9 pt-lg-5 mt-xxl-5">
      <ul className="list-unstyled mb-0 px-4 px-lg-0">
        <ContactItem iconClass="" text="" />
        <ContactItem iconClass="" text="" />
        <ContactItem iconClass="" text="" />
        <ContactItem iconClass="" text="" />
        {/*<ContactItem iconClass="bx-map" text="6391 Elgin St. Celina, Delaware 10299" />*/}
        {/*<ContactItem iconClass="bx-envelope" text="email@example.com" link="mailto:email@example.com" />*/}
        {/*<ContactItem iconClass="bx-phone-call" text="(406) 555-0120" link="tel:4065550120" />*/}
      </ul>
    </div>
  );
};

const ContactItem: React.FC<ContactItemProps> = ({ iconClass, text, link }) => {
  return (
    <li className="d-flex align-items-center pb-1 mb-2">
      <i className={`bx ${iconClass} fs-xl text-primary me-2`}></i>
      {link ? (
        <a href={link} className="nav-link fw-normal p-0">
          {text}
        </a>
      ) : (
        <span>{text}</span>
      )}
    </li>
  );
};

export default HeroSection;
