import React from "react";

import lauch from "assets/img/ipercom/services/ERP/company/icons/launch.svg";
import search from "assets/img/ipercom/services/TelephonySolution/software/search.svg";
import appmobile from "assets/img/ipercom/services/TelephonySolution/software/mobile-app.svg";
import video from "assets/img/ipercom/services/TelephonySolution/software/timer.svg";
import computer from "assets/img/ipercom/icons/computerOurCrm.webp";
import screen from "assets/img/ipercom/icons/screenOurCrm.svg";

const Services = () => {
  return (
    <>
      {/* Services */}
      <section className="bg-secondary">
        <div className="container pt-2">
          <h2 className="text-center text-md-start mb-lg-4 pt-1 pt-md-4">
            Nos services
          </h2>

          <p className="fs-lg text-muted mb-md-3">
            Maximisez l'efficacité de votre entreprise avec notre solution
            logicielle de téléphonie iper-X. Dotée de fonctionnalités avancées
            telles que la gestion des appels, la messagerie instantanée et la
            vidéoconférence, notre solution répond à tous vos besoins de
            communication.
          </p>

          <div className="row row-cols-1 row-cols-md-2">
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <div
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 me-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={computer}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="h4 d-inline-flex align-items-center">
                    Compatible ERP
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    Notre solution de téléphonie est conçue pour s'intégrer
                    parfaitement à votre infrastructure ERP, vous permettant de
                    gérer facilement vos communications et vos opérations.{" "}
                  </p>
                </div>
              </div>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <div
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={lauch}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="h4 d-inline-flex align-items-center">
                    Plateforme
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    Avec notre logiciel de téléphonie compatible avec
                    différentes plateformes, vous pouvez facilement connecter
                    votre système de communication à votre environnement
                    technologique pour une gestion simplifiée.{" "}
                  </p>
                </div>
              </div>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <div
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={appmobile}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="h4 d-inline-flex align-items-center">
                    Couplage téléphonique informatique
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    Grâce à notre solution de couplage téléphonique
                    informatique, vous pouvez synchroniser efficacement vos
                    communications téléphoniques avec vos processus
                    informatiques, améliorant ainsi la productivité et
                    l'efficacité de votre entreprise.
                  </p>
                </div>
              </div>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <div
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={screen}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="h4 d-inline-flex align-items-center">
                    Hébergé Cloud AWS
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    Simplifiez la gestion de votre système téléphonique avec
                    notre solution hébergée sur le <a href="/services/services-proposes/solution-iper-cloud"> cloud</a>. Profitez d'une
                    installation rapide, de fonctionnalités avancées et d'une
                    flexibilité accrue pour répondre aux besoins changeants de
                    votre entreprise.
                  </p>
                </div>
              </div>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <div
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={search}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="h4 d-inline-flex align-items-center">
                    Choix du trunk SIP
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    L'intégration d'un logiciel de téléphonie compatible avec
                    n’importe quel trunk SIP vous permet d'optimiser vos
                    communications. Profitez d'une connectivité fiable et d'une
                    gestion efficace des appels pour améliorer la productivité
                    de votre entreprise.
                  </p>
                </div>
              </div>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <div
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={video}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="h4 d-inline-flex align-items-center">
                    Visioconférence
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    Un logiciel de téléphonie avec fonctionnalité de
                    visioconférence offre une expérience immersive pour vos
                    réunions à distance. Optimisez la communication avec des
                    échanges en face à face, quel que soit l'endroit.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;
