import React from "react";
import appelWaiting from 'assets/img/ipercom/services/TelephonySolution/software/appelWaiting_8_11zon.webp';
import InfoBouton from "components/modules/showcase/InfoBouton";

const HowToPresente = () => {
  return (
    <>
      <section className="container">
        <h2>Comment se présente iper-X</h2>
        <div className="row">
          <div className="col-md-6">
            <p className="d-md-none d-xl-block pb-2 pb-md-3 mb-3">
              iper-X se présente comme une solution de communication globale,
              simple à commercialiser, à déployer et à gérer, le tout à un prix
              concurrentiel, vous offrant ainsi la possibilité de l'intégrer à
              vos services informatiques existants. Notre offre se concentre
              exclusivement sur la solution logicielle, vous laissant la liberté
              de choisir et de commercialiser le matériel supplémentaire tel que
              les téléphones IP, les interphones, ainsi que les trunks SIP et
              les services d'hébergement.
              <br></br>
              <br></br>
              Nous n'imposons aucun contrat ; nous proposons simplement <a href="/a-propos/devenir-revendeur"> quatre
              niveaux de partenariat </a> facilement accessibles, accompagnés de
              formations pour vous permettre de progresser. Avec des
              fonctionnalités intégrées telles que la visioconférence, le Live
              Chat sur site web et des applications pour Android, iOS et le web.
              iper-X offre à vos clients tous les outils dont ils ont besoin
              dans un ensemble simple à gérer.
            </p>
            <InfoBouton />
          </div>
          <div className="col-md-6">
            <img src={appelWaiting} className="rounded-3" alt="Image" />
          </div>
        </div>
      </section>
    </>
  );
};

export default HowToPresente;
