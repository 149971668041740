import React from "react";
import gold from "assets/img/ipercom/a-propos/revendeur/Gold.webp";
import ivory from "assets/img/ipercom/a-propos/revendeur/Ivory.webp";
import platinium from "assets/img/ipercom/a-propos/revendeur/Platinium.webp";
import silver from "assets/img/ipercom/a-propos/revendeur/Silver.webp";

const Evolution = () => {
  return (
    <section
      className="py-5 my-md-3 my-lg-5"
      style={{ width: "80%", margin: "auto" }}
    >
      <div className="row align-items-center">
        <div className="col-md-4 text-center text-md-start">
          <h2 className="mb-4">Possibilité d'évolution</h2>
        </div>
        <div className="col-md-8">
          <div className="row row-cols-1 row-cols-md-4">
            <div className="col text-md-center pb-2 mb-4">
              <img src={ivory} alt="Ivoire" />
              <h3 className="h5 mb-2 mb-md-3">Ivoire</h3>
            </div>
            <div className="col text-md-center mb-3">
              <img src={silver} alt="Silver" />
              <h3 className="h5 mb-2 mb-md-3">Silver</h3>
            </div>
            <div className="col text-md-center pb-2 mb-4">
              <img src={gold} alt="Gold" />
              <h3 className="h5 mb-2 mb-md-3">Gold</h3>
            </div>
            <div className="col text-md-center mb-3">
              <img src={platinium} alt="Platinium" />
              <h3 className="h5 mb-2 mb-md-3">Platinium</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="d-none d-md-block pt-5 mt-2 pb-4">
        <div className="row g-0">
          <div className="col-md-4 text-dark fw-semibold ps-4 pe-2 py-4">
            Remise sur licences*
          </div>
          <div className="col-md-8 text-center">
            <div className="row row-cols-4 g-0">
            <div className="col px-2 py-4">
            <i className="bx bx-x lead text-muted" />
              </div>
     
              <div className="col position-relative bg-primary px-2 py-4 border-bottom">
                <span className="text-light opacity-80">8%</span>
                <span
                  className="position-absolute start-0 bottom-100 w-100 bg-primary rounded-3 rounded-bottom-0"
                  style={{ height: "2rem" }}
                />
              </div>
              <div className="col px-2 py-4">15%</div>
              <div className="col position-relative bg-primary px-2 py-4 border-bottom">
                <span className="text-light opacity-80">20%</span>
                <span
                  className="position-absolute start-0 bottom-100 w-100 bg-primary rounded-3 rounded-bottom-0"
                  style={{ height: "2rem" }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row g-0 bg-secondary rounded-3">
          <div className="col-md-4 text-dark fw-semibold ps-4 pe-2 py-4">
            Certification expert (Commerciale et technique)
          </div>
          <div className="col-md-8 text-center">
            <div className="row row-cols-4 g-0">
              <div className="col px-2 py-4">
                <i className="bx bx-check lead text-primary" />
              </div>
              <div className="col bg-primary px-2 py-4 border-bottom">
                <i className="bx bx-check lead text-light" />
              </div>
              <div className="col px-2 py-4">
                <i className="bx bx-check lead text-primary" />
              </div>
              <div className="col bg-primary px-2 py-4 border-bottom">
                <i className="bx bx-check lead text-light" />
              </div>
            </div>
          </div>
        </div>
        <div className="row g-0">
          <div className="col-md-4 text-dark fw-semibold ps-4 pe-2 py-4">
            Accès au forum partenaires
          </div>
          <div className="col-md-8 text-center">
            <div className="row row-cols-4 g-0">
              <div className="col px-2 py-4">
                <i className="bx bx-check lead text-primary" />
              </div>
              <div className="col bg-primary px-2 py-4 border-bottom">
                <i className="bx bx-check lead text-light" />
              </div>
              <div className="col px-2 py-4">
                <i className="bx bx-check lead text-primary" />
              </div>
              <div className="col bg-primary px-2 py-4 border-bottom">
                <i className="bx bx-check lead text-light" />
              </div>
            </div>
          </div>
        </div>
        <div className="row g-0 bg-secondary rounded-3">
          <div className="col-md-4 text-dark fw-semibold ps-4 pe-2 py-4">
            Licence iper-X de démonstration accès simultanés
          </div>
          <div className="col-md-8 text-center">
            <div className="row row-cols-4 g-0">
              <div className="col px-2 py-4 text-primary ">Nfr *4</div>
              <div className="col bg-primary px-2 py-4 border-bottom">
                <span className="text-light ">Nfr *8 </span>
              </div>

              <div className="col px-2 py-4 text-primary">4 Nfr *16</div>
              <div className="col bg-primary px-2 py-4 border-bottom">
                <span className="text-light">4 Nfr *32</span>
              </div>
            </div>
          </div>
        </div>
        <div className="row g-0">
          <div className="col-md-4 text-dark fw-semibold ps-4 pe-2 py-4">
            Interface revendeur personnalisée
          </div>
          <div className="col-md-8 text-center">
            <div className="row row-cols-4 g-0">
              <div className="col px-2 py-4">
                <i className="bx bx-check lead text-primary" />
              </div>
              <div className="col bg-primary px-2 py-4 border-bottom">
                <i className="bx bx-check lead text-light" />
              </div>
              <div className="col px-2 py-4">
                <i className="bx bx-check lead text-primary" />
              </div>
              <div className="col bg-primary px-2 py-4 border-bottom">
                <i className="bx bx-check lead text-light" />
              </div>
            </div>
          </div>
        </div>
        <div className="row g-0 bg-secondary rounded-3">
          <div className="col-md-4 text-dark fw-semibold ps-4 pe-2 py-4">
            Support tickets/an
          </div>
          <div className="col-md-8 text-center">
            <div className="row row-cols-4 g-0">
              <div className="col px-2 py-4 text-primary ">5</div>
              <div className="col bg-primary px-2 py-4 border-bottom">
                <span className="text-light ">10</span>
              </div>

              <div className="col px-2 py-4 text-primary">Support illimité</div>
              <div className="col bg-primary px-2 py-4 border-bottom">
                <span className="text-light">
                  Support prioritaire & illimité
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="row g-0">
          <div className="col-md-4 text-dark fw-semibold ps-4 pe-2 py-4">
            Hébergement inclus
          </div>
          <div className="col-md-8 text-center">
            <div className="row row-cols-4 g-0">
              <div className="col px-2 py-4">
                <i className="bx bx-x lead text-muted" />
              </div>
              <div className="col bg-primary px-2 py-4 border-bottom">
                <i className="bx bx-check lead text-light" />
              </div>

              <div className="col px-2 py-4">
                <i className="bx bx-check lead text-primary" />
              </div>
              <div className="col bg-primary px-2 py-4 border-bottom">
                <i className="bx bx-check lead text-light" />
              </div>
            </div>
          </div>
        </div>
        <div className="row g-0 bg-secondary rounded-3">
          <div className="col-md-4 text-dark fw-semibold ps-4 pe-2 py-4">
            Inscription du revendeur sur notre site web
          </div>
          <div className="col-md-8 text-center">
            <div className="row row-cols-4 g-0">
              <div className="col px-2 py-4">
                <i className="bx bx-check lead text-primary" />
              </div>
              <div className="col bg-primary px-2 py-4 border-bottom">
                <i className="bx bx-check lead text-light" />
              </div>
              <div className="col px-2 py-4">
                <i className="bx bx-check lead text-primary" />
              </div>
              <div className="col bg-primary px-2 py-4 border-bottom">
                <i className="bx bx-check lead text-light" />
              </div>
            </div>
          </div>
        </div>
        <div className="row g-0">
          <div className="col-md-4 text-dark fw-semibold ps-4 pe-2 py-4">
            Leads iper-X
          </div>
          <div className="col-md-8 text-center">
            <div className="row row-cols-4 g-0">
              <div className="col px-2 py-4">
                <i className="bx bx-x lead text-muted" />
              </div>
              <div className="col bg-primary px-2 py-4 border-bottom">
                <i className="bx bx-check lead text-light" />
              </div>

              <div className="col px-2 py-4">
                <i className="bx bx-check lead text-primary" />
              </div>
              <div className="col bg-primary px-2 py-4 border-bottom">
                <i className="bx bx-check lead text-light" />
              </div>
            </div>
          </div>
        </div>
        <div className="row g-0 bg-secondary rounded-3">
          <div className="col-md-4 text-dark fw-semibold ps-4 pe-2 py-4 align-content-center">
            Formation technique et commerciale 2 jours 1250 € HT/jour éligible
            OPCO Qualiopi (en cours d'agrément)
          </div>
          <div className="col-md-8 text-center">
            <div className="row row-cols-4 g-0">
              <div className="col px-2 py-5">
                <i className="bx bx-check lead text-primary" />
              </div>
              <div className="col bg-primary px-2 py-5 border-bottom">
                <i className="bx bx-check lead text-light" />
              </div>

              <div className="col px-2 py-5">
                <i className="bx bx-check lead text-primary" />
              </div>
              <div className="col bg-primary px-2 py-5 border-bottom">
                <i className="bx bx-check lead text-light" />
              </div>
            </div>
          </div>
        </div>
        <div className="row g-0">
          <div className="col-md-4 text-dark fw-semibold ps-4 pe-2 py-4 align-content-center">
            Animation commerciale, campagne marketing, accompagnement commercial
          </div>
          <div className="col-md-8 text-center">
            <div className="row row-cols-4 g-0">
              <div className="col px-2 py-5">
                <i className="bx bx-x lead text-muted" />
              </div>
              <div className="col bg-primary px-2 py-5 border-bottom">
                <i className="bx bx-check lead text-light" />
              </div>

              <div className="col px-2 py-5">
                <i className="bx bx-check lead text-primary" />
              </div>
              <div className="col bg-primary px-2 py-5 border-bottom">
                <i className="bx bx-check lead text-light" />
              </div>
            </div>
          </div>
        </div>
        <div className="row g-0 bg-secondary rounded-3">
          <div className="col-md-4 text-dark fw-semibold ps-4 pe-2 py-4">
            Ventes annuelles minimales € HT/an
          </div>
          <div className="col-md-8 text-center">
            <div className="row row-cols-4 g-0">
              <div className="col px-2 py-4 text-primary ">6 000</div>
              <div className="col position-relative bg-primary px-2 py-4">
                <span className="text-light opacity-80">12 000</span>
                <span
                  className="position-absolute start-0 top-100 w-100 bg-primary rounded-3 rounded-top-0"
                  style={{ height: "2rem" }}
                />
              </div>
              <div className="col px-2 py-4 text-primary ">60 000</div>
              <div className="col position-relative bg-primary px-2 py-4">
                <span className="text-light opacity-80">125 000</span>
                <span
                  className="position-absolute start-0 top-100 w-100 bg-primary rounded-3 rounded-top-0"
                  style={{ height: "2rem" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <p>* Licence Nfr interdite à la revente</p>
      <p>* Tarifs exclusivement réservés aux revendeurs</p>
      <p>
        Tous les téléphones SIP du marché, tels que Yealink, Fanvil, Cisco, Grandstream, Snom, Gigaset, etc., sont
        compatibles avec notre solution iper-X. Nous avons opté pour la marque Yealink et Fanvil en tant que partenaire
        privilégié. Avant utilisation, un test de compatibilité est requis pour vos téléphones SIP.
      </p>
      <p> Nous avons validé et testé les lignes trunk disponibles sur le marché.</p>
      <p> Toutes les lignes trunk, comme Unyc, AlphaLink, OVH, Orange, Sewan, Bouygues, SFR, sont compatibles avec notre
        solution iper-X. Nous avons choisi Unyc comme partenaire privilégié.</p>
    </section>
  );
};

export default Evolution;
