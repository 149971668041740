import React from "react";
import graph from "assets/img/ipercom/services/ERP/company/icons/computergraph.svg";
import refresh from "assets/img/ipercom/services/ERP/company/icons/tinyRefresh.svg";
import lauch from "assets/img/ipercom/services/ERP/company/icons/launchTiny.svg";
import graphDown from "assets/img/ipercom/services/ERP/company/icons/graphDown.svg";

const Avantages = () => {
  return (
    <section className="container py-2">
      <h2 className="text-center pt-1 pt-xl-2 mb-4">
        Personnalisation et flexibilité
      </h2>
      <p className="fs-lg text-muted text-center pb-4 mb-2 mb-lg-3">
        Notre solution offre une personnalisation et une flexibilité sans égal
        pour répondre à vos besoins spécifiques.
      </p>
      <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-4 g-4 pb-xl-3">
        <div className="col text-center border-end">
          <div className="card d-table border-0 rounded-circle shadow-sm p-3 mx-auto mb-4">
            <img src={graph} width={32} alt="Icon" />
          </div>
          <h3 className="h5 mb-2 mb-lg-0">Centralisation des données</h3>
        </div>
        <div className="col text-center border-end">
          <div className="card d-table border-0 rounded-circle shadow-sm p-3 mx-auto mb-4">
            <img src={refresh} width={32} alt="Icon" />
          </div>
          <h3 className="h5 mb-2 mb-lg-0">Automatisation des tâches</h3>
        </div>
        <div className="col text-center border-end">
          <div className="card d-table border-0 rounded-circle shadow-sm p-3 mx-auto mb-4">
            <img src={graphDown} width={32} alt="Icon" />
          </div>
          <h3 className="h5 mb-2 mb-lg-0">Réduction des coûts</h3>
        </div>
        <div className="col text-center">
          <div className="card d-table border-0 rounded-circle shadow-sm p-3 mx-auto mb-4">
            <img src={lauch} width={32} alt="Icon" />
          </div>
          <h3 className="h5 mb-2 mb-lg-0">Amélioration de la productivité</h3>
        </div>
      </div>
    </section>
  );
};

export default Avantages;
