import React from "react";
import woman from "assets/img/ipercom/services/Solution proposes/maintenance and support tech/woman on a phone_39_11zon.webp";

const MaintenanceService = () => {
  return (
    <>
      <section className="container mt-5 pb-lg-5 pb-md-4 pb-3">
        <div className="row justify-content-around">
          <div className="col-md-5 pb-2 pb-md-0 mb-4 mb-md-0 order-2">
            <div className="pe-lg-5 text-center">
              <img src={woman} className="rounded-3" alt="Image" />
            </div>
          </div>
          <div className="col-md-5 align-content-center order-1">
            <h2 className="mb-sm-4">Service de maintenance</h2>
            <p className="d-md-none d-xl-block pb-2 pb-md-3 mb-3">
              Nos services de maintenance assurent le bon fonctionnement continu
              de vos systèmes informatiques. Confiez-nous la gestion de vos
              mises à jour et de vos correctifs pour une performance optimale.
            </p>
            <a href="/login" className="btn btn-primary">
              Créer un ticket
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default MaintenanceService;
