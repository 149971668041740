import ClientTalk from "components/modules/showcase/ClientTalk";
import Head from "components/modules/a-propos/historique-de-l-agence/Head";
import HistoryAgency from "components/modules/a-propos/historique-de-l-agence/HistoryAgency";
import IpercomStrenght from "components/modules/a-propos/historique-de-l-agence/IpercomStrenght";
import List from "components/modules/a-propos/historique-de-l-agence/List";
import Partners from "components/modules/showcase/Partners";
import WhyChooseIpercom from "components/modules/a-propos/historique-de-l-agence/WhyChooseIpercom";
import React from "react";
import Collaborater from "components/modules/showcase/Collaborater";
import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";

const HistoryOfTheAgency = () => {
  return (
    <>
      <Head />
      <Breadcrumbs />
      <HistoryAgency />
      <IpercomStrenght />
      <WhyChooseIpercom />
      <List />
      <Partners />
      <ClientTalk />
      <Collaborater />
    </>
  );
};

export default HistoryOfTheAgency;
