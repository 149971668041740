import mobile from "assets/img/ipercom/services/Solution proposes/logiciel caisse/table booking_28_11zon.webp";

const HowDidWeProceed = () => {
  return (
    <section className="container my-5">
      <div className="row">
        <div className="col-md-6 pb-2 pb-md-0 mb-4 mb-md-0 order-1">
          <div className="pe-lg-5 text-center">
            <img src={mobile} className="rounded-3" alt="Image"
                 style={{ width: "56%", marginTop: "-50px" }} /> {/* Ajoutez une marge négative en haut de l'image */}
          </div>
        </div>
        <div className="col-md-6 order-2">
          <h2 className="mb-sm-4">Comment avons-nous procédé ?</h2>
          <p className="d-md-none d-xl-block pb-2 pb-md-3 mb-3">
            Pour mener à bien ce projet, nous avons commencé par une analyse approfondie des besoins du client,
            comprenant des rencontres pour comprendre ses exigences et identifier les défis actuels. Ensuite, nous avons
            conçu une solution sur mesure en élaborant un cahier des charges détaillé et une interface utilisateur
            intuitive et conviviale. Le développement du logiciel i-pos a inclus des fonctionnalités avancées telles que
            la gestion des ventes, les remboursements, les rapports détaillés et la sécurité des données. Une phase de
            test approfondie a été mise en place pour garantir la fiabilité et la performance du logiciel, recueillant
            des feedbacks des utilisateurs pour effectuer les ajustements nécessaires. Le déploiement progressif dans
            les différents magasins a été accompagné d'une formation du personnel pour assurer une transition en douceur
            et une utilisation optimale du logiciel. Enfin, nous avons fourni un support client réactif pour résoudre
            les problèmes rapidement, et nous avons assuré des mises à jour régulières pour intégrer de nouvelles
            fonctionnalités et améliorer la sécurité.
          </p>
        </div>
      </div>
    </section>
  );
};


export default HowDidWeProceed;
