import React from "react";
import woman from "assets/img/ipercom/services/Solution proposes/developpement web/red head woman_13_11zon.webp";
import graphUp from "assets/img/ipercom/services/Solution proposes/developpement web/graphUp.svg";

const Technologies = () => {
  return (
    <>
      <section className="container mt-5 pb-lg-5 pb-md-4 pb-3">
        <div className="row">
          <div className="col-md-5 pb-2 pb-md-0 mb-4 mb-md-0 order-2">
            <div className="pe-lg-5 text-center">
              <img src={woman} className="rounded-3" alt="Image" />
            </div>
          </div>
          <div className="col-md-6 align-content-center order-1 pe-lg-5">
            <h2 className="mb-sm-4">Technologies utilisées</h2>
            <p className="d-md-none d-xl-block pb-2 pb-md-3 mb-3">
              Les frameworks populaires tels que React, Angular et Vue.js sont
              largement utilisés dans le développement d'applications web pour
              créer des interfaces utilisateurs dynamiques et réactives.
              <br></br>
              <br></br>
              Les technologies côté serveur telles que Node.js, Ruby on Rails et
              Django fournissent une base solide pour le développement robuste
              d'applications web avec une gestion efficace des requêtes et des
              données.
            </p>
            <div className="border rounded-3 mb-4 mb-lg-5">
              <div className="row g-0">
                <div className="col d-flex align-items-center border-end-sm border-bottom p-3">
                  <img src={graphUp} alt="Icon" style={{ width: "40px" }} />
                  <div className="ps-2 ms-1">
                    <h3 className="h6 mb-0">Performance</h3>
                  </div>
                </div>
                <div className="col d-flex align-items-center border-end-sm border-bottom p-3">
                  <i
                    className="bx bx-plus-circle fs-1"
                    style={{ color: "#9397AD" }}
                  ></i>
                  <div className="ps-2 ms-1">
                    <h3 className="h6 mb-0">Évolutivité</h3>
                  </div>
                </div>
                <div className="col d-flex align-items-center border-bottom p-3">
                  <i
                    className="bx bx-shield fs-1"
                    style={{ color: "#9397AD" }}
                  ></i>
                  <div className="ps-2 ms-1">
                    <h3 className="h6 mb-0">Sécurité renforcée</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Technologies;
