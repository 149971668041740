import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import React from 'react';

const ResellerInscriptionForm = () => {
  return (
    <section className="position-relative bg-secondary">
      <Breadcrumbs />
      <div className="container position-relative zindex-2">
        {/* Contact links */}
        <h1 className="pb-3 pb-md-4 mb-lg-5">
          Inscription au programme revendeur
        </h1>
        {/* Contact form */}
        <div className="col-xl-7">
          <div className="card border-light shadow-lg p-sm-4 p-md-5">
            <div className="bg-dark position-absolute top-0 start-0 w-100 h-100 rounded-3 d-none d-dark-mode-block" />
            <div className="card-body position-relative zindex-2">
              <h2 className="card-title mb-4">
                Inscription au programme revendeur
              </h2>
              <form
                className="row g-4 needs-validation align-items-start"
                noValidate
              >
                <div className="col-12">
                  <label htmlFor="nom" className="form-label fs-base">
                    *Nom
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    id="nom"
                    required
                  />
                  <div className="invalid-feedback">
                    Veuillez entrer votre nom complet !
                  </div>
                </div>
                <div className="col-12">
                  <label htmlFor="prenom" className="form-label fs-base">
                    *Prénom
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    id="prenom"
                    required
                  />
                  <div className="invalid-feedback">
                    Veuillez entrer votre prénom complet !
                  </div>
                </div>
                <div className="col-12">
                  <label htmlFor="entreprise" className="form-label fs-base">
                    *Nom de l'entreprise
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    id="entreprise"
                    required
                  />
                  <div className="invalid-feedback">
                    Veuillez entrer le nom complet de votre entreprise !
                  </div>
                </div>
                <div className="col-12">
                  <label htmlFor="email" className="form-label fs-base">
                    *Email
                  </label>
                  <input
                    type="email"
                    className="form-control form-control-lg"
                    id="email"
                    required
                  />
                  <div className="invalid-feedback">
                    Veuillez entrer une adresse email valide !
                  </div>
                </div>
                <div className="col-12">
                  <label htmlFor="telephone" className="form-label fs-base">
                    *Numéro de téléphone
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    id="telephone"
                    required
                  />
                  <div className="invalid-feedback">
                    Veuillez entrer un numéro de téléphone valide !
                  </div>
                </div>
                <div className="col-12">
                  <label htmlFor="siret" className="form-label fs-base">
                    *SIRET
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    id="siret"
                    required
                  />
                  <div className="invalid-feedback">
                    Veuillez entrer un numéro SIRET valide !
                  </div>
                </div>
                <div className="col-12 pt-2 pt-sm-3">
                  <button
                    type="submit"
                    className="btn btn-lg btn-primary w-100 w-sm-auto"
                  >
                    Envoyer
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        className="position-absolute bottom-0 start-0 w-100 bg-light"
        style={{ height: "8rem" }}
      />
    </section>
  );
};

export default ResellerInscriptionForm;
