import React from 'react';
import FourHands from 'assets/img/ipercom/ressources/Documentation/guides/four hands_38_11zon.webp';
import WomenBook from 'assets/img/ipercom/ressources/Documentation/guides/women with book_44_11zon.webp';

const Head = () => {
  return (
    <>
      <section className="position-relative mb-5 pb-5">
        <div className="container position-relative zindex-5">
          <div className="row pt-5">
            <div className="col-xl-5 col-lg-5">
              <h1 className="display-5 pb-md-2 pb-lg-4 mt-4">
                Guides pratiques
              </h1>
            </div>
            <div className="col-xl-5 col-lg-6 position-relative mt-5">
              <div
                className="rellax card bg-dark border-0 shadow-third py-2 px-2"
                data-rellax-speed={-1}
                data-disable-parallax-down="lg"
              >
                <div className="card-body">
                  <h2 className="text-light pb-1 pb-md-3">
                    À propos des guides pratiques
                  </h2>
                  <p className="fs-md text-light">
                    Les guides pratiques sont des ressources incontournables
                    pour apprendre et maîtriser de nouveaux concepts ou tâches.
                    Clair et concis, chaque guide détaille des procédures étape
                    par étape, transformant les informations complexes en
                    actions réalisables. Ils sont idéaux pour ceux qui cherchent
                    à élargir leurs compétences rapidement et efficacement. En
                    abordant des sujets variés, ces manuels deviennent des
                    compagnons précieux dans l'aventure de l'apprentissage,
                    facilitant l'accès à des connaissances pratiques et
                    applicables dans la vie quotidienne ou professionnelle.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row align-items-end">
            <div className="col-lg-6 d-none d-lg-block">
              <img
                src={FourHands}
                className="rellax rounded-3"
                alt="Image"
                data-rellax-speed="1.35"
                data-disable-parallax-down="md"
                style={{
                  zIndex: -1,
                  top: "55%",
                  position: "absolute"
                }}
              />
            </div>
          </div>
          <div className="col-lg-6 d-none d-lg-block">
            <img
              src={WomenBook}
              className="rellax rounded-3 ms"
              alt="Image"
              style={{
                zIndex: -1,
                top: "0%",
                position: 'absolute',
                left: '50%',
                width: '750px'
              }}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default Head;
