import React from 'react';
import ampoule from 'assets/img/ipercom/a-propos/commitmentAndValues/ampoule.webp';
import handpaper from 'assets/img/ipercom/a-propos/commitmentAndValues/hand and paper.webp';
import manwomen from 'assets/img/ipercom/a-propos/commitmentAndValues/man and women.webp';

const Avantages = () => {
  return (
    <>
      <section className="container">
        <div className="d-md-flex align-items-start justify-content-between text-md-start text-center pt-1 pt-sm-3 pt-md-4 mb-5">
          <h2 className="mb-md-0">Nos avantages</h2>
        </div>
        <div className="row row-cols-1 row-cols-md-3 g-4 pb-lg-2 pb-xl-3">
          <div className="col">
            <div className="card card-hover border-0 bg-transparent">
              <img
                src={manwomen}
                className="card-img-top rounded-3"
                alt="img"
                style={{ objectFit: 'cover', height: '200px' }}
              />
              <div className="card-body pt-3 ps-0">
                <h3 className="d-inline-flex align-items-start">
                  Accès aux produits ou services
                </h3>
                <p className="fs-sm text-body mb-0">
                  En tant que revendeur, vous avez accès à une gamme étendue de
                  produits ou services à proposer à vos clients, ce qui vous
                  permet de répondre à divers besoins et demandes.
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card card-hover border-0 bg-transparent">
              <img
                src={ampoule}
                className="card-img-top rounded-3"
                alt="img"
                style={{ objectFit: 'cover', height: '200px' }}
              />
              <div className="card-body pt-3 ps-0">
                <h3 className="d-inline-flex align-items-start">
                  Flexibilité et liberté
                </h3>
                <p className="fs-sm text-body mb-0">
                  Vous avez la liberté de gérer votre entreprise de revente
                  selon vos propres conditions, en choisissant les produits que
                  vous souhaitez proposer, les clients avec lesquels vous
                  souhaitez travailler, et votre propre emploi du temps.
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card card-hover border-0 bg-transparent">
              <img
                src={handpaper}
                className="card-img-top rounded-3"
                alt="img"
                style={{ objectFit: 'cover', height: '200px' }}
              />
              <div className="card-body pt-3 ps-0">
                <h3 className="d-inline-flex align-items-start">
                  Construction de la marque
                </h3>
                <p className="fs-sm text-body mb-0">
                  La revente de produits ou de services de qualité peut
                  contribuer à renforcer votre propre marque en associant votre
                  entreprise à des marques bien établies et respectées dans
                  votre secteur d'activité.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Avantages;
