import MainLayout from "layouts/MainLayout";
import { createBrowserRouter, RouteObject } from "react-router-dom";
import MainLayoutProvider from "providers/old/MainLayoutProvider";
import App from "App";
import Showcase from "./pages/Showcase";
import SignInHard from "./pages/auth/SignInHard";
import Error404 from "./pages/error/Error404";
import SignInForm from "pages/auth/SignInForm";
import Dashboard from "pages/account/Dashboard";
import Contact from "pages/Contact";
import Subscriptions from "pages/Subscriptions";
import Payment from "pages/checkout/payment/Payment";
import Card from "pages/checkout/Card";
import WhatErp from "pages/services/erp/WhatErp";
import GuideERP from "pages/services/erp/GuideERP";
import WhatCrm from "pages/services/erp/WhatCrm";
import OurCrm from "pages/services/erp/OurCrm";
import QuoteCreation from "pages/services/erp/QuoteCreation";
import Estimate from "pages/services/erp/Estimate";
import InventoryMgmt from "pages/services/erp/InventoryMgmt";
import ErpForMiddleCompany from "pages/services/erp/ErpForMiddleCompany";
import ErpForCompany from "pages/services/erp/ErpForCompany";
import ErpForBigCompany from "pages/services/erp/ErpForBigCompany";
import PhoneSoftware from "pages/services/telephony-solution/PhoneSoftware";
import SolutionIPBX from "pages/services/telephony-solution/SolutionIPBX";
import Guides from "pages/ressources/documentation/Guides";
import Projects from "pages/ressources/portfolio/Projects";
import WhiteBooks from "pages/ressources/documentation/WhiteBooks";
import BillingsIT from "pages/services/invoicing-software/BillingsIT";
import BillingsTelecom from "pages/services/invoicing-software/BillingsTelecom";
import Desktop from "pages/services/invoicing-software/Desktop";
import EDISolution from "pages/services/invoicing-software/EDISolution";
import AppDevMobile from "pages/services/proposed-services/AppDevMobile";
import AppDevWeb from "pages/services/proposed-services/AppDevWeb";
import Training from "pages/services/proposed-services/Training";
import MaintTechSupp from "pages/services/proposed-services/MaintTechSupp";
import SoftwareAppPOS from "pages/services/proposed-services/SoftwareAppPOS";
import SoftwareConception from "pages/services/proposed-services/SoftwareConception";
import SoftwareIntegration from "pages/services/proposed-services/SoftwareIntegration";
import ClientsReviews from "pages/ressources/portfolio/ClientsReviews";
import SiteWeb from "pages/ressources/portfolio/SiteWeb";
import News from "pages/ressources/blog/news/News";
import Articles from "pages/ressources/blog/articles/Articles";
import Advices from "pages/ressources/blog/advices/Advices";
import OptimizeExpUX from "pages/ressources/blog/articles/OptimizeExpUX";
import ReactFondament from "pages/ressources/blog/advices/ReactFondament";
import Marketing from "pages/ressources/blog/articles/Marketing";
import GuideReactC from "pages/ressources/blog/articles/GuideReactC";
import BackstageStratUp from "pages/ressources/blog/articles/BackstageStratUp";
import UXImportant from "pages/ressources/blog/articles/UXImportant";
import ImportanceUX from "pages/ressources/blog/advices/ImportanceUX";
import GenerationAndSignature from "pages/ressources/blog/advices/GenerationAndSignature";
import IAIntegration from "pages/ressources/blog/advices/IAIntegration";
import HistoryOfTheAgency from "pages/about/HistoryOfTheAgency";
import CommitmentAndValues from "pages/about/CommitmentAndValues";
import TeamAndSkills from "pages/about/TeamAndSkills";
import BecomeAReseller from "pages/about/BecomeAReseller";
import FindAReseller from "pages/about/FindAReseller";
import Career from "pages/about/career/Career";
import PostSheetFullStack from "pages/about/career/PostSheetFullStack";
import PostSheetDevOps from "pages/about/career/PostSheetDevOps";
import PostSheetBusiness from "pages/about/career/PostSheetBusiness";
import ResellerInscriptionForm from "pages/about/ResellerInscriptionForm";
import CommercialTrainingIPERX
  from "components/modules/services/proposed-services/training/commerciale/CommercialTrainingIPERX";
import TechnicalTrainingIPERX
  from "components/modules/services/proposed-services/training/technical/TechnicalTrainingIPERX";
import RequestADemo from "./pages/forms/RequestADemo";
import RequestAQuote from "./pages/forms/RequestAQuote";
import Billings from "pages/checkout/billing/Billings";
import SystemERPFormation
  from "components/modules/services/proposed-services/training/trading-system/SystemERPFormation";
import AppMobileFormationIPERX
  from "components/modules/services/proposed-services/training/iper-X-mobile-app/AppMobileFormationIPERX";
import DesignRevolutionaryERP from "pages/ressources/blog/news/DesignRevolutionaryERP";
import AppsIntegratedIPBX from "pages/ressources/blog/news/AppsIntegratedIPBX";
import NewTechIntegration from "pages/ressources/blog/news/NewTechIntegration";
import LatestTrendsInUIUX from "pages/ressources/blog/news/LatestTrendsInUIUX";
import ComingSoon from "./pages/ComingSoon";
import BecomeReseller from "./pages/forms/BecomeReseller";
import AuthLayout from "./layouts/AuthLayout";
import LogicielDeCaisse from "pages/ressources/portfolio/LogicielDeCaisse";
import IPBX from "pages/ressources/portfolio/IPBX";
import ERP from "pages/ressources/portfolio/ERP";
import Ipercloud from "pages/ressources/portfolio/Ipercloud";
import SolutionIperCloud from "pages/services/proposed-services/SolutionIperCloud";
import DigitalTransformation from "pages/ressources/blog/lastNews/DigitalTransformation";
import Blockchain from "pages/ressources/blog/lastNews/Blockchain";
import ItInformatique from "pages/ressources/blog/lastNews/ItInformatique";
import IaBusinessStrategies from "pages/ressources/blog/lastNews/IaBusinessStrategies";
import RequestAQuoteIperCloud from "pages/forms/RequestAQuoteIperCloud";
import FormulaireFullStack from "pages/forms/FormulaireFullStack";
import FormulaireDevOps from "pages/forms/FormulaireDevOps";
import FormulaireChargeAffaire from "pages/forms/FormulaireChargeAffaire";
import CGU from "pages/Mentions légales/CGU";
import CGVGrossiste from "pages/Mentions légales/CGVGrossiste";
import CGVClientFinal from "pages/Mentions légales/CGVClientFinal";
import CGVDistributeurESN from "pages/Mentions légales/CGVDistributeurESN";
import PolitiqueCookie from "pages/Mentions légales/PolitiqueCookie";
import PolitiqueConfidentialite from "pages/Mentions légales/PolitiqueConfidentialite";
import FormulaireCandidatureSpont from "pages/forms/FormulaireCandidatureSpont";
import FaqIpercom from "pages/about/FaqIpercom";



const routes: RouteObject[] = [
  {
    element: <App />,
    children: [
      {
        path: '/',
        element: (
          <MainLayoutProvider>
            <MainLayout />
          </MainLayoutProvider>
        ),
        children: [
          {
            index: true,
            element: <Showcase />
          },

          {
            path: 'compte',
            element: <Dashboard />
          },
          {
            path: 'panier',
            children: [
              {
                path: '',
                element: <Card />
              },
              {
                path: 'paiement',
                element: <Payment />
              },
              {
                path: 'paiement/facturation',
                element: <Billings />
              }
            ]
          },
          {
            path: 'services',
            children: [
              {
                path: 'erp',
                children: [
                  {
                    path: 'qu-est-ce-qu-un-erp',
                    element: <WhatErp />,
                  },
                  {
                    path: 'guide-pour-un-erp',
                    element: <GuideERP />
                  },
                  {
                    path: 'qu-est-ce-qu-un-crm',
                    element: <WhatCrm />
                  },
                  {
                    path: 'notre-crm',
                    element: <OurCrm />
                  },
                  {
                    path: 'solution-facturation',
                    element: <QuoteCreation />
                  },
                  {
                    path: 'creation-de-devis',
                    element: <Estimate />
                  },
                  {
                    path: 'gestion-de-stock',
                    element: <InventoryMgmt />
                  },
                  {
                    path: 'erp-pour-petite-entreprise',
                    element: <ErpForCompany />
                  },
                  {
                    path: 'erp-pour-moyenne-entreprise',
                    element: <ErpForMiddleCompany />
                  },
                  {
                    path: 'erp-pour-grande-entreprise',
                    element: <ErpForBigCompany />
                  }
                ]
              },
              {
                path: 'solution-telephonie',
                children: [
                  {
                    path: 'qu-est-ce-qu-un-standard-telephonique-ipbx',
                    element: <SolutionIPBX />
                  },
                  {
                    path: 'logiciel-de-telephonie-iper-x',
                    element: <PhoneSoftware />
                  }
                ]
              },
              {
                path: 'services-proposes',
                children: [
                  {
                    path: 'formation',
                    children: [
                      {
                        path: '',
                        element: <Training />
                      },
                      {
                        path: 'apprendre-solution-technique-iper-X',
                        element: <TechnicalTrainingIPERX />
                      },
                      {
                        path: 'apprendre-solution-commerciale-iper-X',
                        element: <CommercialTrainingIPERX />
                      },
                      {
                        path: "formation-en-commerce-pour-les-système-ERP",
                        element: <SystemERPFormation />
                      },
                      {
                        path: "formation-sur-l-utilisation-de-l-application-mobile-iper-X",
                        element: <AppMobileFormationIPERX />
                      }
                    ]
                  },
                  {
                    path: 'logiciel-et-application-de-caisse',
                    element: <SoftwareAppPOS />
                  },
                  {
                    path: 'developpement-application-web',
                    element: <AppDevWeb />
                  },
                  {
                    path: 'developpement-application-mobile',
                    element: <AppDevMobile />
                  },
                  {
                    path: 'conception-logiciel-sur-mesure',
                    element: <SoftwareConception />
                  },
                  {
                    path: 'integration-de-solutions-logicielles',
                    element: <SoftwareIntegration />
                  },
                  {
                    path: 'maintenance-et-support-technique',
                    element: <MaintTechSupp />
                  },
                  {
                    path: "solution-iper-cloud",
                    element: <SolutionIperCloud />
                  }
                ]
              },
              {
                path: 'logiciel-de-facturation-par-metier',
                children: [
                  {
                    path: 'logiciel-de-facturation-bureautique',
                    element: <Desktop />
                  },
                  {
                    path: 'logiciel-de-facturation-informatique',
                    element: <BillingsIT />
                  },
                  {
                    path: 'logiciel-de-facturation-telecom',
                    element: <BillingsTelecom />
                  },
                  {
                    path: 'solution-edi',
                    element: <EDISolution />
                  }
                ]
              }
            ]
          },
          {
            path: 'ressources',
            children: [
              {
                path: 'documentation',
                children: [
                  {
                    path: 'livres-blancs',
                    element: <WhiteBooks />
                  },
                  {
                    path: 'guides-pratiques',
                    element: <Guides />
                  }
                ]
              },
              {
                path: 'portfolio',
                children: [
                  {
                    path: 'projets-realises',
                    children: [
                      {
                        path: "",
                        element: <Projects />
                      },
                      {
                        path: "site-web",
                        element: <SiteWeb />
                      },
                      {
                        path: "logiciel-de-caisse",
                        element: <LogicielDeCaisse />
                      },
                      {
                        path: "ipbx",
                        element: <IPBX />
                      },
                      {
                        path: "erp",
                        element: <ERP />
                      },
                      {
                        path: "iper-cloud",
                        element: <Ipercloud />
                      }
                    ]
                  },
                  // {
                  //   path: 'projets-realises/:name',
                  //   element: <ProjectName />
                  // },
                  {
                    path: 'avis-clients',
                    element: <ClientsReviews />
                  }
                ]
              },
              {
                path: 'blog',
                children: [
                  {
                    path: "dernieres-nouvelles",
                    children: [
                      {
                        path: "transformation-numerique",
                        element: <DigitalTransformation />
                      },
                      {
                        path: "blockchain-cryptomonnaies",
                        element: <Blockchain />
                      },
                      {
                        path: "securite-informatique",
                        element: <ItInformatique />
                      },
                      {
                        path: "strategies-entreprises-ia",
                        element: <IaBusinessStrategies />
                      }
                    ]
                  },
                  {
                    path: 'articles-tendances-developpement-logiciel',
                    children: [
                      {
                        path: '',
                        element: <Articles />
                      },
                      {
                        path: 'optimiser-experience-utilisateur',
                        element: <OptimizeExpUX />
                      },
                      {
                        path: 'strategie-tendances-a-suivre',
                        element: <Marketing />
                      },
                      {
                        path: 'reactjs-c',
                        element: <GuideReactC />
                      },
                      {
                        path: 'coulisses-des-startups',
                        element: <BackstageStratUp />
                      },
                      {
                        path: 'conception-ux',
                        element: <UXImportant />
                      }
                    ]
                  },
                  {
                    path: 'conseils-pratiques-pour-les-entreprises',
                    children: [
                      {
                        path: '',
                        element: <Advices />
                      },
                      {
                        path: 'principes-fondamentaux-pour-une-utilisation-efficace-de-react',
                        element: <ReactFondament />
                      },
                      {
                        path: 'quelle-est-l-importance-de-la-conception-ux-?',
                        element: <ImportanceUX />
                      },
                      {
                        path: 'generation-de-bons-de-commande-et-de-signatures-en-ligne',
                        element: <GenerationAndSignature />
                      },
                      {
                        path: 'integration de-l-intelligence artificielle avec les technologies',
                        element: <IAIntegration />
                      }
                    ]
                  },
                  {
                    path: 'nouveautes-agence-et-secteur',
                    children: [
                      {
                        path: "",
                        element: <News />
                      },
                      {
                        path: "concevoir-un-erp-revolutionnaire-integrer-des-api-vers-l-ia",
                        element: <DesignRevolutionaryERP />
                      },
                      {
                        path: "guide-pour-la-creation-d-applications-mobiles-integrees-a-votre-ipbx",
                        element: <AppsIntegratedIPBX />
                      },
                      {
                        path: "maximiser-l-efficacite-grace-a-l-integration-de-nouvelles-technologies",
                        element: <NewTechIntegration />
                      },
                      {
                        path: "les-dernieres-tendances-en-ui-ux",
                        element: <LatestTrendsInUIUX />
                      }
                    ]
                  },
                ]
              }
            ]
          },
          {
            path: 'a-propos',
            children: [
              {
                path: 'historique-agence',
                element: <HistoryOfTheAgency />
              },
              {
                path: 'engagements-et-valeurs',
                element: <CommitmentAndValues />
              },
              {
                path: 'equipe-et-competences',
                element: <TeamAndSkills />
              },
              {
                path: 'faq',
                element: <FaqIpercom />
              },
              {
                path: 'carriere',
                children: [
                  {
                    path: '',
                    element: <Career />
                  },
                  {
                    path: 'fiche-de-post',
                    children: [
                      {
                        path: 'developpeur-full-stack',
                        element: <PostSheetFullStack />
                      },
                      {
                        path: 'ingenieur-devops',
                        element: <PostSheetDevOps />
                      },
                      {
                        path: 'charge-d-affaires',
                        element: <PostSheetBusiness />
                      }
                    ]
                  }
                ]
              },
              {
                path: 'devenir-revendeur',
                element: <BecomeAReseller />
              },
              {
                path: 'devenir-revendeur/inscription',
                element: <ResellerInscriptionForm />
              },
              {
                path: 'trouver-un-revendeur',
                element: <FindAReseller />
              }
            ]
          },
          {
            path: "cgu",
            element: <CGU />
          }
          ,
          {
            path: "cgv-grossistes",
            element: <CGVGrossiste />
          }
          ,
          {
            path: "cgv-client-final",
            element: <CGVClientFinal />
          }
          ,
          {
            path: "cgv-distributeur-esn",
            element: <CGVDistributeurESN />
          }
          ,
          {
            path: "politique-cookie",
            element: <PolitiqueCookie />
          }
          ,
          {
            path: "politique-confidentialite",
            element: <PolitiqueConfidentialite />
          },
          {
            path: 'contact',
            element: <Contact />
          },
          {
            path: 'abonnements',
            element: <Subscriptions />
          },
          {
        path: 'demander-un-devis',
        element: <RequestAQuote />
      },
      {
        path: "demander-un-devis-iper-cloud",
        element: <RequestAQuoteIperCloud />
      },
      {
        path: "formulaire-developpeur-full-stack",
        element: <FormulaireFullStack />
      },
      {
        path: "formulaire-developpeur-devops",
        element: <FormulaireDevOps />
      },
      {
        path: "formulaire-charge-d-affaire",
        element: <FormulaireChargeAffaire />
      },
      {
        path: "formulaire-candidature-spontanee",
        element: <FormulaireCandidatureSpont />
      },
      {
        path: 'demander-une-demo',
        element: <RequestADemo />
      },
      {
        path: "devenir-revendeur/inscription",
        element: <BecomeReseller />
      },
        ]
      },
      {
        path: "/",
        element: (
          <MainLayoutProvider>
            <AuthLayout />
          </MainLayoutProvider>
        ),

        children: [
          {
            path: "login",
            element: <SignInHard />
          },
          {
            path: "signIn",
            element: <SignInForm />
          }
        ]

      },
      {
        path: "coming-soon",
        element: <ComingSoon />
      },
      {
        path: '*',
        element: <Error404 />
      },
    ]
  }
];

export const router = createBrowserRouter(routes);

export default routes;
