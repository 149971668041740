import React, { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";

const PolitiqueCookie = () => {


    return (
        <>
            <Breadcrumbs />


            <div className="container my-5">
                <h1 className="text-center text-primary mb-4">Politique de Cookies</h1>
                <div><div>
                    <h2>POLITIQUE DE COOKIES</h2>
                    <p>
                        Le site <a href="https://ipercom.com">https://ipercom.com</a> (ci-après le Site) utilise des cookies et traceurs.
                        Cette rubrique vous permet d'en savoir plus sur ces cookies et l'origine et l'usage des informations de navigation traitées à l'occasion de votre consultation du Site.
                    </p>
                    <h3>QUE SONT LES COOKIES ?</h3>
                    <p>
                        Les cookies sont des petits fichiers texte stockés dans le navigateur de votre terminal lorsque
                        vous utilisez notamment un site web, et permettant de reconnaître le navigateur de votre
                        terminal et de conserver des informations pendant la durée de validité du cookie concerné.
                    </p>
                    <h3>QUELS SONT LES COOKIES UTILISES ET POUR QUELLES FINALITES ?</h3>
                    <p>
                        Lors de l’utilisation du Site et de ses fonctionnalités, IPERCOM déposent des cookies sur votre
                        terminal selon vos choix préalablement accomplis.
                    </p>
                    <p>
                        Certains cookies de ce Site sont indispensables et essentiels au fonctionnement même du Site
                        internet. Votre consentement n’est pas requis pour ces cookies.
                    </p>
                    <p>
                        En revanche, d’autres cookies sont utilisés aux fins d’optimiser votre navigation sur notre Site
                        ou encore d’établir des statistiques anonymes et volumes de fréquentation et d'utilisation du
                        contenu du Site afin de nous permettre d'améliorer l'intérêt de nos services ou encore aux
                        fins de vous fournir des contenus en rapport avec vos centres d'intérêt et de cibler et
                        personnaliser les offres que nous vous adressons, ou de limiter le nombre d’apparition de la
                        même annonce.
                    </p>
                    <p>
                        Leur fonctionnement est soumis à votre consentement et vous disposez de la possibilité de
                        les refuser par le biais du module de gestion des cookies accessible depuis toutes les pages de
                        notre site.
                    </p>
                </div>,
                    <div>
                        <h3>Liste des cookies utilisés sur le Site</h3>
                        <ul>
                            <li>Cookies à finalités purement techniques : comme permettre ou faciliter la communication par voie électronique, ou bien encore permettre la fourniture d'un service de communication en ligne demandé par l’utilisateur,</li>
                            <li>Cookies à finalités d’identification de l’utilisateur de l’équipement terminal de communications électroniques (ex. : « reconnaître » l’internaute qui s’est déjà rendu sur son site internet dans le but de lui éviter de devoir saisir une nouvelle fois certaines données le concernant (choix de la langue, etc.),</li>
                            <li>Cookies à finalités publicitaires : comme permettre l’envoi (par courriers électroniques) ou l’affichage (sur les sites internet consultés par l’utilisateur) de messages électroniques de publicité ciblée – le ciblage se faisant sur la base de l’analyse des informations relatives à l’utilisateur (à son équipement terminal de communications électroniques) dont le cookie aura permis la lecture.</li>
                            <li>Cookies à finalités marketing : comme permettre la réalisation d’analyses et de statistiques à partir des informations relatives à l’utilisateur (à son équipement terminal de communications électroniques) dont le cookie aura permis la lecture, ou bien encore permettre l’évaluation de l’efficacité de la conception ou de l’agencement d’un site internet, de son trafic ou de telle campagne publicitaire en ligne.</li>
                        </ul>
                    </div>,
                    <div>
                        <h3>Nature des cookies</h3>
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Nature des cookies</th>
                                    <th>Finalités</th>
                                    <th>Bases légales</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Cookies techniques ou fonctionnels</td>
                                    <td>Permettre l'utilisation d'un service spécifique explicitement demandé par l'utilisateur, ou dans le seul but d'effectuer la transmission d'une communication sur un réseau de communications électroniques.</td>
                                    <td>Exempt de consentement</td>
                                </tr>
                                <tr>
                                    <td>Cookies de mesure d’audience</td>
                                    <td>Permettre d’analyser les statistiques de consultation de notre Plateforme pour optimiser l’expérience de nos Utilisateurs.</td>
                                    <td>Consentement de l’Utilisateur</td>
                                </tr>
                                <tr>
                                    <td>Marketing</td>
                                    <td>Créer des profils d'Utilisateurs afin d’optimiser la publicité, ou suivre l'utilisateur sur un site web ou sur plusieurs sites web à des fins de marketing similaires.</td>
                                    <td>Consentement de l’Utilisateur</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>,
                    <div>
                        <h3>COMMENT DESACTIVER CES COOKIES ?</h3>
                        <p>
                            A l’exception des cookies nécessaires au fonctionnement de notre site, vous pouvez
                            également gérer, désactiver ou autoriser les cookies en modifiant les paramètres de votre
                            navigateur Internet.
                        </p>
                        <p>Vous trouverez ci-dessous toutes les informations nécessaires en fonction de votre navigateur Internet :</p>
                        <ul>
                            <li>Microsoft Edge : <a href="https://support.microsoft.com/fr-fr/windows/gérer-les-cookies-dans-microsoft-edge-afficher-autoriser-bloquer-supprimer-et-utiliser-168dab11-0753-043d-7c16-ede5947fc64d#:~:text=visualiser%20ce%20site.-">Gérer les cookies dans Microsoft Edge</a></li>
                            <li>Chrome : <a href="https://support.google.com/accounts/answer/61416?hl=fr">Gérer les cookies dans Chrome</a></li>
                            <li>Safari : <a href="https://support.apple.com/fr-fr/HT1677">Gérer les cookies dans Safari</a></li>
                            <li>Mozilla : <a href="https://support.mozilla.org/fr/kb/activer-desactiver-cookies">Activer ou désactiver les cookies dans Mozilla</a></li>
                            <li>Opera : <a href="http://help.opera.com/Windows/10.20/fr/cookies.html">Gérer les cookies dans Opera</a></li>
                        </ul>

                    </div>,
                    <div>
                        <h3>DUREE DE CONSERVATION DES COOKIES</h3>
                        <p>
                            La durée de conservation de vos données à caractère personnel diffère selon la finalité de
                            leurs collectes. Elles seront conservées pendant toute la durée nécessaire à la réalisation des
                            finalités pour lesquelles elles ont été collectées.
                        </p>
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Type de Cookie</th>
                                    <th>Durée de Conservation</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Cookies et autres traceurs non soumis au consentement</td>
                                    <td>Conservation conformément à la réglementation applicable pour une durée n’excédant pas 13 mois.</td>
                                </tr>
                                <tr>
                                    <td>Cookies et autres traceurs soumis au consentement</td>
                                    <td>6 mois à compter de leur collecte.</td>
                                </tr>
                                <tr>
                                    <td>Statistiques</td>
                                    <td>3 ans à compter de leur collecte.</td>
                                </tr>
                            </tbody>
                        </table>
                        <h3>MISES A JOUR</h3>
                        <p>
                            Nous pouvons être amenés à modifier la présente politique. Nous vous invitons à vérifier cette
                            page régulièrement afin de vous assurer de votre accord avec toute modification.
                        </p>
                        <p>
                            Nous veillerons à ce que vous soyez informés de ces modifications soit par une mention
                            spéciale sur notre Site, soit par un avertissement personnalisé notamment dans le cadre de
                            nos envois de newsletters. Nous sommes également susceptibles de vous informer des
                            modifications de cette politique par e-mail.
                        </p>
                    </div></div>
            </div>
        </>
    );
};

export default PolitiqueCookie;
