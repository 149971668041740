import React from "react";
import pen from "assets/img/ipercom/services/logiciel-de-facturation/telecom/pen.webp";

const ImportancySoftware = () => {
  return (
    <>
      <section className="container mt-5">
        <div className="row justify-content-around">
          <div className="col-md-5 pb-2 pb-md-0 mb-4 mb-md-0 order-2">
            <div className="pe-lg-5 text-center">
              <img src={pen} className="rounded-3" alt="Image" />
            </div>
          </div>
          <div className="col-md-5 order-1">
            <h2 className="mb-sm-4">
              L'importance des logiciels de facturation télécom
            </h2>
            <p className="d-md-none d-xl-block pb-2 pb-md-3 mb-3">
              Optimiser la facturation télécom via des logiciels dédiés est
              crucial pour les entreprises. Ils assurent une facturation exacte,
              améliorent la satisfaction client et renforcent la rentabilité en
              automatisant le suivi des consommations et des forfaits.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default ImportancySoftware;
