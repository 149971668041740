import React from "react";
import mobile from "assets/img/ipercom/services/Solution proposes/logiciel caisse/table booking_28_11zon.webp";
import sun from "assets/img/ipercom/services/Solution proposes/logiciel caisse/sun.svg";
import graph from "assets/img/ipercom/services/Solution proposes/logiciel caisse/bars-graphic.svg";
import plus from "assets/img/ipercom/services/Solution proposes/logiciel caisse/plus.svg";
import communication from "assets/img/ipercom/services/Solution proposes/logiciel caisse/communication.svg";
import creditCard from "assets/img/ipercom/services/Solution proposes/logiciel caisse/credit-card.svg";
import outils from "assets/img/ipercom/services/Solution proposes/logiciel caisse/outils.svg";

const WhyChooseThis = () => {
  return (
    <>
      <section
        id="features"
        className="container pt-lg-2 pt-xl-3 pb-3 pb-xl-5 mt-n2 mt-sm-0 mb-2 mb-md-4 mb-lg-5"
      >
        <div className="row pb-xl-3">
          <div className="col-lg-5 d-none d-lg-block">
            <div className="position-relative" style={{ maxWidth: 500 }}>
              <img src={mobile} alt="Image" />
              <div
                className="rellax position-absolute top-0"
                data-rellax-vertical-scroll-axis="x"
                data-rellax-horizontal-speed="0.75"
                data-disable-parallax-down="lg"
                style={{ maxWidth: 348, right: "-2.75rem" }}
              ></div>
            </div>
          </div>
          <div className="col-lg-7">
            <h2 className="text-center text-md-start mb-4">
              Pourquoi choisir notre logiciel de caisse ?
            </h2>
            <p className="fs-lg text-muted text-center text-md-start mb-4 mb-xl-5">
              Profitez de fonctionnalités avancées telles que la gestion des
              stocks, les rapports détaillés et la compatibilité avec les
              paiements sans contact.
            </p>
            <div className="row row-cols-1 row-cols-sm-2 pt-2 pt-sm-3 pt-xl-2">
              <div className="col pb-2 pb-xl-0 mb-1 mb-xl-2">
                <div className="d-flex align-items-start pe-xl-3">
                  <div className="d-table bg-secondary rounded-3 flex-shrink-0 p-3 mb-3">
                    <img src={creditCard} alt="Icon" />
                  </div>
                  <div className="ps-4 ps-sm-3 ps-md-4">
                    <h3 className="h5 pb-1 mb-2">Interface intuitive</h3>
                    <p className="d-md-none d-lg-block pb-3 mb-2 mb-md-3">
                      Notre logiciel de caisse i-pos offre une interface
                      conviviale, facilitant ainsi la prise en main pour les
                      utilisateurs novices.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col pb-2 pb-xl-0 mb-1 mb-xl-2">
                <div className="d-flex align-items-start ps-xl-3">
                  <div className="d-table bg-secondary rounded-3 flex-shrink-0 p-3 mb-3">
                    <img src={outils} alt="Icon" />
                  </div>
                  <div className="ps-4 ps-sm-3 ps-md-4">
                    <h3 className="h5 pb-1 mb-2">Personnalisation avancée</h3>
                    <p className="d-md-none d-lg-block pb-3 mb-2 mb-md-3">
                      Avec i-pos, vous pouvez personnaliser vos paramètres de
                      caisse en fonction des besoins spécifiques de votre
                      entreprise.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col pb-2 pb-xl-0 mb-1 mb-xl-2">
                <div className="d-flex align-items-start pe-xl-3">
                  <div className="d-table bg-secondary rounded-3 flex-shrink-0 p-3 mb-3">
                    <img src={graph} alt="Icon" />
                  </div>
                  <div className="ps-4 ps-sm-3 ps-md-4">
                    <h3 className="h5 pb-1 mb-2">Rapports détaillés</h3>
                    <p className="d-md-none d-lg-block pb-3 mb-2 mb-md-3">
                      i-pos génère des rapports détaillés sur les ventes, les
                      stocks et les performances de votre entreprise.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col pb-2 pb-xl-0 mb-1 mb-xl-2">
                <div className="d-flex align-items-start ps-xl-3">
                  <div className="d-table bg-secondary rounded-3 flex-shrink-0 p-3 mb-3">
                    <img src={communication} alt="Icon" />
                  </div>
                  <div className="ps-4 ps-sm-3 ps-md-4">
                    <h3 className="h5 pb-1 mb-2">Support client réactif</h3>
                    <p className="d-md-none d-lg-block pb-3 mb-2 mb-md-3">
                      Nous offrons un support client réactif pour répondre à
                      toutes vos questions et résoudre vos problèmes rapidement.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col pb-2 pb-xl-0 mb-1 mb-xl-2">
                <div className="d-flex align-items-start pe-xl-3">
                  <div className="d-table bg-secondary rounded-3 flex-shrink-0 p-3 mb-3">
                    <img src={sun} alt="Icon" />
                  </div>
                  <div className="ps-4 ps-sm-3 ps-md-4">
                    <h3 className="h5 pb-1 mb-2">Mises à jour régulières</h3>
                    <p className="d-md-none d-lg-block pb-3 mb-2 mb-md-3">
                      i-pos bénéficie de mises à jour régulières pour toujours
                      garantir la disposition des dernières fonctionnalités et
                      améliorations.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col pb-2 pb-xl-0 mb-1 mb-xl-2">
                <div className="d-flex align-items-start ps-xl-3">
                  <div className="d-table bg-secondary rounded-3 flex-shrink-0 p-3 mb-3">
                    <img src={plus} alt="Icon" />
                  </div>
                  <div className="ps-4 ps-sm-3 ps-md-4">
                    <h3 className="h5 pb-1 mb-2">Évolutivité</h3>
                    <p className="d-md-none d-lg-block pb-3 mb-2 mb-md-3">
                      Notre solution de caisse est évolutive, ce qui signifie
                      qu'elle peut s'adapter à la croissance de votre
                      entreprise.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhyChooseThis;
