import ModalDevis from 'pages/coming-soon/ModalDevis';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

type ModalType = "devis" | "demo" | "revendeur";

const Inventory = () => {

  const [showModal, setShowModal] = useState({
    devis: false,
    demo: false,
    revendeur: false
  });

  const handleShow = (modal: ModalType) =>
    setShowModal({ ...showModal, [modal]: true });
  const handleClose = (modal: ModalType) =>
    setShowModal({ ...showModal, [modal]: false });
  return (
    <section className="container pb-4 mb-md-1 mb-lg-3 mb-xl-4">
      <div className="row pb-4 mb-md-1 mb-lg-3 mb-xl-4">
        <div className="col-lg-6">
          <h1 className="display-4 mb-0">Gestion de stock</h1>
        </div>
        <div className="col-lg-6 col-xl-5 offset-xl-1 pt-2 pt-sm-3 pt-lg-2">
          <p className="fs-md pb-3 mb-0 mb-md-1 mb-xl-2">
            La gestion de stock implique la surveillance et le contrôle des niveaux de stock pour éviter les pénuries ou les excès, garantissant ainsi une efficacité opérationnelle optimale.
          </p>
          <Button
                  variant="primary"
                  className="btn btn-lg mb-3 mb-lg-0 me-lg-3"
                  onClick={() => handleShow("devis")}
                >
                  Demander un devis
                </Button>
          <a
            className="btn btn-lg btn-primary shadow-primary"
            href="https://outlook.office.com/bookwithme/user/9e8387330e3644559680de311487211e%40ipercom.com/meetingtype/04c0ddc4-d8b0-4c28-a2fb-1853e564c7ef?anonymous"
          >
            Demander une démo
          </a>
        </div>

      </div>
      <hr />
      <ModalDevis showModal={showModal.devis} handleClose={() => handleClose("devis")} />

    </section>
  );
};

export default Inventory;
