import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";
import FindResellerForm from "components/modules/a-propos/trouver-un-revendeur/FindResellerForm";
import Map from "components/modules/a-propos/trouver-un-revendeur/Map";
import React from "react";

const FindAReseller = () => {
  return (
    <>
      <div className="bg-secondary">
        <Breadcrumbs />
        <div className="container py-5 ">
          <div className="row">
            <div className="col-md-6 align-content-center ">
              <FindResellerForm />
            </div>
            <div className="col-md-6 align-content-center">
              <Map />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FindAReseller;
