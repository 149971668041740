import React from "react";
import device from "assets/img/ipercom/services/Solution proposes/logiciel caisse/device_28_11zon.webp";

const SoftwareTypes = () => {
  return (
    <>
      {/* Portfolio list */}
      <section className="container">
        <div className="ps-md-4 ms-md-2 text-center">
          <h2>Types de logiciels de caisse</h2>
          <p className="d-md-none d-lg-block pb-3 mb-2 mb-md-3 col-lg-8 m-auto">
            Les logiciels SaaS offrent une flexibilité sans pareille, permettant
            aux entreprises d'accéder à des outils puissants depuis n'importe où
            avec une simple connexion Internet.
          </p>
        </div>
        {/* Item */}
        <div className="row pb-5 mb-md-4 mb-lg-5">
          <div
            className="rellax col-md-6 pb-1 mb-3 pb-md-0 mb-md-0 text-center"
            data-rellax-percentage="0.5"
            data-rellax-speed="0.8"
            data-disable-parallax-down="md"
          >
            <img
              src={device}
              className="rounded-3"
              alt="Image"
              style={{ width: "55%" }}
            />
          </div>
          <div
            className="col-md-6 align-content-center"
            data-rellax-percentage="0.5"
            data-rellax-speed="-0.6"
            data-disable-parallax-down="md"
          >
            <div className="ps-md-4 ms-md-2">
              <h3>Logiciels Saas</h3>
              <p className="d-md-none d-lg-block pb-3 mb-2 mb-md-3">
                Avec i-pos en Saas, les entreprises peuvent éviter les coûts
                initiaux élevés liés à l'infrastructure informatique et
                bénéficier d'une tarification flexible basée sur l'utilisation.
                Les mises à jour automatiques et la maintenance sont gérées par
                le fournisseur, offrant ainsi aux entreprises une solution
                toujours à jour et sécurisée.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SoftwareTypes;
