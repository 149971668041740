import meeting from "assets/img/ipercom/services/ERP/company/meeting_34_11zon.webp";
import InfoBouton from "components/modules/showcase/InfoBouton";

const Importancy = () => {
  return (
    <section className="container mt-5">
      <div className="row g-5 justify-content-center">
        <div className="col-md-5 pb-2 pb-md-0 mb-4 mb-md-0 order-2 ms-5">
          <div className="pe-lg-5 text-center">
            <img
              src={meeting}
              className="rounded-3"
              alt="Image"
            />
          </div>
        </div>
        <div className="col-md-5 order-1 me-5">
          <h2 className="mb-sm-4">
            Importance de l'ERP pour les petites entreprises
          </h2>
          <p className="d-md-none d-xl-block pb-2 pb-md-3 mb-3">
            Pour les petites entreprises, l'ERP centralise les données, réduit
            les coûts opérationnels et stimule la productivité, un atout
            indispensable.
          </p>
          <InfoBouton />
        </div>
      </div>
    </section>
  );
};

export default Importancy;
