import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";
import DescriptionDevOps from "components/modules/a-propos/carriere/devOps/DescriptionDevOps";
import Head from "components/modules/a-propos/carriere/devOps/Head";
import React from "react";

const PostSheetDevOps = () => {
  return (
    <>
      <Head />
      <Breadcrumbs />
      <DescriptionDevOps />
    </>
  );
};

export default PostSheetDevOps;
