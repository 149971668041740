import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";
import Head from "components/modules/ressources/blog/Advices/fondamentauReacJs/Head";
import LastNews from "components/modules/showcase/LastNews";
import Paragraph from "components/modules/ressources/blog/Advices/fondamentauReacJs/Paragraph";
import React from "react";

const ReactFondament = () => {
  return (
    <>
      <Breadcrumbs />
      <Head />
      <Paragraph />
      <LastNews />
    </>
  );
};

export default ReactFondament;
