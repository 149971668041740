import React from "react";
import women from "assets/img/ipercom/services/logiciel-de-facturation/informatique/women3_46_11zon.webp";

const CommerceActivity = () => {
  return (
    <>
      <section className="container mt-5 pb-lg-5 pb-md-4 pb-3">
        <div className="row">
          <div className="col-md-6 pb-2 pb-md-0 mb-4 mb-md-0 order-2">
            <div className="pe-lg-5 text-center">
              <img src={women} className="rounded-3" alt="Image" />
            </div>
          </div>
          <div className="col-md-6 align-content-center order-1">
            <h2 className="mb-4">En plein cœur de l'activité commerciale</h2>
            <ul className="list-unstyled mb-5">
              <li className="d-flex align-items-center mb-2">
                <i className="bx bx-check-circle text-primary fs-xl me-2" />
                Organisation des contacts : suspects, prospects, clients
              </li>
              <li className="d-flex align-items-center mb-2">
                <i className="bx bx-check-circle text-primary fs-xl me-2" />
                Gestion des financements externes et des rachats de biens
              </li>
              <li className="d-flex align-items-center mb-2">
                <i className="bx bx-check-circle text-primary fs-xl me-2" />
                Élaboration de devis sur mesure et propositions commerciales
                avec signature électronique / gestion électronique des documents
                commerciaux (GED)
              </li>
              <li className="d-flex align-items-center mb-2">
                <i className="bx bx-check-circle text-primary fs-xl me-2" />
                Création instantanée de prospects : intelligence artificielle
              </li>
              <li className="d-flex align-items-center mb-2">
                <i className="bx bx-check-circle text-primary fs-xl me-2" />
                Suivi des actions commerciales et des objectifs fixés
              </li>
              <li className="d-flex align-items-center mb-2">
                <i className="bx bx-check-circle text-primary fs-xl me-2" />
                Analyse de rentabilité, satisfaction client, etc.
              </li>
              <li className="d-flex align-items-center mb-2">
                <i className="bx bx-check-circle text-primary fs-xl me-2" />
                Suivi des affaires et des projets en cours
              </li>
              <li className="d-flex align-items-center mb-2">
                <i className="bx bx-check-circle text-primary fs-xl me-2" />
                Gestion des parcs clients et concurrents dans les dossiers
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default CommerceActivity;
