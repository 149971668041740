import React, { useState } from "react";
import sign from "assets/img/ipercom/a-propos/revendeur/sign.webp";
import ModalRevendeur from "pages/coming-soon/ModalRevendeur";
import { Button } from "react-bootstrap";

type ModalType = "devis" | "demo" | "revendeur" | "candidature";

const Process = () => {

  const [showModal, setShowModal] = useState({
    devis: false,
    demo: false,
    revendeur: false,
    candidature: false,
  });

  const handleShow = (modal: ModalType) =>
    setShowModal({ ...showModal, [modal]: true });
  const handleClose = (modal: ModalType) =>
    setShowModal({ ...showModal, [modal]: false });

  return (
    <>
      <section className="container mt-3">
        <div className="row justify-content-around">
          <div className="col-md-6 pb-2 pb-md-0 mb-4 mb-md-0 order-2">
            <div className="pe-lg-5 text-center">
              <img src={sign} className="rounded-3" alt="Image" />
            </div>
          </div>
          <div className="col-md-5 align-content-center order-1">
            <h2 className="mb-sm-4">Processus d'inscription</h2>
            <p className="d-md-none d-xl-block pb-2 pb-md-3 mb-3">
              Découvrez notre processus d'inscription simplifié pour devenir
              revendeur. Avec des étapes claires et rapides, vous pouvez
              rejoindre notre réseau de partenaires et accéder à des
              opportunités commerciales attractives. Rejoignez-nous dès
              aujourd'hui et commencez à développer votre activité !
            </p>
            <Button
                  variant="primary"
                  className="btn btn-primary btn-lg shadow-primary mt-2"
                  onClick={() => handleShow("revendeur")}
                >
                  Inscription programme revendeur
                </Button>
          </div>
        </div>
        <ModalRevendeur showModal={showModal.revendeur} handleClose={() => handleClose("revendeur")} />

      </section>
    </>
  );
};

export default Process;
