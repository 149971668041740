import React from "react";
import hierarchyERP from "assets/img/ipercom/landing/about_erp/diagramHierarchyERP.svg";
import optimizeImage from "assets/img/ipercom/services/ERP/analyticsOurCrm.webp";
import reactjs from "assets/img/ipercom/ressources/Documentation/guides/react-javascript_12_11zon.webp";
import jira
  from "assets/img/ipercom/ressources/Documentation/guides/png-clipart-jira-software-full-logo-tech-companies_3_11zon.webp";

const GuideGallery = () => {
  return (
    <>
      <section className="container mb-5 mt-5 pt-5">
        <div className="d-md-flex align-items-center justify-content-between text-md-start pt-1 pt-sm-3 pt-md-4 mb-5">
          <h2 className="mb-md-0 mt-5">Galerie de guides pratiques</h2>
        </div>
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4 pb-lg-2 pb-xl-3">
          {/* Item ReactJS */}
          <div className="col text-center">
            <div className="card card-hover border-0 bg-transparent">
              <div className="position-relative">
                <img
                  src={reactjs}
                  className="rounded-3"
                  alt="ReactJS Guide"
                  style={{
                    width: "100%",
                    height: "200px",
                    objectFit: "cover"
                  }}
                />
              </div>
              <div className="card-body p-3">
                <h3 className="fs-lg fw-semibold pt-1 mb-2">
                  Comment réaliser une page web avec ReactJS
                </h3>
                <p className="fs-sm mb-0">Date d’ajout 03/05/2024</p>
              </div>
            </div>
            <a
              href="/uploads/documentation/comment-realiser-une-page-web-avec-reactjs.pdf"
              className="btn btn-primary"
              download="comment-realiser-une-page-web-avec-reactjs.pdf"
            >
              Télécharger
            </a>
          </div>
          {/* Item Jira */}
          <div className="col text-center">
            <div className="card card-hover border-0 bg-transparent">
              <div className="position-relative">
                <img
                  src={jira}
                  className="rounded-3"
                  alt="Jira Guide"
                  style={{
                    width: "100%",
                    height: "200px",
                    objectFit: "cover"
                  }}
                />
              </div>
              <div className="card-body p-3">
                <h3 className="fs-lg fw-semibold pt-1 mb-2">
                  Savoir utiliser la solution de projet Jira
                </h3>
                <p className="fs-sm mb-0">Date d’ajout 06/05/2024</p>
              </div>
            </div>
            <a
              href="/uploads/documentation/savoir-utiliser-jira.pdf"
              className="btn btn-primary"
              download="savoir-utiliser-jira.pdf"
            >
              Télécharger
            </a>
          </div>
          {/* Item CRM */}
          <div className="col text-center">
            <div className="card card-hover border-0 bg-transparent">
              <div className="position-relative">
                <img
                  src={optimizeImage}
                  className="rounded-3"
                  alt="CRM Guide"
                  style={{
                    width: "100%",
                    height: "200px",
                    objectFit: "cover"
                  }}
                />
              </div>
              <div className="card-body p-3">
                <h3 className="fs-lg fw-semibold pt-1 mb-2">
                  Appréhender la nature d'un CRM
                </h3>
                <p className="fs-sm mb-0">Date d’ajout 06/05/2024</p>
              </div>
            </div>
            <a
              href="/uploads/documentation/apprehender-la-nature-dun-crm.pdf"
              className="btn btn-primary"
              download="apprehender-la-nature-dun-crm.pdf"
            >
              Télécharger
            </a>
          </div>
          {/* Item ERP */}
          <div className="col text-center">
            <div className="card card-hover border-0 bg-transparent">
              <div className="position-relative">
                <img
                  src={hierarchyERP}
                  className="rounded-3"
                  alt="ERP Guide"
                  style={{
                    width: "100%",
                    height: "200px",
                    objectFit: "cover"
                  }}
                />
              </div>
              <div className="card-body p-3">
                <h3 className="fs-lg fw-semibold pt-1 mb-2">
                  Comprendre le fonctionnement d'un ERP
                </h3>
                <p className="fs-sm mb-0">Date d’ajout 06/05/2024</p>
              </div>
            </div>
            <a
              href="/uploads/documentation/comprendre-le-fonctionnement-dun-erp.pdf"
              className="btn btn-primary"
              download="comprendre-le-fonctionnement-dun-erp.pdf"
            >
              Télécharger
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default GuideGallery;
