import React from "react";
import facturation from "assets/img/ipercom/services/ERP/facturation_37_11zon.webp";
import mobilemeeting from "assets/img/ipercom/services/ERP/mobile2_46_11zon.webp";

const Personalization = () => {
  return (
    <section className="container pb-sm-1 pb-md-3">
      {/* Row 1 */}
      <div className="row align-items-lg-center py-3">
        <div className="col-md-6">
          <img src={facturation} className="rounded-3" alt="Image" />
        </div>
        <div className="col-md-6">
          <div className="ps-xl-5 ms-md-2 ms-lg-4">
            <h2 className="mb-3 mb-sm-4">
              Personnalisation pour répondre à vos besoins de facturation
            </h2>
            <p className="mb-4">
              Une solution de facturation sur mesure vous permet de gérer vos
              finances avec précision, en répondant parfaitement à vos besoins.
            </p>
            <a
              href="/abonnements"
              className="btn btn-lg btn-primary shadow-primary me-2 my-3"
            >
              Démarrez votre projet
            </a>
            <a
              className="btn btn-lg btn-primary shadow-primary me-2"
              href="https://outlook.office.com/bookwithme/user/9e8387330e3644559680de311487211e%40ipercom.com/meetingtype/04c0ddc4-d8b0-4c28-a2fb-1853e564c7ef?anonymous"
            >
              Demander une démo
            </a>
          </div>
        </div>
      </div>
      {/* Row 2 */}
      <div className="row align-items-lg-center pt-md-3">
        <div className="col-md-6 order-md-2">
          <img src={mobilemeeting} className="rounded-3" alt="Image" />
        </div>
        <div className="col-md-6 order-md-1">
          <div className="pe-xl-5 me-md-2 me-lg-4">
            <h2 className="mb-3 mb-sm-4">
              Développement de produits avec la meilleure expérience
            </h2>
            <p className="mb-3 mb-sm-4">
              Notre expertise en création de solutions de facturation sur mesure
              vous garantit une réponse à tous vos besoins. Nous vous offrons un
              logiciel de haute qualité et un service irréprochable à chaque
              étape de votre projet.
            </p>
            <ul className="list-unstyled mb-0">
              <li className="d-flex mb-2">
                <i className="bx bx-check text-primary lead me-2" />
                Solution facturation sûre.
              </li>
              <li className="d-flex mb-2">
                <i className="bx bx-check text-primary lead me-2" />
                Facturation rapide et efficace.
              </li>
              <li className="d-flex">
                <i className="bx bx-check text-primary lead me-2" />
                Facturation simplifiée.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Personalization;
