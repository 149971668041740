import React from 'react';


const Collection = () => {
  return (
    <div className="col-md-8 offset-lg-1 pb-5 mb-lg-2 pt-md-5 mt-n3 mt-md-0">
      <div className="ps-md-3 ps-lg-0 mt-md-2 pt-md-4 pb-md-2">
        <div className="d-sm-flex align-items-center justify-content-between pt-xl-1 mb-3 pb-3">
          <h1 className="h2 mb-sm-0">Mes Collections</h1>
          <select className="form-select" style={{ maxWidth: "15rem" }}>
            <option value="Published">Publiées</option>
            <option value="Category">Catégorie</option>
            <option value="Title AZ">Titre AZ</option>
            <option value="Title ZA">Titre ZA</option>
          </select>
        </div>
        {/* Item */}
        <div className="card border-0 shadow-sm overflow-hidden mb-4">
          <div className="row g-0">
            <a
              href="#"
              className="col-sm-4 bg-repeat-0 bg-position-center bg-size-cover"

              aria-label="Image de couverture"
            ></a>
            <div className="col-sm-8">
              <div className="card-body">
                <div className="fs-sm text-muted mb-1">30 Novembre 2023</div>
                <h2 className="h4 pb-1 mb-2">
                  <a href="#">Illustration de Forme 3D</a>
                </h2>
                <p className="mb-4 mb-lg-5">
                  NFT / Design Graphique / Design de Mouvement
                </p>
                <div className="d-flex">
                  <button
                    type="button"
                    className="btn btn-outline-primary px-3 px-lg-4 me-3"
                  >
                    <i className="bx bx-edit fs-xl me-xl-2" />
                    <span className="d-none d-xl-inline">Modifier</span>
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-secondary px-3 px-lg-4 me-3"
                  >
                    <i className="bx bx-power-off fs-xl me-xl-2" />
                    <span className="d-none d-xl-inline">Désactiver</span>
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-danger px-3 px-lg-4"
                  >
                    <i className="bx bx-trash-alt fs-xl me-xl-2" />
                    <span className="d-none d-xl-inline">Supprimer</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Item */}
        <div className="card border-0 shadow-sm overflow-hidden mb-4">
          <div className="row g-0">
            <a
              href="#"
              className="col-sm-4 bg-repeat-0 bg-position-center bg-size-cover"
              aria-label="Image de couverture"
            ></a>
            <div className="col-sm-8">
              <div className="card-body">
                <div className="fs-sm text-muted mb-1">18 Octobre 2023</div>
                <h2 className="h4 pb-1 mb-2">
                  <a href="#">
                    Scène de Lunettes de Soleil &amp; Casque sur une Tête
                    Humaine
                  </a>
                </h2>
                <p className="mb-4 mb-lg-5">
                  Design Graphique / Art / Identité / Design de Mouvement
                </p>
                <div className="d-flex">
                  <button
                    type="button"
                    className="btn btn-outline-primary px-3 px-lg-4 me-3"
                  >
                    <i className="bx bx-edit fs-xl me-xl-2" />
                    <span className="d-none d-xl-inline">Modifier</span>
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-secondary px-3 px-lg-4 me-3"
                  >
                    <i className="bx bx-power-off fs-xl me-xl-2" />
                    <span className="d-none d-xl-inline">Désactiver</span>
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-danger px-3 px-lg-4"
                  >
                    <i className="bx bx-trash-alt fs-xl me-xl-2" />
                    <span className="d-none d-xl-inline">Supprimer</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Item */}
        <div className="card border-0 shadow-sm overflow-hidden mb-4">
          <div className="row g-0">
            <a
              href="#"
              className="col-sm-4 bg-repeat-0 bg-position-center bg-size-cover"
              aria-label="Image de couverture"
            ></a>
            <div className="col-sm-8">
              <div className="card-body">
                <div className="fs-sm text-muted mb-1">05 Août 2023</div>
                <h2 className="h4 pb-1 mb-2">
                  <a href="#">Mains de Mannequin Tenant un Téléphone</a>
                </h2>
                <p className="mb-4 mb-lg-5">
                  NFT / Design d'Application Mobile / Design Graphique / Art
                </p>
                <div className="d-flex">
                  <button
                    type="button"
                    className="btn btn-outline-primary px-3 px-lg-4 me-3"
                  >
                    <i className="bx bx-edit fs-xl me-xl-2" />
                    <span className="d-none d-xl-inline">Modifier</span>
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-secondary px-3 px-lg-4 me-3"
                  >
                    <i className="bx bx-power-off fs-xl me-xl-2" />
                    <span className="d-none d-xl-inline">Désactiver</span>
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-danger px-3 px-lg-4"
                  >
                    <i className="bx bx-trash-alt fs-xl me-xl-2" />
                    <span className="d-none d-xl-inline">Supprimer</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Collection;
