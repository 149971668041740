import React from 'react';

import cms from 'assets/img/ipercom/icons/cms.webp';
import analytics from 'assets/img/ipercom/icons/analytics.webp';
import customization from 'assets/img/ipercom/icons/mobile-app.svg';
import training from 'assets/img/ipercom/icons/school.svg';
import migration from 'assets/img/ipercom/icons/rocket.svg';
import optimization from 'assets/img/ipercom/icons/timer.svg';
import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';

const services = [
  {
    title: 'Évaluation des besoins',
    description:
      "Démarrez votre projet sur de bonnes bases en réalisant une évaluation minutieuse de vos besoins. Cette analyse approfondie aide à aligner vos ressources avec vos objectifs, maximisant ainsi vos chances de succès.",
    icon: analytics,
    link: ""
  },
  {
    title: 'Sélection du bon système',
    description:
      "Optez pour le bon système pour optimiser vos performances. Une sélection avisée garantit une intégration fluide et une adaptation optimale à votre environnement professionnel.",
    icon: cms,
    link: ""
  },
  {
    title: 'Personnalisation et configuration',
    description:
      "La personnalisation et la configuration adaptées à vos besoins sont la clé de la réussite. Maximisez l'efficacité de votre système en le configurant selon vos spécifications exactes.",
    icon: customization,
    link: ""
  },
  {
    title: 'Formation des utilisateurs',
    description: (
      <span>
        Assurez le succès de votre projet avec{' '}
        <a href="/services/services-proposes/formation">une formation complète des utilisateurs</a>.
        Donnez à votre équipe les compétences nécessaires pour tirer le meilleur parti de votre système et rester compétitive.
      </span>
    ),
    icon: training,
    link: ""
  },
  
  {
    title: 'Migration des données',
    description:
      "Simplifiez votre migration de données avec notre assistance spécialisée. Nous gérons chaque étape du processus avec précision pour assurer un transfert rapide et sécurisé vers votre nouvelle plateforme.",
    icon: migration,
    link: ""
  },
  {
    title: 'Suivi et optimisation',
    description:
      "Maximisez l'efficacité de votre stratégie avec notre service de suivi et d'optimisation. Surveillez les performances en temps réel, ajustez les tactiques et atteignez vos objectifs plus rapidement.",
    icon: optimization,
    link: ""
  }
];

const ServicesSection = () => {
  return (
    <section className="bg-secondary pb-2">
      <Breadcrumbs />
      <div className="container pb-4">
        <h1 className="text-center text-md-start">Guide pour un ERP </h1>
        <div className="row row-cols-1 row-cols-md-2">
          {services.map((service, index) => (
            <div key={index} className="col py-4 my-2 my-sm-3">
              <div
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={service.icon}
                      className="d-block m-1"
                      width="40"
                      alt="Icon"
                    />
                  </div>
                  <h2 className="h4 d-inline-flex align-items-center">
                    {service.title}
                  </h2>
                  <p className="fs-sm text-body mb-0">{service.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
