import React from "react";
import woman from "assets/img/ipercom/services/Solution proposes/developpement web/woman_42_11zon.webp";
import income from "assets/img/ipercom/services/TelephonySolution/software/icons/income.svg";

const DevelopmentApproach = () => {
  return (
    <>
      <section className="container">
        <div className="row">
          <div className="col-md-5 pb-2 pb-md-0 mb-4 mb-md-0">
            <div className="pe-lg-5 text-center">
              <img src={woman} className="rounded-3" alt="Image" />
            </div>
          </div>
          <div className="col-md-7 align-content-center">
            <h2 className="mb-sm-4">Approche de développement</h2>
            <p className="d-md-none d-xl-block pb-2 pb-md-3 mb-3">
              Pour une meilleure efficacité, nous adoptons une approche
              structurée et collaborative dans le développement d'applications
              web au sein de notre équipe.
            </p>
            <div className="border rounded-3 mb-4 mb-lg-5">
              <div className="row row-cols-1 row-cols-sm-2 g-0">
                <div className="col d-flex align-items-center border-end-sm border-bottom p-3">
                  <img
                    src={income}
                    width="50"
                    alt="Icon"
                    style={{ color: "#9397AD" }}
                  ></img>
                  <div className="ps-2 ms-1">
                    <h3 className="h6 mb-0">Structuration méthodique</h3>
                    <p className="fs-xs mb-0">
                      Structuration méthodique des processus
                    </p>
                  </div>
                </div>
                <div className="col d-flex align-items-center border-bottom p-3">
                  <i
                    className="bx bx-group fs-1"
                    style={{ color: "#9397AD" }}
                  ></i>
                  <div className="ps-2 ms-1">
                    <h3 className="h6 mb-0">Collaboration</h3>
                    <p className="fs-xs mb-0">
                      Échange entre les membres de l'équipe
                    </p>
                  </div>
                </div>
                <div className="col d-flex align-items-center border-bottom border-end-sm p-3">
                  <i
                    className="bx bx-shuffle fs-1"
                    style={{ color: "#9397AD" }}
                  ></i>
                  <div className="ps-2 ms-1">
                    <h3 className="h6 mb-0">Flexibilité</h3>
                    <p className="fs-xs mb-0">
                      S'adapter aux évolutions des besoins
                    </p>
                  </div>
                </div>
                <div className="col d-flex align-items-center border-bottom p-3">
                  <i
                    className="bx bx-trending-up fs-1"
                    style={{ color: "#9397AD" }}
                  ></i>
                  <div className="ps-2 ms-1">
                    <h3 className="h6 mb-0">Amélioration continue</h3>
                    <p className="fs-xs mb-0">
                      Grâce à l'analyse et l'itération constante
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DevelopmentApproach;
