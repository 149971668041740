import React from "react";
import computer from "assets/img/ipercom/services/Solution proposes/computer_19_11zon.webp";
import man from "assets/img/ipercom/services/Solution proposes/man_29_11zon.webp";
import womenWhite from "assets/img/ipercom/services/Solution proposes/woman.webp";
import womenBlack from "assets/img/ipercom/services/Solution proposes/womenBlack.webp";
import { Link } from "react-router-dom";

const courses = [
  {
    image: computer,
    title: "Formation technique pour apprendre à utiliser notre solution iper-X",
    link: "apprendre-solution-technique-iper-X",
    price: "1250€/Jour hors frais*",
    duration: "16 heures (2 jours)",
  },
  {
    image: man,
    title: "Formation commerciale promouvoir efficacement notre solution iper-X",
    link: "apprendre-solution-commerciale-iper-X",
    price: "1250€/Jour hors frais*",
    duration: "8 heures (1 jour)",
  },
  {
    image: womenWhite,
    title: "Formation en commerce pour les systèmes ERP",
    link: "formation-en-commerce-pour-les-système-ERP",
    price: "1000€/Jour hors frais*",
    duration: "35 heures (7 jours)",
  },
  {
    image: womenBlack,
    title: "Formation sur l'utilisation de l'application mobile iper-X",
    link: "formation-sur-l-utilisation-de-l-application-mobile-iper-X",
    price: "290€/Jour hors frais*",
    duration: "1 heure",
  }
];

const TrainingList = () => {
  return (
    <section className="container">
      <div className="d-lg-flex align-items-center justify-content-between py-4 mt-lg-2">
        <h1 className="me-3">Formations</h1>
 
      </div>
      <div className="mb-4">
        <p>Passer une formation certifiée Qualiopi garantit une haute qualité des prestations, grâce à des critères stricts de certification. Ces formations permettent d'accéder à des financements publics ou mutualisés, ce qui réduit les coûts pour les apprenants. La reconnaissance officielle de Qualiopi améliore l'employabilité et assure une meilleure transparence et fiabilité des informations fournies par les organismes. De plus, les formations sont adaptées aux besoins des participants avec un suivi personnalisé, favorisant une amélioration continue.</p>
      </div>
      <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 gx-3 gx-md-4 mt-n2 mt-sm-0">
        {courses.map((course, index) => (
          <div className="col pb-1 pb-lg-3 mb-4" key={index}>
            <article className="card h-100 border-0 shadow-sm">
              <div className="position-relative">
                <img src={course.image} className="card-img-top" alt="Image" />
              </div>
              <div className="card-body pb-3">
                <h2 className=" h5 mb-2"><Link to={course.link}>{course.title}</Link></h2>
                <p className="fs-sm mb-2">Distancielle</p>
                <p className="fs-lg fw-semibold text-primary mb-0">{course.price}</p>
              </div>
              <div className="card-footer d-flex align-items-center fs-sm text-muted py-4">
                <div className="d-flex align-items-center me-4"><i className="bx bx-time fs-xl me-1" />{course.duration}
                </div>
              </div>
            </article>
          </div>
        ))}
      </div>
    </section>
  );
};

export default TrainingList;
