import Paragraph from "components/modules/ressources/blog/LastNews/ItInformatique/Paragraph";
import Head from "components/modules/ressources/blog/LastNews/ItInformatique/Head";
import React from "react";

const ItInformatique = () => {
  return (
    <>
      <Head />
      <Paragraph />
    </>
  );
};

export default ItInformatique;