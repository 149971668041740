import client from "assets/img/ipercom/ressources/portfolio/projet realisé/clienterp_15_11zon.webp";

const FinalResult = () => {
  return (
    <section className="container my-5">
      <div className="row">
        <div className="col-md-6 pb-2 pb-md-0 mb-4 mb-md-0 order-2">
          <div className="pe-lg-5 text-center">
            <img src={client} className="rounded-3" alt="Image" style={{ width: "130%" }} />
          </div>
        </div>
        <div className="col-md-6 order-1">
          <h2 className="mb-sm-4">Résultat final</h2>
          <p className="d-md-none d-xl-block pb-2 pb-md-3 mb-3">
            Le résultat final était une transformation significative des processus de collaboration et de stockage de
            données de l'entreprise. Grâce à iper-cloud, l'entreprise a bénéficié d'un accès facile et sécurisé à ses
            données depuis n'importe où et à tout moment, améliorant ainsi la productivité de ses équipes dispersées. La
            solution a également permis à l'entreprise de se conformer aux réglementations en matière de confidentialité
            et de sécurité, renforçant ainsi la confiance de ses clients et partenaires commerciaux. En fin de compte,
            iper-cloud a contribué à moderniser les opérations de l'entreprise et à renforcer sa compétitivité sur le
            marché.
          </p>
        </div>
      </div>
    </section>
  );
};

export default FinalResult;
