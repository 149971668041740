import React from "react";
import Impt1 from "assets/img/ipercom/services/TelephonySolution/IMPORTANT 1_7_11zon.webp";
import { useParams } from "react-router-dom";

const WhatTheProject = () => {
  const { name } = useParams();
  return (
    <>
      <section className="container my-5">
        <h1 className="pb-3">
          Solution de standard téléphonique pour entreprises
        </h1>
        <h2 className="mb-5">{name}</h2>
        <div className="row">
          <div className="col-md-6 pb-2 pb-md-0 mb-4 mb-md-0 order-1">
            <div className="pe-lg-5 text-center">
              <img src={Impt1} className="rounded-3" alt="Image" style={{ width: "100%" }} />
            </div>
          </div>
          <div className="col-md-6 order-2">
            <h2 className="mb-sm-4">Quel était le projet ?</h2>
            <p className="d-md-none d-xl-block pb-2 pb-md-3 mb-3">
              Le projet consistait à moderniser le système de téléphonie interne d'une entreprise en passant d'un PABX
              traditionnel à un IPBX. L'objectif principal était de tirer parti des technologies VoIP pour améliorer la
              qualité des communications, réduire les coûts téléphoniques, et offrir une gamme étendue de
              fonctionnalités avancées pour accroître l'efficacité opérationnelle.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhatTheProject;
