import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../providers/AuthProvider";
import imageBackground from "../../assets/img/account/signin-bg.webp";
import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";

const SignInHard = () => {
  const navigate = useNavigate();
  const { login } = useAuthContext();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await login(email, password);
      navigate('/');
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      {/*<Header />*/}
      <section className="position-relative mt-n5 pb-4">
        <Row className="justify-content-center">
          <Col lg={6}>
            <div className="container d-flex flex-wrap justify-content-center justify-content-xl-start h-100 mt-n5">
              <div
                className="w-100 align-self-end pt-1 pt-md-4 pb-4 m-auto"
                style={{ maxWidth: "526px" }}
              >
                <Breadcrumbs />
                <h1 className="text-center text-xl-start">
                  Bienvenue
                </h1>
                {/* <p className="text-center text-xl-start pb-3 mb-3">
                  Vous n'avez pas encore de compte ?{" "}
                  <Link to="/signIn">Inscrivez-vous ici.</Link>
                </p> */}
                <form
                  className="needs-validation mb-2"
                  noValidate
                  onSubmit={handleSubmit}
                >
                  <div className="position-relative mb-4">
                    <label htmlFor="email" className="form-label fs-base">
                      Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      className="form-control form-control-lg"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <div className="invalid-feedback position-absolute start-0 top-100">
                      Veuillez saisir une adresse e-mail valide !
                    </div>
                  </div>
                  <div className="mb-4">
                    <label htmlFor="password" className="form-label fs-base">
                      Mot de passe
                    </label>
                    <div className="password-toggle">
                      <input
                        type={showPassword ? "text" : "password"}
                        id="password"
                        className="form-control form-control-lg"
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <label
                        className="password-toggle-btn"
                        aria-label="Afficher/masquer le mot de passe"
                        onClick={togglePasswordVisibility}
                      >

                        <span className="password-toggle-indicator"></span>
                      </label>
                      <div className="invalid-feedback position-absolute start-0 top-100">
                        Veuillez entrer votre mot de passe !
                      </div>
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        id="remember"
                        className="form-check-input"
                        checked={rememberMe}
                        onChange={(e) => setRememberMe(e.target.checked)}
                      />
                      <label
                        htmlFor="remember"
                        className="form-check-label fs-base"
                      >
                        Se souvenir de moi
                      </label>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary shadow-primary btn-lg w-100"
                  >
                    Se connecter
                  </button>
                </form>
                <a
                  href="http://82.223.216.1:2025/"
                  className="btn btn-link btn-lg w-100"
                  rel="noreferrer"
                >
                  Mot de passe oublié ?
                </a>
                <hr className="my-4" />
                <h6 className="text-center mb-4">
                  Ou connectez-vous avec votre réseau social
                </h6>
                <div className="row row-cols-1 row-cols-sm-2">
                  <div className="col mb-3">
                    <a
                      href=""
                      className="btn btn-icon btn-secondary btn-google btn-lg w-100"
                      style={{pointerEvents:'none'}}  
                    >
                      <i className="bx bxl-google fs-xl me-2" />
                      Google
                    </a>
                  </div>
                  <div className="col mb-3">
                    <a
                      href=""
                      className="btn btn-icon btn-secondary btn-facebook btn-lg w-100"
                    style={{pointerEvents:'none'}}  
                    >
                    
                      <i className="bx bxl-facebook fs-xl me-2" />
                      Facebook
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={6} className="align-self-end pt-1 pt-md-4 pb-4 order-md-2">
            <img
              src={imageBackground}
              alt="Se connecter"
              className="img-fluid"
            />
          </Col>
        </Row>
      </section>
    </>
  );
};

export default SignInHard;
