import React from "react";
import computer from "assets/img/ipercom/services/Solution proposes/integration logiciel/computer3_20_11zon.webp";

const IntegerSolution = () => {
  return (
    <>
      <section className="container mt-5">
        <div className="row ">
          <div className="col-md-7 pb-2 pb-md-0 mb-4 mb-md-0">
            <div className="pe-lg-5 text-center">
              <img src={computer} className="rounded-3" alt="Image" />
            </div>
          </div>
          <div className="col-md-4 align-content-center">
            <h2 className="mb-sm-4">
              Comment intégrer des solutions logicielles{" "}
            </h2>
            <p className="d-md-none d-xl-block pb-2 pb-md-3 mb-3">
              Découvrez comment  <a href="/a-propos/equipe-et-competences">notre équipe </a> intègre efficacement des solutions
              logicielles via des APIs. Grâce à notre expertise, nous assurons
              une intégration fluide, optimisant ainsi vos processus métiers et
              améliorant votre productivité. Avec une approche centrée sur vos
              besoins, nous vous offrons des solutions sur mesure, garantissant
              une connectivité transparente entre vos systèmes. Faites confiance
              à notre savoir-faire pour simplifier vos opérations et stimuler
              votre croissance grâce à des intégrations logicielles efficaces.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default IntegerSolution;
