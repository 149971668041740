import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";
import TrainingList from "components/modules/services/proposed-services/training/TrainingList";
import React from "react";

const Training = () => {
  return (
    <>
      <Breadcrumbs />
      <TrainingList />
    </>
  );
};

export default Training;
