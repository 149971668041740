import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";
import GuideGallery from "components/modules/ressources/documentation/guides/GuideGallery";
import Head from "components/modules/ressources/documentation/guides/Head";
import LastNews from "components/modules/showcase/LastNews";
import Partners from "components/modules/showcase/Partners";
import React from "react";

const Guides = () => {
  return (
    <>
      <Breadcrumbs />
      <Head />
      <GuideGallery />
      <LastNews />
      <Partners />
    </>
  );
};

export default Guides;
