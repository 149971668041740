import React from "react";
import header from "assets/img/ipercom/services/Solution proposes/developpement web/header_45_11zon.webp";
import InfoBouton from "components/modules/showcase/InfoBouton";

const Header = () => {
  return (
    <>
      <section className="position-relative jarallax">
        <div
          className="jarallax-img"
          style={{ backgroundImage: `url(${header})` }}
        />
        <div className="container position-relative pb-5 pt-5 zindex-5">
          {/* Featured article */}
          <div className="row mb-xxl-5 py-md-4 py-lg-5">
            <div className="col-lg-7 col-md-7 pb-3 pb-md-0 mb-4 mb-md-5">
              <h1 className="display-5 pb-md-3">
                Développement d'applications web
              </h1>
              <InfoBouton />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Header;
