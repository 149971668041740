import logoLight from "assets/img/ipercom/logo/logo_light.svg";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import ModalDevis from "./coming-soon/ModalDevis";
import ModalRevendeur from "./coming-soon/ModalRevendeur";
import Footer from "../components/footers/Footer";

type ModalType = "devis" | "demo" | "revendeur";

const ComingSoon = () => {
  const [showModal, setShowModal] = useState({
    devis: false,
    demo: false,
    revendeur: false
  });

  const handleShow = (modal: ModalType) =>
    setShowModal({ ...showModal, [modal]: true });
  const handleClose = (modal: ModalType) =>
    setShowModal({ ...showModal, [modal]: false });

  return (
    <>
      <section
        className="d-flex align-items-center bg-light"
        style={{
          background:
            "radial-gradient(144.3% 173.7% at 71.41% 94.26%, rgba(99, 102, 241, 0.1) 0%, rgba(218, 70, 239, 0.05) 32.49%, rgba(241, 244, 253, 0.07) 82.52%)"
        }}
      >
        <div className="container my-3 text-center text-md-start">
          <div className="row align-items-center">
            <div className="col-md-12">
              <img src={logoLight} className="" style={{ width: "700px" }} alt="ipercom" />
              <h1 className=" display-lg-4  display-md-2 pt-2 pb-2 pb-lg-3 mt-sm-2 mt-lg-4">
                Création de Logiciels et d'Applications
              </h1>
              <div className="fs-5-lg">
                <p className="lead mx-md-0 mx-auto">
                  Bienvenue chez ipercom, spécialiste en conception de logiciels
                  et applications sur mesure, adaptés à vos besoins.
              </p>
                <p className="lead mb-md-5 mb-4 mx-md-0 mx-auto pb-2">
                Nos solutions comprennent des progiciels ERP, des applications
                logicielles, ainsi que des solutions de téléphonie IPBX.
                <span>
                  <a
                    href="https://www.youtube.com/watch?v=ApQ3ogWDLxU"
                    className="text-primary"
                  >
                     Lire la vidéo ERP
                  </a>
                  {" | "}
                  <a
                    href="https://www.youtube.com/watch?v=oNjippiNdcc"
                    className="text-primary"
                  >
                    Lire la vidéo Solution de téléphonie IPERX
                  </a>
                </span>
              </p>
              </div>
              <h2 className="display-4-lg  display-2-md mt-n4">
                Notre site est en cours de construction🛠️
              </h2>
              <div className="d-flex flex-column flex-lg-row align-items-lg-center mb-2">
                <Button
                  variant="primary"
                  className="btn btn-lg mb-3 mb-lg-0 me-lg-3"
                  onClick={() => handleShow("devis")}
                >
                  Demander un devis
                </Button>
                <Button
                  variant="secondary"
                  className="btn btn-lg mb-3 mb-lg-0 me-lg-3"
                  href="https://outlook.office.com/bookwithme/user/f762995ecc274b3283fe2a85126ae90f%40ipercom.com/meetingtype/2a5f598a-5ac7-4c81-9c7d-ef0d53f87204?anonymous"
                >
                  Demander une démo
                </Button>
                <Button
                  variant="secondary"
                  className="btn btn-lg"
                  onClick={() => handleShow("revendeur")}
                >
                  Devenir revendeur
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ModalDevis showModal={showModal.devis} handleClose={() => handleClose("devis")} />
      <ModalRevendeur showModal={showModal.revendeur} handleClose={() => handleClose("revendeur")} />
      <Footer />
    </>
  );
};

export default ComingSoon;
