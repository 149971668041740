import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const HowWeWork = () => {
  // Données pour le carrousel de fonctionnalités
  const featuresData = [
    {
      title: "Collaboration efficace",
      description: "iper-cloud facilite la collaboration en ligne avec une interface intuitive accessible sur tous les appareils. Améliorez la productivité des équipes en déplacement."
    },
    {
      title: "Espaces de travail personnalisés",
      description: "Organisez vos dossiers avec des espaces de travail personnalisables proposés par iper-cloud. Ajoutez des notes, des listes de tâches et des descriptions pour une meilleure coordination."
    },
    {
      title: "Verrouillage des fichiers",
      description: "iper-cloud vous permet d'éviter les conflits de modification en verrouillant les fichiers. Assurez l'intégrité de vos données tout en permettant des modifications sécurisées."
    }
  ];

  return (
    <>
      <section className="container pb-2 pb-lg-3 pt-5">
        {/* Carrousel de fonctionnalités */}
        <div style={{ backgroundColor: "#f5f5f5", padding: "20px 0" }}> {/* Fond plus foncé derrière le carrousel */}
          <section className="container py-3 mb-5">
            <h2 className="text-center pt-1 pt-xl-3 mb-lg-4">
              Ce que iper-cloud vous propose
            </h2>
            <div className="d-flex justify-content-center">
              <Swiper
                slidesPerView={3} // Afficher trois diapositives à la fois
                spaceBetween={0} // Suppression de l'espace entre les diapositives
                pagination={{ clickable: true }}
                breakpoints={{
                  0: {
                    slidesPerView: 1
                  },
                  500: {
                    slidesPerView: 2
                  },
                  800: {
                    slidesPerView: 3
                  }
                }}
                className="swiper-container"
                style={{ maxWidth: "920px" }} // Limite la largeur du conteneur pour réduire l'espace entre les blocs
              >
                {featuresData.map((feature, index) => (
                  <SwiperSlide
                    key={index}
                    className={"pt-2"}
                    style={{ width: "300px", margin: "0 2px" }} // Réduction drastique de la marge
                  >
                    <div
                      className="card h-100 card-body card-hover"
                      style={{
                        width: "300px",
                        height: "340px",
                        margin: "0",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between"
                      }}
                    >
                      <h3 className="h5 pt-3 pb-1 mb-2">{feature.title}</h3>
                      <p className="mb-0">{feature.description}</p>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </section>
        </div>
      </section>
    </>
  );
};

export default HowWeWork;
