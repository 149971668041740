import React from "react";
import meeting from "assets/img/ipercom/a-propos/revendeur/meeting.webp";

const BecomeReseller = () => {
  return (
    <>
      <section className="container mt-5">
        <div className="row">
          <div className="col-md-6 pb-2 pb-md-0 mb-4 mb-md-0 order-1">
            <div className="pe-lg-5 text-center">
              <img src={meeting} className="rounded-3" alt="Image" />
            </div>
          </div>
          <div className="col-md-6 order-2">
            <h2 className="mb-4">Comment devenir revendeur</h2>
            <ul className="list-unstyled mb-5">
              <li className="mb-2">
                <i className="bx bx-check-circle text-primary fs-xl me-2" />
                Posséder une entreprise avec un numéro de SIRET.
              </li>
              <li className="mb-2">
                <i className="bx bx-check-circle text-primary fs-xl me-2" />
                Offrir des services liés aux télécommunications.
              </li>
              <li className="mb-2">
                <i className="bx bx-check-circle text-primary fs-xl me-2" />
                Disposer d'un <a href="/services/services-proposes/maintenance-et-support-technique">service technique</a> comprenant au moins un
                technicien certifié avec des compétences réseaux.*
              </li>
              <li className="mb-2">
                * Si aucun technicien n'est disponible au sein de l'entreprise,
                les tickets seront délégués à ipercom.
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default BecomeReseller;
