import React, { useState } from 'react';

import men from "assets/img/ipercom/ressources/blog/Novelties/men.webp";
import mobile from "assets/img/ipercom/ressources/blog/Novelties/mobile.webp";
import womenBlack from "assets/img/ipercom/ressources/blog/Novelties/womenBlack.webp";
import womenYellow from "assets/img/ipercom/ressources/blog/Novelties/woman.webp";


const NoveltiesList = () => {
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [articles, setArticles] = useState([
    {
      categorie: "Process",
      date: "May 3, 2024",
      title: "Concevoir un ERP Révolutionnaire : Intégrer des API vers l'IA",
      description:
        "Découvrez comment créer un ERP révolutionnaire en intégrant des API vers l'intelligence artificielle. Réinventez la gestion d'entreprise avec cette approche innovante et performante.",
      author: "Jerome Bell",
      image: womenYellow,
      link: "/ressources/blog/nouveautes-agence-et-secteur/concevoir-un-erp-revolutionnaire-integrer-des-api-vers-l-ia"
    },
    {
      categorie: "Startups",
      date: "May 10, 2024",
      title:
        "Guide pour la Création d'Applications Mobiles Intégrées à votre IPBX",
      description:
        "Découvrez notre guide complet pour concevoir des applications mobiles intégrées à votre IPBX. Optimisez la communication et la productivité de votre entreprise avec ces conseils pratiques.",
      author: "Albert Flores",
      image: mobile,
      link: "/ressources/blog/nouveautes-agence-et-secteur/guide-pour-la-creation-d-applications-mobiles-integrees-a-votre-ipbx"
    },
    {
      categorie: "Digital",
      date: "May 9, 2024",
      title:
        "Maximiser l'Efficacité grâce à l'Intégration de Nouvelles Technologies",
      description:
        "Optimisez la productivité de votre entreprise en adoptant une stratégie d'intégration de nouvelles technologies. Découvrez comment maximiser l'efficacité et rester compétitif sur le marché.",
      author: "Jane Cooper",
      image: womenBlack,
      link: "/ressources/blog/nouveautes-agence-et-secteur/maximiser-l-efficacite-grace-a-l-integration-de-nouvelles-technologies"
    },
    {
      categorie: "Business",
      date: "Jul 16, 2024",
      title: "Les Dernières Tendances en UI/UX",
      description:
        "Explorez les dernières tendances en matière d'interface utilisateur (UI) et d'expérience utilisateur (UX) pour garantir des designs innovants et convaincants.",
      author: "Ralph Edwards",
      image: men,
      link: "/ressources/blog/nouveautes-agence-et-secteur/les-dernieres-tendances-en-ui-ux"
    }
  ]);

  const filteredArticles = selectedCategory === "all" ? articles : articles.filter(article => article.categorie === selectedCategory);


  const handleCategoryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCategory(e.target.value);
  };

  return (
    
    <section className="container mt-4 mb-lg-5 pt-lg-2 pb-5">
      <div className="row gy-3 mb-4 pb-lg-3 pb-1 align-items-center">
        <div className="col-lg-5 col-md-4">
          <h1>Nouveautés de l'agence et du secteur</h1>
        </div>
        <div className="col-lg-7 col-md-8">
          <form className="row gy-2">
            <div className="col-lg-5 col-sm-6">
              <div className="d-flex align-items-center">
                <select className="form-select" onChange={handleCategoryChange}>
                  <option value="all">Toutes catégories</option>
                  <option value="Digital">
                    Digital
                  </option>
                  <option value="Business">Business</option>
                  <option value="Process">Process</option>
                  <option value="Startups">Startups</option>
                </select>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* Items */}
      {filteredArticles.map((item, index) => (
        <article
          key={index}
          className="card border-0 shadow-sm overflow-hidden mb-4"
        >
          <div className="row g-0">
            <div
              className="col-sm-4 position-relative bg-position-center bg-repeat-0 bg-size-cover"
              style={{
                backgroundImage: `url(${item.image})`,
                minHeight: "15rem"
              }}
            >
            </div>
            <div className="col-sm-8">
              <div className="card-body">
                <div className="d-flex align-items-center mb-3">
                  <p
                    className="badge fs-sm text-nav bg-secondary  mb-1 text-decoration-none"
                  >
                    {item.categorie}
                  </p>
                  <span className="fs-sm text-muted border-start ps-3 ms-3">
                    {item.date}
                  </span>
                </div>
                <h2 className="h4">
                  <a href={item.link}>{item.title}</a>
                </h2>
                <p>{item.description}</p>
                <hr className="my-2" />
              </div>
            </div>
          </div>
        </article>
      ))}
      {/* Pagination */}
      {/* Your pagination code */}
    </section>
  );
};

export default NoveltiesList;
