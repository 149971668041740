import React from "react";
import woman from "assets/img/ipercom/services/Solution proposes/developpement web/woman_42_11zon.webp";
import graph from "assets/img/ipercom/services/Solution proposes/developpement web/graph.svg";
import loupe from "assets/img/ipercom/services/Solution proposes/developpement web/loupe.svg";
import analyse from "assets/img/ipercom/services/Solution proposes/developpement web/analyses.svg";
import cadenas from "assets/img/ipercom/services/Solution proposes/developpement web/cadenas.svg";

const AvantagesAndPossibilities = () => {
  return (
    <>
      <section className="container mt-5 pb-lg-5 pb-md-4 pb-3">
        <div className="row">
          <div className="col-md-5 pb-2 pb-md-0 mb-4 mb-md-0">
            <div className="pe-lg-5 text-center">
              <img src={woman} className="rounded-3" alt="Image" />
            </div>
          </div>
          <div className="col-md-6 align-content-center">
            <h2 className="mb-sm-4">
              Les avantages et les possibilités offerts par les applications web{" "}
            </h2>
            <p className="d-md-none d-xl-block pb-2 pb-md-3 mb-3">
              Explorez les avantages des applications web pour une accessibilité
              optimale et une collaboration aisée. Découvrez-en plus dès
              maintenant !
            </p>
            <div className="border rounded-3 mb-4 mb-lg-5">
              <div className="row row-cols-1 row-cols-sm-2 g-0">
                <div className="col d-flex align-items-center border-end-sm border-bottom p-3">
                  <img src={analyse} alt="Icon" style={{ width: "40px" }} />
                  <div className="ps-2 ms-1">
                    <h3 className="h6 mb-0">Accessibilité multiplateforme</h3>
                  </div>
                </div>
                <div className="col d-flex align-items-center border-bottom p-3">
                  <img src={cadenas} alt="Icon" style={{ width: "40px" }} />
                  <div className="ps-2 ms-1">
                    <h3 className="h6 mb-0">Maintenance simplifiée</h3>
                  </div>
                </div>
                <div className="col d-flex align-items-center border-bottom border-end-sm p-3">
                  <img src={loupe} alt="Icon" style={{ width: "40px" }} />
                  <div className="ps-2 ms-1">
                    <h3 className="h6 mb-0">Collaboration en temps réel</h3>
                  </div>
                </div>
                <div className="col d-flex align-items-center border-bottom p-3">
                  <img src={graph} alt="Icon" style={{ width: "40px" }} />
                  <div className="ps-2 ms-1">
                    <h3 className="h6 mb-0">Analytique avancée</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AvantagesAndPossibilities;
