import React from "react";
import rgSystem from "assets/img/ipercom/services/logiciel-de-facturation/informatique/rgSystem_17_11zon.webp";

const RGSystem = () => {
  return (
    <>
      <section className="container mt-5 pb-lg-5 pb-md-4 pb-3">
        <div className="row">
          <div className="col-md-6 pb-2 pb-md-0 mb-4 mb-md-0 order-2 align-content-center">
            <div className="pe-lg-5 text-center ">
              <img src={rgSystem} className="rounded-3" alt="Image" />
            </div>
          </div>
          <div className="col-md-6 align-content-center order-1">
            <h2 className="mb-sm-4">
              RG system une solution SaaS pour les professionnels de l'IT,
              centralisée sur une seule plateforme
            </h2>
            <p className="d-md-none d-xl-block pb-2 pb-md-3 mb-3">
              Découvrez RG system conçue pour centraliser toutes vos opérations
              sur une seule et même plateforme, elle offre une gestion
              simplifiée de vos services IT. Gérez efficacement vos dispositifs
              de surveillance, vos interventions et votre support client tout en
              optimisant les performances et la sécurité. RG system est l'allié
              indispensable pour une gestion IT intégrée et performante, adaptée
              aux exigences modernes des entreprises.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default RGSystem;
