import React from "react";
import { Button } from "react-bootstrap";
import BillingsTabProduct from "./BillingsTabProduct";
import BillingsTabServices from "./BillingsTabServices";

const BillingsTab = () => {
  return (
    <section className="container">
      <BillingsTabProduct />
      <BillingsTabServices />
      <div className="py-4 text-end">
        <div className="flex-end">
          <button
            className="btn btn-primary btn-sm fs-6 rounded d-none d-lg-inline-flex mx-3"
          >
            <i className="bx  me-3 bx-download"></i>
            Télécharger la facture
          </button>
          <button
            className="btn btn-secondary btn-sm fs-6 rounded d-none d-lg-inline-flex mx-3"
          >
            <i className="bx  me-3 bx-printer"></i>
            Imprimer
          </button>
        </div>
      </div>
    </section>
  );
};

export default BillingsTab;
