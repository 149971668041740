import React from 'react';

const Security = () => {
  return (
    <div className="col-md-8 offset-lg-1 pb-5 mb-2 mb-lg-4 pt-md-5 mt-n3 mt-md-0">
      <div className="ps-md-3 ps-lg-0 mt-md-2 pt-md-4 pb-md-2">
        <h1 className="h2 pt-xl-1 pb-3">Sécurité</h1>
        {/* Mot de passe */}
        <h2 className="h5 text-primary mb-4">Mot de passe</h2>
        <form className="needs-validation border-bottom pb-3 pb-lg-4">
          <div className="row">
            <div className="col-sm-6 mb-4">
              <label htmlFor="cp" className="form-label fs-base">
                Mot de passe actuel
              </label>
              <div className="password-toggle">
                <input
                  type="password"
                  id="cp"
                  className="form-control form-control-lg"
                  defaultValue="jonnyPass"
                />
                <label
                  className="password-toggle-btn"
                  aria-label="Afficher/masquer le mot de passe"
                >
                  <input className="password-toggle-check" type="checkbox" />
                  <span className="password-toggle-indicator" />
                </label>
                <div className="invalid-tooltip position-absolute top-100 start-0">
                  Mot de passe incorrect !
                </div>
              </div>
            </div>
          </div>
          <div className="row pb-2">
            <div className="col-sm-6 mb-4">
              <label htmlFor="np" className="form-label fs-base">
                Nouveau mot de passe
              </label>
              <div className="password-toggle">
                <input
                  type="password"
                  id="np"
                  className="form-control form-control-lg"
                />
                <label
                  className="password-toggle-btn"
                  aria-label="Afficher/masquer le mot de passe"
                >
                  <input className="password-toggle-check" type="checkbox" />
                  <span className="password-toggle-indicator" />
                </label>
                <div className="invalid-tooltip position-absolute top-100 start-0">
                  Mot de passe incorrect !
                </div>
              </div>
            </div>
            <div className="col-sm-6 mb-4">
              <label htmlFor="cnp" className="form-label fs-base">
                Confirmer le nouveau mot de passe
              </label>
              <div className="password-toggle">
                <input
                  type="password"
                  id="cnp"
                  className="form-control form-control-lg"
                />
                <label
                  className="password-toggle-btn"
                  aria-label="Afficher/masquer le mot de passe"
                >
                  <input className="password-toggle-check" type="checkbox" />
                  <span className="password-toggle-indicator" />
                </label>
                <div className="invalid-tooltip position-absolute top-100 start-0">
                  Mot de passe incorrect !
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex mb-3">
            <button type="reset" className="btn btn-secondary me-3">
              Annuler
            </button>
            <button type="submit" className="btn btn-primary">
              Sauvegarder les modifications
            </button>
          </div>
        </form>
        {/* Sessions */}
        <h2 className="h5 text-primary pt-1 pt-lg-3 mt-4">Vos sessions</h2>
        <p className="pb-3 mb-3">
          Voici la liste des appareils qui se sont connectés à votre compte.
          Supprimez toutes les sessions que vous ne reconnaissez pas.
        </p>
        <ul className="list-unstyled mb-0">
          <li className="d-flex align-items-center justify-content-between mb-4">
            <div className="d-flex align-items-start me-3">
              <div className="bg-secondary rounded-1 p-2">
                <i className="bx bx-desktop fs-xl text-primary d-block" />
              </div>
              <div className="ps-3">
                <div className="fw-medium text-nav mb-1">
                  Mac - New York, États-Unis
                </div>
                <span className="d-inline-block fs-sm text-muted border-end pe-2 me-2">
                  Chrome
                </span>
                <span className="badge bg-success shadow-success">
                  Actif maintenant
                </span>
              </div>
            </div>
            <button
              type="button"
              className="btn btn-outline-secondary px-3 px-sm-4"
            >
              <i className="bx bx-x fs-xl ms-sm-n1 me-sm-1" />
              <span className="d-none d-sm-inline">Supprimer</span>
            </button>
          </li>
          <li className="d-flex align-items-center justify-content-between mb-4">
            <div className="d-flex align-items-start me-3">
              <div className="bg-secondary rounded-1 p-2">
                <i className="bx bx-mobile fs-xl text-primary d-block" />
              </div>
              <div className="ps-3">
                <div className="fw-medium text-nav mb-1">
                  iPhone 12 - New York, États-Unis
                </div>
                <span className="d-inline-block fs-sm text-muted border-end pe-2 me-2">
                  Silicon App
                </span>
                <span className="d-inline-block fs-sm text-muted">
                  Il y a 20 heures
                </span>
              </div>
            </div>
            <button
              type="button"
              className="btn btn-outline-secondary px-3 px-sm-4"
            >
              <i className="bx bx-x fs-xl ms-sm-n1 me-sm-1" />
              <span className="d-none d-sm-inline">Supprimer</span>
            </button>
          </li>
          <li className="d-flex align-items-center justify-content-between mb-4">
            <div className="d-flex align-items-start me-3">
              <div className="bg-secondary rounded-1 p-2">
                <i className="bx bx-desktop fs-xl text-primary d-block" />
              </div>
              <div className="ps-3">
                <div className="fw-medium text-nav mb-1">
                  Windows 10.1 - New York, États-Unis
                </div>
                <span className="d-inline-block fs-sm text-muted border-end pe-2 me-2">
                  Chrome
                </span>
                <span className="d-inline-block fs-sm text-muted">
                  15 novembre à 8:42
                </span>
              </div>
            </div>
            <button
              type="button"
              className="btn btn-outline-secondary px-3 px-sm-4"
            >
              <i className="bx bx-x fs-xl ms-sm-n1 me-sm-1" />
              <span className="d-none d-sm-inline">Supprimer</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Security;
