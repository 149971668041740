import React, { useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useParallaxHooks from "hooks/useParallaxHooks";

import chatIcon from 'assets/img/ipercom/landing/features/chat.svg';
import analyticsIcon from 'assets/img/ipercom/landing/features/analytics.svg';
import bellIcon from 'assets/img/ipercom/landing/features/bell.svg';
import tasksIcon from 'assets/img/ipercom/landing/features/tasks.svg';
import calendarIcon from 'assets/img/ipercom/landing/features/calendar.svg';
import addGroupIcon from 'assets/img/ipercom/landing/features/add-group.svg';
import headsetIcon from 'assets/img/ipercom/landing/features/headset.svg';
import shieldIcon from 'assets/img/ipercom/landing/features/shield.svg';

gsap.registerPlugin(ScrollTrigger);

const featuresData = [
  {
    icon: chatIcon,
    title: "Chat",
    description: "Envoyez vos messages en un clin d'œil avec notre chat."
  },
  {
    icon: analyticsIcon,
    title: "Analyse",
    description: "Exploitez pleinement vos données grâce à nos solutions."
  },
  {
    icon: bellIcon,
    title: "Notifications",
    description: "Notifications en temps réel pour une gestion proactive."
  },
  {
    icon: tasksIcon,
    title: "Sections et sous-tâches",
    description: "Gérez efficacement vos projets."
  },
  {
    icon: calendarIcon,
    title: "Suivi des progrès",
    description: "Suivi de l'avancement pour évaluer les performances."
  },
  {
    icon: addGroupIcon,
    title: "Plusieurs assignés",
    description: "Travaillez en collaboration pour une productivité optimale."
  },
  {
    icon: headsetIcon,
    title: "Support 24/7",
    description: "Bénéficiez d'un soutien permanent avec notre support 24/7."
  },
  {
    icon: shieldIcon,
    title: "Sécurité des données",
    description: "Sécurisez vos données sensibles avec notre expertise."
  }
  // Add more features as needed
];

const Features = () => {
  const containerRef = useRef(null);
  const parallaxElRef = useRef([]);

  useParallaxHooks(containerRef, parallaxElRef, {
    scrollTrigger: {
      trigger: '.gsap',
      start: '+=450 bottom'
    }
  });

  return (
    <section className="container my-5">
      <h2 className="h1 text-center pb-4 pb-lg-5">
        Restez organisé et connecté
      </h2>
      <div className="row">
        {featuresData.map((feature, index) => (
          <div
            className="col-lg-3 col-md-4 col-sm-6 text-center pb-md-2 mb-3 mb-lg-4"
            key={index}
          >
            <div className="d-inline-block bg-secondary rounded-circle p-3 mb-4">
              <img src={feature.icon} width={32} height={50} alt="Icon" />
            </div>
            <h3 className="h5 pb-1 mb-2">{feature.title}</h3>
            <p className="fs-sm">{feature.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Features;
