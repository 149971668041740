import React from "react";
import mastercard from "../../assets/img/account/mastercard.webp";
import visa from "../../assets/img/account/visa.webp";
import bitcoin from "../../assets/img/account/bitcoin.webp";

const PaiementDetails = () => {
  return (
    <div className="col-md-8 offset-lg-1 pb-5 mb-2 mb-lg-4 pt-md-5 mt-n3 mt-md-0">
      <div className="ps-md-3 ps-lg-0 mt-md-2 pt-md-4 pb-md-2">
        <h1 className="h2 pt-xl-1 mb-3 pb-2 pb-lg-3">Détails de paiement</h1>
        <h2 className="h5 text-primary mb-4">
          Vos modes de paiement enregistrés
        </h2>
        {/* Item */}
        <div
          className="card d-sm-flex flex-sm-row align-items-sm-center justify-content-between border-0 shadow-sm p-3 p-md-4 mb-4">
          <div className="d-flex align-items-center pe-sm-3">
            <img src={mastercard} width={84} alt="Mastercard" />
            <div className="ps-3 ps-sm-4">
              <h6 className="mb-2">
                **** **** **** 4298{" "}
                <span className="badge bg-success ms-1">Principal</span>
              </h6>
              <div className="fs-sm">Expiration 09/26</div>
            </div>
          </div>
          <div className="d-flex justify-content-end pt-3 pt-sm-0">
            <button
              type="button"
              className="btn btn-outline-secondary px-3 px-xl-4 me-3"
            >
              <i className="bx bx-edit fs-xl me-lg-1 me-xl-2" />
              <span className="d-none d-lg-inline">Modifier</span>
            </button>
            <button
              type="button"
              className="btn btn-outline-danger px-3 px-xl-4"
            >
              <i className="bx bx-trash-alt fs-xl me-lg-1 me-xl-2" />
              <span className="d-none d-lg-inline">Supprimer</span>
            </button>
          </div>
        </div>
        {/* Item */}
        <div
          className="card d-sm-flex flex-sm-row align-items-sm-center justify-content-between border-0 shadow-sm p-3 p-md-4 mb-4">
          <div className="d-flex align-items-center pe-sm-3">
            <img src={visa} width={84} alt="Visa" />
            <div className="ps-3 ps-sm-4">
              <h6 className="mb-2">**** **** **** 9276</h6>
              <div className="fs-sm">Expiration 11/25</div>
            </div>
          </div>
          <div className="d-flex justify-content-end pt-3 pt-sm-0">
            <button
              type="button"
              className="btn btn-outline-secondary px-3 px-xl-4 me-3"
            >
              <i className="bx bx-edit fs-xl me-lg-1 me-xl-2" />
              <span className="d-none d-lg-inline">Modifier</span>
            </button>
            <button
              type="button"
              className="btn btn-outline-danger px-3 px-xl-4"
            >
              <i className="bx bx-trash-alt fs-xl me-lg-1 me-xl-2" />
              <span className="d-none d-lg-inline">Supprimer</span>
            </button>
          </div>
        </div>
        {/* Item */}
        <div
          className="card d-sm-flex flex-sm-row align-items-sm-center justify-content-between border-0 shadow-sm p-3 p-md-4 mb-4">
          <div className="d-flex align-items-center pe-sm-3">
            <img src={bitcoin} width={84} alt="Bitcoin" />
            <div className="ps-3 ps-sm-4">
              <h6 className="mb-2">Bitcoin (BTC)</h6>
              <div className="fs-sm">•••••••••• UNky</div>
            </div>
          </div>
          <div className="d-flex justify-content-end pt-3 pt-sm-0">
            <button
              type="button"
              className="btn btn-outline-secondary px-3 px-xl-4 me-3"
            >
              <i className="bx bx-edit fs-xl me-lg-1 me-xl-2" />
              <span className="d-none d-lg-inline">Modifier</span>
            </button>
            <button
              type="button"
              className="btn btn-outline-danger px-3 px-xl-4"
            >
              <i className="bx bx-trash-alt fs-xl me-lg-1 me-xl-2" />
              <span className="d-none d-lg-inline">Supprimer</span>
            </button>
          </div>
        </div>
        <div className="py-2">
          <button type="button" className="btn btn-primary ps-4">
            <i className="bx bx-plus fs-xl me-2" />
            Ajouter un nouveau mode de paiement
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaiementDetails;
