import ESN from "assets/img/ipercom/ressources/portfolio/projet realisé/ESN_34_11zon.webp";
import { useParams } from "react-router-dom";

const WhatTheProject = () => {
  const { name } = useParams();
  return (
    <>
      <section className="container my-5">
        <h1 className="pb-3">
          Site Web
        </h1>
        <h2 className="mb-5">{name}</h2>
        <div className="row">
          <div className="col-md-6 pb-2 pb-md-0 mb-4 mb-md-0 order-1">
            <div className="pe-lg-5 text-center">
              <img src={ESN} className="rounded-3" alt="Image" />
            </div>
          </div>
          <div className="col-md-6 order-2">
            <h2 className="mb-sm-4">Quel était le projet ?</h2>
            <p className="d-md-none d-xl-block pb-2 pb-md-3 mb-3">
              Notre récent projet consistait à concevoir et à développer un site
              web novateur pour répondre aux besoins évolutifs de notre client.
              Nous nous sommes engagés à créer une plateforme en ligne qui non
              seulement captiverait l'audience, mais qui refléterait également
              l'essence et la vision de l'entreprise.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhatTheProject;
