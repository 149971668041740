import React from 'react';
import Pexels from "assets/img/ipercom/services/TelephonySolution/pexels-andrea-piacquadio-914931_14_11zon.webp";
import People from 'assets/img/ipercom/services/TelephonySolution/people.svg';
import PlusC from 'assets/img/ipercom/services/TelephonySolution/bx-plus-circle.svg';
import Bars from 'assets/img/ipercom/services/TelephonySolution/bars-graphic.svg';
import Line from 'assets/img/ipercom/services/TelephonySolution/bx-line-chart-down.svg';
import Crossair from 'assets/img/ipercom/services/TelephonySolution/crossair.svg';

const InnovativeFeatures2 = () => {
  return (
    <>
      <section id="features" className="container">
        <div className="row pb-xl-3">
          <div className="col-lg-7 align-content-center me-5 pe-5">
            <p className="fs-lg text-muted text-center text-md-start">
              Encore mieux, la technologie VoIP offre la possibilité d'intégrer
              les données téléphoniques, marketing et commerciales de
              l'entreprise de manière transparente. Comment ? En connectant
              simplement le logiciel téléphonique au <a href="/services/erp/notre-crm"> CRM</a> (gestion de la relation
              client) et aux autres outils professionnels de l'entreprise. Cette
              intégration fournit des fonctionnalités spécialisées pour une
              gestion et un suivi des appels améliorés, boostant ainsi
              l'efficacité opérationnelle.
            </p>

            <div className="row row-cols-1 row-cols-sm-2 pt-2 pt-sm-3 pt-xl-2">
              <div className="col pb-2 pb-xl-0">
                <div className="d-flex align-items-start pe-xl-3">
                  <div className="d-table bg-secondary rounded-3 flex-shrink-0 p-3 mb-3">
                    <img src={People} alt="Icon" />
                  </div>
                  <div className="ps-4 ps-sm-3 ps-md-4">
                    <h4 className="h5 pb-1 mb-2">Surveillance des contacts</h4>
                  </div>
                </div>
              </div>
              <div className="col pb-2 pb-xl-0">
                <div className="d-flex align-items-start pe-xl-3">
                  <div className="d-table bg-secondary rounded-3 flex-shrink-0 p-3 mb-3">
                    <img src={PlusC} alt="Icon" />
                  </div>
                  <div className="ps-4 ps-sm-3 ps-md-4">
                    <h4 className="h5 pb-1 mb-2">
                      Personnalisation des appels
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col pb-2 pb-xl-0">
                <div className="d-flex align-items-start pe-xl-3">
                  <div className="d-table bg-secondary rounded-3 flex-shrink-0 p-3 mb-3">
                    <img src={Bars} alt="Icon" />
                  </div>
                  <div className="ps-4 ps-sm-3 ps-md-4">
                    <h4 className="h5 pb-1 mb-2">
                      Évalutation des performances d'appels
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col pb-2 pb-xl-0">
                <div className="d-flex align-items-start pe-xl-3">
                  <div className="d-table bg-secondary rounded-3 flex-shrink-0 p-3 mb-3">
                    <img src={Line} alt="Icon" />
                  </div>
                  <div className="ps-4 ps-sm-3 ps-md-4">
                    <h4 className="h5 pb-1 mb-2">
                      Dashboard pour suivre l'activité téléphonique
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col pb-2 pb-xl-0">
                <div className="d-flex align-items-start pe-xl-3">
                  <div className="d-table bg-secondary rounded-3 flex-shrink-0 p-3 mb-3">
                    <img src={Crossair} alt="Icon" />
                  </div>
                  <div className="ps-4 ps-sm-3 ps-md-4">
                    <h4 className="h5 pb-1 mb-2">Surveillance télécom</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 justify-content-end">
            <div className="position-relative">
              <img src={Pexels} alt="Image" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default InnovativeFeatures2;
