import ModalCandidature from 'pages/coming-soon/ModalCandidature';
import ModalDemo from 'pages/coming-soon/ModalDemo';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

type ModalType = "devis" | "demo" | "revendeur" | "candidature";

const cardImageStyle = {
  maxWidth: "100%",
  height: "auto"
};

const cardStyle = {
  maxWidth: "285px",
  margin: "auto"
};

const Head = () => {

  const [showModal, setShowModal] = useState({
    devis: false,
    demo: false,
    revendeur: false,
    candidature: false,
  });

  const handleShow = (modal: ModalType) =>
    setShowModal({ ...showModal, [modal]: true });
  const handleClose = (modal: ModalType) =>
    setShowModal({ ...showModal, [modal]: false });

  return (
    <>
    <section className="container">
      <div className="row align-items-end gy-3 mb-4 pb-lg-3 pb-1">
        <div className="col-lg-9 col-md-4">
          <h1 className="mb-2 mb-md-0">Carrières</h1>
        </div>
        <div className="col-lg-3 col-sm-6">
        <Button
                  variant="primary"
                  className="btn btn-primary btn-lg shadow-primary mt-2"
                  onClick={() => handleShow("candidature")}
                >
                  Candidature spontanée
                </Button>
        </div>
      </div>
      <ModalCandidature showModal={showModal.candidature} handleClose={() => handleClose("candidature")} />

    </section>
    </>
  );
};

export default Head;
