import React from "react";

const DemandeDeDémo = () => {
  return (
    <>
      {/* Liens + Formulaire de demande de démo */}
      <section className="position-relative bg-secondary">
        <div className="container position-relative zindex-2">
          {/* Fil d'Ariane */}
          <nav className="pt-lg-4 pb-3 mb-2 mb-sm-3" aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <a href="index.html">
                  <i className="bx bx-home-alt fs-lg me-1" />
                  Accueil
                </a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Demande de démo
              </li>
            </ol>
          </nav>
          <div className="row">
            {/* Formulaire de demande de démo */}
            <div className="col-xl-8 offset-xl-2">
              <div className="card border-light shadow-lg py-3 p-sm-4 p-md-5">
                <div
                  className="bg-dark position-absolute top-0 start-0 w-100 h-100 rounded-3 d-none d-dark-mode-block" />
                <div className="card-body position-relative zindex-2">
                  <h2 className="card-title pb-3 mb-4">Demande de démo</h2>
                  <form className="row g-4 needs-validation">
                    <div className="col-12">
                      <label htmlFor="fn" className="form-label fs-base">
                        Prénom
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="fn"
                        required
                      />
                      <div className="invalid-feedback">
                        Veuillez saisir votre prénom !
                      </div>
                    </div>
                    <div className="col-12">
                      <label htmlFor="ln" className="form-label fs-base">
                        Nom de famille
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="ln"
                        required
                      />
                      <div className="invalid-feedback">
                        Veuillez saisir votre nom de famille !
                      </div>
                    </div>
                    <div className="col-12">
                      <label htmlFor="email" className="form-label fs-base">
                        Adresse e-mail
                      </label>
                      <input
                        type="email"
                        className="form-control form-control-lg"
                        id="email"
                        required
                      />
                      <div className="invalid-feedback">
                        Veuillez fournir une adresse e-mail valide !
                      </div>
                    </div>

                    <div className="col-12">
                      <label htmlFor="need" className="form-label fs-base">
                        Produit/Service
                      </label>
                      <select
                        className="form-select form-select-lg"
                        id="need"
                        required
                      >
                        <option value="">Choisissez un produit/service</option>
                        <option value="Produit A">Produit A</option>
                        <option value="Produit B">Produit B</option>
                        <option value="Service A">Service A</option>
                        <option value="Service B">Service B</option>
                      </select>
                      <div className="invalid-feedback">
                        Veuillez choisir un produit/service dans la liste !
                      </div>
                    </div>
                    <div className="col-12">
                      <label htmlFor="comment" className="form-label fs-base">
                        Détails supplémentaires
                      </label>
                      <textarea
                        id="comment"
                        name="comment"
                        rows={5}
                        cols={33}
                        className="form-control form-control-lg"
                      />
                    </div>
                    <div className="col-12 pt-2 pt-sm-3">
                      <button
                        type="submit"
                        className="btn btn-lg btn-primary w-100 w-sm-auto"
                      >
                        Envoyer
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="position-absolute bottom-0 start-0 w-100 bg-light"
          style={{ height: "8rem" }}
        />
      </section>

      {/* Emplacements */}
      <section className="container py-2 py-lg-4 py-xl-5 mb-2 mb-md-3">
        <div className="row py-5">
          <div className="col-lg-6 mb-5 mb-lg-0">
            <div className="d-flex flex-column h-100 shadow-sm rounded-3 overflow-hidden">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2827.3594260692607!2d-0.4146552234184406!3d44.875333972535316!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd5530f62c4ca3d5%3A0x589b3fdecca9a3fb!2s1%20Rue%20Jean%20Walton%2C%2033450%20Montussan!5e0!3m2!1sfr!2sfr!4v1719582423720!5m2!1sfr!2sfr"
                width={600}
                height={450}
                style={{ border: 0 }}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </div>
          </div>
          <div className="col-lg-5 offset-lg-1">
            <h2 className="h4 mb-4">Coordonnées</h2>
            <ul className="list-unstyled pb-2 pb-lg-0 mb-4 mb-lg-5">
              <li className="d-flex pb-1 mb-2">
                <i
                  className="bx bx-map text-primary fs-xl me-2"
                  style={{ marginTop: ".125rem" }}
                />
                1 Rue Jean Walton, 33450, Montussan
              </li>
              <li className="d-flex pb-1 mb-2">
                <i
                  className="bx bx-phone-call text-primary fs-xl me-2"
                  style={{ marginTop: ".125rem" }}
                />
                05 18 22 22 00
              </li>
              <li className="d-flex">
                <i
                  className="bx bx-time-five text-primary fs-xl me-2"
                  style={{ marginTop: ".125rem" }}
                />
                <div>
                  <strong className="text-nav">Lun - Ven :</strong> 8:00 - 18:00
                </div>
              </li>
            </ul>
            <div className="d-flex pt-1 pt-md-3 pt-xl-4">
              <a
                href="#"
                className="btn btn-icon btn-secondary btn-facebook me-3"
                aria-label="Facebook"
              >
                <i className="bx bxl-facebook" />
              </a>
              <a
                href="#"
                className="btn btn-icon btn-secondary btn-instagram me-3"
                aria-label="Instagram"
              >
                <i className="bx bxl-instagram" />
              </a>
              <a
                href="#"
                className="btn btn-icon btn-secondary btn-twitter me-3"
                aria-label="Twitter"
              >
                <i className="bx bxl-twitter" />
              </a>
              <a
                href="#"
                className="btn btn-icon btn-secondary btn-youtube"
                aria-label="YouTube"
              >
                <i className="bx bxl-youtube" />
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DemandeDeDémo;
