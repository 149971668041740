import BusinessManagement from "components/modules/services/proposed-services/pos/BusinessManagement";
import ClientTalk from "components/modules/showcase/ClientTalk";
import Functionality from "components/modules/services/proposed-services/pos/Functionality";
import Logiciel from "components/modules/services/proposed-services/pos/Logiciel";
import Partners from "components/modules/showcase/Partners";
import SoftwareTypes from "components/modules/services/proposed-services/pos/SoftwareTypes";
import WhyChooseThis from "components/modules/services/proposed-services/pos/WhyChooseThis";
import React from "react";
import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";

const SoftwareAppPOS = () => {
  return (
    <>
      <Breadcrumbs />
      <Logiciel />
      <BusinessManagement />
      <Functionality />
      <SoftwareTypes />
      <WhyChooseThis />
      <ClientTalk />
      <Partners />
    </>
  );
};

export default SoftwareAppPOS;
