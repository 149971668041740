import React from "react";

const RequestAQuoteIperCloud = () => {
  return (
    <>
      {/* Liens + Formulaire de demande de devis */}
      <section className="position-relative bg-secondary ">
        <div className="container position-relative zindex-2 ">
          {/* Fil d'Ariane */}
          <nav className="pt-lg-4 pb-3 mb-2 mb-sm-3" aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <a href="http://82.223.216.1:2025/">
                  <i className="bx bx-home-alt fs-lg me-1" />
                  Accueil
                </a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Demande de devis
              </li>
            </ol>
          </nav>
          <div className="row">
            {/* Formulaire de demande de devis */}
            <div className="col-xl-8 offset-xl-2">
              <div className="card border-light shadow-lg py-3 p-sm-4 p-md-5">
                <div
                  className="bg-dark position-absolute top-0 start-0 w-100 h-100 rounded-3 d-none d-dark-mode-block" />
                <div className="card-body position-relative zindex-2">
                  <h2 className="card-title pb-3 mb-4">Demande de devis</h2>
                  <form className="row g-4 needs-validation">
                    <div className="col-12">
                      <label htmlFor="fn" className="form-label fs-base">
                        Prénom
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="fn"
                        required
                      />
                      <div className="invalid-feedback">
                        Veuillez entrer votre prénom !
                      </div>
                    </div>
                    <div className="col-12">
                      <label htmlFor="ln" className="form-label fs-base">
                        Nom
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="ln"
                        required
                      />
                      <div className="invalid-feedback">
                        Veuillez entrer votre nom !
                      </div>
                    </div>
                    <div className="col-12">
                      <label htmlFor="email" className="form-label fs-base">
                        Adresse e-mail
                      </label>
                      <input
                        type="email"
                        className="form-control form-control-lg"
                        id="email"
                        required
                      />
                      <div className="invalid-feedback">
                        Veuillez fournir une adresse e-mail valide !
                      </div>
                    </div>

                    <div className="col-12">
                      <label htmlFor="fn" className="form-label fs-base">
                        Société
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="fn"
                        required
                      />
                      <div className="invalid-feedback">
                        Veuillez entrer votre nom de société !
                      </div>
                    </div>

                    <div className="col-12">
                      <label htmlFor="fn" className="form-label fs-base">
                        Siret
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="fn"
                        required
                      />
                      <div className="invalid-feedback">
                        Veuillez entrer votre siret !
                      </div>
                    </div>

                    <div className="col-12">
                      <label htmlFor="fn" className="form-label fs-base">
                        Mobile
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="fn"
                        required
                      />
                      <div className="invalid-feedback">
                        Veuillez entrer votre numéro mobile !
                      </div>
                    </div>

                    <div className="col-12">
                      <label htmlFor="need" className="form-label fs-base">
                        Produit/Service
                      </label>
                      <select
                        className="form-select form-select-lg"
                        id="need"
                        required
                      >
                        <option value="">Choisissez un produit/service</option>
                        <option value="#">solution iper-cloud 10G</option>
                        <option value="#">solution iper-cloud 20G</option>
                        <option value="#">solution iper-cloud 30G</option>
                        <option value="#">solution iper-cloud 40G</option>
                        <option value="#">solution iper-cloud 50G</option>

                      </select>
                      <div className="invalid-feedback">
                        Veuillez choisir un produit/service dans la liste !
                      </div>
                    </div>
                    <div className="col-12">
                      <label htmlFor="comment" className="form-label fs-base">
                        Détails supplémentaires
                      </label>
                      <textarea
                        id="comment"
                        name="comment"
                        rows={5}
                        cols={33}
                        className="form-control form-control-lg"
                      />
                    </div>
                    <div className="col-12 pt-2 pt-sm-3">
                      <button
                        type="submit"
                        className="btn btn-lg btn-primary w-100 w-sm-auto"
                        disabled>
                        Soumettre
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="position-absolute bottom-0 start-0 w-100 bg-light"
          style={{ height: "8rem" }}
        />
      </section>
    </>
  );
};

export default RequestAQuoteIperCloud;