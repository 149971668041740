import React from "react";
import code from "assets/img/ipercom/services/Solution proposes/integration logiciel/code2_17_11zon.webp";

const ApiCreation = () => {
  return (
    <>
      <section className="container mt-5">
        <div className="row justify-content-between">
          <div className="col-md-7 pb-2 pb-md-0 mb-4 mb-md-0 order-2">
            <div className="pe-lg-5 text-center">
              <img src={code} className="rounded-3" alt="Image" />
            </div>
          </div>
          <div className="col-md-4 align-content-center order-1">
            <h2 className="h2 mb-sm-4">Création d’APIs</h2>
            <p className="d-md-none d-xl-block pb-2 pb-md-3 mb-3">
              Dynamisez votre système informatique avec notre service de
              création d'API sur mesure. Nous concevons des interfaces robustes
              et flexibles, permettant à vos applications de communiquer de
              manière efficace et sécurisée. Simplifiez l'intégration de
              nouvelles fonctionnalités et optimisez la performance de votre
              infrastructure grâce à nos solutions personnalisées en matière de
              développement d'API.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default ApiCreation;
