import React from "react";
import question from "assets/img/ipercom/services/Solution proposes/conception logiciel/question.svg";
import scapel from "assets/img/ipercom/services/Solution proposes/conception logiciel/scalpel.png";
import route from "assets/img/ipercom/services/Solution proposes/conception logiciel/route_21_11zon.webp";
import lungs from "assets/img/ipercom/services/Solution proposes/conception logiciel/lungs.png";
import graph from "assets/img/ipercom/services/Solution proposes/conception logiciel/graph.png";
import InfoBouton from "components/modules/showcase/InfoBouton";

const Approach = () => {
  return (
    <>
      <section
        style={{ width: "80%", margin: "auto" }}
        className="pb-5 pt-5 mt-5"
      >
        <div className="row">
          <div className="col-lg-4 text-center text-lg-start pb-3 pb-lg-0 mb-4 mb-lg-0 ">
            <h2 className="h2 mb-lg-4 col-lg-8">
              Approche de conception sur mesure
            </h2>
            <p className="pb-4 mb-0 mb-lg-3 col-lg-10">
              Notre approche de conception sur mesure pour les logiciels
              implique une analyse approfondie de vos besoins spécifiques,
              permettant ainsi de créer des solutions personnalisées et
              évolutives.
            </p>
            <InfoBouton />
          </div>
          <div className="col-lg-8 ">
            <div className="row">
              <div className="col border-start">
                <div className="card card-hover bg-secondary border-0 mb-4">
                  <div className="card-body d-flex align-items-start">
                    <div className="flex-shrink-0 bg-light rounded-3 p-3">
                      <img src={question} width={28} alt="Icon" />
                    </div>
                    <div className="ps-4">
                      <h3 className="h5 pb-2 mb-1">
                        Répond aux besoins spécifiques
                      </h3>
                      <p className="pb-2 mb-1">
                        Les logiciels sur mesure sont conçus pour répondre
                        précisément aux besoins et aux exigences uniques de
                        chaque client, ce qui garantit une solution parfaitement
                        adaptée à leurs activités.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card card-hover bg-secondary border-0 mb-4">
                  <div className="card-body d-flex align-items-start">
                    <div className="flex-shrink-0 bg-light rounded-3 p-3">
                      <img src={scapel} width={28} alt="Icon" />
                    </div>
                    <div className="ps-4">
                      <h3 className="h5 pb-2 mb-1">
                        Flexibilité et évolutivité
                      </h3>
                      <p className="pb-2 mb-1">
                        Les logiciels sur mesure sont conçus pour être flexibles
                        et évolutifs, ce qui permet de les adapter facilement
                        aux changements futurs dans les besoins de l'entreprise
                        ou dans l'environnement technologique.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card card-hover bg-secondary border-0 mb-4">
                  <div className="card-body d-flex align-items-start">
                    <div className="flex-shrink-0 bg-light rounded-3 p-3">
                      <img src={route} width={28} alt="Icon" />
                    </div>
                    <div className="ps-4">
                      <h3 className="h5 pb-2 mb-1">
                        {" "}
                        Accompagnement tout au long
                      </h3>
                      <p className="pb-2 mb-1">
                        Les entreprises bénéficient d'un accompagnement
                        personnalisé tout au long du processus de conception et
                        de développement, ce qui garantit une solution
                        parfaitement adaptée à leurs besoins.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col align-content-center">
                <div className="card card-hover bg-secondary border-0 mb-4">
                  <div className="card-body d-flex align-items-start">
                    <div className="flex-shrink-0 bg-light rounded-3 p-3">
                      <img src={graph} width={28} alt="Icon" />
                    </div>
                    <div className="ps-4">
                      <h3 className="h5 pb-2 mb-1">
                        Optimisation de la productivité
                      </h3>
                      <p className="pb-2 mb-1">
                        En <a href="/services/services-proposes/integration-de-solutions-logicielles">intégrant</a> les
                        fonctionnalités nécessaires et en
                        éliminant les éléments superflus, les logiciels sur
                        mesure permettent d'optimiser les processus et
                        d'augmenter la productivité des utilisateurs.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card card-hover bg-secondary border-0 mb-4">
                  <div className="card-body d-flex align-items-start">
                    <div className="flex-shrink-0 bg-light rounded-3 p-3">
                      <img src={lungs} width={28} alt="Icon" />
                    </div>
                    <div className="ps-4">
                      <h3 className="h5 pb-2 mb-1">
                        Expérience utilisateur optimale
                      </h3>
                      <p className="pb-2 mb-1">
                        En prenant en compte les besoins et les préférences des
                        utilisateurs finaux dès la conception, les logiciels sur
                        mesure offrent une expérience utilisateur optimale, ce
                        qui favorise l'adoption et la satisfaction.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Approach;
