import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import axios from "axios";

interface ModalDevisProps {
  showModal: boolean;
  handleClose: () => void;
}

const ModalDevis: React.FC<ModalDevisProps> = ({ showModal, handleClose }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    siretNumber: "",
    companyName: "",
    idProductService: "",
    additionalDetails: ""
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);

    const requestData = {
      idFormResquestQuote: 0,
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      siretNumber: formData.siretNumber,
      companyName: formData.companyName,
      idProductService: parseInt(formData.idProductService), // Ensure it's an integer
      additionalDetails: formData.additionalDetails
    };

    try {
      await axios.post("https://rct-backend.ipercom.io/api/CSoonQuote", requestData, {
        headers: {
          "Content-Type": "application/json",
          "Accept": "text/plain"
        }
      });
      setIsSubmitted(true);
    } catch (error) {
      setError("Erreur lors de l'envoi du devis. Veuillez réessayer.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleModalClose = () => {
    setIsSubmitted(false);
    handleClose();
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      siretNumber: "",
      companyName: "",
      idProductService: "",
      additionalDetails: ""
    });
  };

  return (
    <Modal show={showModal} onHide={handleModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>Demande de devis</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <div className="alert alert-danger">{error}</div>}
        {!isSubmitted ? (
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="fn-devis">Prénom</Form.Label>
              <Form.Control
                type="text"
                id="fn-devis"
                required
                value={formData.firstName}
                onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
              />
              <Form.Control.Feedback type="invalid">Veuillez entrer votre prénom !</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="ln-devis">Nom</Form.Label>
              <Form.Control
                type="text"
                id="ln-devis"
                required
                value={formData.lastName}
                onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
              />
              <Form.Control.Feedback type="invalid">Veuillez entrer votre nom !</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="email-devis">Adresse e-mail</Form.Label>
              <Form.Control
                type="email"
                id="email-devis"
                required
                value={formData.email}
                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              />
              <Form.Control.Feedback type="invalid">Veuillez fournir une adresse e-mail valide !</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="siret-number-devis">Numéro de siret</Form.Label>
              <Form.Control
                type="text"
                id="siret-number-devis"
                required
                value={formData.siretNumber}
                onChange={(e) => setFormData({ ...formData, siretNumber: e.target.value })}
              />
              <Form.Control.Feedback type="invalid">Veuillez entrer votre numéro de siret !</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="company-name-devis">Nom de société et fonction</Form.Label>
              <Form.Control
                type="text"
                id="company-name-devis"
                required
                value={formData.companyName}
                onChange={(e) => setFormData({ ...formData, companyName: e.target.value })}
              />
              <Form.Control.Feedback type="invalid">Veuillez entrer le nom de votre société !</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="need-devis">Produit/Service</Form.Label>
              <Form.Select
                id="need-devis"
                required
                value={formData.idProductService}
                onChange={(e) => setFormData({ ...formData, idProductService: e.target.value })}
              >
                <option value="">Choisissez un produit/service</option>
                <option value="1">Solution de téléphone pour entreprise iper-X</option>
                <option value="2">ERP ipercom (CRM, Facturation, Devis)</option>
                <option value="3">Logiciel de caisse ipos</option>
                <option value="4">Stockage colaboratif iper-cloud</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">Veuillez choisir un produit/service dans la liste
                !</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="comment-devis">Détails supplémentaires</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                id="comment-devis"
                value={formData.additionalDetails}
                onChange={(e) => setFormData({ ...formData, additionalDetails: e.target.value })}
              />
            </Form.Group>
            <Button type="submit" variant="primary" size="lg" className="w-100" disabled={isSubmitting}>
              {isSubmitting ? "En cours..." : "Soumettre"}
            </Button>
          </Form>
        ) : (
          <div className="text-center">
            <h4>Merci pour votre demande de devis!</h4>
            <p>Votre demande a été envoyée avec succès. Nous vous contacterons sous peu.</p>
            <Button variant="primary" onClick={handleModalClose}>
              Fermer
            </Button>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ModalDevis;
