import React from "react";
import Phone from 'assets/img/ipercom/services/TelephonySolution/woman-with-a-phone.svg';

const DefaultsIPBX = () => {
  return (
    <>
      <section className="container mt-5 pb-lg-5 pb-md-4 pb-3">
        <div className="row">
          <div className="align-content-center col-md-6">
            <h2 className="pb-2 pb-lg-3">Quels défauts présente un IPBX ?</h2>
            <p style={{ paddingBottom: "2rem", marginBottom: "3rem" }}>
              Malgré ses atouts évidents, un IPBX comporte quelques
              inconvénients.
              <br />
              Cependant, il peut évoluer vers une solution totalement
              dématérialisée pour plus de flexibilité et de mobilité.
            </p>
          </div>
          <div className="col-md-6 pb-2 pb-md-0 mb-4 mb-md-0">
            <div style={{ paddingRight: "1.25rem" }}>
              <img
                src={Phone}
                className="rounded-3"
                alt="Image"
                style={{ display: "block", maxWidth: "100%", height: "auto" }}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DefaultsIPBX;
