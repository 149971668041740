import ModalCandidature from 'pages/coming-soon/ModalCandidature';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

type ModalType = "devis" | "demo" | "revendeur" | "candidature";

const DescriptionFullStack = () => {

  const [showModal, setShowModal] = useState({
    devis: false,
    demo: false,
    revendeur: false,
    candidature: false,
  });

  const handleShow = (modal: ModalType) =>
    setShowModal({ ...showModal, [modal]: true });
  const handleClose = (modal: ModalType) =>
    setShowModal({ ...showModal, [modal]: false });

  return (
    <section className="container pt-5 mt-2 mt-lg-4 mt-xl-5">
      <div className="row">
        {/* Sidebar (Course summary) */}
        <aside className="col-lg-4 col-md-5 offset-xl-1 order-md-2 mb-5">
          <div style={{ marginTop: "-96px" }} />
          <div className="pt-5">
            <div className="pt-5 mt-md-3">
              <div className="card shadow-sm p-sm-3">
                <div className="card-body">
                  <h2 className="mb-4 h3">Critères de candidature</h2>
                  <ul className="list-unstyled">
                    <li>
                      <p>CV</p>
                    </li>
                    <li>
                      <p>Lettre de motivation</p>
                    </li>
                    <li>
                      <p>Book</p>
                    </li>
                    <li>
                      <p>Entretiens</p>
                    </li>
                  </ul>
                  <Button
                  variant="primary"
                  className="btn btn-primary btn-lg shadow-primary mt-2"
                  onClick={() => handleShow("candidature")}
                >
                  Postuler
                </Button>
                </div>
              </div>
            </div>
          </div>
        </aside>
        {/* Content */}
        <div className="col-xl-7 col-lg-8 col-md-7 order-md-1 mb-5">
          <h2 className="pb-md-2 pb-lg-3">Missions :</h2>
          <ul className="mb-4 pb-2 ps-4">
            <li>
              Conception et développement de nouvelles fonctionnalités pour nos
              applications web et mobiles.
            </li>
            <li>
              Maintenance et optimisation des applications existantes pour
              améliorer les performances et l'expérience utilisateur.
            </li>
            <li>
              Collaboration étroite avec l'équipe de design pour transformer les
              maquettes en applications fonctionnelles et réactives.
            </li>
            <li>
              Participation à la définition de l'architecture technique et à la
              sélection des technologies à utiliser.
            </li>
            <li>
              Assurer une veille technologique constante pour proposer des
              solutions innovantes.
            </li>
          </ul>

          <h3 className="pb-md-2 pb-lg-3">Profil recherché :</h3>
          <ul className="mb-4 pb-2 ps-4">
            <li>Formation supérieure en informatique ou équivalent.</li>
            <li>
              Expérience solide en développement full stack avec une bonne
              connaissance des langages tels que JavaScript, HTML, CSS pour le
              front-end et Node.js, Python ou Java pour le back-end.
            </li>
            <li>
              Maîtrise des frameworks et bibliothèques courants (React, Angular,
              Vue.js pour le front-end ; Express, Django, Spring pour le
              back-end).
            </li>
            <li>Bonne compréhension des bases de données SQL et NoSQL.</li>
            <li>
              Expérience avec les systèmes de versionnage de code (Git) et les
              méthodologies Agile.
            </li>
            <li>
              Capacité à travailler en équipe et à communiquer efficacement
              aussi bien techniquement que non techniquement.
            </li>
          </ul>

          <h3>Expérience :</h3>
          <ul className="mb-4 pb-2 ps-4">
            <li>
              Au moins 3 ans d'expérience dans un rôle Full Stack ou similaire.
            </li>
          </ul>
          <h3>Qualités personnelles :</h3>
          <ul className="mb-4 pb-2 ps-4">
            <li>
              Forte capacité à travailler en équipe et à communiquer avec des
              interlocuteurs variés (développeurs, chefs de projets, etc.).
            </li>
            <li>Proactivité et capacité à prendre des initiatives.</li>
            <li>
              Rigueur, sens de l'organisation et capacité à gérer plusieurs
              projets simultanément.
            </li>
            <li>
              Curiosité technologique et volonté d'apprendre continuellement.
            </li>
          </ul>
          <h2>Pourquoi nous rejoindre ?</h2>
          <ul className="list-unstyled mb-5">
            <li>
              <i className="bx bx-check-circle text-primary fs-xl me-2" />
              Environnement de travail stimulant au sein d'une équipe
              talentueuse et passionnée.
            </li>
            <li>
              <i className="bx bx-check-circle text-primary fs-xl me-2" />
              Projets variés et challengeants dans différents secteurs
              d'activité.
            </li>
            <li>
              <i className="bx bx-check-circle text-primary fs-xl me-2" />
              Opportunités de formation continue et de développement
              professionnel.
            </li>
            <li>
              <i className="bx bx-check-circle text-primary fs-xl me-2" />
              Avantages sociaux compétitifs et possibilité de télétravail.
            </li>
          </ul>
        </div>
      </div>
      <ModalCandidature showModal={showModal.candidature} handleClose={() => handleClose("candidature")} />

    </section>
  );
};

export default DescriptionFullStack;
