import { useEffect, useState } from "react";
import JohnDoe from "../../assets/img/avatar/01.jpg";
import Details from "./Details";
import Collection from "./Collection";
import Security from "./Security";
import Notifications from "./Notifications";
import Messages from "./Messages";
import RegisterElement from "./RegisterElement";
import PaiementDetails from "./PaiementDetails";

const Dashboard = () => {
  const [selectedComponent, setSelectedComponent] = useState<string>("details");

  const handleItemClick = (component: string) =>
    setSelectedComponent(component);

  const selectedMenuStyle: React.CSSProperties = {
    backgroundColor: "#CD1F90",
    color: "white"
  };

  useEffect(() => {
  }, [selectedComponent]);

  return (
    <section className="container pt-5">
      <div className="row">
        {/* Barre latérale (Informations utilisateur + Menu du compte) */}
        <aside className="col-lg-3 col-md-4 border-end pb-5 mt-n5">
          <div className="position-sticky top-0">
            <div className="text-center pt-5">
              <div className="d-table position-relative mx-auto mt-2 mt-lg-4 pt-5 mb-3">
                <img
                  src={JohnDoe}
                  className="d-block rounded-circle"
                  width={120}
                  alt="John Doe"
                />
                <button
                  type="button"
                  className="btn btn-icon btn-light bg-white btn-sm border rounded-circle shadow-sm position-absolute bottom-0 end-0 mt-4"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  title="Changer l'image"
                  aria-label="Changer l'image"
                >
                  <i className="bx bx-refresh" />
                </button>
              </div>
              <h2 className="h5 mb-1">John Doe</h2>
              <p className="mb-3 pb-3">jonny@email.com</p>
              <button
                type="button"
                className="btn btn-secondary w-100 d-md-none mt-n2 mb-3"
                data-bs-toggle="collapse"
                data-bs-target="#account-menu"
              >
                <i className="bx bxs-user-detail fs-xl me-2" />
                Menu du compte
                <i className="bx bx-chevron-down fs-lg ms-1" />
              </button>
              <div
                id="account-menu"
                className="list-group list-group-flush collapse d-md-block"
              >
                <button
                  onClick={() => handleItemClick("details")}
                  className="list-group-item list-group-item-action d-flex align-items-center "
                  style={
                    selectedComponent === "details" ? selectedMenuStyle : {}
                  }
                >
                  <i className="bx bx-cog fs-xl opacity-60 me-2" />
                  Détails du compte
                </button>
                <button
                  onClick={() => handleItemClick("securite")}
                  className="list-group-item list-group-item-action d-flex align-items-center"
                  style={
                    selectedComponent === "securite" ? selectedMenuStyle : {}
                  }
                >
                  <i className="bx bx-lock-alt fs-xl opacity-60 me-2" />
                  Sécurité
                </button>
                <button
                  onClick={() => handleItemClick("notifications")}
                  className="list-group-item list-group-item-action d-flex align-items-center "
                  style={
                    selectedComponent === "notifications"
                      ? selectedMenuStyle
                      : {}
                  }
                >
                  <i className="bx bx-bell fs-xl opacity-60 me-2" />
                  Notifications
                </button>
                <button
                  onClick={() => handleItemClick("messages")}
                  className="list-group-item list-group-item-action d-flex align-items-center "
                  style={
                    selectedComponent === "messages" ? selectedMenuStyle : {}
                  }
                >
                  <i className="bx bx-chat fs-xl opacity-60 me-2" />
                  Messages
                </button>
                <button
                  onClick={() => handleItemClick("sauvegardes")}
                  className="list-group-item list-group-item-action d-flex align-items-center "
                  style={
                    selectedComponent === "sauvegardes" ? selectedMenuStyle : {}
                  }
                >
                  <i className="bx bx-bookmark fs-xl opacity-60 me-2" />
                  Éléments enregistrés
                </button>
                <button
                  onClick={() => handleItemClick("collections")}
                  className="list-group-item list-group-item-action d-flex align-items-center "
                  style={
                    selectedComponent === "collections" ? selectedMenuStyle : {}
                  }
                >
                  <i className="bx bx-collection fs-xl opacity-60 me-2" />
                  Mes collections
                </button>
                <button
                  onClick={() => handleItemClick("paiement")}
                  className="list-group-item list-group-item-action d-flex align-items-center "
                  style={
                    selectedComponent === "paiement" ? selectedMenuStyle : {}
                  }
                >
                  <i className="bx bx-credit-card-front fs-xl opacity-60 me-2" />
                  Détails de paiement
                </button>
                <button
                  //  onClick={() => disconnect() }
                  className="list-group-item list-group-item-action d-flex align-items-center "
                >
                  <i className="bx bx-log-out fs-xl opacity-60 me-2" />
                  Déconnexion
                </button>
              </div>
            </div>
          </div>
        </aside>
        {/* Détails du compte */}

        {selectedComponent === "details" && <Details />}
        {selectedComponent === "securite" && <Security />}
        {selectedComponent === "notifications" && <Notifications />}
        {selectedComponent === "messages" && <Messages />}
        {selectedComponent === "sauvegardes" && <RegisterElement />}
        {selectedComponent === "collections" && <Collection />}
        {selectedComponent === "paiement" && <PaiementDetails />}
      </div>
    </section>
  );
};

export default Dashboard;
