import ModalDevis from "pages/coming-soon/ModalDevis";
import React, { useState } from "react";
import { Button } from "react-bootstrap";

type ModalType = "devis" | "demo" | "revendeur";

const Description = () => {
  const [showModal, setShowModal] = useState({
    devis: false,
    demo: false,
    revendeur: false
  });

  const handleShow = (modal: ModalType) =>
    setShowModal({ ...showModal, [modal]: true });
  const handleClose = (modal: ModalType) =>
    setShowModal({ ...showModal, [modal]: false });


  return (
    <section className="container my-">
      <div className="row">
        {/* Sidebar (Course summary) */}
        <aside className="col-lg-4 col-md-5 offset-xl-1 order-md-2 mb-5">
          <div style={{ marginTop: "-96px" }} />
          <div className="pt-5">
            <div className="pt-5 mt-md-3">
              <div className="card shadow-sm p-sm-3">
                <div className="card-body">
                  <h2 className="mb-4">Ce cours comprend :</h2>
                  <ul className="list-unstyled pb-3">
                    <li className=" mb-2">
                      <i className="bx bx-file fs-xl text-muted me-2 pe-1" />
                      18 documents
                    </li>
                    <li className=" mb-2">
                      <i className="bx bx-download fs-xl text-muted me-2 pe-1" />
                      25 ressources téléchargeables
                    </li>
                    <li className=" mb-2">
                      <i className="bx bx-trophy fs-xl text-muted me-2 pe-1" />
                      Certificat d'achèvement
                    </li>
                  </ul>
                  <div className="h3 mb-0">1000€/Jour hors frais*</div>
  
                  <Button
                  variant="primary"
                  className="btn btn-primary btn-lg shadow-primary mt-2"
                  onClick={() => handleShow("devis")}
                >
                  Demander un devis
                </Button>
                </div>
              </div>
              <p className="text-muted">
                *Hors frais de déplacement pour les formations en présentielle{" "}
              </p>
            </div>
          </div>
        </aside>
        {/* Content */}
        <div className="col-xl-7 col-lg-8 col-md-7 order-md-1 mt-4">
          <h2>Description de la formation</h2>
          <p className="pb-2 mb-3">
            Cette formation a pour but de doter les participants des compétences
            nécessaires pour comprendre, promouvoir et vendre efficacement des
            systèmes  <a href="/services/erp/qu-est-ce-qu-un-erp">ERP</a>. En se concentrant sur l'identification des besoins des
            clients, la présentation de solutions adaptées, les techniques de
            vente et de négociation, ainsi que le suivi et le service
            après-vente, cette formation vise à renforcer les capacités des
            professionnels du commerce dans le domaine des ERP.
          </p>
          <h3 className="mb-4">Ce que vous apprendrez</h3>
          <ul className="list-unstyled mb-5">
            <li className=" mb-2">
              <i className="bx bx-check-circle text-primary fs-xl me-2" />
              Apprendre les concepts fondamentaux des systèmes ERP (Enterprise
              Resource Planning) ainsi que les principes de base du commerce.
            </li>
            <li className=" mb-2">
              <i className="bx bx-check-circle text-primary fs-xl me-2" />
              Identifier les besoins des clients en matière de systèmes ERP,
              présenter efficacement les fonctionnalités et avantages des
              solutions proposées, pour conclure des ventes réussies.
            </li>
            <li className=" mb-2">
              <i className="bx bx-check-circle text-primary fs-xl me-2" />
              Développer des compétences en matière de communication, de
              négociation et de gestion des relations clients.
            </li>
            <li className=" mb-2">
              <i className="bx bx-check-circle text-primary fs-xl me-2" />
              Établir des relations durables et fructueuses avec les clients
            </li>
            <li className="mb-2">
              <i className="bx bx-check-circle text-primary fs-xl me-2" />
              Explorer les meilleures pratiques et les astuces pour tirer le meilleur parti de la solution <a href="/services/solution-telephonie/logiciel-de-telephonie-iper-x">iper-X</a> dans votre environnement de
              travail spécifique.
            </li>
          </ul>
          <h2 className="mb-4">Détails du programme</h2>
          <div className="steps steps-sm">
            <div className="step">
              <div className="step-number">
                <div className="step-number-inner">1</div>
              </div>
              <div className="step-body">
                <h3 className="h4 mb-2">
                  Session 1 : Introduction aux Systèmes ERP
                </h3>
                <ul className="mb-0">
                  <li>
                    Présentation des Systèmes ERP : définition, fonctionnalités,
                    et avantages.
                  </li>
                  <li>
                    Compréhension des différents modules et composants d'un
                    système ERP.
                  </li>
                  <li>
                    Étude des tendances actuelles dans le domaine des ERP et de
                    leur impact sur le commerce.
                  </li>
                </ul>
              </div>
            </div>
            <div className="step">
              <div className="step-number">
                <div className="step-number-inner">2</div>
              </div>
              <div className="step-body">
                <h3 className="h4 mb-2">
                  Session 2 : Analyse des Besoins des Clients
                </h3>
                <ul className="mb-0">
                  <li>
                    Techniques pour identifier les besoins des clients en
                    matière de systèmes ERP.
                  </li>
                  <li>
                    Analyse des processus métier et des flux de travail des
                    clients.
                  </li>
                  <li>
                    Pratique de l'écoute active et de la formulation de
                    questions pertinentes pour comprendre les exigences des
                    clients
                  </li>
                </ul>
              </div>
            </div>
            <div className="step">
              <div className="step-number">
                <div className="step-number-inner">3</div>
              </div>
              <div className="step-body">
                <h3 className="h4 mb-2">
                  Session 3 : Présentation de Solutions ERP
                </h3>
                <ul className="mb-0">
                  <li>
                    Présentation des solutions ERP disponibles sur le marché.
                  </li>
                  <li>
                    Comparaison des fonctionnalités et des prix des différents
                    système ERP.
                  </li>
                  <li>
                    Pratique de la démonstration des fonctionnalités clés d'un
                    système ERP et de la personnalisation de la présentation en
                    fonction des besoins spécifiques des clients.
                  </li>
                </ul>
              </div>
            </div>
            <div className="step">
              <div className="step-number">
                <div className="step-number-inner">4</div>
              </div>
              <div className="step-body">
                <h3 className="h4 mb-2">
                  Session 4 : Techniques de Vente et de Négociation
                </h3>
                <ul className="mb-0">
                  <li>
                    Les étapes du processus de vente dans le contexte des
                    systèmes ERP.
                  </li>
                  <li>
                    Développement de compétences en communication persuasive et
                    en argumentation.
                  </li>
                  <li>
                    Pratique des techniques de négociation pour conclure des
                    ventes et obtenir des accords favorables.
                  </li>
                </ul>
              </div>
            </div>
            <div className="step">
              <div className="step-number">
                <div className="step-number-inner">5</div>
              </div>
              <div className="step-body">
                <h3 className="h4 mb-2">
                  Session 5 : Suivi et Service Après-Vente
                </h3>
                <ul className="mb-0">
                  <li>
                    Importance du suivi après-vente pour assurer la satisfaction
                    des clients.
                  </li>
                  <li>
                    Gestion des demandes de support et des problèmes potentiels
                    après l'implémentation d'un système ERP.
                  </li>
                  <li>
                    Techniques de fidélisation des clients et de promotion du
                    bouche-à-oreille positif.
                  </li>
                </ul>
              </div>
            </div>
            <div className="step align-items-center">
              <div className="step-number mb-5 ">
                <div className="step-number-inner">6</div>
              </div>
              <div className="step-body">
                <h3 className="h4 mb-2">
                  Session 6 : Études de Cas et Mises en Situation
                </h3>
                <ul className="mb-0">
                  <li>
                    Études de cas basées sur des scénarios réels de vente et de
                    négociation.
                  </li>
                  <li>
                    Mises en situation pour mettre en pratique les compétences
                    acquises.
                  </li>
                  <li>
                    Feedback et conseils individualisés pour amélioration
                    continue.
                  </li>
                </ul>
              </div>
            </div>
            <div className="step align-items-center">
              <div className="step-number mb-5 ">
                <div className="step-number-inner">7</div>
              </div>
              <div className="step-body">
                <h3 className="h4 mb-2">
                  Session 7 : Évaluation et Certification
                </h3>
                <ul className="mb-0">
                  <li>
                    Évaluation des connaissances acquises tout au long de la
                    formation.
                  </li>
                  <li>
                    Certification de réussite pour les participants ayant
                    satisfait aux critères d'évaluation.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalDevis showModal={showModal.devis} handleClose={() => handleClose("devis")} />

    </section>
  );
};

export default Description;
