import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";
import React from "react";
import BillingsTab from "./BillingsTab";
import BillingsDetails from "./BillingsDetails";

const Billings = () => {
  return (
    <>
      <Breadcrumbs />
      <BillingsDetails />
      <BillingsTab />
    </>
  );
};

export default Billings;
