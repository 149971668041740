import { Row, Col } from "react-bootstrap";
import PaymentCard from "./PaymentCard";
import PaymentSummaryCard from "./PaymentSummaryCard";
import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";

const Payment = () => {
  return (
    <section className=" container my-5">
      <Breadcrumbs />
      <Row className="g-4">
        <Col xl={8}>
          <PaymentCard />
        </Col>
        <Col xl={4} className="align-content-center">
          <PaymentSummaryCard />
        </Col>
      </Row>
    </section>
  );
};

export default Payment;
