import React from "react";
import Appel from "assets/img/ipercom/ressources/portfolio/projet realisé/Appel en cours_6_11zon.webp";

const WhoIsTheClient = () => {
  return (
    <section className="container my-5">
      <div className="row">
        <div className="col-md-6 pb-2 pb-md-0 mb-4 mb-md-0 order-2">
          <div className="pe-lg-5 text-center">
            <img src={Appel} className="rounded-3" alt="Image" style={{ width: "39%" }} />
          </div>
        </div>
        <div className="col-md-6 order-1">
          <h2 className="mb-sm-4">Qui est le client ?</h2>
          <p className="d-md-none d-xl-block pb-2 pb-md-3 mb-3">
            Le client est une entreprise de taille moyenne opérant dans le secteur des services professionnels, avec
            plusieurs bureaux répartis sur le territoire national. L'entreprise cherchait à unifier ses communications
            internes et externes, tout en intégrant son infrastructure téléphonique avec ses systèmes de gestion de la
            relation client (CRM) et autres outils professionnels.
          </p>
        </div>
      </div>
    </section>
  );
};

export default WhoIsTheClient;
