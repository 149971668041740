import Head from "components/modules/services/proposed-services/dev-app-mobiles/Head";
import LastNews from "components/modules/showcase/LastNews";
import OurServices from "components/modules/services/proposed-services/dev-app-mobiles/OurServices";
import Possibility from "components/modules/services/proposed-services/dev-app-mobiles/Possibility";
import SupportedPlatforms from "components/modules/services/proposed-services/dev-app-mobiles/SupportedPlatforms";
import TechLangUsed from "components/modules/services/proposed-services/dev-app-mobiles/TechLangUsed";
import React from "react";
import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";

const AppDevMobile = () => {
  return (
    <>
      <Head />
      <Breadcrumbs />
      <Possibility />
      <OurServices />
      <SupportedPlatforms />
      <TechLangUsed />
      <LastNews />
    </>
  );
};

export default AppDevMobile;
