import React from "react";
import client from "assets/img/ipercom/ressources/portfolio/projet realisé/clienterp_15_11zon.webp";

const WhoIsTheClient = () => {
  return (
    <section className="container my-5">
      <div className="row">
        <div className="col-md-6 pb-2 pb-md-0 mb-4 mb-md-0 order-2">
          <div className="pe-lg-5 text-center">
            <img src={client} className="rounded-3" alt="Image" style={{ width: "56%" }} />
          </div>
        </div>
        <div className="col-md-6 order-1">
          <h2 className="mb-sm-4">Qui est le client ?</h2>
          <p className="d-md-none d-xl-block pb-2 pb-md-3 mb-3">
            Notre client est une entreprise en pleine croissance cherchant à optimiser ses opérations et à améliorer sa
            prise de décision grâce
            à une gestion centralisée et des données cohérentes. Nous proposons à nos clients la plus large gamme de
            services et de solutions
            informatiques de haute qualité, améliorant ainsi leur activité.
          </p>
        </div>
      </div>
    </section>
  );
};

export default WhoIsTheClient;
