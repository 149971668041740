import React from "react";
import computer from "assets/img/ipercom/services/ERP/inventory/computer4_21_11zon.webp";

const PersonalizationInventory = () => {
  return (
    <section className="container pb-sm-1 pb-md-3">
      {/* Row 1 */}
      <div className="row align-items-lg-center pb-5 mb-2 mb-lg-4 mb-xl-5">
        <div className="col-md-6 mb-4 mb-md-0">
          <img src={computer} className="rounded-3" alt="Image" />
        </div>
        <div className="col-md-6">
          <div className="ps-xl-5 ms-md-2 ms-lg-4">
            <h2 className="mb-3 mb-sm-4">
              Personnalisation pour répondre à votre besoin gestion de stock
            </h2>
            <p className="mb-4 mb-lg-5">
              Des fonctionnalités personnalisées pour une gestion optimale de
              vos stocks.
            </p>
            <a
              href="/abonnements"
              className="btn btn-lg btn-primary shadow-primary me-2"
            >
              Démarrez votre projet
            </a>
            <a
              className="btn btn-lg btn-primary shadow-primary"
              href="https://outlook.office.com/bookwithme/user/9e8387330e3644559680de311487211e%40ipercom.com/meetingtype/04c0ddc4-d8b0-4c28-a2fb-1853e564c7ef?anonymous"
            >
              Demander une démo
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PersonalizationInventory;
