import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { AuthProvider, useAuthContext } from "./providers/AuthProvider";
import { useSettingsPanelContext } from "./providers/old/SettingsPanelProvider";
import useToggleStyle from "./hooks/useToggleStyle";
import { Spinner } from "react-bootstrap";
import "boxicons/css/boxicons.min.css";
// import useGoogleAnalytics from "./hooks/useGoogleAnalytics";

const App = () => {
  const [clientIP, setClientIP] = useState("");
  const [ipCheckLoading, setIpCheckLoading] = useState(true); // New loading state for IP check
  const exemptedIP = "217.128.32.10";

  const { isStylesheetLoaded } = useToggleStyle();
  const { pathname } = useLocation();
  const {
    settingsPanelConfig: { showSettingPanelButton },
    setSettingsPanelConfig
  } = useSettingsPanelContext();
  const {
    isAuthenticated,
    loading,
    config: { theme, isRTL }
  } = useAuthContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    // setSettingsPanelConfig({
    //   openSettingPanel: false
    // });
  }, [isRTL]);

  useEffect(() => {
    setIpCheckLoading(false);
    // Fetch client IP address
    // axios.get("https://api.ipify.org?format=json")
    //   .then(response => {
    //     console.log("Client IP:", response.data.ip); // Log for debugging
    //     setClientIP(response.data.ip);
    //     setIpCheckLoading(false); // Set loading to false after getting IP
    //   })
    //   .catch(error => {
    //     console.error("Error fetching IP address:", error);
    //     setIpCheckLoading(false); // Ensure loading state is updated even if there's an error
    //   });
  }, []);

  // const clientId = '66333dc6d9405b9c2573449c'; // Assurez-vous que c'est le bon clientId
  // useAxeptio(clientId);

  // const trackingId = 'G-MFB31KTVTL'; // Replace with your actual tracking ID
  // useGoogleAnalytics(trackingId);

  useEffect(() => {
    // Create the script element
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.charset = "UTF-8";
    script.src = "//cdn.cookie-script.com/s/4e5d467977be299506e8063fb8ada35a.js";

    // Append the script to the body
    document.body.appendChild(script);

    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  if (loading || ipCheckLoading) {
    // Wait for both auth and IP check to complete
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  }

  // if (!isAuthenticated) {
  //   if (
  //     location.pathname !== "/login" &&
  //     location.pathname !== "/coming-soon"
  //   ) {
  //     if (location.pathname !== "/login") {
  //       return <Navigate to="/coming-soon" />;
  //     } else {
  //       return <Navigate to="/login" />;
  //     }
  //   }
  // }

  return (
    <>
      {!isStylesheetLoaded ? (
        <div
          style={{
            position: "fixed",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: theme === "dark" ? "#000" : "#fff"
          }}
        />
      ) : (
        <>
          <Outlet />
          {showSettingPanelButton && <></>}
        </>
      )}
    </>
  );
};

const AppWithAuthProvider = () => {
  return (
    <AuthProvider>
      {" "}
      {/* Wrap the entire application with AuthProvider */}
      <App />
    </AuthProvider>
  );
};

export default AppWithAuthProvider;
