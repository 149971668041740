import React from 'react';
import "swiper/swiper-bundle.css";
import 'swiper/css';
import 'swiper/css/pagination';
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";

// Importez vos images d'avatar ici
import manmanman from "assets/img/ipercom/services/ERP/collab/Mask group2.svg";
import manman from "assets/img/ipercom/services/ERP/collab/Mask group3_32_11zon.webp";
import wowomen from "assets/img/ipercom/services/ERP/collab/Mask group4_33_11zon.webp";
import wowomenmen from "assets/img/ipercom/services/ERP/collab/heleneAvatar_50_11zon.webp";
import { Autoplay } from "swiper/modules";

// Initialisation de Swiper
SwiperCore.use([Autoplay]);

const Collaborater = () => {
  const collaborators = [
    {
      name: "Stéphane Degouve",
      job: "Président",
      avatar: manman,
      facebook: false,
      linkedin: false,
      twitter: false
    },
    {
      name: "Hélène",
      job: "DAF",
      avatar: wowomenmen,
      instagram: false,
      linkedin: false
    },
    {
      name: "Benoit",
      job: "Directeur commercial",
      avatar: manman,
      instagram: false,
      linkedin: false
    },
    {
      name: "Laura",
      job: "Assistante des Ventes",
      avatar: wowomen,
      instagram: false,
      linkedin: false
    },
    {
      // name: "Akrem",
      job: "Ingénieur DevOps Niveau 2",
      avatar: manmanman,
      facebook: false,
      linkedin: false,
      twitter: false
    },
    {
      // name: "Alaeddin",
      job: "Ingénieur VOIP Niveau 2",
      avatar: manmanman,
      instagram: false,
      linkedin: false
    },
    {
      name: "Valentin",
      job: "Alternant Développeur Full Stack",
      avatar: manmanman,
      instagram: false,
      linkedin: false
    },
    {
      name: "Damien",
      job: "Alternant Développeur Full Stack",
      avatar: manmanman,
      facebook: false,
      linkedin: false,
      twitter: false
    },
  ];

  return (
    <section className="container my-3">
      <div className="row justify-content-center pt-1 mb-md-3 mb-lg-4">
        <div className="col-lg-8 col-md-9 text-center">
          <h2>Nos collaborateurs</h2>
        </div>
      </div>
      <Swiper
        spaceBetween={0}
        slidesPerView={5}
        centeredSlides={true}
        loop={true}
        autoplay={{ delay: 2000, disableOnInteraction: false }}
      >
        {collaborators.map((collaborator, index) => (
          <SwiperSlide key={index}>
            <div>
              <div
                className="card border-0 shadow-sm text-center"
                style={{ width: "auto", maxWidth: "150px", height: "auto" }}
              >
                <img
                  src={collaborator.avatar}
                  className="img-fluid "
                  alt={collaborator.name}
                  style={{
                    width: "100%",
                    borderRadius: "50%"
                  }}
                />
                <div className="card-body pb-2">
                    <h3 className="fs-sm mb-0">{collaborator.name}</h3>
                  <p className="fs-xs mb-3">{collaborator.job}</p>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default Collaborater;
