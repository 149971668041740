import InfoBouton from "components/modules/showcase/InfoBouton";
import React from "react";

const IpercomStrenght = () => {
  return (
    <>
      {/* Services */}
      <section className="bg-secondary pb-md-2 pb-lg-5 mb-3">
        <div
          className="d-none d-lg-block"
          style={{ marginTop: "-60px", paddingTop: 60 }}
        />

        <div className="container pb-4 pt-2">
          <div className="col-md-6 align-content-center order-1 mb-5">
            <InfoBouton />
          </div>
          <h2 className="text-center text-md-start mb-lg-4 pt-1 pt-md-4">
            La force d’ipercom
          </h2>
          <div className="text-center text-md-start">
            <p className="fs-md text-muted mb-md-3">
              Depuis sa fondation en 2009, ipercom s'est imposé comme un acteur clé
              dans le secteur des services informatiques et sécurité. Notre voyage a débuté
              avec une passion en bureautique et solutions informatiques, évoluant rapidement
              pour inclure des services de sécurité IT avant-gardistes : la création de sites
              web sur mesure et le développement d'applications innovantes.
              Le 9 février 2024, nous avons franchi une nouvelle
              étape majeure en devenant éditeur de logiciels, élargissant ainsi
              notre gamme de solutions pour mieux servir nos clients dans une
              ère numérique en constante évolution.
            </p>
          </div>
          <div className="row row-cols-1 row-cols-md-2">
            {/* Item */}
            <div className="col  my-sm-3">
              <a
                href=""
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-2 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 me-xl-2"
              >
                <div className="card-body pt-3">
                  <h3 className="d-inline-flex align-items-center">
                    Solutions Bureautiques et Informatiques :
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    Nous offrons une gamme complète de services destinés à
                    optimiser votre infrastructure IT. De l'installation
                    matérielle à la maintenance logicielle, notre objectif est
                    de garantir que votre environnement de travail soit à la
                    fois sécurisé et efficace.
                  </p>
                </div>
              </a>
            </div>
            {/* Item */}
            <div className="col  my-sm-3">
              <a
                href=""
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-2 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2"
              >
                <div className="card-body pt-3">
                  <h3 className="d-inline-flex align-items-center">
                    Sécurité IT :
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    La protection de vos données est notre priorité absolue.
                    Nous proposons des solutions de sécurité sur mesure,
                    incluant des audits de sécurité, la mise en œuvre de
                    protocoles de cryptage avancés, et des services de
                    surveillance en continu pour protéger votre entreprise
                    contre les menaces numériques.
                  </p>
                </div>
              </a>
            </div>
            {/* Item */}
            <div className="col  my-sm-3">
              <a
                href=""
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-2 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2"
              >
                <div className="card-body pt-3">
                  <h3 className="d-inline-flex align-items-center">
                    Développement Web et Applications :
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    <a href="/a-propos/equipe-et-competences">Notre équipe</a>  de développeurs talentueux crée des sites web
                    esthétiques, réactifs et faciles à naviguer, ainsi que des
                    applications mobiles personnalisées, afin de vous aider à
                    atteindre votre public cible et à améliorer l'engagement
                    client.
                  </p>
                </div>
              </a>
            </div>
            {/* Item */}
            <div className="col  my-sm-3">
              <a
                href=""
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-2 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2"
              >
                <div className="card-body pt-3">
                  <h3 className="d-inline-flex align-items-center">
                    Édition de Logiciels :
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    Depuis le 9 février 2024, nous avons étendu notre expertise
                    à l'édition de logiciels, offrant des solutions innovantes
                    conçues pour répondre aux défis spécifiques de votre
                    entreprise. Nos produits logiciels sont développés avec un
                    accent sur l'usabilité, la performance et l'intégration.
                  </p>
                </div>
              </a>
            </div>
            {/* Item */}
            <div className="col  my-sm-3">
              <a
                href=""
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-2 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2"
              >
                <div className="card-body pt-3">
                  <h3 className="d-inline-flex align-items-center">
                    Maintenance et Support par des Experts Certifiés :
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    Notre équipe de <a href="/services/services-proposes/maintenance-et-support-technique">support technique</a> est composée de
                    professionnels hautement qualifiés et certifiés, prêts à
                    résoudre tout problème technique que vous pourriez
                    rencontrer. Nous offrons des services de maintenance
                    préventive et corrective pour assurer la continuité et
                    l'efficacité de vos opérations. Notre support est disponible
                    24/7, garantissant une réponse rapide à toutes vos demandes.
                  </p>
                </div>
              </a>
            </div>
            {/* Item */}
            <div className="col  my-sm-3">
              <a
                href=""
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-2 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2"
              >
                <div className="card-body pt-3">
                  <h3 className="d-inline-flex align-items-center">
                    Développement de Logiciels et Applications Sur Mesure :
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    Chez ipercom, nous comprenons que chaque entreprise a des
                    besoins uniques. C'est pourquoi nous développons des
                    logiciels et applications personnalisés, conçus
                    spécifiquement pour répondre à vos exigences spécifiques.
                  </p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default IpercomStrenght;
