import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";
import HeadWhatIsCRM from "components/modules/services/whats-crm/HeadWhatIsCRM";
import WhatIsItFor from "components/modules/services/whats-crm/WhatIsItFor";
import image from "../../../assets/img/ipercom/services/ERP/peopleWorkingWhatIsCRM.svg";
import OurSoftware from "components/modules/services/whats-crm/OurSoftware";
import Partners from "components/modules/showcase/Partners";
import ClientTalk from "components/modules/showcase/ClientTalk";
import Collaborater from "components/modules/showcase/Collaborater";

const WhatCrm = () => {
  return (
    <>
      <img src={image} alt="" />
      <Breadcrumbs />
      <HeadWhatIsCRM />
      <WhatIsItFor />
      <OurSoftware />
      <Partners />
      <ClientTalk />
      <Collaborater />
    </>
  );
};

export default WhatCrm;
