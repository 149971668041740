import React from 'react';

import work from "assets/img//ipercom/a-propos/revendeur/work.webp";
import InfoBouton from "components/modules/showcase/InfoBouton";

const Opportunity = () => {
  return (
    <>
      <section className="container mt-5">
        <div className="row justify-content-around">
          <div className="col-md-6 pb-2 pb-md-0 mb-4 mb-md-0 order-2">
            <div className="pe-lg-5 text-center">
              <img src={work} className="rounded-3" alt="Image" />
            </div>
          </div>
          <div className="col-md-5 order-1">
            <h2 className="mb-sm-4">Une opportunité</h2>
            <p className="d-md-none d-xl-block pb-2 pb-md-3 mb-3">
              Devenir revendeur est une opportunité d'affaires lucrative offrant
              flexibilité et croissance. En choisissant les bons produits et
              partenaires, les revendeurs peuvent établir une présence
              significative sur le marché. Il est crucial de comprendre les
              besoins de votre public cible et de fournir un service client
              exceptionnel. En adoptant une stratégie adaptée et en restant
              informé des tendances du marché, devenir revendeur peut devenir
              une entreprise prospère et enrichissante.
            </p>
            <InfoBouton />
          </div>
        </div>
      </section>
    </>
  );
};
export default Opportunity;
