import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import useParallaxHooks from 'hooks/useParallaxHooks';

// Register GSAP ScrollTrigger
gsap.registerPlugin(ScrollTrigger);

// Data array for features
const featuresData = [
  {
    icon: "bx bx-plus-circle",
    title: "Intégration des Processus",
    description:
      "Un ERP connecte les départements comme la comptabilité, RH, stocks, production, logistique, pour une gestion fluide des informations et processus entre eux."
  },
  {
    icon: "bx bx-current-location",
    title: "Centralisation des données",
    description:
      "Les données centralisées permettent un accès rapide pour tous. Elles évitent les doublons et garantissent la cohérence des informations."
  },
  {
    icon: "bx bx-slider-alt",
    title: "Automatisation des tâches",
    description:
      "Les ERP automatisent les tâches répétitives (saisie de données, rapports, commandes), libérant du temps pour des tâches à haute valeur ajoutée."
  },
  {
    icon: "bx bx-line-chart",
    title: "Prise de décision basée sur les données",
    description:
      "Les ERP offrent des outils d’analyse et de reporting avancés pour prendre des décisions basées sur des données en temps réel, améliorant visibilité et transparence."
  }
];

const Features = () => {
  const containerRef = React.useRef(null);
  const parallaxElRef = React.useRef([]);

  useParallaxHooks(containerRef, parallaxElRef, {
    scrollTrigger: {
      trigger: '.gsap',
      start: "+=450 bottom"
    }
  });

  return (
    <section className="container py-3 mb-5">
      <h2 className="h1 text-center pt-1 pt-xl-3 mb-lg-4">
        Ce que notre ERP vous propose
      </h2>
      <Swiper
        slidesPerView={1}
        spaceBetween={8}
        pagination={{ clickable: true }}
        breakpoints={{
          500: {
            slidesPerView: 2
          },
          800: {
            slidesPerView: 3
          },
          1200: {
            slidesPerView: 4
          }
        }}
      >
        {featuresData.map((feature, index) => (
          <SwiperSlide
            className={"pt-2"}
            key={index}
            style={{ width: "306px", height: "340px" }}
          >
            <div
              className="card h-100 card-body card-hover mx-2"
              style={{ width: "306px", height: "340px" }}
            >
              <i
                className={`${feature.icon} display-5 fw-normal card-icon`}
                style={{ color: "#b4b7c9" }}
              />
              <h3 className="h5 pt-3 pb-1 mb-2">{feature.title}</h3>
              <p className="mb-0">{feature.description}</p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default Features;
