import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";
import Head from "components/modules/ressources/portfolio/customers-opinion/Head";
import LastReviews from "components/modules/ressources/portfolio/customers-opinion/LastReviews";
import LastNews from "components/modules/showcase/LastNews";
import Partners from "components/modules/showcase/Partners";
import React from "react";

const ClientsReviews = () => {
  return (
    <>
      <Head />
      <Breadcrumbs />
      <LastReviews />
      <LastNews />
      <Partners />
    </>
  );
};

export default ClientsReviews;
