import React from "react";
import meeting from "../../../../../assets/img/ipercom/services/ERP/company/meetingBig_35_11zon.webp";
import InfoBouton from "components/modules/showcase/InfoBouton";

const Interest = () => {
  return (
    <section className="container mt-5">
      <div className="row g-5 justify-content-center">
        <div className="col-md-5 pb-2 pb-md-0 mb-4 mb-md-0 order-2 ms-5">
          <div className="pe-lg-5 text-center">
            <img
              src={meeting}
              className="rounded-3"
              alt="Image"
              style={{ height: "350px" }}
            />
          </div>
        </div>
        <div className="col-md-5 order-1 me-5">
          <h2 className="mb-sm-4">
            L’intérêt de l'ERP pour les grandes entreprises
          </h2>
          <p className="d-md-none d-xl-block pb-2 pb-md-3 mb-3">
            Grâce à l'ERP, les grandes entreprises peuvent éliminer les silos
            d'informations, favorisant une collaboration accrue entre les
            départements et une meilleure réactivité aux défis du marché.
          </p>
          <InfoBouton />
        </div>
      </div>
    </section>
  );
};

export default Interest;
