import React from "react";
import products from "assets/img/ipercom/services/ERP/inventory/products_5_11zon.webp";

const WhatWeOffert = () => {
  return (
    <section className="overflow-hidden">
      <div className="container">
        <div className="d-flex flex-column flex-md-row flex-md-nowrap align-items-start">
          <div className="col-md-7 ps-0 pe-lg-4 mb-5 mb-md-0">
            <div style={{ maxWidth: 660 }}>
              <h2 className="pb-3 mb-2 mb-md-3">Ce que nous offrons</h2>
              <div className="row row-cols-1 row-cols-sm-2 gx-lg-5 g-4">
                <div className="col pt-1 pt-sm-2 pt-lg-3">
                  <i className="bx bx-calendar-check d-block fs-2 text-primary mb-2 mb-sm-3" />
                  <h3 className="h5 pb-sm-1 mb-2">
                    Planification de la demande
                  </h3>
                  <p className="mb-0">
                    Anticipez les fluctuations du marché avec une planification
                    efficace de la demande.
                  </p>
                </div>
                <div className="col pt-1 pt-sm-2 pt-lg-3">
                  <i className="bx bx-badge-check d-block fs-2 text-primary mb-2 mb-sm-3" />
                  <h3 className="h5 pb-sm-1 mb-2">
                    Gestion des approvisionnements
                  </h3>
                  <p className="mb-0">
                    Optimisez vos approvisionnements pour éviter les ruptures de
                    stock et les surplus.
                  </p>
                </div>
                <div className="col pt-1 pt-sm-2 pt-lg-3">
                  <i className="bx bx-line-chart d-block fs-2 text-primary mb-2 mb-sm-3"></i>
                  <h3 className="h5 pb-sm-1 mb-2">
                    Optimisation des niveaux de stock
                  </h3>
                  <p className="mb-0">
                    Gérez vos stocks avec précision grâce à notre solution.
                    Optimisez vos flux et réduisez les coûts dès maintenant !
                  </p>
                </div>
                <div className="col pt-1 pt-sm-2 pt-lg-3">
                  <i className="bx bx-trip d-block fs-2 text-primary mb-2 mb-sm-3" />
                  <h3 className="h5 pb-sm-1 mb-2">
                    Contrôle et suivi des stocks
                  </h3>
                  <p className="mb-0">
                    Surveillez vos stocks pour éviter les ruptures et maximiser
                    la disponibilité.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <img
            src={products}
            width={836}
            className="ms-3 ms-sm-5 ms-xl-3"
            alt="Dashboard"
          />
        </div>
      </div>
    </section>
  );
};

export default WhatWeOffert;
