import React from "react";
import reunionERP from "assets/img/ipercom/ressources/portfolio/projet realisé/reunionERP_18_11zon.webp";

const HowDidWeProceed = () => {
  return (
    <section className="container my-5">
      <div className="row">
        <div className="col-md-6 pb-2 pb-md-0 mb-4 mb-md-0 order-1">
          <div className="pe-lg-5 text-center">
            <img src={reunionERP} className="rounded-3" alt="Image" style={{ width: "70%" }} />
          </div>
        </div>
        <div className="col-md-6 order-2">
          <h2 className="mb-sm-4">Comment avons-nous procédé ?</h2>
          <p className="d-md-none d-xl-block pb-2 pb-md-3 mb-3">
            Pour mener à bien ce projet, nous avons débuté par une analyse approfondie des besoins du client à travers
            des rencontres pour comprendre ses exigences et défis actuels. Ensuite, nous avons sélectionné le système
            ERP
            le plus adapté et avons conçu une solution personnalisée en configurant l'ERP selon les spécifications
            exactes du client.
            Nous avons assuré une migration sécurisée des données et mis en place une formation complète des
            utilisateurs pour garantir
            une adoption optimale du système. Une phase de test approfondie a permis de vérifier la fiabilité du
            système,
            intégrant les feedbacks des utilisateurs pour effectuer les ajustements nécessaires.
            Le déploiement progressif a été accompagné d'une formation continue du personnel pour assurer une transition
            en douceur.
            Enfin, nous avons fourni un support client réactif et mis en place un suivi et une optimisation continue
            pour surveiller
            les performances et intégrer des mises à jour régulières.
          </p>
        </div>
      </div>
    </section>
  );
};

export default HowDidWeProceed;
