import classNames from 'classnames';
import Footer from 'components/footers/Footer';
import { useMainLayoutContext } from 'providers/old/MainLayoutProvider';
import { Container } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import MainNavbar from "../components/navbars/MainNavbar";

const MainLayout = () => {
  const { contentClass } = useMainLayoutContext();
  return (
    <Container fluid className="px-0">
      <div
        className={classNames(contentClass, "content")}
        style={{ paddingTop: "63px" }}
      >
        <MainNavbar />
        <Outlet />
        <Footer />
      </div>
    </Container>
  );
};

export default MainLayout;
