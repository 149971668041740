import React from "react";
import Image from "assets/img/ipercom/services/Solution proposes/developpement mobile/image2_6_11zon.webp";

const Head = () => {
  return (
    <section
      className="jarallax bg-dark"
      data-jarallax=""
      data-speed="0.35"
      data-bs-theme="dark"
      style={{ height: 509 }}
    >
      <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-40" />
      <div
        className="jarallax-img opacity-70"
        style={{ backgroundImage: `url(${Image})` }}
      />

      <div
        className="position-relative pb-5 text-center"
        style={{ top: 100, width: "50%", margin: "auto" }}
      >
        {/* Title */}
        <h1 style={{ fontSize: "80px" }}>
          Développement d'applications mobiles
        </h1>
        <p className="fs-xl text-light opacity-70">
          Notre équipe expérimentée en développement d'applications mobiles crée
          des solutions sur mesure pour répondre à vos besoins spécifiques.
        </p>
        {/* Stats */}
      </div>
    </section>
  );
};

export default Head;
