import React from 'react';
import dashboardImage from "assets/img/ipercom/services/ERP/dashboard.webp";
import { Parallax } from 'react-parallax';

interface ContactItemProps {
  iconClass: string;
  text: string;
  link?: string; // Making 'link' optional
}

// HeroSection component
const HeroSection = () => {
  return (
    <section className="pt-lg-4 mt-lg-3">
      <div className="position-relative overflow-hidden">
        <HeroImage />
        <HeroContent />
      </div>
    </section>
  );
};

// HeroImage component for the background image section
const HeroImage: React.FC = () => {
  return (
    <div className="container-fluid position-relative position-lg-absolute top-0 start-0 h-100">
      <div className="row h-100 me-n4 me-n2">
        <div className="col-lg-7 position-relative">
          <div
            className="d-none d-sm-block d-lg-none"
            style={{ height: "400px" }}
          ></div>
          <div className="d-sm-none" style={{ height: '300px' }}></div>
          {/* Implementing the parallax effect */}
          <Parallax
            bgImage={dashboardImage}
            strength={300} // Adjust the parallax strength as needed
            className="position-absolute top-0 start-0 w-100 h-100 rounded-3 rounded-start-0 overflow-hidden"
          >
            <div style={{ height: '100%' }}></div>
          </Parallax>
        </div>
      </div>
    </div>
  );
};

// HeroContent component for the main content section
const HeroContent = () => {
  return (
    <div className="container position-relative zindex-5 pt-lg-5 px-0 px-lg-3">
      <div className="row pt-lg-5 mx-n4 mx-lg-n3">
        <ContentCard />
        <Contacts />
      </div>
    </div>
  );
};

// ContentCard component for the card with text and buttons
const ContentCard = () => {
  return (
    <div className="col-xl-6 col-lg-7 offset-lg-5 offset-xl-6 pb-2">
      <div className="card bg-dark border-light overflow-hidden px-2">
        <span
          className="position-absolute top-0 start-0 w-100 h-100"
          style={{ backgroundColor: "rgba(255,255,255,.05)" }}
        ></span>
        <div className="card-body position-relative zindex-5">
          <p className="fw-bold text-primary text-uppercase mb-3">
            Augmenter l'efficacité
          </p>
          <h1 className="text-light pb-3 mb-3">Qu'est-ce qu'un ERP ?</h1>
          <p className="fs-lg text-light opacity-70 mb-5">
            Un ERP combine plusieurs modules pour simplifier la gestion des
            ressources et améliorer la prise de décision.
          </p>
          <div className="d-flex flex-column flex-sm-row">
            <a
              href="/abonnements"
              className="btn btn-primary shadow-primary btn-lg mb-3 mb-sm-0 me-sm-4"
            >
              Commencer
            </a>
            <a href="/a-propos/historique-agence" className="btn btn-outline-light btn-lg">
              Qui sommes nous ?
              <i className="bx bx-right-arrow-alt fs-4 lh-1 ms-2 me-n1" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const Contacts: React.FC = () => {
  return (
    <div className="col-xxl-3 col-lg-4 offset-lg-8 offset-xxl-9 pt-lg-5 mt-xxl-5">
      <ul className="list-unstyled mb-0 px-4 px-lg-0">
        <ContactItem iconClass="" text="" />
        <ContactItem iconClass="" text="" />
        <ContactItem iconClass="" text="" />
        <ContactItem iconClass="" text="" />
        {/*<ContactItem iconClass="bx-map" text="6391 Elgin St. Celina, Delaware 10299" />*/}
        {/*<ContactItem iconClass="bx-envelope" text="email@example.com" link="mailto:email@example.com" />*/}
        {/*<ContactItem iconClass="bx-phone-call" text="(406) 555-0120" link="tel:4065550120" />*/}
      </ul>
    </div>
  );
};

const ContactItem: React.FC<ContactItemProps> = ({ iconClass, text, link }) => {
  return (
    <li className="d-flex align-items-center pb-1 mb-2">
      <i className={`bx ${iconClass} fs-xl text-primary me-2`}></i>
      {link ? (
        <a href={link} className="nav-link fw-normal p-0">
          {text}
        </a>
      ) : (
        <span>{text}</span>
      )}
    </li>
  );
};

export default HeroSection;
