import wallet from "assets/img/ipercom/services/Solution proposes/logiciel caisse/wallet.svg";
import credit from "assets/img/ipercom/services/Solution proposes/logiciel caisse/credit.svg";
import shield from "assets/img/ipercom/services/Solution proposes/logiciel caisse/shield.svg";
import graph from "assets/img/ipercom/services/Solution proposes/logiciel caisse/bars-graphic.svg";

const Functionality = () => {
  return (
    <section className="overflow-hidden">
      <div className="container">
        <div className="d-flex flex-column flex-md-row flex-md-nowrap align-items-start">
          <div className="col-md-7 ps-0 pe-lg-4 mb-5 mb-md-0">
            <div style={{ maxWidth: 660 }}>
              <h2 className="mb-2 mb-md-3">
                Fonctionnalités clés d'i-pos
              </h2>
              <div className="row row-cols-1 row-cols-sm-2 gx-lg-5 g-4">
                <div>
                  <div className="d-table bg-secondary rounded-3 flex-shrink-0 p-3 mb-3">
                    <img src={credit} alt="Icon" style={{ width: "20px" }} />
                  </div>
                  <h3 className="h5 pb-sm-1 mb-2">Gestion des ventes</h3>
                  <p className="mb-0">
                    Permet de traiter les transactions de vente, d'enregistrer
                    les paiements et d'émettre des reçus.{" "}
                  </p>
                </div>
                <div>
                  <div className="d-table bg-secondary rounded-3 flex-shrink-0 p-3 mb-3">
                    <img src={wallet} alt="Icon" style={{ width: "20px" }} />
                  </div>
                  <h3 className="h5 pb-sm-1 mb-2">Remboursements et retours</h3>
                  <p className="mb-0">
                    Facilite la gestion des retours de produits et des
                    remboursements en traitant les transactions de manière
                    efficace.{" "}
                  </p>
                </div>
                <div>
                  <div className="d-table bg-secondary rounded-3 flex-shrink-0 p-3 mb-3">
                    <img src={graph} alt="Icon" style={{ width: "20px" }} />
                  </div>
                  <h3 className="h5 pb-sm-1 mb-2">Rapports et analyses</h3>
                  <p className="mb-0">
                    Des rapports détaillés sur les ventes, les performances des
                    produits, les tendances des clients, ect....
                  </p>
                </div>
                <div>
                  <div className="d-table bg-secondary rounded-3 flex-shrink-0 p-3 mb-3">
                    <img src={shield} alt="Icon" style={{ width: "20px" }} />
                  </div>
                  <h3 className="h5 pb-sm-1 mb-2">Sécurité des données</h3>
                  <p className="mb-0">
                    Des fonctionnalités avancées de sécurité pour protéger les
                    informations sensibles des clients et de l'entreprise.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <iframe
            src="https://template.opuslab.works/templates/MarvelPrototype_2d65463g_15022022111556_v1?emb=1&amp;iosapp=false&amp;frameless=false"
            style={{ height: "600px" }}
          ></iframe>
          {/*<img*/}
          {/*  src={menu}*/}
          {/*  width={836}*/}
          {/*  className="ms-3 ms-sm-5 ms-xl-3"*/}
          {/*  alt="Dashboard"*/}
          {/*  */}
          {/*/>*/}
        </div>
      </div>
    </section>
  );
};

export default Functionality;
