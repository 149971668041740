import React from "react";
import menWorking from "assets/img/ipercom/services/logiciel-de-facturation/edi/menWorking_43_11zon.webp";

const Creation = () => {
  return (
    <>
      <section className="container mt-5 pb-lg-5 pb-md-4 pb-3">
        <div className="row">
          <div className="col-md-6 pb-2 pb-md-0 mb-4 mb-md-0 order-2">
            <div className="pe-lg-5 text-center">
              <img src={menWorking} className="rounded-3" alt="Image" />
            </div>
          </div>
          <div className="col-md-6 align-content-center order-1">
            <h2 className="mb-sm-4">
              Création facile de messages EDI : avantages et limites
            </h2>
            <p className="d-md-none d-xl-block pb-2 pb-md-3 mb-3">
              La création des messages EDI repose sur des formulaires en ligne,
              faciles à remplir de manière intuitive. Les données sont saisies
              manuellement, puis traduites dans la norme requise par le logiciel
              EDI. Ce dernier assure également le traitement et la transmission
              des documents selon le protocole de communication nécessaire. Bien
              que ces solutions puissent être complètes, elles ne conviennent
              pas aux projets EDI de grande envergure ou avec de multiples
              partenaires. Dans ces cas, une solution intégrée adaptée est
              recommandée.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Creation;
