import React from "react";
import Impt2 from 'assets/img/ipercom/services/TelephonySolution/IMPORTANT 2_8_11zon.webp';
import Paper from "assets/img/ipercom/services/TelephonySolution/PaperAndHand.webp";
import Cover2 from 'assets/img/ipercom/services/TelephonySolution/cover2.svg';

const WhatBenefitsIPBX = () => {
  return (
    <>
      {/* Portfolio list */}
      <section className="container pb-2">
        {/* Item */}
        <div className="row pb-5">
          <div
            className="rellax col-md-6 pb-1 mb-3 pb-md-0 mb-md-0"
            data-rellax-percentage="0.5"
            data-rellax-speed="0.8"
            data-disable-parallax-down="md"
          >
            <a href="portfolio-single-project.html">
              <img src={Impt2} className="rounded-3" width={600} alt="Image" />
            </a>
          </div>
          <div
            className="rellax col-md-6 align-content-center"
            data-rellax-percentage="0.5"
            data-rellax-speed="-0.6"
            data-disable-parallax-down="md"
          >
            <div className="ps-md-4 ms-md-2 ">
              <h2>Quels bénéfices offre un IPBX ?</h2>
              <p className="d-md-none d-lg-block pb-3 mb-2 mb-md-3">
                Découvrez les bénéfices d'un réseau téléphonique IPBX :
                réduction des coûts, nouvelles fonctionnalités et amélioration
                de la productivité.
              </p>
            </div>
          </div>
        </div>
        {/* Item */}
        <div className="row pb-5">
          <div
            className="rellax col-md-6 order-md-2 pb-1 mb-3 pb-md-0 mb-md-0"
            data-rellax-percentage="0.5"
            data-rellax-speed="0.8"
            data-disable-parallax-down="md"
          >
            <a href="portfolio-single-project.html" className="float-md-end">
              <img src={Paper} className="rounded-3" width={600} alt="Image" />
            </a>
          </div>
          <div
            className="rellax col-md-6 order-md-1 align-content-center"
            data-rellax-percentage="0.5"
            data-rellax-speed="-0.6"
            data-disable-parallax-down="md"
          >
            <div className="pe-md-4 me-md-2">
              <h3 className="h2"> Diminution des dépenses liées à la téléphonie</h3>
              <p className="d-md-none d-lg-block pb-3 mb-2 mb-md-3">
                Intégrer un réseau IPBX réduit les dépenses téléphoniques, en
                privilégiant les protocoles Internet aux lignes traditionnelles.
                Les économies sont particulièrement significatives pour les
                appels longue distance et internationaux, épargnant les coûts
                supplémentaires du RTC.
              </p>
            </div>
          </div>
        </div>
        {/* Item */}
        <div className="row pb-5">
          <div
            className="rellax col-md-6 pb-1 mb-3 pb-md-0 mb-md-0"
            data-rellax-percentage="0.5"
            data-rellax-speed="0.8"
            data-disable-parallax-down="md"
          >
            <a href="portfolio-single-project.html">
              <img src={Cover2} className="rounded-3" width={600} alt="Image" />
            </a>
          </div>
          <div
            className="rellax col-md-6 align-content-center"
            data-rellax-percentage="0.5"
            data-rellax-speed="-0.6"
            data-disable-parallax-down="md"
          >
            <div className="ps-md-4 ms-md-2">
            <h4 className="h2">Garantie d'une qualité d'appel constante</h4>
              <p className="d-md-none d-lg-block pb-3 mb-2 mb-md-3">
                Sous réserve d'une connexion Internet stable, la technologie
                VoIP, fondement de l'IPBX, offre une garantie de qualité et de
                fiabilité. Les appels ne sont plus sujets aux perturbations des
                infrastructures physiques telles que les coupures de ligne, qui
                peuvent entraîner des retards, des interférences sonores ou même
                des interruptions de communication.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhatBenefitsIPBX;
