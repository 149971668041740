import Features from "components/modules/services/whats-erp/Features";
import ResponseSection from "components/modules/services/whats-erp/ResponseSection";
import LastNews from "components/modules/showcase/LastNews";
import HeroSection from "components/modules/services/whats-erp/HeroSection";
import ClientTalkforUs from "components/modules/showcase/ClientTalkforUs";
import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";

const WhatErp = () => {
  return (
    <>
      <HeroSection />
      <Breadcrumbs />
      <Features />
      <ResponseSection />
      <ClientTalkforUs />
      <LastNews />
    </>
  );
};

export default WhatErp;
