import React from "react";
import image from "assets/img/ipercom/services/ERP/image.svg";

const WhatWeOffert = () => {
  return (
    <section className="overflow-hidden pt-md-2">
      <div className="container">
        <div className="d-flex flex-column flex-md-row flex-md-nowrap align-items-start">
          <div className="col-md-7 ps-0 pe-lg-4 mb-5 mb-md-0">
            <div style={{ maxWidth: 660 }}>
              <h2 className="pb-3 mb-2 mb-md-3">Ce que nous offrons</h2>
              <div className="row row-cols-1 row-cols-sm-2 gx-lg-5 g-4">
                <div className="col pt-1 pt-sm-2">
                  <i className="bx bx-rocket d-block fs-2 text-primary mb-2 mb-sm-3" />
                  <h3 className="h5 pb-sm-1 mb-2">Innovation Solutions</h3>
                  <p className="mb-0">
                    Simplifiez votre facturation avec notre solution innovante.
                    Gérez vos finances efficacement dès aujourd'hui.
                  </p>
                </div>
                <div className="col pt-1 pt-sm-2">
                  <i className="bx bx-like d-block fs-2 text-primary mb-2 mb-sm-3" />
                  <h3 className="h5 pb-sm-1 mb-2">Garantie de réussite</h3>
                  <p className="mb-0">
                    Notre engagement pour la réussite en facturation assure une
                    gestion financière optimale.
                  </p>
                </div>
                <div className="col pt-1 pt-sm-2">
                  <i className="bx bx-group d-block fs-2 text-primary mb-2 mb-sm-3" />
                  <h3 className="h5 pb-sm-1 mb-2">Équipe professionnelle</h3>
                  <p className="mb-0">
                    Avec <a href="/a-propos/equipe-et-competences">notre équipe</a> spécialisée en facturation,
                    simplifiez
                    votre gestion financière.
                  </p>
                </div>
                <div className="col pt-1 pt-sm-2">
                  <i className="bx bx-time-five d-block fs-2 text-primary mb-2 mb-sm-3" />
                  <h3 className="h5 pb-sm-1 mb-2">Économisez du temps</h3>
                  <p className="mb-0">
                    Gagnez du temps avec nos outils innovants et simplifiez
                    votre quotidien professionnel.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <img
            src={image}
            width={836}
            className="ms-3 ms-sm-5 ms-xl-3"
            alt="Dashboard"
          />
        </div>
      </div>
    </section>
  );
};

export default WhatWeOffert;
