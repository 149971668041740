import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import mastercard from "assets/img/account/mastercard.webp";
import visa from "assets/img/account/visa.webp";
import amex from "assets/img/account/amex.webp";
import discover from "assets/img/account/discover.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faHome,
  faPhone,
  faUser
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const PaymentCard = () => {
  const [showForm, setShowForm] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const [showSave, setShowSave] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [formData, setFormData] = useState({
    name: "Ansolo Lazinatov",
    email: "Shantinon@jeemail.com",
    phone: "0634235486",
    address1: "Apartment 6/B",
    address2: "192 Edsel Road",
    city: "Van Nuys",
    region: "California",
    postalCode: "1216",
    country: "USA"
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log(formData);
    handleCloseButton();
    handleCloseForm();
    handleOpenCancel();
    handleOpenSave();
  };

  const handleOpenCancel = () => setShowCancel(true);
  const handleCloseCancel = () => setShowCancel(false);

  const handleOpenForm = () => setShowForm(true);
  const handleCloseForm = () => setShowForm(false);

  const handleOpenButton = () => setShowButton(true);
  const handleCloseButton = () => setShowButton(false);

  const handleOpenSave = () => setShowSave(true);
  const handleCloseSave = () => setShowSave(false);

  return (
    <>
      <h1 className="mb-3">Paiement</h1>
      <h2 className="mb-4"><Link to="facturation">Détails de la facturation</Link></h2>
      {showButton && (
        <Button
          className="w-20 mb-4"
          variant="light"
          onClick={() => {
            handleOpenForm();
            handleCloseButton();
          }}
          style={{ color: "#CD1F90", borderColor: "#CD1F90" }}
        >
          Ajouter des informations +
        </Button>
      )}
      {showForm && (
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicName">
            <Form.Label>Nom et prénom</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
          </Form.Group>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formBasicEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formBasicPhone">
              <Form.Label>Téléphone</Form.Label>
              <Form.Control
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
              />
            </Form.Group>
          </Row>
          <Form.Group className="mb-3" controlId="formBasicAddress1">
            <Form.Label>Adresse ligne 1</Form.Label>
            <Form.Control
              type="text"
              name="adress1"
              value={formData.address1}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicAddress2">
            <Form.Label>Adresse ligne 2</Form.Label>
            <Form.Control
              type="text"
              name="adress2"
              value={formData.address2}
              onChange={handleChange}
            />
          </Form.Group>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formBasicCity">
              <Form.Label>Ville</Form.Label>
              <Form.Control
                as="select"
                name="city"
                value={formData.city}
                onChange={handleChange}
              >
                <option>Van Nuys</option>
                <option>Option 2</option>
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} controlId="formBasicRegion">
              <Form.Label>Région</Form.Label>
              <Form.Control
                as="select"
                name="region"
                value={formData.region}
                onChange={handleChange}
              >
                <option>California</option>
                <option>Option 2</option>
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} controlId="formBasicPostalCode">
              <Form.Label>Code postal</Form.Label>
              <Form.Control
                type="text"
                name="postalCode"
                value={formData.postalCode}
                onChange={handleChange}
              />
            </Form.Group>
          </Row>
          <Form.Group
            as={Col}
            controlId="formBasicCountry"
            style={{ width: "31.5%" }}
          >
            <Form.Label>Pays</Form.Label>
            <Form.Control
              as="select"
              name="country"
              value={formData.country}
              onChange={handleChange}
            >
              <option>USA</option>
              <option>Option 2</option>
            </Form.Control>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicSelect"></Form.Group>
          <Button variant="primary" type="submit">
            Sauvegarder
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              handleCloseForm();
              handleOpenButton();
            }}
            className="ms-3"
          >
            Quitter sans sauvegarder
          </Button>
        </Form>
      )}
      {showSave && (
        <Row>
          <Col>
            <div className="d-flex align-items-center m-2">
              <FontAwesomeIcon icon={faUser} />
              <p className="mb-0 ms-2">
                <span className="fw-bold">Nom et prénom : </span>
                {formData.name}
              </p>
            </div>
            <div className="d-flex align-items-center m-2">
              <FontAwesomeIcon icon={faHome} />
              <p className="mb-0 ms-2">
                <span className="fw-bold">Adresse : </span>
                {formData.address1}, {formData.address2}, {formData.city},{" "}
                {formData.region}, {formData.country}
              </p>
            </div>
            <div className="d-flex align-items-center m-2">
              <FontAwesomeIcon icon={faEnvelope} />
              <p className="mb-0 ms-2">
                <span className="fw-bold">Adresse mail : </span>
                {formData.email}
              </p>
            </div>
            <div className="d-flex align-items-center m-2 mb-3">
              <FontAwesomeIcon icon={faPhone} />
              <p className="mb-0 ms-2">
                <span className="fw-bold">Téléphone : </span>
                {formData.phone}
              </p>
            </div>
          </Col>
        </Row>
      )}
      {showCancel && (
        <Button
          variant="primary"
          onClick={() => {
            handleOpenForm();
            handleCloseCancel();
            handleCloseSave();
          }}
          className="ms-3"
        >
          Annuler
        </Button>
      )}
      <hr className="mt-3"></hr>
      <h5 className="mt-3">Mode de paiement</h5>
      <Row className="g-4 mb-5 mt-3 text-1100">
        <Col xs={12}>
          <Row className="gx-lg-11">
            <Col xs={12} md="auto">
              <div className="d-flex">
                <Form.Check type="radio" id="creditCard" className="me-3">
                  <Form.Check.Input
                    value="credit_card"
                    type="radio"
                    name="paymentMethod"
                  />
                  <Form.Check.Label className="fs-8 text-900">
                    Carte de crédit
                  </Form.Check.Label>
                </Form.Check>
                <img
                  className="h-100 me-2"
                  src={visa}
                  alt="visa"
                  style={{ width: "50px" }}
                />
                <img
                  className="h-100 me-2"
                  src={discover}
                  alt="amex"
                  style={{ width: "50px" }}
                />
                <img
                  className="h-100 me-2"
                  src={amex}
                  alt="amex"
                  style={{ width: "50px" }}
                />
                <img
                  className="h-100 me-2"
                  src={mastercard}
                  alt="mastercard"
                  style={{ width: "50px" }}
                />
              </div>
            </Col>
            <Col xs={12} md="auto">
              <Form.Check type="radio" id="paypal">
                <Form.Check.Input
                  value="paypal"
                  type="radio"
                  name="paymentMethod"
                />
                <Form.Check.Label className="fs-8 text-900">
                  PayPal
                </Form.Check.Label>
              </Form.Check>
            </Col>
          </Row>
        </Col>
        <Col md={6}>
          <Form.Group>
            <Form.Label>Sélectionnez la carte</Form.Label>
            <Form.Select>
              <option>Sélectionnez une carte</option>
              <option value="visa">Visa</option>
              <option value="discover">Discover</option>
              <option value="mastercard">Mastercard</option>
              <option value="american-express">American Express</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group>
            <Form.Label>Numéro de carte</Form.Label>
            <Form.Control type="text" value="Entrez le numéro de la carte" />
          </Form.Group>
        </Col>
        <Col xs={12}>
          <Form.Group as={Col}>
            <Form.Label>Nom complet</Form.Label>
            <Form.Control
              name="full_name"
              type="text"
              value="Tapez votre nom complet"
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Label>Expire le</Form.Label>
          <div className="d-flex gap-3">
            <Form.Select name="month">
              <option>Mois</option>
              <option>Janvier</option>
              <option>Février</option>
              <option>Mars</option>
              <option>Avril</option>
              <option>Mai</option>
            </Form.Select>

            <Form.Select className="form-select text-black">
              <option>Année</option>
              <option>2022</option>
              <option>2023</option>
              <option>2024</option>
              <option>2025</option>
              <option>2026</option>
            </Form.Select>
          </div>
        </Col>
        <Col md={6}>
          <Form.Group as={Col}>
            <Form.Label>CVC</Form.Label>
            <Form.Control
              className="text-1100 input-spin-none"
              type="text"
              value="Entrez un CVC"
            />
          </Form.Group>
        </Col>
        <Col xs={12}>
          <Form.Check type="checkbox" id="gridCheck" className="me-3">
            <Form.Check.Input
              type="checkbox"
              value="save"
              name="paymentMethod"
            />
            <Form.Check.Label className="fs-8 text-black mb-3">
              Enregistrer les détails de la carte
            </Form.Check.Label>
          </Form.Check>
        </Col>
        <Row>
          <Col className="col-8">
            <Button className="w-100" variant="primary">
              Payer 625.20€
            </Button>
          </Col>
          <Col className="col-4">
            <Button className="w-100" variant="secondary">
              Enregistrer la commande et quitter
            </Button>
          </Col>
        </Row>
      </Row>
    </>
  );
};

export default PaymentCard;
