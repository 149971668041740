import React from 'react';
import Reunion from "assets/img/ipercom/services/ERP/reunion.webp";
import Other from 'assets/img/ipercom/services/ERP/otherWomenWithACalculatorEstimate.svg';
import Phone from "assets/img/ipercom/services/ERP/phone.webp";

const OurServicesEstimate = () => {
  return (
    <section className="container py-3 mt-md-3 my-lg-5">
      <h2
        className="h1 pt-xl-2 pb-4 mb-2 mb-lg-3"
        style={{ textAlign: "left", paddingLeft: "15px" }}
      >
        Nos services
      </h2>
      <div className="d-flex justify-content-between align-items-start">
        {/* Création Rapide */}
        <div className="service-item" style={{ width: "32%", margin: "0 1%" }}>
          <a href="#">
            <img src={Reunion} className="rounded-3" alt="Création rapide" />
          </a>
          <div className="pt-4">
            <h3 className="h4">Création Rapide</h3>
            <ul className="list-unstyled">
              <li className="d-flex align-items-center fs-sm mb-2">
                <i className="bx bx-check fs-xl text-primary me-2" />
                Génération devis express
              </li>
              <li className="d-flex align-items-center fs-sm mb-2">
                <i className="bx bx-check fs-xl text-primary me-2" />
                Création devis simple
              </li>
              <li className="d-flex align-items-center fs-sm mb-2">
                <i className="bx bx-check fs-xl text-primary me-2" />
                Réalisation efficace de devis
              </li>
            </ul>
          </div>
        </div>

        {/* Suivi Commercial Complet */}
        <div className="service-item" style={{ width: '32%', margin: '0 1%' }}>
          <a href="#">
            <img
              src={Other}
              className="rounded-3"
              alt="Suivi commercial complet"
            />
          </a>
          <div className="pt-4">
            <h3 className="h4">Suivi Commercial Complet</h3>
            <ul className="list-unstyled">
              <li className="d-flex align-items-center fs-sm mb-2">
                <i className="bx bx-check fs-xl text-primary me-2" />
                Gestion devis optimale
              </li>
              <li className="d-flex align-items-center fs-sm mb-2">
                <i className="bx bx-check fs-xl text-primary me-2" />
                Suivi commercial précis
              </li>
              <li className="d-flex align-items-center fs-sm mb-2">
                <i className="bx bx-check fs-xl text-primary me-2" />
                Gestion devis fluide
              </li>
            </ul>
          </div>
        </div>

        {/* Facturation en un clic */}
        <div className="service-item" style={{ width: '32%', margin: '0 1%' }}>
          <a href="#">
            <img
              src={Phone}
              className="rounded-3"
              alt="Facturation en un clic"
            />
          </a>
          <div className="pt-4">
            <h3 className="h4">Facturation en un clic</h3>
            <ul className="list-unstyled">
              <li className="d-flex align-items-center fs-sm mb-2">
                <i className="bx bx-check fs-xl text-primary me-2" />
                Rapidité de facturation
              </li>
              <li className="d-flex align-items-center fs-sm mb-2">
                <i className="bx bx-check fs-xl text-primary me-2" />
                Simplicité de facturation
              </li>
              <li className="d-flex align-items-center fs-sm mb-2">
                <i className="bx bx-check fs-xl text-primary me-2" />
                Facturation automatisée
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurServicesEstimate;
