import React from 'react';
import People from "assets/img/ipercom/a-propos/teamSkills/people.webp";

const Head = () => {
  return (
    <section
      className="jarallax bg-dark"
      data-jarallax=""
      data-speed="0.35"
      data-bs-theme="dark"
      style={{ height: 509 }}
    >
      <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-40" />
      <div
        className="jarallax-img opacity-70"
        style={{ backgroundImage: `url(${People})` }}
      />

      <div
        className="position-relative pb-5 text-center"
        style={{ top: 170, width: "50%", margin: "auto" }}
      >
        {/* Title */}
        <h1 style={{ fontSize: "60px" }}>Équipe et compétences </h1>
      </div>
    </section>
  );
};

export default Head;
