import Benefits from "components/modules/services/proposed-services/software-design/Benefits";
import Approach from "components/modules/services/proposed-services/software-design/Approach";
import Header from "components/modules/services/proposed-services/software-design/Header";
import TechUsed from "components/modules/services/proposed-services/software-design/TechUsed";
import React from "react";
import ClientTalk from "components/modules/showcase/ClientTalk";
import Partners from "components/modules/showcase/Partners";
import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";

const SoftwareConception = () => {
  return (
    <>
      <Breadcrumbs />
      <Header />
      <Approach />
      <TechUsed />
      <Benefits />
      <ClientTalk />
      <Partners />
    </>
  );
};

export default SoftwareConception;
