import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";
import DescriptionFullStack from "components/modules/a-propos/carriere/fullStack/DescriptionFullStack";
import Head from "components/modules/a-propos/carriere/fullStack/Head";
import React from "react";

const PostSheetFullStack = () => {
  return (
    <>
      <Head />
      <Breadcrumbs />
      <DescriptionFullStack />
    </>
  );
};

export default PostSheetFullStack;
