import React from 'react';

const Benefits = () => {
  const stepStyle = {
    width: "25%"
  };

  return (
    <section className="container mt-5">
      <div className="text-start pb-4 pb-md-0 mb-2 mb-md-5 mx-auto">
        <h2>Avantages de la conception sur mesure</h2>
      </div>
      {/* Steps */}
      <div className="steps steps-sm steps-horizontal-md pb-5 mb-md-2 mb-lg-3">
        <div className="m-3" style={stepStyle}>
          <div className="step-number">
            <div className="step-number-inner">1</div>
          </div>
          <div className="step-body me-4">
            <h3 className="h4 mb-3">Répond aux besoins spécifiques </h3>
            <p className="mb-0">
              Les solutions sur mesure sont conçues pour répondre précisément
              aux besoins uniques de chaque client ou entreprise.
            </p>
          </div>
        </div>
        <div className="m-3" style={stepStyle}>
          <div className="step-number">
            <div className="step-number-inner">2</div>
          </div>
          <div className="step-body me-4">
            <h3 className="h4 mb-3">Optimisation des processus</h3>
            <p className="mb-0">
              Les solutions sur mesure permettent d'optimiser les processus
              internes, ce qui peut entraîner une amélioration significative de
              l'efficacité et de la productivité.
            </p>
          </div>
        </div>
        <div className="m-3" style={stepStyle}>
          <div className="step-number">
            <div className="step-number-inner">3</div>
          </div>
          <div className="step-body me-4">
            <h3 className="h4 mb-3">Support dédié</h3>
            <p className="mb-0">
              Les entreprises bénéficient souvent d'un support dédié
              lorsqu'elles optent pour des solutions sur mesure, ce qui leur
              permet de résoudre rapidement les problèmes et les défis
              rencontrés.
            </p>
          </div>
        </div>
        <div className="m-3" style={stepStyle}>
          <div className="step-number">
            <div className="step-number-inner">4</div>
          </div>
          <div className="step-body me-4">
            <h3 className="h4 mb-3">Flexibilité et adaptabilité</h3>
            <p className="mb-0">
              Les solutions sur mesure peuvent être ajustées et adaptées en
              fonction des évolutions du marché et des besoins changeants des
              utilisateurs.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Benefits;
