import React from "react";
import menWomen from "assets/img/ipercom/services/logiciel-de-facturation/bureautique/menWomen_42_11zon.webp";

const Toners = () => {
  return (
    <>
      <section className="container mt-5 pb-lg-5 pb-md-4 pb-3">
        <div className="row">
          <div className="col-md-6 pb-2 pb-md-0 mb-4 mb-md-0 order-2">
            <div className="pe-lg-5 text-center">
              <img src={menWomen} className="rounded-3" alt="Image" />
            </div>
          </div>
          <div className="col-md-6 align-content-center order-1">
            <h2 className="mb-4">Maîtrisez vos toners</h2>
            <p>
              Grâce à l'intégration entre votre solution MPS KPAX (Bluemega) et
              l'ERP ipercom, vous bénéficiez de :
            </p>
            <ul className="list-unstyled mb-5">
              <li className="d-flex align-items-center mb-2">
                <i className="bx bx-check-circle text-primary fs-xl me-2" />
                Mise en place d'une gestion préventive pour le remplacement des
                pièces d'usure (four, tambour, patin).
              </li>
              <li className="d-flex align-items-center mb-2">
                <i className="bx bx-check-circle text-primary fs-xl me-2" />
                Facturation automatique de la surconsommation selon les termes
                contractuels.
              </li>
              <li className="d-flex align-items-center mb-2">
                <i className="bx bx-check-circle text-primary fs-xl me-2" />
                Surveillance automatisée des niveaux d'encre dans ipercom.
              </li>
              <li className="d-flex align-items-center mb-2">
                <i className="bx bx-check-circle text-primary fs-xl me-2" />
                Visualisation des machines présentant une surconsommation.
              </li>
              <li className="d-flex align-items-center mb-2">
                <i className="bx bx-check-circle text-primary fs-xl me-2" />
                Création automatisée des commandes de consommables depuis
                ipercom, avec intégration du bac de récupération.
              </li>
              <li className="d-flex align-items-center mb-2">
                <i className="bx bx-check-circle text-primary fs-xl me-2" />
                Collecte automatique des compteurs dans ipercom.
              </li>
              <li className="d-flex align-items-center mb-2">
                <i className="bx bx-check-circle text-primary fs-xl me-2" />
                Valorisation du stock de toner « pied de machine ».
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default Toners;
