import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";
import Collaborater from "components/modules/a-propos/equipe-et-compétences/Collaborater";
import Head from "components/modules/a-propos/equipe-et-compétences/Head";
import LastNews from "components/modules/showcase/LastNews";
import React from "react";

const TeamAndSkills = () => {
  return (
    <>
      <Head />
      <Breadcrumbs/>
      <Collaborater />
      <LastNews />
    </>
  );
};

export default TeamAndSkills;
