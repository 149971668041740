import woman from "assets/img/ipercom/services/Solution proposes/logiciel caisse/woman pink_40_11zon.webp";
import { useParams } from "react-router-dom";

const WhatTheProject = () => {
  const { name } = useParams();
  return (
    <>
      <section className="container my-5">
        <h1 className="pb-3">
          Logiciel de caisse
        </h1>
        <h2 className="mb-5">{name}</h2>
        <div className="row">
          <div className="col-md-6 pb-2 pb-md-0 mb-4 mb-md-0 order-1">
            <div className="pe-lg-5 text-center">
              <img src={woman} className="rounded-3" alt="Image" style={{ width: "64%" }} />
            </div>
          </div>
          <div className="col-md-6 order-2">
            <h2 className="mb-sm-4">Quel était le projet ?</h2>
            <p className="d-md-none d-xl-block pb-2 pb-md-3 mb-1">
              Le projet consistait à développer et implémenter un logiciel de caisse innovant et complet, destiné à
              améliorer la gestion des transactions, des stocks et des finances des entreprises. L'objectif était de
              fournir une solution flexible, sécurisée et facile à utiliser, capable de répondre aux besoins spécifiques
              des entreprises modernes.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhatTheProject;
