import React from "react";
import computer from "assets/img/ipercom/icons/computerOurCrm.webp";
import launch from "assets/img/ipercom/icons/launchOurCrm.svg";
import mobile from "assets/img/ipercom/services/Solution proposes/developpement mobile/mobile-app.svg";
import screen from "assets/img/ipercom/icons/screenOurCrm.svg";
import graph from "assets/img/ipercom/services/ERP/company/icons/graph.svg";
import agent from "assets/img/ipercom/services/ERP/company/icons/agent.svg";

const OurServices = () => {
  return (
    <>
      {/* Services */}
      <section className="bg-secondary">
        <div className="container pb-4 pt-2">
          <h2 className="h2 text-center text-md-start mb-lg-4 pt-1 pt-md-4">
            Services de développement d'applications mobiles
          </h2>
          <div className="col-md-8 text-center text-md-start">
            <p className="fs-lg text-muted mb-md-3">
              Nos services de développement d'applications mobiles garantissent
              des solutions sur mesure, adaptées à vos besoins spécifiques et
              axées sur la performance.
            </p>
          </div>
          <div className="row row-cols-1 row-cols-md-2">
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <div
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 me-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={computer}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="d-inline-flex align-items-center">
                    Expertise spécialisée
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    Nos spécialistes dans le développement d'applications
                    mobiles possèdent une expertise approfondie dans ce domaine,
                    ce qui garantit des résultats de haute qualité.
                  </p>
                </div>
              </div>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <div
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={mobile}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="d-inline-flex align-items-center">
                    Connaissance des plateformes
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    Nous maîtrisons les spécificités des différentes <a href="/ressources/blog/nouveautes-agence-et-secteur/guide-pour-la-creation-d-applications-mobiles-integrees-a-votre-ipbx"> plateformes
                    mobiles</a>  telles qu'iOS et Android, ce qui leur permet de
                    créer des applications optimisées pour chaque système
                    d'exploitation.{" "}
                  </p>
                </div>
              </div>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <div
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={launch}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="d-inline-flex align-items-center">
                    Performances
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    L'importance d'une application mobile réactive et rapide, ce
                    qui peut réduire le taux de rebond sur votre site et
                    améliorer votre positionnement dans les résultats de
                    recherche.
                  </p>
                </div>
              </div>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <div
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={screen}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="d-inline-flex align-items-center">
                    Support multiplateforme
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    Nos services de développement d'applications mobiles offrent
                    un support multiplateforme pour garantir une présence
                    efficace sur tous les appareils, amplifiant ainsi votre
                    portée.
                  </p>
                </div>
              </div>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <div
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={agent}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="d-inline-flex align-items-center">
                    Maintenance continue
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    Notre service de maintenance continue pour les applications
                    mobiles assure un fonctionnement fluide et des mises à jour
                    régulières pour une expérience utilisateur optimale.
                  </p>
                </div>
              </div>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <div
                className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div
                    className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img
                      src={graph}
                      className="d-block m-1"
                      width={40}
                      alt="Icon"
                    />
                  </div>
                  <h3 className="d-inline-flex align-items-center">
                    Évolutivité
                  </h3>
                  <p className="fs-sm text-body mb-0">
                    Notre service de développement d'applications mobiles
                    garantit une évolutivité sans limite, vous permettant
                    d'ajuster facilement votre application en fonction de vos
                    besoins changeants.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurServices;
