import React from "react";
import computer from "assets/img/ipercom/services/Solution proposes/developpement web/computer4_21_11zon.webp";

const DevelopmentProces = () => {
  return (
    <>
      <section className="container my-5">
        <div className="row">
          <div className="col-md-5 order-2">
            <div className="pe-lg-5 text-center">
              <img src={computer} className="rounded-3" alt="Image" />
            </div>
          </div>
          <div className="col-md-6 align-content-center order-1 pe-lg-5">
            <h2 className="mb-sm-4">Processus de développement</h2>
            <p className="d-md-none d-xl-block pb-2 pb-md-3 mb-3">
              Le processus de développement est le cœur de la création
              d'applications web réussies. Il commence par la planification
              minutieuse des besoins et des objectifs, puis passe par la
              conception, le codage, les tests et enfin la mise en production.
              Chaque étape est cruciale pour garantir la qualité, la sécurité et
              la fonctionnalité de l'application finale. En suivant un processus
              bien défini, les équipes de développement peuvent assurer la
              cohérence et l'efficacité tout au long du projet, tout en
              minimisant les risques et en respectant les délais.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default DevelopmentProces;
