import React, { useRef, useState } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { RouteItems, routes } from "../../sitemap";
import ThemeToggler from "../common/ThemeToggler";
import { useAppContext } from "../../providers/AppProvider";
import NavbarBrand from "./nav-items/NavbarBrand";

interface DropdownLinkProps {
  route: RouteItems;
}

const DropdownLink: React.FC<DropdownLinkProps> = ({ route }) => {
  const [isOpen, setOpen] = useState(false); // State is now internal

  const handleLinkClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    setOpen(false); // Close the dropdown menu when a link is clicked
  };

  const toggleDropdown = () => setOpen(!isOpen); // Toggle for dropdown

  const {
    config: { theme }
  } = useAppContext();

  return (
    <>
      {!route.labelDisabled && (
        <Nav.Link
          href={route.path}
          className={route.pages?.length ? "dropdown-toggle" : ""}
          data-bs-toggle="dropdown"
          aria-current="page"
          aria-expanded={isOpen}
          onClick={toggleDropdown} // Using toggle function here
        >
          {route.name}
        </Nav.Link>
      )}
      {route.pages && (
        <div
          title={route.name}
          className={`dropdown-menu p-0 ${isOpen ? "show" : ""}`}
        >
          <div className="d-lg-flex p-3">
            <div className="mega-dropdown-column" style={{ width: "300px" }}>
              <ul className="list-unstyled mb-0">
                <li className="py-1 fs-5 fw-bold">{route.name}</li>
                <p className="">{route.description}</p>
              </ul>
            </div>
            {route.pages.map((sroute, index) => {
              if (index % 2 === 0) {
                const nextSRoute = route.pages[index + 1];
                return (
                  <div className="mega-dropdown-column" key={index}>
                    <div className="px-3 mb-2">
                      <div className="d-flex align-items-center">
                        <i
                          className={`bx bx-${sroute.icon} fs-bold bg-secondary rounded-circle p-1 mr-2 me-1`}
                        />
                        <span className="text-dark fw-bold">{sroute.name}</span>
                      </div>
                    </div>
                    <ul className="list-unstyled mb-3">
                      {sroute.pages?.map(ssroute => (
                        <li key={ssroute.name}>
                          <a href={ssroute.path} className="dropdown-item py-1">
                            {ssroute.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                    {nextSRoute && (
                      <div>
                        <div className="px-3 mb-2">
                          <div className="d-flex align-items-center">
                            <i
                              className={`bx bx-${sroute.icon} fs-bold bg-secondary rounded-circle p-1 mr-2 me-1`}
                            />
                            <span className="text-dark">
                              <b>{nextSRoute.name}</b>
                            </span>
                          </div>
                        </div>
                        <ul className="list-unstyled mb-3">
                          {nextSRoute.pages?.map(ssroute => (
                            <li key={ssroute.name}>
                              <a
                                href={ssroute.path}
                                className="dropdown-item py-1"
                              >
                                {ssroute.name}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                );
              }
              return null;
            })}
          </div>
        </div>
      )}
    </>
  );
};

const AuthNavbar: React.FC = () => {
  const navbarRef = useRef(null);
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <header className="header navbar navbar-expand-lg position-absolute navbar-sticky">
        <Navbar
          ref={navbarRef}
          expand="lg"
          sticky="top"
          className="navbar navbar-expand-lg navbar-sticky py-0 container-fluid d-flex justify-content-between px-4"
        >
          <NavbarBrand />
          {/*<div className="navbar-brand me-auto">*/}
          {/*  <a href="/" className="d-flex align-items-center">*/}
          {/*    <img src={logoLight} width={230} height={"auto"} alt="Ipercom" />*/}
          {/*    <img src={logoDark} width={230} height={"auto"} alt="Ipercom" />*/}
          {/*  </a>*/}
          {/*</div>*/}
          <Navbar.Collapse
            className="offcanvas offcanvas-end"
            id="navbarNav"
            role="dialog"
            style={{ alignItems: "stretch" }}
          >
            <div className="offcanvas-header border-bottom">
              <h5 className="offcanvas-title">Menu</h5>
              <Navbar.Toggle>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                />
              </Navbar.Toggle>
            </div>
            <div className="offcanvas-body">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                {routes.map((route, index) => (
                  <Nav.Item
                    key={index}
                    className={
                      route.pages && route.pages.length ? "dropdown" : ""
                    }
                  >
                    <DropdownLink route={route} />
                  </Nav.Item>
                ))}
              </ul>
            </div>
            <div className="offcanvas-header border-top">
              {/* <a
                href="/panier"
                className="btn btn-primary btn-sm fs-8 rounded d-none d-lg-inline-flex mx-3"
                rel="noopener"
              >
                <i className="bx bx-cart fs-5 lh-1 me-1" />
                &nbsp;Panier
              </a> */}
              <a
                href="/login"
                className="btn btn-secondary btn-sm fs-8 rounded d-none d-lg-inline-flex mx-3"
                rel="noopener"
              >
                <i className="bx bx-cart fs-5 lh-1 me-1" />
                &nbsp;Se connecter
              </a>
              {/* <a
                href="/signIn"
                className="btn btn-primary btn-sm fs-8 rounded d-none d-lg-inline-flex mx-3"
                rel="noopener"
              >
                <i className="bx bx-cart fs-5 lh-1 me-1" />
                &nbsp;S'inscrire
              </a> */}
            </div>
          </Navbar.Collapse>
          <div className="d-flex align-items-center">
            <ThemeToggler />

            <Navbar.Toggle aria-controls="navbarNav">
              <span className="navbar-toggler-icon"></span>
            </Navbar.Toggle>
            {/* <a
              href="/panier"
              className="btn btn-primary btn-sm fs-8 rounded d-none d-lg-inline-flex mx-3"
              rel="noopener"
            >
              <i className="bx bx-cart fs-5 lh-1 me-1" />
              &nbsp;Panier
            </a> */}
            <a
              href="/login"
              className="btn btn-secondary btn-sm fs-8 rounded d-none d-lg-inline-flex mx-3"
              rel="noopener"
            >
              <i className="bx bx-log-in fs-5 lh-1 me-1" />
              &nbsp;Se connecter
            </a>
            {/* <a
              href="/signIn"
              className="btn btn-primary btn-sm fs-8 rounded d-none d-lg-inline-flex mx-3"
              rel="noopener"
            >
              <i className="bx bx-user fs-5 lh-1 me-1" />
              &nbsp;S'inscrire
            </a> */}
          </div>
        </Navbar>
      </header>
    </>
  );
};

export default AuthNavbar;
