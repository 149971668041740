import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Lottie from "lottie-react";
import animationData from "assets/json/animation-financial-landing.json"; // Assurez-vous d'avoir le bon chemin d'accès à votre fichier d'animation
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useParallaxHooks from "hooks/useParallaxHooks";

// Register GSAP ScrollTrigger
gsap.registerPlugin(ScrollTrigger);

const Technologies = () => {
  const containerRef = React.useRef(null);
  const parallaxElRef = React.useRef([]);

  useParallaxHooks(containerRef, parallaxElRef, {
    scrollTrigger: {
      trigger: ".gsap",
      start: "+=450 bottom"
    }
  });

  const technologiesData = [
    {
      title: "Compatibilité",
      description: "L’auto-hébergement des communications simplifie la conformité avec les réglementations nationales et internationales en matière de confidentialité et de sécurité."
    },
    {
      title: "Contrôle Total",
      description: "Gardez vos données sur vos propres serveurs à tout moment, sans aucune perte, même de métadonnées."
    },
    {
      title: "Protection Unique",
      description: "Offrez des conférences audio/vidéo privées sur site et des conversations textuelles via un navigateur et des interfaces mobiles, avec partage d’écran intégré et intégration SIP."
    },
    {
      title: "Accès Facile Partout",
      description: "Une interface web moderne et facile à utiliser, des outils bureautiques et des applications mobiles. Collaboration en temps réel et accès instantané à toutes les données depuis n’importe quel appareil, où que ce soit !"
    },
    {
      title: "RGPD et Sécurité des Données", // Modification du titre
      description: "Assurez-vous que vos données sont traitées en conformité avec le Règlement Général sur la Protection des Données (RGPD). Iper-cloud offre des solutions robustes pour garantir la sécurité et la confidentialité de vos informations personnelles, en respectant les principes de minimisation des données, de transparence et de consentement. Protégez vos données contre les accès non autorisés et les fuites grâce à des mesures de sécurité avancées, tout en respectant les droits des utilisateurs à l'accès et à l'effacement des données." // Modification du texte
    }
  ];

  // Lottie options
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <section className="bg-secondary py-5">
      <div className="container py-2 py-md-4 py-lg-5">
        <div className="row justify-content-center">
          <div className="col-md-12 align-content-center order-1 pe-lg-5">
            <h2 className="mb-sm-4 text-center">Pourquoi choisir iper-cloud ?</h2>
            <p className="text-center"><a href="/ressources/portfolio/projets-realises/iper-cloud">iper-cloud</a> est
              une solution de stockage et de partage de fichiers dans le cloud
              qui facilite l'accès, le partage et la collaboration à partir de n'importe où et à tout moment. Cette
              plateforme garantit l'intégrité des données en offrant un contrôle total sans aucun risque de fuite vers
              des tiers.</p>
            <div style={{ display: "flex", justifyContent: "space-around", flexWrap: "wrap" }}>
              <Swiper
                slidesPerView={1}
                spaceBetween={8}
                pagination={{ clickable: true }}
                breakpoints={{
                  500: {
                    slidesPerView: 2
                  },
                  800: {
                    slidesPerView: 3
                  },
                  1200: {
                    slidesPerView: 4
                  }
                }}
              >
                {technologiesData.slice(0, 4).map((technology, index) => (
                  <SwiperSlide
                    className={"pt-2"}
                    key={index}
                    style={{ width: "306px", height: "340px" }}
                  >
                    <div
                      className="card h-100 card-body card-hover mx-2"
                      style={{
                        width: "306px",
                        height: "340px",
                        border: "1px solid #eaeaea",
                        boxShadow: index === 3 ? "0px 4px 10px rgba(0, 0, 0, 0.1)" : "none" // Ombre ajoutée pour "Accès Facile Partout"
                      }}
                    >
                      <h3 className="h5 pt-3 pb-1 mb-2">{technology.title}</h3>
                      <p className="mb-0">{technology.description}</p>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            <div className="text-center" style={{ marginTop: "40px" }}>
              <h2 >Stockage sécurisé dans le cloud et partage de fichiers avec iper-cloud pour les entreprises</h2>
              <p className="d-md-none d-lg-block pb-3 mb-2 mb-md-3 mx-auto" style={{ maxWidth: "800px" }}>
                iper-cloud offre un accès direct et simple aux données à travers une variété d'outils de synchronisation
                disponibles sur le Web, mobiles, et de bureau. Les entreprises ont le choix entre un déploiement sur
                site, dans le cloud, ou une solution hybride, avec des fonctionnalités avancées de cryptage et de
                stockage de données externes.
              </p>
            </div>
            <div style={{ marginTop: "20px", width: "100%", display: "flex", justifyContent: "center" }}>
              <div className="card bg-white border-primary shadow-sm px-4 px-lg-0" data-bs-theme="light"
                   style={{ width: "95%" }}>
                <div className="row align-items-center py-3">
                  <div className="col-xl-5 col-md-6 offset-lg-1 text-center text-md-start py-4 py-lg-5">
                    <h2>RGPD et Sécurité des Données</h2> {/* Modification du titre */}
                    <p className="mb-2">
                      Assurez-vous que vos données sont traitées en conformité avec le Règlement Général sur la
                      Protection des Données (RGPD). Iper-cloud offre des solutions robustes pour garantir la sécurité
                      et la confidentialité de vos informations personnelles, en respectant les principes de
                      minimisation des données, de transparence et de consentement. Protégez vos données contre les
                      accès non autorisés et les fuites grâce à des mesures de sécurité avancées, tout en respectant les
                      droits des utilisateurs à l'accès et à l'effacement des données. {/* Modification du texte */}
                    </p>
                  </div>
                  <div className="col-xl-6 col-lg-5 col-md-6">
                    <div className="mx-auto" style={{ maxWidth: "416px" }}>
                      <Lottie {...defaultOptions} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Technologies;
