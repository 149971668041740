import React from "react";
import woman from "assets/img/ipercom/services/logiciel-de-facturation/informatique/desk_27_11zon.webp";

const UseERP = () => {
  return (
    <>
      <section className="container mt-5 pb-lg-5 pb-md-4 pb-3">
        <div className="row">
          <div className="col-md-6 pb-2 pb-md-0 mb-4 mb-md-0 order-1">
            <div className="pe-lg-5 text-center">
              <img src={woman} className="rounded-3" alt="Image" />
            </div>
          </div>
          <div className="col-md-6 align-content-center order-2">
            <h2 className="mb-4">
              Utilisez notre ERP ipercom avec une solution de surveillance et
              gestion à distance de votre parc informatique
            </h2>
            <p>
              ARTIS soutient les entreprises informatiques et les prestataires
              de services gérés (MSP) dans leur transition numérique en
              intégrant des fonctionnalités métiers directement dans son ERP et
              en se connectant à des solutions RMM (supervision et gestion à
              distance).
              <br />
              <br />
              Ces interfaces assurent une communication automatisée et sécurisée
              entre l'équipement chez les clients et le logiciel ERP artis.net.
              L'objectif est d'automatiser et de rationaliser les alertes de
              supervision et de gestion de parc clients depuis le système ERP.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default UseERP;
