import React from "react";

const Details = () => {
  return (
    <div className="col-md-8 offset-lg-1 pb-5 mb-2 mb-lg-4 pt-md-5 mt-n3 mt-md-0">
      <div className="ps-md-3 ps-lg-0 mt-md-2 py-md-4">
        <h1 className="h2 pt-xl-1 pb-3">Détails du compte</h1>
        {/* Informations de base */}
        <h2 className="h5 text-primary mb-4">Informations de base</h2>
        <form className="needs-validation border-bottom pb-3 pb-lg-4">
          <div className="row pb-2">
            <div className="col-sm-6 mb-4">
              <label htmlFor="fn" className="form-label fs-base">
                Prénom
              </label>
              <input
                type="text"
                id="fn"
                className="form-control form-control-lg"
                defaultValue="John"
              />
              <div className="invalid-feedback">
                Veuillez entrer votre prénom !
              </div>
            </div>
            <div className="col-sm-6 mb-4">
              <label htmlFor="sn" className="form-label fs-base">
                Nom de famille
              </label>
              <input
                type="text"
                id="sn"
                className="form-control form-control-lg"
                defaultValue="Doe"
              />
              <div className="invalid-feedback">
                Veuillez entrer votre nom de famille !
              </div>
            </div>
            <div className="col-sm-6 mb-4">
              <label htmlFor="email" className="form-label fs-base">
                Adresse e-mail
              </label>
              <input
                type="email"
                id="email"
                className="form-control form-control-lg"
                defaultValue="jonny@email.com"
              />
              <div className="invalid-feedback">
                Veuillez fournir une adresse e-mail valide !
              </div>
            </div>
            <div className="col-sm-6 mb-4">
              <label htmlFor="phone" className="form-label fs-base">
                Téléphone <small className="text-muted">(optionnel)</small>
              </label>
              <input
                type="text"
                id="phone"
                className="form-control form-control-lg"
                data-format='{"numericOnly": true, "delimiters": ["+1 ", " ", " "], "blocks": [0, 3, 3, 2]}'
                placeholder="+1 ___ ___ __"
              />
            </div>
            <div className="col-12 mb-4">
              <label htmlFor="bio" className="form-label fs-base">
                Bio <small className="text-muted">(optionnel)</small>
              </label>
              <textarea
                id="bio"
                className="form-control form-control-lg"
                rows={4}
                placeholder="Ajouter une courte bio..."
                defaultValue={""}
              />
            </div>
          </div>
          <div className="d-flex mb-3">
            <button type="reset" className="btn btn-secondary me-3">
              Annuler
            </button>
            <button type="submit" className="btn btn-primary">
              Enregistrer les modifications
            </button>
          </div>
        </form>
        {/* Adresse */}
        <h2 className="h5 text-primary pt-1 pt-lg-3 my-4">Adresse</h2>
        <form className="needs-validation border-bottom pb-2 pb-lg-4">
          <div className="row pb-2">
            <div className="col-sm-6 mb-4">
              <label htmlFor="country" className="form-label fs-base">
                Pays
              </label>
              <select id="country" className="form-select form-select-lg">
                <option value="">Choisir le pays</option>
                <option value="Australia">Australie</option>
                <option value="Belgium">Belgique</option>
                <option value="Canada">Canada</option>
                <option value="Denmark">Danemark</option>
                <option value="USA">États-Unis</option>
              </select>
              <div className="invalid-feedback">
                Veuillez choisir votre pays !
              </div>
            </div>
            <div className="col-sm-6 mb-4">
              <label htmlFor="state" className="form-label fs-base">
                État
              </label>
              <select id="state" className="form-select form-select-lg">
                <option value="">Choisir l'état</option>
                <option value="Arizona">Arizona</option>
                <option value="California">Californie</option>
                <option value="Iowa">Iowa</option>
                <option value="Florida">Floride</option>
                <option value="Michigan">Michigan</option>
                <option value="Texas">Texas</option>
              </select>
              <div className="invalid-feedback">
                Veuillez choisir votre état !
              </div>
            </div>
            <div className="col-sm-6 mb-4">
              <label htmlFor="city" className="form-label fs-base">
                Ville
              </label>
              <select id="city" className="form-select form-select-lg">
                <option value="">Choisir la ville</option>
                <option value="Boston">Boston</option>
                <option value="Chicago">Chicago</option>
                <option value="Los Angeles">Los Angeles</option>
                <option value="Miami">Miami</option>
                <option value="New York">New York</option>
                <option value="Philadelphia">Philadelphie</option>
              </select>
              <div className="invalid-feedback">
                Veuillez choisir votre ville !
              </div>
            </div>
            <div className="col-sm-6 mb-4">
              <label htmlFor="zip" className="form-label fs-base">
                Code postal
              </label>
              <input
                type="text"
                id="zip"
                className="form-control form-control-lg"
              />
              <div className="invalid-feedback">
                Veuillez entrer votre code postal !
              </div>
            </div>
            <div className="col-12 mb-4">
              <label htmlFor="address1" className="form-label fs-base">
                Adresse ligne 1
              </label>
              <input
                type="text"
                id="address1"
                className="form-control form-control-lg"
              />
            </div>
            <div className="col-12 mb-4">
              <label htmlFor="address2" className="form-label fs-base">
                Adresse ligne 2{" "}
                <small className="text-muted">(optionnel)</small>
              </label>
              <input
                type="text"
                id="address2"
                className="form-control form-control-lg"
              />
            </div>
          </div>
          <div className="d-flex mb-3">
            <button type="reset" className="btn btn-secondary me-3">
              Annuler
            </button>
            <button type="submit" className="btn btn-primary">
              Enregistrer les modifications
            </button>
          </div>
        </form>
        {/* Supprimer le compte */}
        <h2 className="h5 text-primary pt-1 pt-lg-3 mt-4">
          Supprimer le compte
        </h2>
        <p>
          Lorsque vous supprimez votre compte, votre profil public sera
          immédiatement désactivé. Si vous changez d'avis avant la fin des 14
          jours, connectez-vous avec votre adresse e-mail et votre mot de passe,
          et nous vous enverrons un lien pour réactiver votre compte.
        </p>
        <div className="form-check mb-4">
          <input
            type="checkbox"
            id="delete-account"
            className="form-check-input"
          />
          <label htmlFor="delete-account" className="form-check-label fs-base">
            Oui, je veux supprimer mon compte
          </label>
        </div>
        <button type="button" className="btn btn-danger">
          Supprimer
        </button>
      </div>
    </div>
  );
};

export default Details;
