import React from "react";
import Head from "components/modules/ressources/blog/Novelties/Guide pour la creation d'application mobiles/Head";
import Paragraph
  from "components/modules/ressources/blog/Novelties/Guide pour la creation d'application mobiles/Paragraph";
import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";

const AppsIntegratedIPBX = () => {
  return (
    <>
      <Breadcrumbs />
      <Head />
      <Paragraph />
    </>
  );
};

export default AppsIntegratedIPBX;
