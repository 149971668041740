import React from "react";
import { Col, Row } from "react-bootstrap";

const WhyUs = () => {
  return (
    <>
      {/* Features (Icon boxes) */}
      <section className="container py-3 my-2 my-md-4 my-lg-5">
        <h2 className="text-center pt-1 pt-xl-3 mb-lg-4">Pourquoi nous ?</h2>
        <Row>
          {/* Item */}
          <Col lg={3}>
            <div className="card h-100 card-body card-hover mx-2">
              <i
                className="bx bx-rocket display-5 fw-normal card-icon"
                style={{ color: "#b4b7c9" }}
              />
              <h3 className="h5 pt-3 pb-1 mb-2">Solutions innovantes</h3>
              <p className="mb-0">
                Simplifiez votre facturation avec nos solutions innovantes.
                Gérez vos finances efficacement dès aujourd'hui.
              </p>
            </div>
          </Col>
          {/* Item */}
          <Col lg={3}>
            <div className="card h-100 card-body card-hover mx-2">
              <i
                className="bx bx-like display-5 fw-normal card-icon"
                style={{ color: "#b4b7c9" }}
              />
              <h3 className="h5 pt-3 pb-1 mb-2">Garantie de réussite</h3>
              <p className="mb-0">
                Notre engagement pour la réussite en facturation assure une
                gestion financière optimale.
              </p>
            </div>
          </Col>
          {/* Item */}
          <Col lg={3}>
            <div className="card h-100 card-body card-hover mx-2">
              <i
                className="bx bx-time-five display-5 fw-normal card-icon"
                style={{ color: "#b4b7c9" }}
              />
              <h3 className="h5 pt-3 pb-1 mb-2">Service à temps</h3>
              <p className="mb-0">
                Avec notre équipe spécialisée en facturation, simplifiez votre
                gestion financière.
              </p>
            </div>
          </Col>
          {/* Item */}
          <Col lg={3}>
            <div className="card h-100 card-body card-hover mx-2">
              <i
                className="bx bx-group display-5 fw-normal card-icon"
                style={{ color: "#b4b7c9" }}
              />
              <h3 className="h5 pt-3 pb-1 mb-2">Equipe professionelle</h3>
              <p className="mb-0">
                Gagnez du temps avec nos outils innovants et simplifiez votre
                quotidien professionnel.
              </p>
            </div>
          </Col>
        </Row>
        {/* Pagination (bullets) */}
        <div className="d-flex flex-column flex-sm-row justify-content-center pt-3 pt-sm-4">
          <a
            href="/demander-un-devis"
            className="btn btn-primary shadow-primary btn-lg"
          >
            Demander un devis
          </a>
        </div>
      </section>
    </>
  );
};

export default WhyUs;
