import React from "react";
import LastNews from "components/modules/showcase/LastNews";
import Head from "./Head";
import Description from "./Description";

const CommercialTrainingIPERX = () => {
  return (
    <>
      <Head />
      <Description />
      <LastNews />
    </>
  );
};

export default CommercialTrainingIPERX;
