import React from "react";
import mobile from "assets/img/ipercom/services/logiciel-de-facturation/telecom/mobile_11_11zon.webp";

const InvoiceManagement = () => {
  return (
    <>
      <section className="container mt-5 pb-lg-5 pb-md-4 pb-3">
        <div className="row">
          <div className="col-md-6 pb-2 pb-md-0 mb-4 mb-md-0 order-2">
            <div className="pe-lg-5 text-center">
              <img src={mobile} className="rounded-3" alt="Image" />
            </div>
          </div>
          <div className="col-md-6 align-content-center order-1">
            <h2 className="mb-sm-4">
              Facilitez la gestion de vos factures Télécom
            </h2>
            <p className="d-md-none d-xl-block pb-2 pb-md-3 mb-3">
              Le module Télécom d'artis.net facilite la gestion des opérations
              téléphoniques dans l'ERP, permettant la vente et la facturation
              périodique de services télécoms tels que VGA, VOIP, DATA, et
              mobiles. La facturation est effectuée à partir des modules, qui
              enregistrent les détails des appels (fixes, VoIP, mobiles) pour
              une facturation précise des consommations mensuelles, incluant les
              utilisations dans et hors forfait.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default InvoiceManagement;
