const WhatIsItFor = () => {
  return (
    <section className="container mb-2 pb-lg-2 pb-md-2 pb-2">
      <div className="text-start pb-4 pb-md-0 mb-md-4 mx-auto">
        <h2>À quoi ça sert ?</h2>
      </div>
      {/* Steps */}
      <div className="steps steps-sm steps-horizontal-md ">
        <div className=" m-3">
          <div className="step-number ">
            <div className="step-number-inner">1</div>
          </div>
          <div className="step-body align-content-center">
            <h3 className="h4 mb-3">Gestion des relations clients </h3>
            <p className="mb-0">
              Optimisez votre relation client avec une gestion efficace.
              Améliorez la satisfaction et la fidélité dès maintenant !
            </p>
          </div>
        </div>
        <div className="m-3">
          <div className="step-number ">
            <div className="step-number-inner">2</div>
          </div>
          <div className="step-body align-content-center">
            <h3 className="h4 mb-3">Automatisation des ventes</h3>
            <p className="mb-0">
              Boostez vos ventes avec l'automatisation efficace. Gagnez du temps
              et maximisez vos revenus dès aujourd'hui !{" "}
            </p>
          </div>
        </div>
        <div className="m-3">
          <div className="step-number ">
            <div className="step-number-inner">3</div>
          </div>
          <div className="step-body align-content-center">
            <h3 className="h4 mb-3">Service client</h3>
            <p className="mb-0">
              Améliorez votre service client pour une expérience client
              mémorable. Répondez aux besoins et dépassez les attentes !
            </p>
          </div>
        </div>
        <div className="m-3">
          <div className="step-number ">
            <div className="step-number-inner">4</div>
          </div>
          <div className="step-body align-content-center">
            <h3 className="h4 mb-3">Analyse et reporting</h3>
            <p className="mb-0">
              Maximisez vos performances grâce à une analyse précise et des
              rapports détaillés. Boostez votre stratégie !
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhatIsItFor;
