import Head_ourcrm from "components/modules/services/our-crm/OurCRMHead";
import OurCRMOptimize from "components/modules/services/our-crm/OurCRMOptimize";
import Partners from "components/modules/showcase/Partners";
import OurCRMAdvantages from "components/modules/services/our-crm/OurCRMAdvantages";
import React from "react";
import Collaborater from "components/modules/showcase/Collaborater";
import ClientTalk from "components/modules/showcase/ClientTalk";
import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";

const OurCrm = () => {
  return (
    <>
      <div className="div">
        <Head_ourcrm />
        <Breadcrumbs />
        <OurCRMOptimize />
        <OurCRMAdvantages />
        <Partners />
        <ClientTalk />
        <Collaborater />
      </div>
    </>
  );
};

export default OurCrm;
