import React, { useState } from "react";
import axios from "axios";
import { Button, Form } from "react-bootstrap";

const NewsletterForm: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);

    try {
      const response = await axios.post("https://rct-backend.ipercom.io/api/CSoonNewsleter", { email });

      if (response.status === 200) {
        setSuccess(true);
        setEmail(""); // Clear the email field
      }
    } catch (error) {
      // setError('There was an issue with your subscription. Please try again.');
      // setSuccess(true);
      setEmail(""); // Clear the email field
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Form className="needs-validation" noValidate onSubmit={handleSubmit}>
      <Form.Label htmlFor="subscr-email" className="text-color-classes">
        Abonnez-vous à notre newsletter
      </Form.Label>
      <div className="input-group">
        <Form.Control
          type="email"
          id="subscr-email"
          className="form-control rounded-start"
          placeholder="Votre e-mail"
          required
          value={email}
          onChange={handleEmailChange}
        />
        <Button type="submit" className="btn btn-primary" disabled={isSubmitting}>
          S'abonner
        </Button>
      </div>
      {error && <div className="alert alert-danger mt-3">{error}</div>}
      {success && <div className="alert alert-success mt-3">Merci de vous être abonné(e)!</div>}
    </Form>
  );
};

export default NewsletterForm;
