import Head from "components/modules/ressources/documentation/livres blancs/Head";
import LastNews from "components/modules/showcase/LastNews";
import Partners from "components/modules/showcase/Partners";
import WhatsAWhiteBook from "components/modules/ressources/documentation/livres blancs/WhatsAWhiteBook";
import React from "react";
import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";

const WhiteBooks = () => {
  return (
    <>
      <Head />
      <Breadcrumbs />
      <WhatsAWhiteBook />
      <LastNews />
      <Partners />
    </>
  );
};

export default WhiteBooks;
