import React, { useState } from "react";
import womenblack from "assets/img/ipercom/ressources/blog/Articles/article/womenBlack.webp";
import computer from "assets/img/ipercom/ressources/blog/Articles/article/computer.webp";
import mencomputer from "assets/img/ipercom/ressources/blog/Articles/article/mencomputer.webp";

const ArticlesLists = () => {

  const [selectedCategory, setSelectedCategory] = useState("all");
  const [articles, setArticles] = useState([
    {
      category: "Digital",
      color: "blue",
      image: womenblack,
      title: "Optimiser l'Expérience Utilisateur (UX) : Les Clés d\'une Interface Utilisateur (UI) Efficace",
      summary: "Optimiser l'Expérience Utilisateur (UX)",
      date: "May 9, 2023",
      url: "/ressources/blog/articles-tendances-developpement-logiciel/optimiser-experience-utilisateur"
    },
    {
      category: "Business",
      color: "black",
      image: computer,
      title: "Comment l'Intelligence Artificielle Révolutionne le Marketing : Stratégies et Tendances à Suivre",
      summary: "Comment l'Intelligence Artificielle Révolutionne le Marketing",
      date: "May 19, 2024",
      url: "/ressources/blog/articles-tendances-developpement-logiciel/conception-ux"
    },
    {
      category: "Technologie",
      color: "red",
      image: mencomputer,
      title: "Maîtriser React.js et C#: Guide Complet pour Développer des Applications Web Performantes",
      summary: "Maîtriser React.js et C#",
      date: "Jul 16, 2024",
      url: "/ressources/blog/articles-tendances-developpement-logiciel/reactjs-c"
    },
    {
      category: "Startups",
      color: "green",
      image: computer,
      title: "Les Coulisses des Startups : Découvrez Comment Elles Travaillent et Révolutionnent l'Industrie",
      summary: "Les Coulisses des Startups",
      date: "May 10, 2024",
      url: "/ressources/blog/articles-tendances-developpement-logiciel/coulisses-des-startups"
    },
    {
      category: "Business",
      color: "black",
      image: computer,
      title: "Pourquoi la conception UX est importante et comment elle affecte le classement",
      summary: "Pourquoi la conception UX est importante et comment elle affecte le classement",
      date: "May 19, 2024",
      url: "/ressources/blog/articles-tendances-developpement-logiciel/conception-ux"
    }
  ]);

  const filteredArticles = selectedCategory === "all" ? articles : articles.filter(article => article.category === selectedCategory);

  const handleCategoryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCategory(e.target.value);
  };


  return (
    <section className="container mb-3">
      {/* Page title + Layout switcher */}
      <div className="d-flex align-items-center justify-content-between mb-4 pb-1 pb-md-3">
        <h1 className="mb-0">
          Articles sur les tendances du développement logiciel
        </h1>
        <div className="col-lg-7 col-md-8">
          <form className="row gy-2">
            <div className="col-lg-5 col-sm-6">
              <div className="d-flex align-items-center">
                <select className="form-select" onChange={handleCategoryChange}>
                  <option value="all">Toutes catégories</option>
                  <option value="Digital">
                    Digital
                  </option>
                  <option value="Business">Business</option>
                  <option value="Technologie">Technologie</option>
                  <option value="Startups">Startups</option>
                </select>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* Blog list + Sidebar */}
      <div className="row">
        <div className="col-xl-9 col-lg-8">
          {filteredArticles.map((article, index) => (
            <article className="card border-0 bg-transparent me-xl-5 mb-4" key={index}>
              <div className="row g-0">
                <div className="col-sm-4 position-relative bg-position-center bg-repeat-0 bg-size-cover rounded-3"
                  style={{
                    backgroundImage: `url(${article.image})`,
                    minHeight: "15rem"
                  }}
                >
                </div>
                <div className="col-sm-8">
                  <div className="card-body px-0 pt-sm-0 ps-sm-4 pb-0 pb-sm-4">
                    <p
                      className="badge fs-sm text-white shadow-info text-decoration-none mb-3 opacity-60" style={{ backgroundColor: article.color }}
                    >
                      {article.category.charAt(0).toUpperCase() + article.category.slice(1)}
                    </p>
                    <h2 className="h4">
                      <a href={article.url}>
                        {article.title}
                      </a>
                    </h2>
                    <p className="mb-4">
                      {/* Résumé de l'article */}
                    </p>
                    <div className="d-flex align-items-center text-muted">
                      <div className="fs-sm border-end pe-3 me-3">
                        {article.date}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          ))}
          <div className="pb-2 pb-lg-3" />
        </div>
        <aside className="col-xl-3 col-lg-4">
          <div className="offcanvas-lg offcanvas-end" id="blog-sidebar" tabIndex={-1}>
            <div className="offcanvas-body">
              <div className="card card-body mb-4">
                <h3 className="h5">Sommaire</h3>
                <ul className="nav flex-column fs-sm">
                  {articles.map((article, index) => (
                    <li className="nav-item mb-1" key={index}>
                      <a className="nav-link" href={article.url}>
                        <div className="flex-grow-1">{article.summary}</div>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </aside>
      </div>
    </section>
  );
};

export default ArticlesLists;
