import React from "react";
import Headset from 'assets/img/ipercom/icons/headset.webp';
import Wifi from 'assets/img/ipercom/icons/_wifi.webp';
import Xray from 'assets/img/ipercom/icons/x-ray.svg';
import Stethoscope from 'assets/img/ipercom/icons/stethoscope.webp';
import Lungs from 'assets/img/ipercom/icons/lungs.webp';
import Tooth from 'assets/img/ipercom/icons/tooth.svg';
import Cardiology from 'assets/img/ipercom/icons/cardiology.webp';
import Scalpel from 'assets/img/ipercom/icons/scalpel.webp';
import Headphone from 'assets/img/ipercom/icons/bx-headphone.webp';

const HowVoIPWorks = () => {
  return (
    <>
      <section style={{ width: "85%", margin: "auto" }} className="mb-4">
        <div className="row">
          <div className="col-lg-3 text-center text-lg-start">
            <h2 className="mb-lg-4">Fonctionnement de la VoIP</h2>
            <p className="pb-4 mb-0 mb-lg-3">
              Le principe de fonctionnement d'un IPBX repose sur la technologie
              VoIP, ou Voix sur IP. Contrairement à la téléphonie classique
              basée sur des lignes physiques, la VoIP permet de passer et
              recevoir des appels via Internet, grâce à des terminaux SIP comme
              les téléphones IP ou les ordinateurs. Pour acheminer la voix sur
              les réseaux IP, cette méthode nécessite plusieurs étapes.
            </p>
          </div>
          <div className="col-xl-9">
            <div className="row">
              <div className="col">
                <div className="card card-hover bg-secondary border-0 mb-4">
                  <div className="card-body d-flex align-items-start">
                    <div className="flex-shrink-0 bg-light rounded-3 p-3">
                      <img src={Headset} width={28} alt="Icon" />
                    </div>
                    <div className="ps-4">
                      <h3 className="h5 pb-2 mb-1">
                        Changement du son en signaux
                      </h3>
                      <p className="pb-2 mb-1">
                        Le terminal SIP convertit le son en signaux électriques
                        via un pilote audio.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card card-hover bg-secondary border-0 mb-4">
                  <div className="card-body d-flex align-items-start">
                    <div className="flex-shrink-0 bg-light rounded-3 p-3">
                      <img src={Wifi} width={28} alt="Icon" />
                    </div>
                    <div className="ps-4">
                      <h3 className="h5 pb-2 mb-1">
                        Passage des signaux au codec
                      </h3>
                      <p className="pb-2 mb-1">
                        Les signaux sont transformés en langage binaire par un
                        logiciel nommé codec.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card card-hover bg-secondary border-0 mb-4">
                  <div className="card-body d-flex align-items-start">
                    <div className="flex-shrink-0 bg-light rounded-3 p-3">
                      <img src={Xray} width={28} alt="Icon" />
                    </div>
                    <div className="ps-4">
                      <h3 className="h5 pb-2 mb-1"> Séparation des données</h3>
                      <p className="pb-2 mb-1">
                        Les données informatiques sont ensuite décomposées en
                        paquets IP.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card card-hover bg-secondary border-0 mb-4">
                  <div className="card-body d-flex align-items-start">
                    <div className="flex-shrink-0 bg-light rounded-3 p-3">
                      <img src={Stethoscope} width={28} alt="Icon" />
                    </div>
                    <div className="ps-4">
                      <h3 className="h5 pb-2 mb-1">Trajet des informations</h3>
                      <p className="pb-2 mb-1">
                        Les paquets sont transmis au destinataire de l'appel via
                        les réseaux IP en plusieurs étapes.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card card-hover bg-secondary border-0 mb-4">
                  <div className="card-body d-flex align-items-start">
                    <div className="flex-shrink-0 bg-light rounded-3 p-3">
                      <img src={Lungs} width={28} alt="Icon" />
                    </div>
                    <div className="ps-4">
                      <h3 className="h5 pb-2 mb-1">Source de l'appel</h3>
                      <p className="pb-2 mb-1">
                        Depuis l'ordinateur, l'émetteur de l'appel établit une
                        connexion directe.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card card-hover bg-secondary border-0 mb-4">
                  <div className="card-body d-flex align-items-start">
                    <div className="flex-shrink-0 bg-light rounded-3 p-3">
                      <img src={Tooth} width={28} alt="Icon" />
                    </div>
                    <div className="ps-4">
                      <h3 className="h5 pb-2 mb-1">Trajet par routeur</h3>
                      <p className="pb-2 mb-1">
                        Via la fibre optique le signal transite par un routeur.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card card-hover bg-secondary border-0 mb-4">
                  <div className="card-body d-flex align-items-start">
                    <div className="flex-shrink-0 bg-light rounded-3 p-3">
                      <img src={Cardiology} width={28} alt="Icon" />
                    </div>
                    <div className="ps-4">
                      <h3 className="h5 pb-2 mb-1">Destinataire de l'appel</h3>
                      <p className="pb-2 mb-1">
                        Recevez les appels entrants sur votre ordinateur en tant
                        que destinataire de l'appel.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card card-hover bg-secondary border-0 mb-4">
                  <div className="card-body d-flex align-items-start">
                    <div className="flex-shrink-0 bg-light rounded-3 p-3">
                      <img src={Scalpel} width={28} alt="Icon" />
                    </div>
                    <div className="ps-4">
                      <h3 className="h5 pb-2 mb-1">Traitement à l'arrivée</h3>
                      <p className="pb-2 mb-1">
                        À l'arrivée, les paquets sont traités et décompressés
                        par un codec.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card card-hover bg-secondary border-0 mb-4">
                  <div className="card-body d-flex align-items-start">
                    <div className="flex-shrink-0 bg-light rounded-3 p-3">
                      <img src={Headphone} width={28} alt="Icon" />
                    </div>
                    <div className="ps-4">
                      <h3 className="h5 pb-2 mb-1">
                        Finalisation de la réception
                      </h3>
                      <p className="pb-2 mb-1">
                        Le son est enfin renvoyé au récepteur via un
                        périphérique de réception tel qu'un téléphone IP ou un
                        softphone.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HowVoIPWorks;
