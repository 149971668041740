import ModalDevis from "pages/coming-soon/ModalDevis";
import React, { useState } from "react";
import { Button } from "react-bootstrap";


type ModalType = "devis" | "demo" | "revendeur";


const Description = () => {

  const [showModal, setShowModal] = useState({
    devis: false,
    demo: false,
    revendeur: false
  });

  const handleShow = (modal: ModalType) =>
    setShowModal({ ...showModal, [modal]: true });
  const handleClose = (modal: ModalType) =>
    setShowModal({ ...showModal, [modal]: false });

  return (
    <section className="container my-">
      <div className="row">
        {/* Sidebar (Course summary) */}
        <aside className="col-lg-4 col-md-5 offset-xl-1 order-md-2 mb-5">
          <div style={{ marginTop: "-96px" }} />
          <div className="pt-5">
            <div className="pt-5 mt-md-3">
              <div className="card shadow-sm p-sm-3">
                <div className="card-body">
                  <h2 className="mb-4">Ce cours comprend :</h2>
                  <ul className="list-unstyled pb-3">
                    <li className="d-flex align-items-center mb-2">
                      <i className="bx bx-file fs-xl text-muted me-2 pe-1" />6
                      documents
                    </li>
                    <li className="d-flex align-items-center mb-2">
                      <i className="bx bx-download fs-xl text-muted me-2 pe-1" />
                      5 ressources téléchargeables
                    </li>
                    <li className="d-flex align-items-center mb-2">
                      <i className="bx bx-trophy fs-xl text-muted me-2 pe-1" />
                      Certificat d'achèvement
                    </li>
                  </ul>
                  <div className="h3 mb-0">290€/Jour hors frais*</div>
                  <Button
                  variant="primary"
                  className="btn btn-primary btn-lg shadow-primary mt-2"
                  onClick={() => handleShow("devis")}
                >
                  Demander un devis
                </Button>
                </div>
              </div>
              <p className="text-muted">
                *Hors frais de déplacement pour les formations en présentielle{" "}
              </p>
            </div>
          </div>
        </aside>
        {/* Content */}
        <div className="col-xl-7 col-lg-8 col-md-7 order-md-1 mt-4">
          <h2>Description de la formation</h2>
          <p className="pb-2 mb-3">
            Cette formation offre aux participants une compréhension approfondie
            de l'application mobile <a href="/services/solution-telephonie/logiciel-de-telephonie-iper-x"> 
            iper-X</a>, leur permettant ainsi d'exploiter
            pleinement les fonctionnalités de communication disponibles. À
            travers des sessions interactives et des exercices pratiques, les
            apprenants apprendront à configurer, gérer et utiliser l'application
            sur les appareils mobiles. Cette formation est conçue pour améliorer
            la productivité et la collaboration au sein de l'entreprise en
            exploitant les capacités avancées de l'application mobile iper-X.
          </p>
          <h3 className="mb-4">Ce que vous apprendrez</h3>
          <ul className="list-unstyled mb-5">
            <li className="d-flex align-items-center mb-2">
              <i className="bx bx-check-circle text-primary fs-xl me-2" />
              Configuration de l'application : Vous apprendrez à configurer
              l'application mobile IPBX sur différents appareils pour une
              utilisation optimale.
            </li>
            <li className="d-flex align-items-center mb-2">
              <i className="bx bx-check-circle text-primary fs-xl me-2" />
              Fonctionnalités de communication : Vous découvrirez comment
              utiliser les fonctionnalités de communication telles que les
              appels vocaux, la messagerie instantanée et la vidéoconférence via
              l'application mobile IPBX.
            </li>
            <li className="d-flex align-items-center mb-2">
              <i className="bx bx-check-circle text-primary fs-xl me-2" />
              Gestion des contacts : Vous apprendez à gérer vos contacts
              professionnels à travers l'application, en ajoutant, modifiant et
              organisant les informations de contact.
            </li>
          </ul>
          <h2 className="mb-4">Détails du programme</h2>
          <div className="steps steps-sm">
            <div className="step">
              <div className="step-number">
                <div className="step-number-inner">1</div>
              </div>
              <div className="step-body">
                <h3 className="h4 mb-2">
                  Session 1 : Introduction à l'application mobile iper-X
                </h3>
                <ul className="mb-0">
                  <li>Présentation d'iper-X et de ses fonctionnalités.</li>
                  <li>
                    Avantages de l'application mobile pour la communication
                    d'entreprise.
                  </li>
                </ul>
              </div>
            </div>
            <div className="step">
              <div className="step-number">
                <div className="step-number-inner">2</div>
              </div>
              <div className="step-body">
                <h3 className="h4 mb-2">
                  Session 2 : Configuration et Installation de l'Application
                </h3>
                <ul className="mb-0">
                  <li>
                    Téléchargement et installation de l'application sur
                    différents appareils mobiles (iOS et Android).
                  </li>
                  <li>
                    Configuration initiale de l'application avec les
                    identifiants d'utilisateur et les paramètres.
                  </li>
                </ul>
              </div>
            </div>
            <div className="step">
              <div className="step-number">
                <div className="step-number-inner">3</div>
              </div>
              <div className="step-body">
                <h3 className="h4 mb-2">
                  Session 3 : Navigation dans l'Application
                </h3>
                <ul className="mb-0">
                  <li>
                    Présentation de l'interface utilisateur de l'application
                    mobile.
                  </li>
                  <li>
                    Exploration des différentes sections : appels, contacts,
                    historique, chat, réglages, etc.
                  </li>
                </ul>
              </div>
            </div>
            <div className="step">
              <div className="step-number">
                <div className="step-number-inner">4</div>
              </div>
              <div className="step-body">
                <h3 className="h4 mb-2">
                  Session 4 : Gestion des Appels et Contacts
                </h3>
                <ul className="mb-0">
                  <li>
                    Réalisation d'appels vocaux et vidéo depuis l'application
                    mobile.
                  </li>
                  <li>
                    Utilisation des fonctionnalités avancées telles que la mise
                    en attente, le transfert d'appel et la conférence
                    téléphonique.
                  </li>
                  <li>
                    Importation et synchronisation des contacts depuis le
                    répertoire de l'appareil mobile.
                  </li>
                  <li>
                    Création de listes de contacts favoris et de groupes pour
                    une communication plus efficace.
                  </li>
                </ul>
              </div>
            </div>
            <div className="step">
              <div className="step-number">
                <div className="step-number-inner">5</div>
              </div>
              <div className="step-body">
                <h3 className="h4 mb-2">
                  Session 5 : Dépannage et Support Technique
                </h3>
                <ul className="mb-0">
                  <li>
                    Résolution des problèmes courants liés à l'utilisation de
                    l'application mobile.
                  </li>
                  <li>
                    Accès aux ressources du spport technique et aux guides de
                    dépannage en cas de besoin.
                  </li>
                </ul>
              </div>
            </div>
            <div className="step align-items-center">
              <div className="step-number mb-5 ">
                <div className="step-number-inner">6</div>
              </div>
              <div className="step-body">
                <h3 className="h4 mb-2">
                  Session 6 : Exercices Pratiques et Études de Cas
                </h3>
                <ul className="mb-0">
                  <li>
                    Exercices pratiques pour mettre en pratique les compétences
                    acquises.
                  </li>
                  <li>
                    Études de cas basées sur des scénarios réels pour illustrer
                    l'utilisation de l'application dans des situations
                    professionnelles variées.
                  </li>
                </ul>
              </div>
            </div>
            <div className="step align-items-center">
              <div className="step-number mb-5 ">
                <div className="step-number-inner">7</div>
              </div>
              <div className="step-body">
                <h3 className="h4 mb-2">
                  Session 7 : Évaluation et Certification
                </h3>
                <ul className="mb-0">
                  <li>
                    Évaluation des connaissances et des compétences à la fin de
                    la formation.
                  </li>
                  <li>
                    Délivrance d'une certification de réussite pour les
                    participants ayant satisfait aux critères d'évalutaion.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalDevis showModal={showModal.devis} handleClose={() => handleClose("devis")} />

    </section>
  );
};

export default Description;
