import React from "react";
import Head from "components/modules/services/company/big/Head";
import Solution from "components/modules/services/company/big/Solution";
import Avantages from "components/modules/services/company/big/Avantages";
import ClientTalk from "components/modules/showcase/ClientTalk";
import Partners from "components/modules/showcase/Partners";
import Interest from "components/modules/services/company/big/Interest";
import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";

const ErpForBigCompany = () => {
  return (
    <>
      <Head />
      <Breadcrumbs />
      <Interest />
      <Solution />
      <Avantages />
      <ClientTalk />
      <Partners />
    </>
  );
};

export default ErpForBigCompany;
