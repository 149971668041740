import React, { useState } from "react";
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import { currencyFormat } from 'helpers/utils';
import { Card } from 'react-bootstrap';
import OrderSummaryDetails from './OrderSummaryDetails';

const CartSummaryCard = () => {
  const [couponCode, setCouponCode] = useState('');

  const handleCouponCodeChange = () => {
    setCouponCode("test");
  };

  const applyCoupon = () => {
    console.log('Coupon applied:', couponCode);
  };

  return (
    <Card>
      <Card.Body>
        <div className="d-flex flex-between-center mb-3">
          <h3 className="mb-0">Récapitulatif</h3>
        </div>
        <OrderSummaryDetails />
        <div className="input-group mb-3">
          <input
            type="text"
            placeholder="Code de réduction"
            value={couponCode}
            onChange={handleCouponCodeChange}
            className="form-control rounded-start"
          />
          <Button
            variant="light"
            className=""
            onClick={applyCoupon}
            style={{ backgroundColor: "#F5F7FA", color: "#CD1F90" }}
          >
            Appliquer
          </Button>
        </div>

        <div className="d-flex justify-content-between border-y border-dashed py-3 mb-4">
          <h4 className="mb-0">Total :</h4>
          <h4 className="mb-">
            {currencyFormat(695.2, { minimumFractionDigits: 2 })}
          </h4>
        </div>

        <Button
          className="w-100"
          variant="primary"
          endIcon={
            <FontAwesomeIcon icon={faChevronRight} className="ms-1 fs-10" />
          }
          href="/panier/paiement"
        >
          Paiement
        </Button>
      </Card.Body>
    </Card>
  );
};

export default CartSummaryCard;
