import React from 'react';
import { Autoplay } from "swiper/modules";
import SwiperCore from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/swiper-bundle.css";
import 'swiper/css';
import 'swiper/css/pagination';
import "swiper/css/navigation";

import news1 from "assets/img/ipercom/showcase/01.webp";
import news2 from "assets/img/ipercom/showcase/02.webp";
import news3 from "assets/img/ipercom/showcase/03.webp";

import logo_ipercom from "assets/img/ipercom/services/ERP/Group.svg";

import { Link } from "react-router-dom";

SwiperCore.use([Autoplay]);

const LastNews = () => {

  const newsItems = [
    {
      title:
        "Transformation numérique des entreprises : adoption de nouvelles technologies, résistance au changement",
      slug: "transformation-numerique",
      category: "Affaire",
      time: "il y a 12 heures",
      image: news1,
      author: "Service développement",
      role: "Directeur adjoint, Département des capitaux",
      avatar: logo_ipercom
    },
    {
      title:
        "Blockchain et cryptomonnaies : tendances du marché des cryptomonnaies",
      slug: "blockchain-cryptomonnaies",
      category: "Entreprise",
      time: "il y a 1 jour",
      image: news2,
      author: "Service développement",
      role: "Experte du secteur financier",
      avatar: logo_ipercom
    },
    {
      title:
        "Sécurité informatique : tendances des cybermenaces",
      slug: "securite-informatique",
      category: "Finance",
      time: "24 mai 2024",
      image: news3,
      author: "Service développement",
      role: "Conseiller Financier et Directeur",
      avatar: logo_ipercom
    },

    {
      title:
        "Les entreprises révisent leurs stratégies data sous l'impact de l'IA",
      slug: "strategies-entreprises-ia",
      category: "Entreprise",
      time: "il y a 12 heures",
      image: news2,
      author: "Service développement",
      role: "Experte du secteur financier",
      avatar: logo_ipercom
    },
    {
      title:
        "Transformation numérique des entreprises : adoption de nouvelles technologies, résistance au changement",
      slug: "transformation-numerique",
      category: "Affaire",
      time: "il y a 12 heures",
      image: news1,
      author: "Service développement",
      role: "Directeur adjoint, Département des capitaux",
      avatar: logo_ipercom
    },
    {
      title:
        "Blockchain et cryptomonnaies : tendances du marché des cryptomonnaies",
      slug: "blockchain-cryptomonnaies",
      category: "Entreprise",
      time: "il y a 1 jour",
      image: news2,
      author: "Service développement",
      role: "Experte du secteur financier",
      avatar: logo_ipercom
    },
    {
      title:
        "Sécurité informatique : tendances des cybermenaces",
      slug: "securite-informatique",
      category: "Finance",
      time: "14 mai 2024",
      image: news3,
      author: "Service développement",
      role: "Conseiller Financier et Directeur",
      avatar: logo_ipercom
    },

    {
      title:
        "Les entreprises révisent leurs stratégies data sous l'impact de l'IA",
      slug: "strategies-entreprises-ia",
      category: "Entreprise",
      time: "il y a 12 heures",
      image: news2,
      author: "Service développement",
      role: "Experte du secteur financier",
      avatar: logo_ipercom
    }, {
      title:
        "Transformation numérique des entreprises : adoption de nouvelles technologies, résistance au changement",
      slug: "transformation-numerique",
      category: "Affaire",
      time: "il y a 12 heures",
      image: news1,
      author: "Service développement",
      role: "Directeur adjoint, Département des capitaux",
      avatar: logo_ipercom
    },
    {
      title:
        "Blockchain et cryptomonnaies : tendances du marché des cryptomonnaies",
      slug: "blockchain-cryptomonnaies",
      category: "Entreprise",
      time: "il y a 1 jour",
      image: news2,
      author: "Service développement",
      role: "Experte du secteur financier",
      avatar: logo_ipercom
    },
    {
      title:
        "Sécurité informatique : tendances des cybermenaces",
      slug: "securite-informatique",
      category: "Finance",
      time: "24 juin 2024",
      image: news3,
      author: "Service développement",
      role: "Conseiller Financier et Directeur",
      avatar: logo_ipercom
    },

    {
      title:
        "Les entreprises révisent leurs stratégies data sous l'impact de l'IA",
      slug: "strategies-entreprises-ia",
      category: "Entreprise",
      time: "il y a 12 heures",
      image: news2,
      author: "Service développement",
      role: "Experte du secteur financier",
      avatar: logo_ipercom
    },
  ];

  return (
    <section className="bg-secondary py-1">
      <div className="container py-2 py-md-4 py-lg-5">
        <h2 className="text-center pb-4 mb-1 mb-lg-3">Dernières nouvelles</h2>
        <div className="position-relative px-xl-5">
          <Swiper
            spaceBetween={20}
            slidesPerView={3}
            centeredSlides={true}
            loop={true}
            autoplay={{ delay: 2000, disableOnInteraction: false }}
            breakpoints={{

              0: {
                slidesPerView: 1,
                spaceBetween: 10
              },

              568: {
                slidesPerView: 2,
                spaceBetween: 15
              },

              1024: {
                slidesPerView: 3,
                spaceBetween: 20
              }
            }}
          >
            {newsItems.map((item, index) => (
              <SwiperSlide key={index} className="h-auto pb-3">
                <article className="card h-100 border-0 shadow-sm mx-2" style={{ width: "auto", height: "auto" }}>
                  <div className="position-relative">
                    <img
                      src={item.image}
                      className="card-img-top"
                      alt="Image"

                    />
                  </div>
                  <div className="card-body pb-4">
                    <div className="d-flex align-items-center justify-content-between mb-3">
                      <p className="badge fs-sm text-nav bg-secondary text-decoration-none">
                        {item.category}
                      </p>
                      <span className="fs-sm text-muted">{item.time}</span>
                    </div>
                    <h3 className="h5 mb-0">
                      <Link to={"/ressources/blog/dernieres-nouvelles/" + item.slug}>
                        {item.title}
                      </Link>
                    </h3>
                  </div>
                  <div className="card-footer py-4">
                    <p className="d-flex align-items-center text-decoration-none">
                      <img
                        src={item.avatar}
                        className="rounded-circle"
                        width="48"
                        alt="Avatar"
                      />
                      <div className="ps-3">
                        <h4 className="fs-base fw-semibold mb-0">
                          {item.author}
                        </h4>
                        {/* <span className="fs-sm text-muted">{item.role}</span> */}
                      </div>
                    </p>
                  </div>
                </article>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default LastNews;
