import React from 'react';
import People from "assets/img/ipercom/services/Solution proposes/maintenance and support tech/group-of-people.svg";

const Head = () => {
  return (
    <section
      className="jarallax bg-dark pt-2 pt-lg-3 pb-lg-5 position-relative"
      data-jarallax=""
      data-speed="0.35"
      data-bs-theme="dark"
      style={{ height: 609 }}
    >
      <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-50" />
      <div
        className="jarallax-img"
        style={{ backgroundImage: `url(${People})` }}
      />

      <div
        className="container position-absolute top-50 start-50 translate-middle"
        style={{ width: 614 }}
      >
        {/* Title */}
        <h1 className="text-center" style={{ fontSize: "80px" }}>
          Avis clients
        </h1>
      </div>
    </section>
  );
};

export default Head;
