import React from "react";
import man from "assets/img/ipercom/services/Solution proposes/maintenance and support tech/man_10_11zon.webp";

const TechnicalSupport = () => {
  return (
    <>
      <section className="container">
        <div className="row justify-content-around">
          <div className="col-md-5 pb-2 pb-md-0 mb-4 mb-md-0">
            <div className="pe-lg-5 text-center">
              <img src={man} className="rounded-3" alt="Image" />
            </div>
          </div>
          <div className="col-md-6 align-content-center">
            <h2 className="mb-sm-4">Support technique </h2>
            <p className="d-md-none d-xl-block pb-2 pb-md-3 mb-3">
              Notre support technique hautement qualifié est disponible 24/7
              pour résoudre tous vos problèmes informatiques. Bénéficiez d'une
              assistance rapide et efficace pour maintenir vos systèmes
              opérationnels et maximiser votre productivité.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default TechnicalSupport;
