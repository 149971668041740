import React, { useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useParallaxHooks from "hooks/useParallaxHooks";

gsap.registerPlugin(ScrollTrigger);

const SignupCTA = () => {
  const containerRef = useRef(null);
  const parallaxElRef = useRef([]);

  useParallaxHooks(containerRef, parallaxElRef, {
    scrollTrigger: {
      trigger: '.gsap',
      start: '+=450 bottom'
    }
  });

  return (
    <section className="container mb-5">
      <div className="bg-dark border border-light rounded-3 py-5 px-4 px-sm-0">
        <div className="row justify-content-center py-sm-2 py-lg-5">
          <div className="col-xl-6 col-lg-7 col-md-8 col-sm-10 text-center">
            <h2 className="h1 text-light mb-4">Prêt à commencer?</h2>
            <p className="fs-lg text-light opacity-70 pb-4 mb-3">
              Testez notre essai gratuit et simplifiez votre planification.
            </p>
            {/* Desktop form */}
            <form
              className="input-group input-group-lg d-none d-sm-flex needs-validation mb-3"
              // noValidate=""
            >
              <input
                type="email"
                className="form-control rounded-start ps-5"
                placeholder="Votre e-mail"
                // required=""
              />
              <i
                className="bx bx-envelope fs-lg text-muted position-absolute top-50 start-0 translate-middle-y ms-3 zindex-5" />
              <div className="invalid-tooltip position-absolute top-100 start-0">
                Please provide a valid email address.
              </div>
              <button type="submit" className="btn btn-primary">
                Commencez gratuitement
              </button>
            </form>
            {/* Mobile form */}
            <form
              className="needs-validation d-sm-none mb-3"
              // noValidate=""
            >
              <div className="position-relative mb-3">
                <input
                  type="email"
                  className="form-control form-control-lg rounded-start ps-5"
                  placeholder="Your email"
                  // required=""
                />
                <i
                  className="bx bx-envelope fs-lg text-muted position-absolute top-50 start-0 translate-middle-y ms-3 zindex-5" />
                <div className="invalid-tooltip position-absolute top-0 start-0 mt-n4">
                  Please provide a valid email address.
                </div>
              </div>
              <button type="submit" className="btn btn-primary btn-lg w-100">
                Get started for free
              </button>
            </form>
            <p className="fs-sm text-light opacity-50 mb-0">
              Aucun abonnement. Pas de frais annuels. Pas de blocage.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignupCTA;
