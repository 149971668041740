import React from "react";
import computer from "assets/img/ipercom/services/Solution proposes/computer_19_11zon.webp";
import men from "assets/img/ipercom/services/Solution proposes/formation/men.svg";

const Head = () => {
  return (
    <section
      className="jarallax bg-dark pt-2 pt-lg-5 pb-lg-5"
      data-jarallax=""
      data-speed="0.35"
      data-bs-theme="dark"
    >
      <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-70" />
      <div
        className="jarallax-img"
        style={{ backgroundImage: `url(${computer})` }}
      />
      <div className="container position-relative zindex-5 pb-5">
        {/* Title */}
        <h1>Formation sur l'utilisation de l'application mobile iper-X</h1>
        <p className="fs-lg text-light opacity-70">Distancielle</p>
        {/* Stats */}
        <div className="d-sm-flex py-3">
          <div className="d-flex border-sm-end px-sm-3 me-sm-3 border-end border-start b-2">
            <i className="bx bx-like fs-xl text-light opacity-70 me-1" />
            <span className="text-light opacity-70">3.3K likes</span>
          </div>
          <div className="d-flex">
            <i className="bx bx-time fs-xl text-light opacity-70 me-1" />
            <span className="text-light opacity-70">1 heure</span>
          </div>
        </div>
        {/* Author */}
        <div className="d-flex align-items-center mt-xl-5 pt-2 pt-md-4 pt-lg-5">
          <img
            src={men}
            className="rounded-circle"
            width={60}
            alt="Albert Flores"
          />
          <div className="ps-3">
            <div className="text-light opacity-80 mb-1">
              Personne en charge de la formation
            </div>
            <h2 className="h6">Albert Flores</h2>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Head;
