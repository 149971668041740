import React from 'react';
import computer from 'assets/img/ipercom/services/Solution proposes/computer_19_11zon.webp';
import { useNavigate } from 'react-router-dom';

interface Career {
  title: string;
  slug: string;
}

const careers: Career[] = [
  {
    title: "Développeur full stack /CDI",
    slug: "developpeur-full-stack"
  },
  {
    title: "Dev ops /CDI",
    slug: "ingenieur-devops"
  },
  {
    title: "Chargé d'affaires /CDI",
    slug: "charge-d-affaires"
  }
];

const CareerList: React.FC = () => {
  const navigate = useNavigate();

  const handleClick = (slug: string) => {
    navigate(`/a-propos/carriere/fiche-de-post/${slug}`);
  };

  return (
    <section className="container mt-4">
      <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 gx-3 gx-md-4 mt-n2 mt-sm-0">
        {careers.map((career, index) => (
          <div className="col mb-5" key={index}>
            <article className="card h-100 border-0 shadow-sm">
              <img src={computer} alt={`Fiche de Poste : ${career.title}`} />
              <div className="card-body">
                <h2 className="h4">
                  <a href="" onClick={() => handleClick(career.slug)}>
                    {career.title}
                  </a>
                </h2>
                <p className="fs-xl fw-bold text-primary mb-0">
                  Rémunération : À négocier selon profil
                </p>
              </div>
              <div className="card-footer">
                <span className="fs-sm text-muted">
                  Niveau d'études : BAC +5
                </span>
                <br />
                <span className="fs-sm text-muted">
                  Années d'expérience requises : Plus de 2 ans
                </span>
              </div>
            </article>
          </div>
        ))}
      </div>
    </section>
  );
};

export default CareerList;
