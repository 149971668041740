import Scrollbar from "components/base/Scrollbar";
import React from "react";
import gold from "assets/img/ipercom/ranks/gold.svg";
import platinium from "assets/img/ipercom/ranks/platinum.svg";
import silver from "assets/img/ipercom/ranks/silver.svg";
// import { optionCSS } from "react-select/dist/declarations/src/components/Option";


const products = [
  {
    id: 1,
    image: platinium,
    nameGrade: "platinium",
    name: "Pack Grande entreprise solution de téléphonie iper-X avec 4 appels simultanés, (tacit de reconduction automatique)",
    quantity: 1,
    years: 1,
    price: 199,
    options: [
      {
        id: 1,
        name: "Modules ERP",
        years: 2,
        quantity: 2,
        price: 199,
        //ces données sont justes fictives , je les utilise simplement dans la facturation//
        taxRate: 2.5,
        taxType: "TVA",
        taxAmount: 199,
        total: 398
      },
    ],
  },
  {
    id: 2,
    image: silver,
    nameGrade: "silver",
    name: "Pack Grande entreprise solution de téléphonie iper-X avec 4 appels simultanés,  (tacit de reconduction automatique)",
    quantity: 2,
    years: 1,
    price: 398,
    options: [
      {
        id: 1,
        name: "Modules IPBX",
        years: 2,
        quantity: 2,
        price: 299,
        taxRate: 2.5,
        taxType: "TVA",
        taxAmount: 199,
        total: 398
      },
    ],
  },
  {
    id: 3,
    image: gold,
    nameGrade: "gold",
    name: "Modules de facturation télécom",
    quantity: 3,
    years: 2,
    price: 597,
    options: [
      {
        id: 1,
        name: "Modules ERP",
        years: 1,
        quantity: 2,
        price: 299,
        taxRate: 2.5,
        taxType: "TVA",
        taxAmount: 199,
        total: 398
      },
    ],
  },
];

const BillingsTabProduct = () => {
  return (
    <section className="container mt-5">
      <Scrollbar autoHeight autoHeightMax="100%">
        <table className="table">
          <thead>
          <tr className="text-uppercase fs-sm text-center bg-secondary">
            <th scope="col" className="text-start" style={{ width: 900 }}>
              produits
            </th>
            <th scope="col" style={{ width: 200 }}>
              prix unitaire ht
            </th>
            <th scope="col" style={{ width: 100 }}>
              quantités
            </th>
            <th className="text-end" scope="col" style={{ width: 150 }}>
              Total ht
            </th>
          </tr>
          </thead>
          <tbody>
          {products.map(product => (
            <>
              {product.options.map((option, optionIndex) => (
                <tr key={option.id} className="fs-sm text-center">
                  {optionIndex === 0 && (
                    <>
                      <td
                        className="text-start"
                        rowSpan={product.options.length}
                      >
                        <div className="d-flex">
                          <img
                            src={product.image}
                            alt={product.nameGrade}
                            style={{ width: "5%" }}
                            className="me-2"
                          />
                          <div className="d-flex flex-column">
                            <p className="mb-0 fw-bold">
                              {product.name} abonnement {option.years} {option.years > 1 ? "ans" : "an"}
                            </p>
                            {/* <p className=''> - {option.name} abonnement {option.years} {option.years > 1 ? 'ans' : 'an'}</p> */}
                          </div>
                        </div>
                      </td>
                    </>
                  )}
                  <td className="align-middle fw-semi-bold">
                    <div className="d-flex flex-column">
                      <p className="m-0"> {product.price} €</p>
                      {/* <p className='mt-3'> {option.price} €</p> */}
                    </div>
                  </td>
                  <td className="align-middle">
                    {product.quantity}
                  </td>
                  <td className="align-middle fw-semi-bold text-end">{option.total} €</td>
                </tr>
              ))}
              {/* <tr className="bg-200 bg-secondary">
                  <td className=" fw-bolder" colSpan={5}>
                    Sous-total des articles :
                  </td>
                  <td className=" text-end fw-bold">{product.options.reduce((acc, option) => acc + calculerPrixTotal(option, product.id, option.id), 1)}</td>
                  <td />
                </tr> */}
            </>
          ))}

          <tr className="bg-200 bg-secondary">
            <td className="fw-bold text-1000" colSpan={3}>
              Total Hors Taxes:
            </td>
            <td className=" text-end fw-bold">1200 €</td>
          </tr>
          </tbody>
        </table>
      </Scrollbar>
    </section>
  );
};

export default BillingsTabProduct;
