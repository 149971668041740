import React from "react";
import { currencyFormat } from 'helpers/utils';
import { Card } from 'react-bootstrap';
import gold from "../../../assets/img/ipercom/ranks/gold.svg";
import platinium from "../../../assets/img/ipercom/ranks/platinum.svg";
import silver from "../../../assets/img/ipercom/ranks/silver.svg";

const products = [
  {
    image: gold,
    name: "Pack Moyenne entreprise solution de téléphonie IPBX",
    quantity: 1,
    price: 597
  },
  {
    image: platinium,
    name: "Pack Grande entreprise solution de téléphonie iper-X avec 4 appels simultanés, abonnements 2 ans (tacit de reconduction automatique)",
    quantity: 1,
    price: 199
  },
  {
    image: silver,
    name: "Pack Petite entreprise solution de téléphonie IPBX",
    quantity: 1,
    price: 398
  }
];

const PaymentSummaryCard = () => {
  return (
    <Card>
      <Card.Body>
        <div className="d-flex flex-between-center mb-3">
          <h3 className="mb-0">Résumé</h3>
        </div>
        <div className="d-flex flex-column align-middle">
          {products.map((product, index) => (
            <div key={index} className="d-flex flex-column">
              <div className="d-flex align-items-center">
                <img
                  src={product.image}
                  alt=""
                  style={{
                    width: "40px",
                    marginRight: "10px",
                    marginBottom: "5px"
                  }}
                  className="flex-shrink-0 bg-secondary rounded-3 p-2"
                />
                <p>{product.name}</p>
                <p style={{ marginLeft: "auto", marginRight: "10px" }}>x{product.quantity}</p>
                <p>{product.price}€</p>
              </div>
            </div>
          ))}
        </div>
        <hr className="my-2" style={{ borderStyle: "dashed" }}></hr>
        <div>
          <div className="d-flex justify-content-between">
            <p className="text-900 fw-semi-bold">Sous-total des articles :</p>
            <p className="text-1100 fw-semi-bold">{currencyFormat(691)}</p>
          </div>
          <div className="d-flex justify-content-between">
            <p className="text-900 fw-semi-bold">Remise :</p>
            <p className="text-danger fw-semi-bold">-{currencyFormat(59)}</p>
          </div>
          <div className="d-flex justify-content-between">
            <p className="text-900 fw-semi-bold">Taxe :</p>
            <p className="text-1100 fw-semi-bold">{currencyFormat(126.2)}</p>
          </div>
          <div className="d-flex justify-content-between">
            <p className="text-900 fw-semi-bold">Sous-total :</p>
            <p className="text-1100 fw-semi-bold">{currencyFormat(665)}</p>
          </div>
        </div>
        <hr className="my-2" style={{ borderStyle: "dashed" }}></hr>
        <div className="d-flex justify-content-between border-y border-dashed py-3 mb-4">
          <h4 className="mb-0">Total :</h4>
          <h4 className="">
            {currencyFormat(products.reduce((acc, curr) => acc + curr.price, 0), { minimumFractionDigits: 2 })}
          </h4>
        </div>
      </Card.Body>
    </Card>
  );
};

export default PaymentSummaryCard;
