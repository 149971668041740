import womenBlack from 'assets/img/ipercom/ressources/portfolio/avis clients/data/womenBlack.webp';
import tinyWomen from 'assets/img/ipercom/ressources/portfolio/avis clients/data/image_5_11zon.webp';
import tinyblackWomen from 'assets/img/ipercom/ressources/portfolio/avis clients/data/tinyblackwomen_33_11zon.webp';
import montre from 'assets/img/ipercom/ressources/portfolio/avis clients/data/montre_50_11zon.webp';
import bvannu from "assets/img/ipercom/services/ERP/logo-bvannu.webp"

const LastReviews = () => {
  const blogPosts = [
    {
      id: 1,
      category: 'Digital',
      job: "PDG",
      date: 'May 9, 2024',
      title: "Création site web",
      texte:
        "J’ai contacté la société ipercom pour la réalisation de mon site web et je suis ravis du résultat. Le personnel en charge de mon projet s’est montré à l’écoute de mes besoins et a su agir rapidement. Je n’hésiterai pas à faire appel à leurs services de nouveau.",
      author: 'Direction',
      avatar: tinyWomen,
      image: womenBlack
    },
    {
      id: 2,
      category: 'Startups',
      job: "ADV",
      date: 'May 10, 2024',
      title: "Application logiciel de caisse",
      texte:
        "Le logiciel créé par ipercom m’a permis de rendre mon activité plus productive. Moins de perte de temps, une clientèle servis plus rapidement et une satisfaction client au rendez-vous. Merci à vous et à bientôt !",
      author: 'Administratif',
      avatar: tinyblackWomen,
      image: montre
    },
    {
      id: 3,
      category: 'Startups',
      date: 'Jul 23, 2024',
      title: "Ipercom",
      texte:
        "Depuis le temps que je cherchais à mutualiser mes services ERP et téléphonie. C'est maintenant chose faite. Mon entreprise a enfin trouvé son autonomie grâce à ipercom. De très bons conseils, pas de bla bla, de l'efficacité. Du travail de pro, réalisé par de vrais spécialistes. Mon entreprise utilise maintenant des services modernes, cela nous permet d'optimiser nos taches, dans un confort absolu. je conseille vivement.",
      avatar: tinyblackWomen,
      image: bvannu
    }
  ];


  return (
    <section className="container mt-4 mb-lg-5 pt-lg-2">
      <div className="row gy-3 mb-4 pb-lg-3 pb-1 text-center">
        <div>
          <h2 className="my-3">Derniers Avis</h2>
        </div>
        {/* //commentaire sous demande de Mr Degouve// */}
        {/* <div className="">
            <button className="btn btn-outline-primary me-3" onClick={() => handleFilter('Tous les sujets')}>Tous les sujets</button>
            <button className="btn btn-outline-primary me-3" onClick={() => handleFilter('Digital')}>Digital</button>
            <button className="btn btn-outline-primary me-3" onClick={() => handleFilter('Marketing')}>Marketing</button>
            <button className="btn btn-outline-primary me-3" onClick={() => handleFilter('Réussites')}>Réussites</button>
            <button className="btn btn-outline-primary me-3" onClick={() => handleFilter('Startups')}>Startups</button>
            <button className="btn btn-outline-primary me-3" onClick={() => handleFilter('Events')}>Events</button>
            <button className="btn btn-outline-primary me-3" onClick={() => handleFilter('Technologie')}>Technologie</button>
            <button className="btn btn-outline-primary me-3" onClick={() => handleFilter('Entreprise')}>Entreprise</button>
            <button className="btn btn-outline-primary me-3" onClick={() => handleFilter('Processus')}>Processus</button>
        </div> */}
      </div>
      <div className=" gy-md-4 gy-2">
        {blogPosts
          .map(post => (
            <div className="col pb-3" key={post.id}>
              <article className="card border-0 shadow-sm">
                <div className="row">
                  <div className="col-md-4">
                    <div className="position-relative">
                      <img
                        src={post.image}
                        className="card-img-top"
                        alt="Image"
                      />
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="card-body pb-4">
                      <div className="d-flex justify-content-start mb-3">
                        <p className="bg-secondary fs-sm text-decoration-none fw-bold me-3 mb-0">
                          {post.job}
                        </p>
                        <span className="fs-sm text-muted border-start ps-3">
                          {post.date}
                        </span>
                      </div>
                      <h3 className="mb-2">{post.title}</h3>
                      <p>{post.texte}</p>
                      <div className="d-flex align-content-center fw-bold text-dark text-decoration-none">
                        <p>{post.author}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          ))}
      </div>
    </section>
  );
};

export default LastReviews;
