import React from "react";
import mobile from "assets/img/ipercom/services/Solution proposes/logiciel caisse/mobile.webp";
import InfoBouton from "components/modules/showcase/InfoBouton";

const BusinessManagement = () => {
  return (
    <>
      {/* Portfolio list */}
      <section className="container my-3">
        {/* Item */}
        <div className="row">
          <div
            className="rellax col-md-5 pb-1 mb-3 pb-md-0 mb-md-0 text-center"
            data-rellax-percentage="0.5"
            data-rellax-speed="0.8"
            data-disable-parallax-down="md"
          >
            <img
              src={mobile}
              className="rounded-3"
              alt="Image"
              style={{ width: "40%" }}
            />
          </div>
          <div
            className="col-md-7 align-content-center"
            data-rellax-percentage="0.5"
            data-rellax-speed="-0.6"
            data-disable-parallax-down="md"
          >
            <div className="">
              <h2>
                L'importance des logiciels et applications de caisse dans la
                gestion des entreprises
              </h2>
              <p>
                Les logiciels et applications de caisse jouent un rôle crucial
                dans la gestion efficace des entreprises modernes. Ils offrent
                un moyen centralisé de gérer les transactions, les stocks et les
                finances, ce qui améliore considérablement l'efficacité
                opérationnelle.<br></br>
                <br></br>Grâce à une interface conviviale et intuitive, les
                <a href="/ressources/portfolio/projets-realises/logiciel-de-caisse"> logiciels de caisse
                  i-pos</a> permettent à tout le personnel de
                s'adapter rapidement à leur utilisation, réduisant ainsi les
                temps de formation et augmentant la productivité globale.
              </p>
              <InfoBouton />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BusinessManagement;
