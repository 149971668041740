import React, { useRef, useState } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ipbx from "assets/img/ipercom/landing/our_apps/ipbx.webp";
import pos from "assets/img/ipercom/services/Solution proposes/logiciel caisse/mobile.webp";
import crm from "assets/img/ipercom/landing/our_apps/graphapp.webp";
import billing from "assets/img/ipercom/landing/our_apps/imgFactu.webp";
import erp from "assets/img/ipercom/landing/our_apps/ImgErp.webp";
import iperCloud from "assets/img/ipercom/ressources/portfolio/projet realisé/iper-cloudApp.webp";

gsap.registerPlugin(ScrollTrigger);

const tabsData = [
  {
    id: "#iper-x",
    title: "iper-X solution de téléphonie IPBX",
    iconClass: "bx bx-phone",
    content:
      "Découvrez notre solution <a href='/services/solution-telephonie/qu-est-ce-qu-un-standard-telephonique-ipbx'>IPBX</a>, un <a href='/services/solution-telephonie/logiciel-de-telephonie-iper-x'>système de téléphonie</a> avancé qui révolutionne la communication d'entreprise. Avec iper-X, bénéficiez de fonctionnalités puissantes pour améliorer la collaboration et la productivité. Notre solution offre une gamme complète de solutions de communication d'entreprise pour répondre à vos besoins spécifiques. Ainsi, vous pouvez améliorer l'efficacité de vos communications et optimiser la collaboration au sein de votre organisation.",
    image: ipbx,
    imageWidth: 800,
    imageHeight: 600,
    imageClass: ""
  },
  {
    id: "#i-pos",
    title: "i-pos logiciel de caisse",
    iconClass: "bx bxs-calculator",
    content:
      "Optimisez vos transactions de vente avec <a href='/services/services-proposes/logiciel-et-application-de-caisse'>i-pos</a>, notre logiciel de caisse conçu pour simplifier la gestion financière. Facile à utiliser, i-pos supporte le suivi des stocks, la gestion des ventes, et l'intégration de paiements sécurisés, vous permettant de servir vos clients efficacement tout en gérant votre comptabilité avec précision.",
    image: pos,
    imageWidth: 250,
    imageHeight: 200,
    imageClass: "custom-image-left"
  },
  {
    id: "#crm",
    title: "CRM",
    iconClass: "bx bx-group",
    content:
      "Améliorez vos relations clients avec notre solution <a href='/services/erp/qu-est-ce-qu-un-crm'>CRM</a>, conçue pour vous aider à gérer et analyser les interactions clients tout au long de leur cycle de vie. Avec <a href='/services/erp/notre-crm'>notre CRM</a>, engagez vos clients de manière plus efficace, augmentez vos ventes et améliorez le service client grâce à une meilleure compréhension de leurs besoins.",
    image: crm,
    imageWidth: 700,
    imageHeight: 500,
    imageClass: ""
  },
  {
    id: "#estimate",
    title: "Devis",
    iconClass: "bx bx-file",
    content:
      "Créez, envoyez et gérez vos devis facilement avec notre outil dédié. Conçu pour accélérer le processus de vente, notre <a href='/services/erp/creation-de-devis'>système de devis</a> vous permet de personnaliser vos propositions, de suivre leur statut et de transformer les devis approuvés en <a href='/services/erp/solution-facturation'>factures</a>, le tout dans une interface simple et intuitive.",
    image: billing,
    imageWidth: 800,
    imageHeight: 600,
    imageClass: ""
  },
  {
    id: "#billing",
    title: "Facturation",
    iconClass: "bx bx-receipt",
    content:
      "Simplifiez votre processus de facturation avec notre solution automatisée. Générez des factures précises, suivez les paiements et gérez vos finances avec facilité. Nous vous proposons des systèmes de facturation <a href='/services/logiciel-de-facturation-par-metier/logiciel-de-facturation-bureautique'>bureautique</a>, <a href='/services/logiciel-de-facturation-par-metier/logiciel-de-facturation-informatique'>informatique</a> et <a href='/services/logiciel-de-facturation-par-metier/logiciel-de-facturation-telecom'>télécom</a>, intégrés pour une comptabilité à jour et une gestion simplifiée des tâches administratives.",
    image: billing,
    imageWidth: 800,
    imageHeight: 600,
    imageClass: ""
  },
  {
    id: "#erp",
    title: "ERP",
    iconClass: "bx bx-buildings",
    content:
      "Intégrez et automatisez les processus d'affaires essentiels de votre entreprise avec notre système <a href='/services/erp/qu-est-ce-qu-un-erp'>ERP</a>. De la production à la logistique, en passant par l'inventaire et les finances, notre <a href='/services/erp/guide-pour-un-erp'>ERP</a> offre une vue d'ensemble de votre activité pour vous aider à prendre des décisions basées sur des données réelles, améliorant ainsi l'efficacité et la rentabilité.",
    image: erp,
    imageWidth: 800,
    imageHeight: 600,
    imageClass: ""
  },
  {
    id: "#ipercloud",
    title: "iper-Cloud",
    iconClass: "bx bx-buildings",
    content:
      "Découvrez comment iper-cloud peut transformer votre entreprise avec ses capacités avancées de stockage de données et de collaboration sécurisée évolutive. <a href='/services/services-proposes/solution-iper-cloud'>Iper-cloud</a> est une solution de stockage et de partage de fichiers dans le cloud qui facilite l'accès, le partage et la collaboration à partir de n'importe où et à tout moment. Cette plateforme garantit l'intégrité des données en offrant un contrôle total sans aucun risque de fuite vers des tiers.",
    image: iperCloud,
    imageWidth: 800,
    imageHeight: 600,
    imageClass: ""
  }
];

const OurApps = () => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [activeTab, setActiveTab] = useState(tabsData[0].id); // Default to the first tab

  return (
    <section
      className="container pt-0 pb-2 mt-3 mt-sm-4 mt-xl-0 mb-5"
      ref={containerRef}
    >
      <h3 className="h1 text-center pb-3 pb-lg-4">Nos applications</h3>
      {/* Nav tabs */}
      <ul
        className="nav nav-tabs flex-nowrap justify-content-lg-center overflow-auto pb-2 mb-3 mb-lg-4"
        role="tablist"
      >
        {tabsData.map(tab => (
          <li className="nav-item" role="presentation" key={tab.id}>
            <button
              className={`nav-link text-nowrap ${activeTab === tab.id ? "active" : ""}`}
              id={`${tab.id}-tab`}
              data-bs-toggle="tab"
              data-bs-target={tab.id}
              type="button"
              role="tab"
              aria-controls={tab.id.substring(1)} // Remove the "#" for aria-controls
              aria-selected={activeTab === tab.id}
              onClick={() => setActiveTab(tab.id)} // Set active tab
            >
              <i className={`${tab.iconClass} fs-lg opacity-60 me-2`} />
              {tab.title}
            </button>
          </li>
        ))}
      </ul>
      {/* Tab panes */}
      <div className="tab-content bg-secondary rounded-3 ">
        {tabsData.map(tab => (
          <div
            className={`tab-pane fade ${activeTab === tab.id ? "show active" : ""}`}
            id={tab.id.substring(1)}
            role="tabpanel"
            aria-labelledby={`${tab.id}-tab`.substring(1)}
            key={tab.id}
          >
            <div className="row align-items-center pt-3 pt-sm-4 pt-md-0 px-3 px-sm-4 px-lg-0">
              <div className="col-lg-4 col-md-5 offset-lg-1 text-center text-md-start">
                <h4 className="mb-lg-4">{tab.title}</h4>
                <p dangerouslySetInnerHTML={{ __html: tab.content }}></p>
              </div>
              <div className="col-lg-6 col-md-7 mt-md-4">
                <img
                  src={tab.image}
                  className={`d-block my-lg-2 mx-auto ${tab.imageClass}`}
                  width={tab.imageWidth}
                  height={tab.imageHeight}
                  alt="Image"
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default OurApps;
