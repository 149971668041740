import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";

const CGU = () => {
  return (
    <>
    <Breadcrumbs />
    <div className="container  my-5">
      <h1 className="text-center text-primary mb-4">Conditions Générales d'Utilisation</h1>
      <div>
        <h2>ARTICLE 1 – DEFINITIONS</h2>
        <ul>
          <li>
            <strong>« Client » :</strong> désigne toute personne physique ou morale domiciliée en France
            métropolitaine qui souscrit au Contrat pour les besoins de son activité.
          </li>
          <li>
            <strong>« Contrat » :</strong> désigne l’ensemble contractuel constitué des Conditions Générales de Vente,
            des présentes Conditions Générales d’Utilisation, du Bon de Commande ou du Devis signé
            physiquement ou électroniquement, de la Brochure Tarifaire le cas échéant, ainsi que du Contrat de
            sous-traitance, après acceptation de la Commande par IPERCOM.
          </li>
          <li>
            <strong>« Données Personnelles » :</strong> désigne toute information se rapportant au Client (ou, lorsque le
            Client est une personne morale, ses Utilisateurs), que ce soit directement ou indirectement, notamment
            par référence à un identifiant, tel qu'un nom, un numéro d'identification, des données de localisation, un
            identifiant en ligne, ou à un ou plusieurs éléments spécifiques propres à son identité physique,
            physiologique, génétique, psychique, économique, culturelle ou sociale qui pourra être communiquée ou
            mise à disposition dans le cadre de la conclusion et l’exécution du Contrat.
          </li>
          <li>
            <strong>« Identifiants » :</strong> désigne les codes confidentiels ou mot de passe permettant au Client d’accéder
            à certains Services et/ou à l’Espace Client.
          </li>
          <li>
            <strong>« IPERCOM » :</strong> désigne la société IPERCOM immatriculée au RCS de Bordeaux sous le numéro
            509 795 829, dont le siège social est situé 8 Allée Cantillac – ZA de Gachet – 33370 POMPIGNAC ou tout
            cessionnaire ultérieur.
          </li>
          <li>
            <strong>« Participant » :</strong> désigne toute personne invitée par le Client à utiliser le Service étant précisé que
            l’Organisateur a la possibilité d’inviter un ou plusieurs Participants.
          </li>
          <li>
            <strong>« Services » :</strong> désigne l’ensemble des services fournis par IPERCOM dans le cadre des Conditions
            Générales de Vente et de Service, sous réserve d’éligibilité et de couverture, et plus amplement décrits
            dans les Conditions Particulières et/ou la Brochure Tarifaire.
          </li>
        </ul>

        <h2>ARTICLE 1 – ACCEPTATION ET MODIFICATIONS DES CONDITIONS GENERALES D’UTILISATION</h2>
        <h3>1.1 ACCEPTATION DES CONDITIONS GENERALES D’UTILISATION</h3>
        <p>
          En utilisant les Services fournis par IPERCOM, le Client reconnait avoir pris connaissance de l’ensemble des
          dispositions des présentes CGU et accepté de s’y soumettre sans aucune réserve.
        </p>

        <h3>1.2 MODIFICATIONS DES CONDITIONS D’UTILISATION DES SERVICES</h3>
        <p>
          IPERCOM se réserve le droit de changer les présentes CGU à tout moment et notamment en cas de mise en
          place de nouveaux services. Les Clients sont informés desdites modifications par message électronique
          (envoyé à l’adresse électronique fournie lorsque le Client utilise le Service).
        </p>
        <p>
          L’ensemble des modifications des présentes CGU s’appliqueront à tous les Clients, y compris ceux inscrits
          avant le changement, l’utilisation desdits Services équivalent à une acceptation des modifications desdites
          CGU.
        </p>

        <h2>ARTICLE 2 – CONDITIONS D’UTILISATION</h2>
        <p>
          Le Client bénéficie uniquement du droit d’utiliser les Services pendant la durée de sa Souscription,
          conformément aux présentes CGU. L’utilisation de l’espace client est gratuit et permet notamment au Client
          de :
        </p>
        <ul>
          <li>Souscrire à une ou plusieurs offres de Services ;</li>
          <li>Mettre à jour ses Données Personnelles ;</li>
          <li>Accéder aux documents contractuels et techniques des offres de services ;</li>
          <li>Consulter l’ensemble de ses factures ;</li>
          <li>Ouvrir des demandes au support et en suivre l’avancement.</li>
        </ul>
        <p>
          Chaque Compte Utilisateur est strictement personnel et doit être utilisé uniquement par l’Utilisateur concerné,
          depuis le navigateur web de son ordinateur personnel ou depuis son téléphone IP personnel.
        </p>
        <p>
          Le partage et l’usage mutualisé du Compte Utilisateur sont interdits. En particulier, il est interdit d’utiliser le
          Compte Utilisateur comme fournisseur de collecte et/ou de terminaison d’appels, ainsi que pour recevoir ou
          émettre des appels via un commutateur téléphonique, un automate d’appel ou un logiciel de téléphonie. Le
          Compte Utilisateur est limité à un seul appel simultané par Utilisateur. Des canaux d’appels supplémentaires
          peuvent être ajoutés en option payante.
        </p>

        <h2>ARTICLE 3 – OBLIGATIONS DU CLIENT</h2>
        <h3>3.1 UTILISATION CONFORME A LA LOI</h3>
        <p>
          Le Service doit être utilisé uniquement dans un cadre légal. Le Client est seul responsable de l’utilisation des
          Services et des réglementations en vigueur associés à son activité professionnelle.
        </p>
        <p>
          Le Client s’engage à ne pas utiliser les Services contrairement à leur destination, à des fins impropres, illicites,
          contraires à l'ordre public ou aux bonnes mœurs, interdites par les lois ou règlements applicables ou en
          violation des droits d’un tiers, et à ne pas causer de perte ou de dommage, quels qu’ils soient, à IPERCOM ou à
          tout tiers.
        </p>
        <p>
          IPERCOM ne pourra jamais être tenu responsable en pareil cas puisque n’exerçant aucun contrôle sur le
          contenu de l’information véhiculée via ses Services.
        </p>
        <p>
          Sont notamment strictement interdites, les utilisations suivantes :
        </p>
        <ul>
          <li>
            A d’autres fins que des communications interpersonnelles soit les besoins légitimes et licites du Client dans le
            cadre de son activité professionnelle propre,
          </li>
          <li>
            En violation de la loi et/ou de la règlementation aux fins de propager des données, ou des sons pouvant
            constituer divers délits tels que : la diffamation, l’injure, le dénigrement ou porter atteinte à la vie privée, au
            droit à l’image, aux bonnes mœurs ou à l’ordre public,
          </li>
          <li>A des fins ou de manière frauduleuse ou illicite en violation des droits de propriété intellectuelle,</li>
          <li>Aux fins d’en faire commerce sans l’accord exprès de IPERCOM,</li>
          <li>Via une composition automatique ou en continu de numéros non conforme à la réglementation et aux décisions et recommandations de l'ARCEP,</li>
          <li>Comme passerelle de réacheminement de communications de mise en relation, que ce soit à titre onéreux ou non,</li>
          <li>Comme passerelle radio,</li>
          <li>Par l’envoi de messages électroniques non sollicités.</li>
        </ul>
        <p>
          Le Client s'engage à adopter un comportement raisonnable dans l'utilisation des Services. Le Client s’engage à
          ne pas utiliser de logiciels ou de procédés manuels pour copier les droits de propriété intellectuelle d’IPERCOM
          et à ne pas utiliser de dispositifs ou logiciels aux fins de perturber ou tenter de perturber le bon
          fonctionnement du Service.
        </p>
        <p>
          La réglementation concernant l’enregistrement des conversations téléphoniques et leur durée de conservation
          varie selon les pays/zones géographiques concernés. Il appartient au Client et aux Utilisateurs de vérifier la
          légalité de cette option conformément aux normes applicables. Dans certains pays, l’Utilisateur doit informer ses
          correspondants de l’enregistrement de leur conversation téléphonique et de leur droit à s’y opposer à tout
          moment. L’utilisation de cette option se fait sous la seule responsabilité du Client et des Utilisateurs, qui doivent
          s’assurer du respect de la réglementation locale.
        </p>

        <h3>3.2 GARANTIE DUE PAR LE CLIENT ET LES PARTICIPANTS</h3>
        <p>
          En cas de poursuite intentée par des tiers contre IPERCOM suite à une infraction ou une violation des présentes
          CGU qui serait imputable à un ou plusieurs Client(s), ce ou ces dernier(s) accepte(nt) d’indemniser IPERCOM
          (incluant ses employés, administrateurs, représentants, distributeurs et affiliés) en lui versant des dommages et
          intérêts correspondant aux frais engagés (y compris les honoraires d’avocat) et à la compensation des
          préjudices subis.
        </p>

        <h3>3.3 - SANCTION DU COMPORTEMENT FAUTIF DU CLIENT ET DES PARTICIPANTS</h3>
        <p>
          IPERCOM se réserve le droit de suspendre, de limiter ou de restreindre partiellement ou complètement l'accès
          aux Services après mise en demeure restée infructueuse sous quinze (15) jours à compter de la date d’envoi et
          sans que le Client ne puisse prétendre d’une quelconque indemnité en cas d’urgence caractérisée résultant
          d’une utilisation des Services manifestement illicite et/ou non conforme au Contrat, d’un défaut de paiement, ou
          d’une modification de la situation du Client entraînant un risque de non-conformité pour IPERCOM. La
          résiliation pourra être immédiate en cas de manquement légal grave de la part du Client.
        </p>
        <p>
          IPERCOM est habilité à suspendre, limiter ou restreindre les Services pour toutes opérations de mise à niveau,
          de maintenance préventive, curative ou encore pour des raisons de sécurité.
        </p>
        <p>
          IPERCOM est également habilité à pareille intervention s’il y est contraint par un texte législatif ou réglementaire
          ou un ordre, une instruction ou une exigence du Gouvernement, d'une autorité de régulation, d’une autorité
          judiciaire, ou de toute autorité administrative compétente.
        </p>

        <h2>ARTICLE 4 - IDENTIFIANTS ET DONNEES PERSONNELLES</h2>
        <h3>4.1 IDENTIFIANTS</h3>
        <p>
          Le Client reste seul responsable de la conservation, de la confidentialité et de l’utilisation des codes d’accès,
          identifiants et lignes fournies dans le cadre du Contrat. Toute utilisation des identifiants est présumée faite par
          le Client qui est responsable des actions et messages transmis depuis ses équipements via lesdits identifiants,
          codes d’accès ou lignes.
        </p>
        <p>
          En cas de divulgation, détournement, perte ou vol desdits identifiants, codes d’accès ou lignes, le Client doit
          contacter immédiatement IPERCOM selon les modalités suivantes : par mail à l’adresse :
          direction@ipercom.com ou par courrier à l’adresse susvisée à l’article 1.
        </p>

        <h3>4.2 DONNEES PERSONNELLES</h3>
        <p>
          Le présent Contrat est soumis à la règlementation applicable soit les dispositions et réglementations
          européennes en matière de protection des données à caractère personnel, partant le Règlement européen
          n°2016/679 du Parlement européen et du Conseil du 27 avril 2016 relatif à la protection des personnes physiques
          à l’égard du traitement des données à caractère personnel et à la libre circulation de ces données (« RGPD »), la
          loi n°78-17 du 6 janvier 1978 dite « Informatique libertés » et ses modifications successives ainsi que toute
          réglementation nationale ou européenne applicable ou législations ultérieures et l’ensemble des
          recommandations, délibérations et autres normes édictées par l’autorité de contrôle compétente (ci-avant et
          après dans son ensemble la « Réglementation Applicable »).
        </p>
        <p>
          Dans le cadre du Contrat, les Parties pourront recueillir, collecter et/ou avoir accès à des Données Personnelles
          relatives à des personnes physiques et notamment au Client ou, le cas échéant, aux Utilisateurs.
        </p>
        <p>
          Les Parties s’engagent à traiter toutes les Données Personnelles conformément à la règlementation en vigueur et
          chacune des Parties garantit à l’autre le respect de ses obligations légales et règlementaires à ce titre,
          conformément au Contrat de sous-traitance. Les Parties s’engagent également à s’assurer que tous leurs
          employés respectent les dispositions de la règlementation en vigueur.
        </p>
        <p>
          Pour le cas où les données personnelles seraient transférées en dehors de l’Union Européenne, les Parties
          s’engagent à signer les « clauses contractuelles types » de la Commission européenne.
        </p>
        <p>
          Les Données Personnelles sont traitées uniquement pour les besoins de l’exécution du Contrat, la fourniture des
          Services et la gestion du compte Client et sont conservées pour la durée nécessaire aux finalités des traitements
          auxquelles le Client a consenti et/ou à ce que la règlementation en vigueur exige, conformément à la Politique
          de confidentialité d’IPERCOM.
        </p>

        <h3>4.3 UTILISATION DES DONNEES PERSONNELLES DU CLIENT A DES FINS STATISTIQUES OU COMMERCIALES</h3>
        <p>
          IPERCOM s’accorde le droit de traiter et d’utiliser les Données Personnelles récoltées aux fins d’exécution du
          contrat, d’évaluation, d’amélioration et de développement de ses Services, mais également aux fins de
          fidélisation, de prospection, de sondage et de promotion. IPERCOM pourra utiliser certaines des Données
          Personnelles du Client, à des fins statistiques, uniquement après anonymisation.
        </p>
        <p>
          Le Client est libre de s’opposer à recevoir par courrier postal et/ou téléphone des offres de la part de IPERCOM
          et/ou de ses partenaires commerciaux pour des services similaires à ceux auxquels il a souscrit. Avec l’accord
          exprès du Client, IPERCOM se réserve le droit de lui communiquer par courrier électronique des informations
          commerciales pour des services non similaires à ceux déjà fournis ou de transmettre à des partenaires
          commerciaux son adresse électronique à des fins de prospection directe.
        </p>
        <p>
          Le Client, dispose d’un droit d’accès, de rectification, de suppression et d’opposition pour des motifs légitimes
          sur les Données Personnelles le concernant. Ces droits peuvent être exercés par courriel adressé à
          direction@ipercom.com.
        </p>

        <h2>ARTICLE 5 - PROPRIETE INTELLECTUELLE</h2>
        <p>
          Tous les droits, y compris les droits de propriété intellectuelle et industrielle tels que les droits d’auteur, les
          marques, les brevets, les noms de domaine, les secrets d’affaires et le savoir-faire relatifs aux Services et à leur
          contenu, appartiennent à IPERCOM. Les présentes CGU n’impliquent aucun transfert de droits de propriété sur
          les Services ou leur contenu.
        </p>
        <p>
          Le Client s'interdit strictement toute autre utilisation du logiciel, et notamment toute adaptation, modification,
          duplication ou reproduction de ces logiciels, quelle qu'en soit la nature, de l’installer sur d'autres équipements,
          de démonter, décompiler, décomposer, examiner ou analyser de quelque manière que ce soit le logiciel. Il
          s’interdit également toute cession ou sous-licence de sa licence d’utilisation. Il s’interdit enfin de corriger ou
          d’essayer de corriger toute anomalie quelle qu’elle soit.
        </p>
        <p>
          La marque, le logo et le contenu du site internet d’Ipercom et notamment sa structure, son design, ses textes,
          image et éléments graphique, ainsi que les balises HTML de référencement (méta-tags), sont protégés par le
          Code de la propriété intellectuelle et toute autre loi applicable au droit d’auteur.
        </p>
        <p>
          Le Client reste propriétaire de ses propres contenus et doit en détenir les droits nécessaires. Le Client est seul
          responsable de l’exactitude, de la qualité, de l’intégrité, de la légalité, de la fiabilité et de la pertinence de ses
          contenus.
        </p>
        <p>
          Le Client est entièrement responsable de ses obligations envers les sociétés de gestion des droits d’auteur,
          compositeurs, éditeurs, producteurs et autres ayants droit, notamment en ce qui concerne les musiques
          d’attente téléphonique choisies. IPERCOM décline expressément toute responsabilité à cet égard.
        </p>

        <h2>ARTICLE 6 - FORCE MAJEURE/IMPREVISION</h2>
        <h3>6.1</h3>
        <p>
          Les Parties ne seront responsable d’aucun incident résultant directement ou indirectement d’une cause en dehors
          de leur contrôle et/ou pouvant être interprétée comme un cas de force majeure (ci-après dénommé « Cas de
          Force Majeure »).
        </p>
        <p>
          Les Parties s’accordent sur le fait que constitue un Cas de Force Majeure, outre les événements qualifiés comme
          tels par la Cour de Cassation, les intempéries exceptionnelles, les catastrophes naturelles, les virus informatiques,
          les phénomènes d’origine électrique ou électromagnétique perturbant les Réseaux, les attentats, les restrictions
          d’origine légale et plus généralement tout événement répondant aux caractéristiques de l’article 1218 du Code
          civil.
        </p>

        <h3>6.2</h3>
        <p>
          En pareil cas, le Contrat sera suspendu le temps de l’événement entrainant la suspension des obligations de
          chacune des Parties. Celles-ci s’efforcent de tout mettre en œuvre pour que la suspension soit la plus courte
          possible.
        </p>
        <p>
          En cas de suspension excédant 1 mois, chacune des Parties aura la possibilité d’invoquer une résolution de plein-
          droit du contrat conformément à l’article 1225 du Code civil. La Partie invoquant la résolution du Contrat doit en
          aviser l’autre par l’envoi d’une lettre recommandé avec accusé de réception afin de conférer date certaine à la
          notification.
        </p>
        <p>
          Les Parties sont alors libérées de leurs engagements réciproques, sans indemnisation possible. Cette faculté doit
          être utilisée de manière raisonnable. La résiliation invoquée de manière abusive peut donner lieu à une
          indemnité pour résiliation anticipée.
        </p>

        <h3>6.3</h3>
        <p>
          Conformément à l’article 1195 du Code civil, si un changement de circonstances imprévisibles lors de la conclusion
          du Contrat rend l’exécution de ce dernier excessivement onéreuse pour
        </p>

        <h2>ARTICLE 7 - NON VALIDITE PARTIELLE</h2>
        <p>
          En cas de remise en cause de l’une au moins des présentes stipulations ou des stipulations des autres documents
          contractuels précédemment énoncés, les autres stipulations garderont toute leur force et leur portée.
        </p>

        <h2>ARTICLE 8 - DROIT APPLICABLE ET ATTRIBUTION DE JURIDICTION</h2>
        <p>
          Le Contrat est régi par le droit français et interprété conformément à celui-ci. Tout différend relatif à la formation,
          l’exécution ou l’interprétation du Contrat entrainera une tentative de résolution à l’amiable entre les parties.
        </p>
        <p>
          Conformément à l’article L616-1 du Code de la consommation, les Parties ne parviennent pas à s’entendre, le Client
          a la possibilité de recourir à un médiateur : <a href="https://www.mediation-telecom.org/saisir-le-mediateur">https://www.mediation-telecom.org/saisir-le-mediateur</a>
        </p>
        <p>
          En outre, les Parties ont la possibilité de saisir la plateforme européenne de règlement en ligne des litiges (RLL) :
          <a href="https://entreprendre.service-public.fr/vosdroits/R48100">https://entreprendre.service-public.fr/vosdroits/R48100</a>
        </p>
        <p>
          En cas d’échec, le différend sera soumis à  <strong>la compétence exclusive du tribunal de commerce de Bordeaux.</strong>
        </p>
      </div>
    </div>
    </>
  );
};

export default CGU;
